import * as React from 'react'

import { isFunction, isEmpty } from 'lodash'

import { Intent } from '@blueprintjs/core'
import classes from 'classnames'

import { forms } from '../redux'
import { useTranslation } from '../hooks'

import Button from '../components/Button'

import styles from './FormContainer.module.scss'

type Props = forms.FormProps<any> & {
    buttons?: React.ReactNode
    className?: string
    header?: React.ReactNode
    footer?: React.ReactNode
    submitText?: string
    isDisabled?: boolean
    isCentered?: boolean
    isInline?: boolean
    isPadded?: boolean
    onCancel?: () => void
    onSubmit?: () => void
}

const FormContainer: React.FC<Props> = (props) => {
    const {
        buttons, header, footer, handleSubmit, resetForm,
        initialValues, onCancel, onSubmit, children, className,
        isPristine, isSubmitting, isDisabled, isCentered, isInline, isPadded
    } = props

    const [t] = useTranslation()

    const submitText = isEmpty(props.submitText)
        ? isPristine ? t('Changes Saved') : t('Save Changes')
        : props.submitText

    const performSubmit = isFunction(onSubmit)
        ? onSubmit : isFunction(handleSubmit) ? handleSubmit : null

    return (
        <div
            className={ classes(
                styles.container,
                isCentered && styles.centered,
                isInline && styles.inline,
                isPadded && styles.padded,
                className
            ) }
        >
            <div className={ styles.header }>
                { header }
            </div>
            <div
                className={
                    classes(
                        styles.content,
                        isDisabled && styles.disabled
                    )
                }
            >
                { children }
                { buttons !== false && !buttons && (
                    <div className={ styles.buttons }>
                        { isFunction(performSubmit) && (
                            <Button
                                text={ submitText }
                                onClick={ performSubmit }
                                intent={ Intent.PRIMARY }
                                loading={ isSubmitting }
                                type="submit"
                                disabled={ isPristine || isSubmitting }
                            />
                        ) }
                        {
                            isFunction(onCancel)
                                ? (
                                    <Button
                                        text={ t('Cancel') }
                                        onClick={ onCancel }
                                        minimal
                                    />
                                ) : (
                                    !isPristine ? (
                                        <Button
                                            text={ t('Discard Changes') }
                                            onClick={ () => resetForm({ values: initialValues || {} }) }
                                            minimal
                                        />
                                    ) : null
                                )
                        }
                    </div>
                ) }
                { buttons !== false && buttons }
                { isDisabled && (
                    <div className={ styles.disabledOverlay } />
                ) }
            </div>
            { footer && (
                <div className={ styles.footer }>
                    { footer }
                </div>
            ) }
        </div>
    )
}

FormContainer.defaultProps = {
    isCentered : false,
    isInline   : false,
    isDisabled : false
}

export default FormContainer
