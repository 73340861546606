import * as React from 'react'
import { Tab, Tabs, Tooltip, Classes } from '@blueprintjs/core'
import { Box } from 'reflexbox'
import classes from 'classnames'

import { map, head, uniq, max } from 'lodash'

import { sites, conditions, routing, ui, navigation } from '../redux'
import { useTranslation } from '../hooks'

import Icon, { IconName } from '../components/Icon'
import TitleBar from '../components/TitleBar'
import Button from '../components/Button'
import ConditionsList from '../components/ConditionsList'

import styles from './SiteComponentsStatusTabs.module.scss'

type Props = {
    entry: sites.ISite
}

const GROUP_ICON: Record<sites.ComponentGroupName, IconName> = {
    [sites.ComponentGroupName.database]   : 'database',
    [sites.ComponentGroupName.wordpress]  : 'wordpress',
    [sites.ComponentGroupName.routing]    : 'globe',
    [sites.ComponentGroupName.storage]    : 'drive',
    [sites.ComponentGroupName.cache]      : 'cache',
    [sites.ComponentGroupName.monitoring] : 'chart-line'
}

const SiteComponentsStatusTabs: React.FC<Props> = (props) => {
    const { entry } = props
    const [t] = useTranslation()

    const groupedConditions = sites.getGroupedConditions(entry)

    const erredCondition = head(conditions.getErredConditions(entry?.status?.componentStatus))
    const selectedTab = (erredCondition
        ? sites.getGroupForCondition(erredCondition)
        : sites.ComponentGroupName.wordpress) as sites.ComponentGroupName

    return (
        <Tabs id="status-tabs" className={ styles.tabs } defaultSelectedTabId={ selectedTab }>
            { map(groupedConditions, (conditionsList: conditions.ICondition[], group: sites.ComponentGroupName) => {
                const groupSeverity = max(uniq(map(conditionsList, 'severity')))
                const groupIntent = conditions.severityIntent(groupSeverity)
                return (
                    <Tab
                        id={ group }
                        key={ group }
                        title={ (
                            <>
                                <div className={ classes(styles.indicator, Classes.intentClass(groupIntent)) } />
                                <Tooltip content={ t(group) }>
                                    <Icon name={ GROUP_ICON[group] } size={ 20 } />
                                </Tooltip>
                            </>
                        ) }
                        panel={ (
                            <>
                                <TitleBar
                                    title={ t(group) }
                                    rightItem={ <ConfigButton entry={ entry } group={ group } /> }
                                />
                                <Box mt={ 2 }>
                                    <ConditionsList
                                        items={ conditionsList }
                                    />
                                </Box>
                            </>
                        ) }
                    />
                )
            }) }
        </Tabs>
    )
}

export const ConfigButton: React.FC<{
    entry: sites.ISite
    group: sites.ComponentGroupName
}> = (props) => {
    const { entry, group } = props
    const [t] = useTranslation()
    switch (group) {
        case sites.ComponentGroupName.database: {
            return (
                <Tooltip content={ t(ui.createTitle(navigation.MenuEntry.settings)) }>
                    <Button
                        icon="tune"
                        href={
                            routing.routeForResource(
                                { name: entry.mysqlCluster || '' },
                                { action: navigation.MenuEntry.resources }
                            )
                        }
                        minimal
                        isSquare
                    />
                </Tooltip>
            )
        }

        case sites.ComponentGroupName.cache: {
            return (
                <Tooltip content={ t(ui.createTitle(navigation.MenuEntry.settings)) }>
                    <Button
                        icon="tune"
                        href={
                            routing.routeForResource(
                                { name: entry.memcached || '' },
                                { action: navigation.MenuEntry.resources }
                            )
                        }
                        minimal
                        isSquare
                    />
                </Tooltip>
            )
        }

        default:
            return null
    }
}

export default SiteComponentsStatusTabs
