import { Intent } from '@blueprintjs/core'

import { get, reject, uniqBy } from 'lodash'

import { bitpoke } from '@bitpoke/bitpoke-proto'

import { statuses } from '../redux'
import { Maybe } from '../utils'

const {
    Condition
} = bitpoke.conditions.v1

const {
    Severity
} = Condition

export {
    Condition,
    Severity
}

export function severityIntent(severity: Maybe<Severity>): Intent {
    if (!severity) {
        return Intent.NONE
    }

    const mapping: Record<Severity, Intent> = {
        [Severity.UNSPECIFIED] : Intent.NONE,
        [Severity.NEUTRAL]     : Intent.NONE,
        [Severity.WARNING]     : Intent.WARNING,
        [Severity.OK]          : Intent.SUCCESS,
        [Severity.ERROR]       : Intent.DANGER
    }

    return get(mapping, severity, Intent.NONE)
}

export function getErredConditions(entry: Maybe<statuses.IComponentStatus>): ICondition[] {
    const statusConditions: ICondition[] = entry?.conditions ?? []
    const nonOkConditions = reject(statusConditions, { severity: Severity.OK })
    return uniqBy(nonOkConditions, 'message')
}

export type Condition  = bitpoke.conditions.v1.Condition
export type ICondition = bitpoke.conditions.v1.ICondition
export type Severity   = bitpoke.conditions.v1.Condition.Severity
