import { fork, takeEvery } from 'redux-saga/effects'
import { createSelector } from 'reselect'

import { find, pick, keys, startsWith } from 'lodash'

import { bitpoke } from '@bitpoke/bitpoke-proto'

import {
    RootState, Selector, SelectorCreator, AnyAction,
    api, grpc, forms, projects, ui, routing
} from '../redux'
import { Maybe } from '../utils'

const {
    Prometheus,
    ResourceAllocation,
    PrometheusService,
    ListPrometheusesRequest,
    ListPrometheusesResponse,
    GetPrometheusRequest,
    UpdatePrometheusRequest,
    UpdatePrometheusResourcesRequest
} = bitpoke.prometheus.v1

export {
    Prometheus,
    ResourceAllocation,
    PrometheusService,
    ListPrometheusesRequest,
    ListPrometheusesResponse,
    GetPrometheusRequest,
    UpdatePrometheusRequest,
    UpdatePrometheusResourcesRequest
}


//
//  TYPES

export type PrometheusName = string
export interface IPrometheus extends bitpoke.prometheus.v1.IPrometheus {
    name: PrometheusName
}
export type Prometheus                        = bitpoke.prometheus.v1.Prometheus
export type ResourceAllocation                = bitpoke.prometheus.v1.ResourceAllocation
export type IResourceAllocation               = bitpoke.prometheus.v1.IResourceAllocation
export type IListPrometheusesRequest          = bitpoke.prometheus.v1.IListPrometheusesRequest
export type IListPrometheusesResponse         = bitpoke.prometheus.v1.IListPrometheusesResponse
export type GetPrometheusRequest              = bitpoke.prometheus.v1.GetPrometheusRequest
export type IGetPrometheusRequest             = bitpoke.prometheus.v1.IGetPrometheusRequest
export type UpdatePrometheusRequest           = bitpoke.prometheus.v1.UpdatePrometheusRequest
export type IUpdatePrometheusRequest          = bitpoke.prometheus.v1.IUpdatePrometheusRequest
export type UpdatePrometheusResourcesRequest  =
    bitpoke.prometheus.v1.UpdatePrometheusResourcesRequest
export type IUpdatePrometheusResourcesRequest =
    bitpoke.prometheus.v1.IUpdatePrometheusResourcesRequest

export type State = api.State<IPrometheus>

export enum FieldName {
    backupsCron = 'MySQL Backup Frequency',
    backupsRetainCount = 'Number of MySQL backups to keep',
    scaling = 'Pod allocation',
    desiredStorageSizePerPod = 'MySQL Cluster storage size',
    storageClass = 'MySQL Cluster storage type'
}

export enum FieldDescription {
    backupsCron = 'The time entered here should be in UTC timezone',
    backupsRetainCount = '0 = keep all',
    storageClassHDD = 'It uses a standard storage type backed by hard disks',
    storageClassSSD = 'It uses a faster storage type backed by SSD'
}

export enum ResourceType {
    cpuPerPod        = 'cpuPerPod',
    memoryPerPod     = 'memoryPerPod',
    storageSize      = 'storageSize'
}

export enum ResourceName {
    cpuPerPod        = 'CPU / Pod',
    memoryPerPod     = 'Memory / Pod',
    storageSize      = 'Storage Size'
}


const service = PrometheusService.create(
    grpc.createTransport('bitpoke.prometheus.v1.PrometheusService')
)

export const { parseName, buildName } = api.createNameHelpers(api.Resource.prometheus)

export const DEFAULT_STORAGE_SIZE = '8Gi'

//
//  ACTIONS

export const get = (payload: IGetPrometheusRequest) => grpc.invoke({
    service,
    method       : 'getPrometheus',
    data         : GetPrometheusRequest.create(payload),
    responseType : Prometheus
})

export const list = (payload?: IListPrometheusesRequest) => grpc.invoke({
    service,
    method       : 'listPrometheuses',
    data         : ListPrometheusesRequest.create(payload),
    responseType : ListPrometheusesResponse
})

export const update = (payload: IUpdatePrometheusRequest) => grpc.invoke({
    service,
    method       : 'updatePrometheus',
    data         : UpdatePrometheusRequest.create(payload),
    responseType : Prometheus
})

export const updateResources = (payload: IUpdatePrometheusResourcesRequest) => grpc.invoke({
    service,
    method       : 'updatePrometheusResources',
    data         : UpdatePrometheusResourcesRequest.create(payload),
    responseType : Prometheus
})

const apiTypes = api.createActionTypes(api.Resource.prometheus)

export const {
    GET_REQUESTED,
    GET_SUCCEEDED,
    GET_FAILED,

    UPDATE_REQUESTED,
    UPDATE_SUCCEEDED,
    UPDATE_FAILED
} = apiTypes

//
//  REDUCER

const apiReducer = api.createReducer(api.Resource.prometheus, apiTypes)

export function reducer(state: State = api.initialState, action: AnyAction) {
    return apiReducer(state, action)
}


//
//  SAGA

export function* saga() {
    yield fork(api.emitResourceActions, api.Resource.prometheus, apiTypes)
    yield fork(forms.takeEverySubmission, keys(FORM_HANDLERS) as forms.Name[], handleFormSubmission)

    yield takeEvery([
        UPDATE_SUCCEEDED, UPDATE_FAILED
    ], ui.notifyAboutAction)
}

const FORM_HANDLERS: forms.HandlerMapping = {
    [forms.Name.updatePrometheus]          : update,
    [forms.Name.updatePrometheusResources] : updateResources
}

// const FORM_HANDLERS: forms.HandlerMapping = {
//     [forms.Name.updatePrometheus]: update
// }

const handleFormSubmission = forms.createHandlers(FORM_HANDLERS, apiTypes)

export function isPrometheus(entry: any): entry is IPrometheus {
    return api.isOfType(api.Resource.prometheus, entry)
}


//
//  SELECTORS

const selectors: api.Selectors<IPrometheus> = api.createSelectors(api.Resource.prometheus)
export const { getState, getAll, countAll, getByName, getForURL, getForCurrentURL } = selectors

export const getForProject: SelectorCreator<projects.ProjectName, Maybe<IPrometheus>> = (
    project: projects.ProjectName
) => createSelector(
    getAll,
    (clusters) => find(clusters, ({ name }) => startsWith(name, project))
)
export const isNotFound: Selector<boolean> = createSelector(
    [routing.getCurrentRoute, (state: RootState) => pick(state, 'grpc')],
    (currentRoute: routing.Route, state) => {
        const parsedName = parseName(currentRoute.url)
        if (!parsedName.name) {
            return false
        }

        const request: grpc.Request = get({ name: parsedName.name }).payload
        return grpc.isFailedRequest(request)(state as RootState)
    }
)
