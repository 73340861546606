import * as React from 'react'
import { connect } from 'react-redux'
import { MenuItem } from '@blueprintjs/core'
import { Select as BaseSelect } from '@blueprintjs/select'

import { filter, isFunction } from 'lodash'

import { useTranslation } from '../hooks'
import { RootState, nodes } from '../redux'
import { matchesQuery } from '../utils'

import Button from '../components/Button'
import NodeCounters from '../components/NodeCounters'

import styles from './NodeLabelSelect.module.scss'

type ReduxProps = {
    labels: nodes.Label[]
}

type Props = {
    onChange: (item: nodes.Label) => void
    selectedItem?: nodes.Label
    emptyValueText?: string
    itemPredicate?: (item: nodes.Label) => boolean
    trigger?: React.ReactNode
} & ReduxProps

const Select = BaseSelect.ofType<nodes.Label>()

const NodeLabelSelect: React.FC<Props> = (props) => {
    const { itemPredicate, selectedItem, trigger, emptyValueText, onChange } = props
    const [t] = useTranslation()
    const labels = isFunction(itemPredicate)
        ? filter(props.labels, itemPredicate)
        : props.labels

    return (
        <Select
            popoverProps={ { portalClassName: styles.menu, minimal: true } }
            items={ labels }
            onItemSelect={ onChange }
            itemListPredicate={ (query: string, items: nodes.Label[]) => (
                filter(items, (item) => (
                    matchesQuery(nodes.labelDisplayName(item), query) || matchesQuery(item.value, query)
                ))
            ) }
            itemRenderer={ (item: nodes.Label, { handleClick }) => {
                const { key } = item
                return (
                    <MenuItem
                        key={ key }
                        onClick={ handleClick }
                        active={ selectedItem === item }
                        text={ nodes.labelDisplayName(item) }
                        labelElement={ (
                            <NodeCounters label={ item } />
                        ) }
                    />
                )
            } }
        >
            { trigger || (
                <Button rightIcon="caret-down" isBordered>
                    {
                        selectedItem
                            ? nodes.labelDisplayName(selectedItem)
                            : emptyValueText || t('Label')
                    }
                </Button>
            ) }
        </Select>
    )
}

function mapStateToProps(state: RootState): ReduxProps {
    return {
        labels: nodes.getAllLabels(state)
    }
}

export default connect(mapStateToProps)(NodeLabelSelect)
