import * as React from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { Flex, Box } from 'reflexbox'

import { pick } from 'lodash'

import {
    RootState, DispatchProp, routing, grafanas,
    projects, organizations, api, navigation, errors
} from '../redux'
import { Maybe } from '../utils'

import PageMeta from '../components/PageMeta'
import MainContainer from '../components/MainContainer'
import MainMenu from '../components/MainMenu'
import ErrorMessage from '../components/ErrorMessage'
import Container from '../components/Container'
import GrafanaResourcesForm from '../components/GrafanaResourcesForm'
import GrafanaSchedulingForm from '../components/GrafanaSchedulingForm'
import GrafanaSchedulingStatus from '../components/GrafanaSchedulingStatus'
import ResourcePods from '../components/ResourcePods'

type ReduxProps = {
    grafana: Maybe<grafanas.IGrafana>
    project: Maybe<projects.IProject>
    activeMenuEntry: Maybe<navigation.MenuEntry>
    organization: Maybe<organizations.OrganizationName>
    isNotFound: boolean
}

type Props = ReduxProps & DispatchProp

const GrafanasContainer: React.FC<Props> = (props) => {
    const { grafana, project, activeMenuEntry, organization, isNotFound } = props

    if (project && !grafana) {
        return (
            <>
                <MainMenu
                    resource={ api.Resource.project }
                    entry={ project }
                />
                <MainContainer>
                    { isNotFound && <ErrorMessage error={ errors.Error.notFound } /> }
                </MainContainer>
            </>
        )
    }

    if (!project || !organization || !grafana) {
        return <MainContainer />
    }

    return (
        <>
            <PageMeta
                entry={ grafana }
                section={ activeMenuEntry }
            />
            <MainMenu
                resource={ api.Resource.grafana }
                entry={ grafana }
            />
            <MainContainer>
                <Switch>
                    <Route
                        key={ navigation.MenuEntry.scheduling }
                        path={ routing.routeForResource(grafana, { action: navigation.MenuEntry.scheduling }) }
                        render={ () => (
                            <Container>
                                <Flex column>
                                    <Box>
                                        <GrafanaSchedulingForm
                                            initialValues={ { grafana } }
                                        />
                                    </Box>
                                    <Box mt={ 50 }>
                                        <GrafanaSchedulingStatus
                                            entry={ grafana }
                                        />
                                    </Box>
                                </Flex>
                            </Container>
                        ) }
                    />
                    <Route
                        key={ navigation.MenuEntry.resources }
                        path={ routing.routeForResource(grafana, { action: navigation.MenuEntry.resources }) }
                        render={ () => (
                            <Container>
                                <GrafanaResourcesForm
                                    initialValues={ pick(grafana, ['name', 'resources']) }
                                />
                            </Container>
                        ) }
                    />
                    <Route
                        key={ navigation.MenuEntry.pods }
                        path={ routing.routeForResource(grafana, { action: navigation.MenuEntry.pods }) }
                        render={ () => (
                            <ResourcePods
                                entry={ grafana }
                            />
                        ) }
                    />
                    <Route
                        path={ routing.routeForResource(grafana) }
                        render={ () => (
                            <div />
                        ) }
                    />
                </Switch>
            </MainContainer>
        </>
    )
}

function mapStateToProps(state: RootState): ReduxProps {
    const project = projects.getForCurrentURL(state)
    const grafana = grafanas.getForCurrentURL(state)
    const isNotFound = grafanas.isNotFound(state)
    const activeMenuEntry = navigation.getActiveMenuEntry(state)
    const organization = organizations.getCurrentName(state)

    return {
        project,
        grafana,
        activeMenuEntry,
        organization,
        isNotFound
    }
}

export default connect(mapStateToProps)(GrafanasContainer)
