/* eslint-disable no-use-before-define */

import { ActionType } from 'typesafe-actions'
import { fork, put, takeEvery } from 'redux-saga/effects'

import { bitpoke, google } from '@bitpoke/bitpoke-proto'

import { isString, isEmpty } from 'lodash'

import { AnyAction, grpc, api, auth, ui } from '../redux'
import { Maybe, gravatarURL } from '../utils'

const {
    AccountBinding,
    AccountBindingsService,
    ListAccountBindingsRequest,
    ListAccountBindingsResponse,
    GetAccountBindingRequest,
    DeleteAccountBindingRequest
} = bitpoke.accountbindings.v1

export {
    AccountBinding,
    AccountBindingsService,
    ListAccountBindingsRequest,
    ListAccountBindingsResponse,
    GetAccountBindingRequest,
    DeleteAccountBindingRequest
}


//
//  TYPES

export type AccountBindingName = string
export interface IAccountBinding extends bitpoke.accountbindings.v1.IAccountBinding {
    name: AccountBindingName
}
export type AccountBinding               = bitpoke.accountbindings.v1.AccountBinding
export type IAccountBindingPayload       = bitpoke.accountbindings.v1.IAccountBinding
export type ListAccountBindingsResponse  = bitpoke.accountbindings.v1.ListAccountBindingsRequest
export type IListAccountBindingsRequest  = bitpoke.accountbindings.v1.IListAccountBindingsRequest
export type IGetAccountBindingRequest    = bitpoke.accountbindings.v1.IGetAccountBindingRequest
export type IDeleteAccountBindingRequest = bitpoke.accountbindings.v1.IDeleteAccountBindingRequest
export type IListAccountBindingsResponse = bitpoke.accountbindings.v1.IListAccountBindingsResponse

export type State = api.State<IAccountBinding>
export type Actions = ActionType<typeof actions>

const service = AccountBindingsService.create(
    grpc.createTransport('bitpoke.accountbindings.v1.AccountBindingsService')
)

export const { parseName, buildName } = api.createNameHelpers(api.Resource.accountBinding)


//
//  ACTIONS

export const get = (payload: IGetAccountBindingRequest) => grpc.invoke({
    service,
    method       : 'getAccountBinding',
    data         : GetAccountBindingRequest.create(payload),
    responseType : AccountBinding
})

export const list = (payload?: IListAccountBindingsRequest) => grpc.invoke({
    service,
    method       : 'listAccountBindings',
    data         : ListAccountBindingsRequest.create(payload),
    responseType : ListAccountBindingsResponse
})

export const destroy = (payload: IAccountBindingPayload) => grpc.invoke({
    service,
    method : 'deleteAccountBinding',
    data   : DeleteAccountBindingRequest.create(payload)
})

export const ping = () => grpc.invoke({
    service,
    method : 'sendAuthenticatedPing',
    data   : google.protobuf.Empty
})

const actions = {
    get,
    list,
    destroy
}

const apiTypes = api.createActionTypes(api.Resource.accountBinding)

export const {
    LIST_REQUESTED,    LIST_SUCCEEDED,    LIST_FAILED,
    GET_REQUESTED,     GET_SUCCEEDED,     GET_FAILED,
    DESTROY_REQUESTED, DESTROY_SUCCEEDED, DESTROY_FAILED
} = apiTypes


//
//  REDUCER

const apiReducer = api.createReducer(api.Resource.accountBinding, apiTypes)

export function reducer(state: State = api.initialState, action: AnyAction) {
    return apiReducer(state, action)
}


//
//  SAGA

export function* saga() {
    yield fork(api.emitResourceActions, api.Resource.accountBinding, apiTypes)
    yield takeEvery(auth.ACCESS_GRANTED, triggerPing)
    yield takeEvery([
        DESTROY_SUCCEEDED, DESTROY_FAILED
    ], ui.notifyAboutAction)
}

function* triggerPing() {
    yield put(ping())
}

export function isAccountBinding(entry: Maybe<api.AnyResourceInstance>): entry is IAccountBinding {
    return api.isOfType(api.Resource.accountBinding, entry)
}

export function avatarURL(entry: IAccountBinding) {
    return isString(entry.email) && !isEmpty(entry.email) && isEmpty(entry.profilePictureUrl)
        ? gravatarURL(entry.email)
        : entry.profilePictureUrl
}

//
//  SELECTORS

const selectors: api.Selectors<IAccountBinding> = api.createSelectors(api.Resource.accountBinding)
export const {
    getState,
    getAll,
    countAll,
    getByName,
    getForURL,
    getForCurrentURL,
    getForOrganization,
    getForCurrentOrganization
} = selectors
