import * as React from 'react'
import { connect } from 'react-redux'
import { Menu, MenuDivider } from '@blueprintjs/core'

import { map } from 'lodash'

import {
    RootState, DispatchProp, api, projects, organizations, navigation
} from '../redux'
import { Maybe, arePropsEqual } from '../utils'

import SideBar from '../components/SideBar'
import ResourceMenuItem from '../components/ResourceMenuItem'

type Resource = api.Resource
type OwnProps = {
    resource: Resource
    entry?: api.AnyResourceInstance | null
}
type ReduxProps = {
    currentProject: Maybe<projects.IProject>
    currentOrganization: Maybe<organizations.IOrganization>
    activeMenuEntry: Maybe<navigation.MenuEntry>
}

type Props = OwnProps & ReduxProps & DispatchProp

const MainMenu: React.FC<Props> = React.memo((props) => {
    const { entry, resource, activeMenuEntry } = props

    if (!entry) {
        return null
    }

    const { Fragment } = React

    const menuEntries = navigation.getMainMenuForResource(resource)

    return (
        <SideBar>
            <Menu>
                { map(navigation.MenuSection, (section: navigation.MenuSection) => {
                    if (!menuEntries[section]) {
                        return null
                    }
                    const entries = menuEntries[section]
                    const hasHeader = section && section !== navigation.MenuSection.topLevel

                    return (
                        <Fragment key={ section }>
                            { hasHeader && (
                                <MenuDivider title={ section } />
                            ) }
                            { map(entries, (action, index) => {
                                const isActive = action === activeMenuEntry
                                const href = navigation.routeForMenuEntry(action, entry)

                                return (
                                    <ResourceMenuItem
                                        key={ action }
                                        action={ action as navigation.MenuEntry }
                                        href={ href ?? undefined }
                                        isActive={ isActive }
                                        isButton={ false }
                                        { ...props }
                                    />
                                )
                            }) }
                        </Fragment>
                    )
                }) }
            </Menu>
        </SideBar>
    )
}, arePropsEqual(['entry', 'currentOrganization', 'currentProject', 'activeMenuEntry']))

function mapStateToProps(state: RootState) {
    const currentProject = projects.getForCurrentURL(state)
    const currentOrganization = organizations.getCurrent(state)
    const activeMenuEntry = navigation.getActiveMenuEntry(state)

    return {
        currentProject,
        currentOrganization,
        activeMenuEntry
    }
}

export default connect(mapStateToProps)(MainMenu)
