import * as React from 'react'
import { Alert, Intent, Classes, IButtonProps } from '@blueprintjs/core'

import Icon, { IconName } from '../components/Icon'
import Button from '../components/Button'

import { useState, useTranslation } from '../hooks'

type OwnProps = {
    confirmationText: React.ReactNode | string
    confirmationIcon?: IconName
    onConfirm: () => void
    button?: React.ReactNode
}

type Props = OwnProps & IButtonProps

const ConfirmButton: React.FC<Props> = (props) => {
    const { text, button, confirmationText, confirmationIcon, minimal, onConfirm } = props
    const [isConfirming, setConfirming] = useState(false)
    const [t] = useTranslation()

    const onClick = (e: React.SyntheticEvent<HTMLElement>) => {
        e.stopPropagation()
        setConfirming(true)
    }

    return (
        <>
            { React.isValidElement(button)
                ? React.cloneElement(button, { ...button.props, onClick })
                : (
                    <Button
                        text={ text }
                        minimal={ minimal }
                        icon="bin-full"
                        intent={ Intent.DANGER }
                        onClick={ onClick }
                    />
                ) }
            { isConfirming && (
                <Alert
                    icon={ <Icon name={ confirmationIcon || 'bin-full' } size={ 32 } /> }
                    intent={ Intent.DANGER }
                    isOpen={ isConfirming }
                    onConfirm={ (e: React.SyntheticEvent<HTMLElement>) => {
                        setConfirming(false)
                        onConfirm()
                    } }
                    onCancel={ (e: React.SyntheticEvent<HTMLElement>) => {
                        setConfirming(false)
                    } }
                    cancelButtonText={ t('Cancel') }
                    canEscapeKeyCancel
                    canOutsideClickCancel
                    className={ Classes.POPOVER_DISMISS }
                >
                    { confirmationText }
                </Alert>
            ) }
        </>
    )
}

export default ConfirmButton
