import * as React from 'react'
import { connect } from 'react-redux'

import { map, compact, head, isString } from 'lodash'

import {
    RootState, api, navigation, routing, pods, sites, projects,
    mysqlClusters, memcacheds, organizations, nodes
} from '../redux'
import { Maybe } from '../utils'

import PageMeta from '../components/PageMeta'
import MainMenu from '../components/MainMenu'
import MainContainer from '../components/MainContainer'
import PodsList from '../components/PodsList'

type ReduxProps = {
    pod: Maybe<pods.IPod>
    parent: Maybe<pods.Parent>
}

type Props = ReduxProps

const PARENT_SELECTORS = [
    memcacheds.getByName,
    mysqlClusters.getByName,
    sites.getByName,
    projects.getByName
]

const PodsContainer: React.FC<Props> = (props) => {
    const { parent } = props

    if (!parent) {
        return null
    }

    const listRequest = (projects.isProject(parent) && pods.list({ projects: [parent.name] }))
        || (organizations.isOrganization(parent) && pods.list({ organizations: [parent.name] }))
        || (nodes.isNode(parent) && pods.list({ nodes: [parent.name] }))
        || (parent && pods.list({ parents: [parent.name] }))

    if (!listRequest) {
        return null
    }

    return (
        <>
            <PageMeta
                entry={ parent }
                section={ navigation.MenuEntry.pods }
            />
            <MainMenu
                resource={ api.Resource.site }
                entry={ parent }
            />
            <MainContainer>
                <PodsList
                    parents={ [parent.name] }
                    itemsRequest={ listRequest }
                />
            </MainContainer>
        </>
    )
}

function mapStateToProps(state: RootState): ReduxProps {
    const pod = pods.getForCurrentURL(state)
    const parentName = routing.getParam('parent')(state)

    const parent = isString(parentName)
        ? head(compact(map(PARENT_SELECTORS, (selector) => selector(parentName)(state))))
        : null

    return {
        pod,
        parent
    }
}

export default connect(mapStateToProps)(PodsContainer)
