import * as React from 'react'
import { Field } from 'formik'

import { get, join, identity, size, isEmpty } from 'lodash'

import { forms, resources } from '../redux'
import { useTranslation } from '../hooks'
import { parseQuantity, formatQuantity } from '../utils'

import SliderField, { Scale, Value } from '../components/SliderField'

type Props = forms.FormProps<any> & {
    resource: resources.ResourceType
}

const STEPS: Record<resources.ResourceType, number> = {
    [resources.ResourceType.cpuPerPod]    : size(resources.RESOURCE_SCALES.cpu.default),
    [resources.ResourceType.memoryPerPod] : size(resources.RESOURCE_SCALES.memory.large)
}

const SCALES: Record<resources.ResourceType, Scale> = {
    [resources.ResourceType.cpuPerPod]    : (step: number) => resources.RESOURCE_SCALES.cpu.default[step - 1],
    [resources.ResourceType.memoryPerPod] : (step: number) => resources.RESOURCE_SCALES.memory.large[step - 1]
}

const shouldSetLimit = (resource: resources.ResourceType) => (
    resource !== resources.ResourceType.cpuPerPod
)

const CommonResourceAllocationSliderField: React.FC<Props> = (props) => {
    const { initialValues, resource } = props
    const [t] = useTranslation()

    const name = join(['resources', resource], '.')
    const isDisabled = isEmpty(get(initialValues, name))

    const omitUnit = resource !== resources.ResourceType.memoryPerPod

    return (
        <Field
            label={ t(resources.ResourceName[resource]) }
            name={ name }
            scale={ SCALES[resource] || identity }
            steps={ STEPS[resource] }
            disabled={ isDisabled }
            component={ SliderField }
            labelRenderer={ (value: Value) => formatQuantity(value, { omitUnit }) }
            format={ (value: any, rawValue: resources.IResource) => {
                const quantity = formatQuantity(value)
                if (shouldSetLimit(resource)) {
                    return {
                        ...rawValue,
                        requested : quantity,
                        limit     : quantity
                    }
                }
                return {
                    ...rawValue,
                    requested: quantity
                }
            } }
            parse={ (rawValue: resources.IResource) => {
                if (!rawValue || !rawValue.requested) {
                    return 0
                }

                return parseQuantity(rawValue.requested)
            } }
        />
    )
}

export default CommonResourceAllocationSliderField
