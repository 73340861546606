import * as React from 'react'

import { api, invites, ui } from '../redux'

import ResourceTitle, { Props as BaseProps } from '../components/ResourceTitle'
import Avatar from '../components/Avatar'

type Props = Omit<BaseProps, 'resource'>

const InviteTitle: React.FC<Props> = (props) => {
    const { entry } = props

    if (!invites.isInvite(entry)) {
        return null
    }

    return (
        <ResourceTitle
            resource={ api.Resource.invite }
            avatar={ <Avatar name={ api.displayName(entry) } imageURL={ ui.avatarURL(entry) } isRound /> }
            title={ entry.email }
            { ...props }
        />
    )
}

export default InviteTitle
