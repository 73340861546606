import * as React from 'react'

import { pick } from 'lodash'

import { projects } from '../redux'
import { useInterval, useDispatch } from '../hooks'
import { POLL_INTERVAL } from '../utils'

import ProjectQuotasForm from '../components/ProjectQuotasForm'

type OwnProps = {
    entry: projects.IProject
}

type Props = OwnProps

const ProjectQuotas: React.FC<Props> = (props) => {
    const { entry } = props
    const dispatch = useDispatch()

    useInterval(() => {
        dispatch(projects.get({ name: entry.name }))
    }, POLL_INTERVAL.slow)

    return (
        <ProjectQuotasForm
            initialValues={ pick(entry, ['name', 'resourceQuotas']) }
        />
    )
}

export default ProjectQuotas
