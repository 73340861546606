import * as React from 'react'
import { Flex, Box } from 'reflexbox'

import { resources, forms, mysqlClusters } from '../redux'
import { useTranslation } from '../hooks'

import { withForm } from '../components/Form'
import FormContainer from '../components/FormContainer'
import TitleBar from '../components/TitleBar'
import CommonScalingSliderField from '../components/CommonScalingSliderField'
import CommonResourceAllocationSliderField from '../components/CommonResourceAllocationSliderField'
import TotalResourceUsageLabel from '../components/TotalResourceUsageLabel'

type Props = forms.FormProps<mysqlClusters.IMySQLCluster>

const MySQLClusterResourcesForm: React.FC<Props> = (props) => {
    const { values } = props
    const [t] = useTranslation()

    return (
        <FormContainer
            { ...props }
        >
            <Flex column>
                <TitleBar
                    title={ t('Database Scaling Settings') }
                    subtitle={ t('Adjust the number of pods used to scale the database') }
                />
                <Flex>
                    <Box w={ 0.5 } mr={ 30 }>
                        <CommonScalingSliderField { ...props } />
                    </Box>
                    <Box w={ 0.5 }>
                        <TotalResourceUsageLabel entry={ values } />
                    </Box>
                </Flex>
                <TitleBar
                    title={ t('Pod Resource Allocation Settings') }
                    subtitle={ t('Configure resource allocation for each of the database replica') }
                    isSmall
                />
                <Flex justify="space-between">
                    <Box w={ 0.5 } mr={ 30 }>
                        <CommonResourceAllocationSliderField
                            resource={ resources.ResourceType.cpuPerPod }
                            { ...props }
                        />
                    </Box>
                    <Box w={ 0.5 }>
                        <CommonResourceAllocationSliderField
                            resource={ resources.ResourceType.memoryPerPod }
                            { ...props }
                        />
                    </Box>
                </Flex>
            </Flex>
        </FormContainer>
    )
}

export default withForm({ name: forms.Name.updateMySQLClusterResources })(MySQLClusterResourcesForm)
