import * as React from 'react'
import { InputGroup, Intent } from '@blueprintjs/core'

import { toString } from 'lodash'

import FormGroup from '../components/FormGroup'
import { Maybe } from '../utils'

import styles from './Input.module.scss'

export type Props = {
    type?: 'text' | 'password'
    label?: string
    value: Maybe<string>
    disabled?: boolean
    readOnly?: boolean
    helperText?: React.ReactNode
    rightItem?: React.ReactNode
    intent?: Intent
    isMonospaced?: boolean
}

const Input: React.FC<Props> = (props) => {
    const { intent, label, helperText, rightItem, isMonospaced, ...inputProps } = props

    return (
        <FormGroup
            label={ label }
            intent={ intent }
            helperText={ React.isValidElement(helperText) ? helperText : undefined }
        >
            <InputGroup
                { ...inputProps }
                id={ label }
                value={ toString(props.value) }
                className={ isMonospaced ? styles.monospaced : undefined }
                rightElement={ React.isValidElement(rightItem) ? rightItem : undefined }
                large
            />
        </FormGroup>
    )
}

export default Input
