import * as React from 'react'

import { Tooltip } from '@blueprintjs/core'

import { api, nodes } from '../redux'
import ResourceTitle, { Props as BaseProps } from '../components/ResourceTitle'

type Props = Omit<BaseProps, 'resource'>

const NodeTitle: React.FC<Props> = (props) => {
    const { entry } = props
    if (!entry) {
        return null
    }

    return (
        <Tooltip content={ entry.name }>
            <ResourceTitle
                resource={ api.Resource.node }
                { ...props }
                title={ nodes.shortName(entry) }
                subtitle={ nodes.zone(entry) }
            />
        </Tooltip>
    )
}

export default NodeTitle
