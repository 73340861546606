import * as React from 'react'
import { ButtonGroup as BaseButtonGroup, IButtonGroupProps } from '@blueprintjs/core'
import { Box } from 'reflexbox'
import classes from 'classnames'

import styles from './ButtonGroup.module.scss'

export type Props = IButtonGroupProps

const ButtonGroup: React.FC<Props> = (props) => {
    const { className, ...otherProps } = props
    return (
        <Box>
            <BaseButtonGroup
                className={ classes(styles.group, className) }
                { ...otherProps }
            />
        </Box>
    )
}

export default ButtonGroup
