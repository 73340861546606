import * as React from 'react'
import { Intent } from '@blueprintjs/core'

import { get, toUpper, isEmpty } from 'lodash'

import { statuses, conditions } from '../redux'
import { useTranslation } from '../hooks'
import { Maybe, arePropsEqual, keyOfEnum } from '../utils'

import StatusTag from '../components/StatusTag'
import ConditionsList from '../components/ConditionsList'

type OwnProps = {
    entry?: Maybe<statuses.IComponentStatus>
    onClick?: () => void
    large?: boolean
    withIcon?: boolean
    minimal?: boolean
}

type Props = OwnProps

const STATUS_INTENT: Record<statuses.GeneralStatus, Intent> = {
    [statuses.GeneralStatus.UNSPECIFIED]  : Intent.NONE,
    [statuses.GeneralStatus.WARNING]      : Intent.WARNING,
    [statuses.GeneralStatus.PROVISIONING] : Intent.PRIMARY,
    [statuses.GeneralStatus.RUNNING]      : Intent.SUCCESS,
    [statuses.GeneralStatus.PAUSED]       : Intent.NONE,
    [statuses.GeneralStatus.ERROR]        : Intent.DANGER,
    [statuses.GeneralStatus.PENDING]      : Intent.PRIMARY,
    [statuses.GeneralStatus.SUCCEEDED]    : Intent.SUCCESS,
    [statuses.GeneralStatus.FAILED]       : Intent.DANGER,
    [statuses.GeneralStatus.EVICTED]      : Intent.WARNING,
    [statuses.GeneralStatus.TERMINATING]  : Intent.NONE
}

const ComponentStatusTag: React.FC<Props> = React.memo((props) => {
    const { entry, ...otherProps } = props
    const [t] = useTranslation()

    const status = get(entry, 'generalStatus')

    if (!entry || !status) {
        return null
    }

    const statusName = t(toUpper(keyOfEnum(statuses.GeneralStatus, status) as string))
    const statusIntent = get(STATUS_INTENT, status || '', Intent.NONE)
    const erredConditions = conditions.getErredConditions(entry)

    return (
        <StatusTag
            name={ statusName }
            intent={ statusIntent }
            tooltip={ !isEmpty(erredConditions) ? <ConditionsList items={ erredConditions } /> : undefined }
            { ...otherProps }
        />
    )
}, arePropsEqual('entry'))

export default ComponentStatusTag
