import * as React from 'react'
import { Field } from 'formik'
import { Flex, Box } from 'reflexbox'
import statusCodes, { getStatusText } from 'http-status-codes'

import { startCase, toLower, isNumber, isNaN, isEmpty } from 'lodash'

import { api, forms, sites, ui, navigation, errors } from '../redux'
import { useState, useDispatch, useInterval, useTranslation } from '../hooks'
import { POLL_INTERVAL, keyOfEnum } from '../utils'
import { T } from '../i18n'

import { withForm } from '../components/Form'
import TitleBar from '../components/TitleBar'
import Container from '../components/Container'
import FormContainer from '../components/FormContainer'
import ErrorMessage from '../components/ErrorMessage'
import InputField from '../components/InputField'
import Button from '../components/Button'
import Icon from '../components/Icon'
import SwitchField from '../components/SwitchField'
import MultiSelectField from '../components/MultiSelectField'
import ButtonGroupSelectField from '../components/ButtonGroupSelectField'

type Props = forms.FormProps<sites.ISite>

const SiteCachingForm: React.FC<Props> = (props) => {
    const { initialValues, values } = props
    const [t] = useTranslation()
    const dispatch = useDispatch()

    const isEditable = !isEmpty(initialValues.pageCache)
    const isEnabled = values?.pageCache?.enabled
    const isRedisBackend = values?.pageCache?.backend === sites.PageCacheBackend.REDIS

    const [showAdvanced, setShowAdvanced] = useState(false)

    useInterval(() => {
        if (!isEditable) {
            dispatch(sites.get({ name: initialValues.name }))
        }
    }, POLL_INTERVAL.default)

    if (!isEditable) {
        return <ErrorMessage error={ errors.Error.siteProvisioning } />
    }

    return (
        <Container>
            <FormContainer
                header={ (
                    <TitleBar
                        title={ t(ui.createTitle(api.Resource.site, navigation.MenuEntry.pageCaching)) }
                    />
                ) }
                { ...props }
            >
                <Flex w={ 0.5 } column>
                    <Field
                        name="pageCache.enabled"
                        label={ t(sites.PageCacheFieldName.enabled) }
                        component={ SwitchField }
                        autoFocus
                    />
                    { isEnabled && (
                        <>
                            <Field
                                name="pageCache.backend"
                                label={ t(sites.PageCacheFieldName.backend) }
                                component={ ButtonGroupSelectField }
                                items={ [sites.PageCacheBackend.MEMCACHED, sites.PageCacheBackend.REDIS] }
                                itemParser={ (value: sites.PageCacheBackend) => ({
                                    value,
                                    label: t(startCase(toLower(keyOfEnum(sites.PageCacheBackend, value) as string)))
                                }) }
                            />
                            { isRedisBackend && (
                                <>
                                    <Field
                                        name="pageCache.redisHost"
                                        label={ t(sites.PageCacheFieldName.redisHost) }
                                        component={ InputField }
                                    />
                                    <Field
                                        name="pageCache.redisPort"
                                        label={ t(sites.PageCacheFieldName.redisPort) }
                                        component={ InputField }
                                    />
                                </>
                            ) }
                            <Field
                                name="pageCache.keyIncludedQueryParams"
                                label={ t(sites.PageCacheFieldName.keyIncludedQueryParams) }
                                helperText={ t(sites.PageCacheFieldDescription.keyIncludedQueryParams) }
                                component={ MultiSelectField }
                                items={ values?.pageCache?.keyIncludedQueryParams }
                                placeholder={ t('Add query parameter') }
                                allowCreate
                            />
                            <Field
                                name="pageCache.keyDiscardedQueryParams"
                                label={ t(sites.PageCacheFieldName.keyDiscardedQueryParams) }
                                helperText={ t(sites.PageCacheFieldDescription.keyDiscardedQueryParams) }
                                component={ MultiSelectField }
                                items={ values?.pageCache?.keyDiscardedQueryParams }
                                placeholder={ t('Add query parameter') }
                                allowCreate
                            />
                            <Box mb={ 2 } ml={ -18 }>
                                <Button
                                    text={ showAdvanced ? t('Hide Advanced Settings') : t('Show Advanced Settings') }
                                    rightIcon={ <Icon size={ 22 } name={ showAdvanced ? 'care-n' : 'caret-s' } /> }
                                    onClick={ () => setShowAdvanced(!showAdvanced) }
                                    minimal
                                />
                            </Box>
                            { showAdvanced && (
                                <>
                                    <Field
                                        name="pageCache.storeStatuses"
                                        label={ t(sites.PageCacheFieldName.storeStatuses) }
                                        helperText={ t(sites.PageCacheFieldDescription.storeStatuses) }
                                        component={ MultiSelectField }
                                        items={ statusCodes }
                                        itemParser={ (statusCode: number) => {
                                            if (!isNumber(statusCode) || isNaN(statusCode)) {
                                                return null
                                            }
                                            try {
                                                const statusText = getStatusText(statusCode)
                                                return {
                                                    value : statusCode,
                                                    label : `${statusCode}: ${statusText}`
                                                }
                                            }
                                            catch (e) {
                                                return {
                                                    value : statusCode,
                                                    label : `${statusCode}: Unknown`
                                                }
                                            }
                                        } }
                                    />
                                    <Field
                                        name="pageCache.expireSeconds"
                                        label={ t(sites.PageCacheFieldName.expireSeconds) }
                                        helperText={ t(sites.PageCacheFieldDescription.expireSeconds) }
                                        component={ InputField }
                                    />
                                    <Field
                                        name="pageCache.responseCacheControl"
                                        label={ t(sites.PageCacheFieldName.responseCacheControl) }
                                        helperText={ (
                                            <T
                                                defaults={ sites.PageCacheFieldDescription.responseCacheControl }
                                                components={ [
                                                    <a href="https://www.bitpoke.io/docs/app-for-wordpress/reference/site/configuration/page-caching/#bypassing-the-page-cache" target="_blank" rel="noopener noreferrer">X</a> // eslint-disable-line max-len
                                                ] }
                                            />
                                        ) }
                                        component={ SwitchField }
                                    />
                                </>
                            ) }
                        </>
                    ) }
                </Flex>
            </FormContainer>
        </Container>
    )
}

export default withForm({ name: forms.Name.updateSitePageCache })(SiteCachingForm)
