import * as React from 'react'
import { Field } from 'formik'
import { Flex, Box } from 'reflexbox'
import { Intent } from '@blueprintjs/core'

import { get, parseInt, replace, isEmpty } from 'lodash'

import { forms, mysqlClusters } from '../redux'
import { useTranslation } from '../hooks'

import { withForm } from '../components/Form'
import Container from '../components/Container'
import FormContainer from '../components/FormContainer'
import CronField from '../components/CronField'
import TitleBar from '../components/TitleBar'
import MaskedInputField from '../components/MaskedInputField'

type Props = forms.FormProps<{ mysqlCluster: mysqlClusters.IMySQLCluster }>

const MySQLClusterBackupGeneralForm: React.FC<Props> = (props) => {
    const { initialValues, values } = props
    const [t] = useTranslation()

    const isDisabled = isEmpty(initialValues.mysqlCluster)

    const initialRetain = parseInt(get(initialValues, 'mysqlCluster.backupsRetainCount')) || 0
    const currentRetain = parseInt(get(values, 'mysqlCluster.backupsRetainCount')) || 0
    const backupsToBeDeleted = initialRetain - currentRetain
    const willDeleteBackups = backupsToBeDeleted > 0 && currentRetain > 0
    const backupDeletionWarning = t('Warning! Setting a lower backup retain count will'
        + ' result in the deletion of {{numberOfBackups}} backups!', { numberOfBackups: backupsToBeDeleted })

    return (
        <FormContainer
            header={ (
                <TitleBar
                    title={ t('Backup Settings') }
                    subtitle={ t('These changes affect all the sites in this project') }
                    isPadded
                    isBordered
                />
            ) }
            isPadded
            { ...props }
        >
            <Container>
                <Flex w={ 0.5 } column>
                    <Field
                        name="mysqlCluster.backupsCron"
                        label={ t(mysqlClusters.FieldName.backupsCron) }
                        component={ CronField }
                        disabled={ isDisabled }
                        helperText={ t(mysqlClusters.FieldDescription.backupsCron) }
                        autoFocus
                    />
                    <Box w={ 0.5 }>
                        <Field
                            name="mysqlCluster.backupsRetainCount"
                            label={ t(mysqlClusters.FieldName.backupsRetainCount) }
                            component={ MaskedInputField }
                            mask={ (value: string) => replace(value, /[^0-9]/, '') }
                            intent={ willDeleteBackups ? Intent.DANGER : null }
                            helperText={ (
                                willDeleteBackups
                                    ? backupDeletionWarning
                                    : t(mysqlClusters.FieldDescription.backupsRetainCount)
                            ) }
                            disabled={ isDisabled }
                        />
                    </Box>
                </Flex>
            </Container>
        </FormContainer>
    )
}

export default withForm({ name: forms.Name.updateMySQLCluster })(MySQLClusterBackupGeneralForm)
