import * as React from 'react'
import { connect } from 'react-redux'
import { Flex, Box } from 'reflexbox'
import { Intent } from '@blueprintjs/core'

import { RootState, auth, system } from '../redux'
import { useTranslation, useDispatch } from '../hooks'
import { Maybe } from '../utils'

import StatusIcon from '../components/StatusIcon'
import TitleBar from '../components/TitleBar'
import Button from '../components/Button'

type OwnProps = {
    config: Maybe<system.IAuthConfiguration>
}
type ReduxProps = {
    isVerified: boolean
}
type Props = OwnProps & ReduxProps

const AuthVerificationCard: React.FC<Props> = (props) => {
    const { isVerified } = props
    const [t] = useTranslation()
    const dispatch = useDispatch()
    const config = props.config || {}

    return (
        <Flex
            align="center"
            mb={ 2 }
            column={ [true, true, true, false] }
        >
            <Box
                w={ [1, 1, 1, 0.4] }
                mb={ [1, 1, 1, 0] }
                mr={ 2 }
            >
                <Button
                    text={ t('Verify Configuration') }
                    onClick={ () => dispatch(system.verifyAuthConfiguration(config)) }
                    disabled={ isVerified || !auth.isValidConfiguration(config) }
                />
            </Box>
            <Box w={ [1, 1, 1, 0.6] }>
                <TitleBar
                    title={
                        isVerified
                            ? t('The configuration has been verified and is correct')
                            : t('The configuration has not been verified yet or is incorrect')
                    }
                    icon={
                        <StatusIcon
                            intent={ isVerified ? Intent.SUCCESS : Intent.DANGER }
                        />
                    }
                    isCompact
                    isSmall
                />
            </Box>
        </Flex>
    )
}

function mapStateToProps(state: RootState, ownProps: OwnProps): ReduxProps {
    const config = ownProps.config || {}
    const isVerified = system.isAuthConfigVerified(config)(state)

    return {
        isVerified
    }
}

export default connect(mapStateToProps)(AuthVerificationCard)
