import * as React from 'react'
import { connect } from 'react-redux'
import { Flex, Box } from 'reflexbox'

import { map, sortBy } from 'lodash'

import { Tooltip, Position } from '@blueprintjs/core'

import { RootState, DispatchProp, api, auth, organizations, routing, ui } from '../redux'
import { useTranslation, useDispatch } from '../hooks'
import { Maybe, arePropsEqual } from '../utils'

import Avatar from '../components/Avatar'
import Button from '../components/Button'
import NetworkLoadingIndicator from '../components/NetworkLoadingIndicator'

import styles from './OrganizationSwitcher.module.scss'

type ReduxProps = {
    entries: organizations.IOrganization[]
    selectedEntry: Maybe<organizations.IOrganization>
    isAdmin: boolean
}

type Props = ReduxProps & DispatchProp

const OrganizationSwitcher: React.FC<Props> = React.memo((props) => {
    const { entries, selectedEntry, isAdmin } = props
    const dispatch = useDispatch()
    const [t] = useTranslation()

    return (
        <Container>
            <Flex column>
                { map(entries, (organization: organizations.IOrganization) => (
                    <Box mb={ 2 } key={ organization.name }>
                        <Tooltip
                            content={ api.displayName(organization) }
                            position={ Position.RIGHT }
                        >
                            <Button
                                icon={ (
                                    <Avatar
                                        name={ api.displayName(organization) }
                                        imageURL={ organization.iconUrl }
                                        isLight={ false }
                                    />
                                ) }
                                active={ organization === selectedEntry }
                                onClick={ () => {
                                    if (organization !== selectedEntry) {
                                        dispatch(organizations.select(organization))
                                    }
                                    dispatch(routing.push(routing.routeFor(routing.Key.dashboard)))
                                } }
                                isSquare
                            />
                        </Tooltip>
                    </Box>
                )) }
                { isAdmin && (
                    <Tooltip
                        content={ t(ui.createTitle(api.Resource.organization, api.Request.create)) }
                        position={ Position.RIGHT }
                    >
                        <Button
                            icon="add"
                            href={ routing.routeFor(routing.Key.organizations, { slug: '_', action: 'new' }) }
                            isSquare
                        />
                    </Tooltip>
                ) }
            </Flex>
            <NetworkLoadingIndicator />
        </Container>
    )
}, arePropsEqual(['selectedEntry', 'entries']))

const Container: React.FC = ({ children }) => (
    <div className={ styles.container }>
        { children }
    </div>
)

function mapStateToProps(state: RootState): ReduxProps {
    const selectedEntry = organizations.getCurrent(state)
    const entries = sortBy(organizations.getAll(state), 'name')
    const isAdmin = auth.isAdmin(state)

    return {
        entries,
        selectedEntry,
        isAdmin
    }
}

export default connect(mapStateToProps)(OrganizationSwitcher)
