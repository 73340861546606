import { createSelector } from 'reselect'
import { IBreadcrumbProps } from '@blueprintjs/core'

import {
    reduce, get, compact, flatten, omitBy, keys, values, keyBy,
    head, upperCase, startCase, snakeCase, includes, has, isString, isEmpty, isEqual
} from 'lodash'

import { IconName } from '../components/Icon'

import {
    Selector, SelectorCreator, RootState,
    api, routing, ui, memcacheds, prometheuses, grafanas,
    projects, sites, mysqlClusters, organizations, invites
} from '../redux'
import { Maybe, Modify, singular } from '../utils'
import { i18n } from '../i18n'


//
//  TYPES

export enum MenuKind {
    main    = 'main',
    context = 'context'
}

export enum MenuEntry {
    overview       = 'overview',
    settings       = 'settings',
    general        = 'general',
    access         = 'access',
    sites          = 'sites',
    projects       = 'projects',
    runtime        = 'runtime',
    buckets        = 'buckets',
    backups        = 'backups',
    repos          = 'repos',
    pods           = 'pods',
    quotas         = 'quotas',
    mysqlCluster   = 'mysqlCluster',
    routing        = 'routing',
    memcached      = 'memcached',
    prometheus     = 'prometheus',
    grafana        = 'grafana',
    resources      = 'resources',
    scheduling     = 'scheduling',
    environment    = 'environment',
    pageCaching    = 'page-caching',
    events         = 'events',
    metrics        = 'metrics',
    logs           = 'logs',
    smtp           = 'smtp',
    commands       = 'commands',
    system         = 'system',
    status         = 'status',
    install        = 'install'
}

export enum TabEntry {
    backups            = 'backups',
    resourceAllocation = 'resource-allocation',
    scheduling         = 'scheduling',
    wordpress          = 'wordpress',
    memcached          = 'memcached',
    adminUsers         = 'admin-users',
    authentication     = 'authentication',
    certificates       = 'certificates',
    configConnector    = 'config-connector'
}

export enum MenuSection {
    topLevel      = 'topLevel',
    status        = 'status',
    configuration = 'configuration',
    scaling       = 'scaling',
    components    = 'components',
    management    = 'management'
}

export type MainMenu = Record<MenuSection, MenuEntry[]>
export type Entry = MenuEntry | TabEntry | MenuSection

export type Breadcrumb = Modify<IBreadcrumbProps, {
    icon?: Maybe<IconName>
    resource?: Maybe<api.Resource>
    entry?: Maybe<api.AnyResourceInstance | MenuEntry>
    title?: Maybe<string>
}>

const BREADCRUMBABLE_RESOURCES = [
    api.Resource.site,
    api.Resource.project,
    api.Resource.organization,
    api.Resource.mysqlCluster,
    api.Resource.memcached,
    api.Resource.prometheus,
    api.Resource.grafana
]

export function getMainMenuForResource(resource: api.Resource): MainMenu {
    switch (resource) {
        case api.Resource.organization:
            return groupMenuEntries([MenuEntry.overview, MenuEntry.projects, MenuEntry.general, MenuEntry.access])

        case api.Resource.project:
            return groupMenuEntries([
                MenuEntry.sites, MenuEntry.pods,
                MenuEntry.quotas, MenuEntry.general,
                MenuEntry.mysqlCluster, MenuEntry.prometheus, MenuEntry.grafana
            ])

        case api.Resource.site:
            return groupMenuEntries([
                MenuEntry.overview, MenuEntry.routing, MenuEntry.runtime,
                MenuEntry.pods, MenuEntry.resources, MenuEntry.scheduling, MenuEntry.pageCaching,
                MenuEntry.environment, MenuEntry.smtp, MenuEntry.events, MenuEntry.metrics, MenuEntry.logs,
                MenuEntry.general, MenuEntry.commands,
                MenuEntry.mysqlCluster, MenuEntry.memcached
            ])

        case api.Resource.mysqlCluster:
            return groupMenuEntries([
                MenuEntry.resources, MenuEntry.scheduling, MenuEntry.backups,
                MenuEntry.pods, MenuEntry.metrics, MenuEntry.logs
            ])

        case api.Resource.grafana:
        case api.Resource.prometheus:
            return groupMenuEntries([
                MenuEntry.resources, MenuEntry.scheduling, MenuEntry.pods
            ])

        case api.Resource.memcached:
            return groupMenuEntries([
                MenuEntry.resources, MenuEntry.scheduling,
                MenuEntry.pods, MenuEntry.metrics, MenuEntry.logs
            ])

        default:
            return {} as MainMenu
    }
}

export const getContextMenuForResource = (
    resource: api.Resource | ui.Resource,
    entry?: Maybe<api.AnyResourceInstance>
): Array<ui.Action | api.Request | MenuEntry> => {
    switch (resource) {
        default:
        case api.Resource.site:
            return [api.Request.destroy]

        case api.Resource.project:
            return [ui.Action.edit, api.Request.destroy]

        case api.Resource.organization:
            return [ui.Action.edit, api.Request.destroy]

        case api.Resource.accountBinding:
            return [api.Request.destroy]

        case api.Resource.pod:
            return [MenuEntry.logs, api.Request.destroy]

        case api.Resource.invite:
            if (!entry || !invites.isInvite(entry)) {
                return [ui.Action.copy, ui.Action.recreate, api.Request.destroy]
            }

            return invites.isValid(entry)
                ? [ui.Action.copy, api.Request.destroy]
                : [ui.Action.recreate, api.Request.destroy]

        case ui.Resource.link:
            return [ui.Action.copy]
    }
}

export function isValidMenuEntry(resource: api.Resource, entry: any) {
    const menuItems = flatten(values(getMainMenuForResource(resource)))
    return includes(menuItems, entry)
}

export function getTabsForResource(resource: api.Resource): TabEntry[] {
    return []
}

export function getSectionForMenuEntry(menuEntry: MenuEntry): MenuSection {
    switch (menuEntry) {
        case MenuEntry.resources:
        case MenuEntry.scheduling:
        case MenuEntry.quotas:
            return MenuSection.scaling

        case MenuEntry.runtime:
        case MenuEntry.routing:
        case MenuEntry.pageCaching:
        case MenuEntry.environment:
        case MenuEntry.smtp:
            return MenuSection.configuration


        case MenuEntry.pods:
        case MenuEntry.metrics:
        case MenuEntry.events:
        case MenuEntry.logs:
            return MenuSection.status

        case MenuEntry.mysqlCluster:
        case MenuEntry.memcached:
        case MenuEntry.prometheus:
        case MenuEntry.grafana:
            return MenuSection.components

        case MenuEntry.access:
        case MenuEntry.general:
        case MenuEntry.commands:
            return MenuSection.management

        default:
            return MenuSection.topLevel
    }
}

export function groupMenuEntries(entries: MenuEntry[]): MainMenu {
    return reduce(entries, (acc, entry) => {
        const section = getSectionForMenuEntry(entry)
        const entries = acc[section] ? [...acc[section], entry] : [entry]

        return {
            ...acc,
            [section]: entries
        }
    }, {} as MainMenu)
}

export function getTabsForURL(url: routing.Path) {
    const matchedPayload = routing.matchURL(url)
    return getTabsForResource(matchedPayload.key as api.Resource)
}

export function getDefaultMenuEntry(resource: api.Resource): Maybe<MenuEntry> {
    const menu = getMainMenuForResource(resource)
    const section = menu[MenuSection.topLevel] ? MenuSection.topLevel : head(keys(menu))
    if (!menu || !section) {
        return null
    }

    return head(menu[section])
}

export function routeForMenuEntry(menuEntry: MenuEntry, entry: api.AnyResourceInstance) {
    const defaultRoute = routing.routeForResource(entry, { action: menuEntry })

    const routeForResourceOrDefault = (name: Maybe<string>) => (
        name ? routing.routeForResource({ name }) : defaultRoute
    )

    if (menuEntry === MenuEntry.logs && has(entry, 'logsUrl')) {
        return get(entry, 'logsUrl', defaultRoute)
    }

    if (menuEntry === MenuEntry.metrics && has(entry, 'metricsUrl')) {
        return get(entry, 'metricsUrl', defaultRoute)
    }

    if (sites.isSite(entry)) {
        switch (menuEntry) {
            case MenuEntry.mysqlCluster:
                return routeForResourceOrDefault(entry.mysqlCluster)

            case MenuEntry.memcached:
                return routeForResourceOrDefault(entry.memcached)

            default:
                return defaultRoute
        }
    }

    if (projects.isProject(entry)) {
        switch (menuEntry) {
            case MenuEntry.mysqlCluster:
                return routeForResourceOrDefault(entry.mysqlCluster)

            case MenuEntry.prometheus:
                return routeForResourceOrDefault(entry.prometheus)

            case MenuEntry.grafana: {
                return routeForResourceOrDefault(entry.grafana)
            }

            default:
                return defaultRoute
        }
    }

    return defaultRoute
}

export function isMenuEntry(entry: string): entry is MenuEntry {
    return entry in MenuEntry || includes(values(MenuEntry), entry)
}

export function isTabEntry(entry: string): entry is TabEntry {
    return entry in TabEntry || includes(values(TabEntry), entry)
}

export function isMenuEntryDisabled(entry: string, resource: ui.Resource | api.Resource) {
    switch (resource) {
        case api.Resource.project:
            return includes([MenuEntry.buckets, MenuEntry.repos], entry)

        default:
            return false
    }
}

export function entryDisplayName(entry: MenuEntry | TabEntry | string, asSingular: boolean = false) {
    switch (entry) {
        case MenuEntry.smtp:
            return upperCase(entry)

        case MenuEntry.mysqlCluster:
            return 'MySQL Cluster'

        case TabEntry.wordpress: {
            return 'WordPress'
        }

        default: {
            const name = startCase(snakeCase(entry))
            return asSingular ? singular(name) : name
        }
    }
}

//
//  SELECTORS

export const getBreadcrumbsForURL = (
    url: routing.Path
) => createSelector(
    [
        organizations.getCurrent,
        api.getState(BREADCRUMBABLE_RESOURCES)
    ],
    (
        currentOrganization: Maybe<organizations.IOrganization>,
        state: Partial<RootState>
    ) => {
        const matchedRoute = routing.matchURL(url)
        const action = get(matchedRoute, 'params.action')
        const tab = get(matchedRoute, 'params.tab')

        if (matchedRoute.key === routing.Key.system) {
            const homeCrumb: Breadcrumb = {
                href  : routing.routeFor(routing.Key.system, { action, tab: TabEntry.authentication }),
                text  : i18n.t(ui.createTitle(action)),
                title : i18n.t('System')
            }

            const activeMenuEntry = isMenuEntry(tab) || isTabEntry(tab) ? tab : null

            const menuCrumb: Maybe<Breadcrumb> = activeMenuEntry ? omitBy({
                href  : matchedRoute.url,
                text  : i18n.t(ui.createTitle(activeMenuEntry, activeMenuEntry)),
                entry : activeMenuEntry
            }, isEmpty) : null

            return compact([homeCrumb, menuCrumb])
        }

        if (!currentOrganization) {
            return []
        }

        const matchers = {
            [api.Resource.project]      : projects.getForURL,
            [api.Resource.site]         : sites.getForURL,
            [api.Resource.mysqlCluster] : mysqlClusters.getForURL,
            [api.Resource.memcached]    : memcacheds.getForURL,
            [api.Resource.prometheus]   : prometheuses.getForURL,
            [api.Resource.grafana]      : grafanas.getForURL
        }

        const activeMenuEntry = isMenuEntry(action) ? action : null
        const menuCrumb: Maybe<Breadcrumb> = activeMenuEntry ? omitBy({
            href  : matchedRoute.url,
            text  : i18n.t(ui.createTitle(activeMenuEntry, activeMenuEntry)),
            entry : activeMenuEntry
        }, isEmpty) : null

        const crumbsForResources = reduce(matchers, (acc, matcher, resource) => {
            const entry = matcher(url)(state as RootState)

            if (!entry) {
                return acc
            }

            const crumb: Breadcrumb = {
                href     : routing.routeForResource(entry),
                text     : api.displayName(entry),
                title    : i18n.t(ui.createTitle(resource as api.Resource)),
                resource : resource as api.Resource,
                entry    : entry as api.AnyResourceInstance
            }
            return [...acc, crumb]
        }, [] as Breadcrumb[]) as Breadcrumb[]

        if (isEmpty(crumbsForResources)) {
            const homeCrumb: Breadcrumb = {
                href     : routing.routeFor(routing.Key.dashboard),
                text     : currentOrganization.displayName,
                title    : i18n.t(ui.createTitle(api.Resource.organization)),
                entry    : currentOrganization,
                resource : api.Resource.organization
            }

            return compact([homeCrumb, menuCrumb])
        }

        return compact([...crumbsForResources, menuCrumb])
    }
)
export const getBreadcrumbsForCurrentURL: Selector<Breadcrumb[]> = createSelector(
    [routing.getCurrentRoute, api.getState(BREADCRUMBABLE_RESOURCES)],
    (route: routing.Route, state) => getBreadcrumbsForURL(route.url)(state as RootState)
)
export const getTabsForCurrentURL: Selector<TabEntry[]> = createSelector(
    routing.getCurrentRoute,
    (route: routing.Route) => getTabsForURL(route.url)
)
export const getActiveTab: Selector<Maybe<TabEntry>> = createSelector(
    [routing.getParam('tab'), getTabsForCurrentURL],
    (tab, tabs) => {
        if (!isString(tab) || !isTabEntry(tab)) {
            return head(tabs)
        }

        return tab as TabEntry
    }
)
export const getActiveMenuEntry: Selector<Maybe<MenuEntry>> = createSelector(
    [routing.getParam('action'), routing.getCurrentRoute],
    (action, route) => {
        if (route.key === routing.Key.pods) {
            return MenuEntry.pods
        }

        if (!isString(action) || !isMenuEntry(action)) {
            return null
        }

        return action as MenuEntry
    }
)
export const getPeerResources: SelectorCreator<
    { name: api.ResourceName, resource: api.Resource },
    api.GroupedResourcesList
> = ({ name, resource }) => createSelector(
    api.getState(BREADCRUMBABLE_RESOURCES),
    (state) => {
        const parsedName = api.parseName(name, resource)
        switch (parsedName.type) {
            case api.Resource.site:
            case api.Resource.memcached:
            case api.Resource.prometheus:
            case api.Resource.grafana:
            case api.Resource.mysqlCluster: {
                const project = parsedName.parent
                const peerSites = sites.getAll(state as RootState)
                const peerMemcacheds = project ? memcacheds.getForProject(project)(state as RootState) : {}

                const peerPrometheuses = project ? keyBy(
                    [prometheuses.getForProject(project)(state as RootState)],
                    'name'
                ) as api.ResourcesList<prometheuses.IPrometheus> : {}

                const peerGrafanas = project ? keyBy(
                    [grafanas.getForProject(project)(state as RootState)],
                    'name'
                ) as api.ResourcesList<grafanas.IGrafana> : {}

                const peerMysqlClusters = project ? keyBy(
                    [mysqlClusters.getForProject(project)(state as RootState)],
                    'name'
                ) as api.ResourcesList<mysqlClusters.IMySQLCluster> : {}

                return {
                    [api.Resource.site]         : peerSites,
                    [api.Resource.memcached]    : peerMemcacheds,
                    [api.Resource.prometheus]   : peerPrometheuses,
                    [api.Resource.grafana]      : peerGrafanas,
                    [api.Resource.mysqlCluster] : peerMysqlClusters
                }
            }

            case api.Resource.project: {
                return {
                    [api.Resource.project]: projects.getAll(state as RootState)
                }
            }

            default:
                return {}
        }
    }
)
export const isCreateEntryRoute: Selector<boolean> = createSelector(
    routing.getParams,
    (params) => isEqual(params?.slug, '_') && isEqual(params?.action, 'new')
)
