import * as React from 'react'
import classes from 'classnames'

import styles from './Container.module.scss'

export type BorderProps = {
    isBordered?: boolean
    isBorderedTop?: boolean
    isBorderedRight?: boolean
    isBorderedBottom?: boolean
    isBorderedLeft?: boolean
}

export type Props = {
    className?: string
} & BorderProps

const Container: React.FC<Props> = (props) => {
    const { children, className, isBordered, isBorderedTop, isBorderedRight, isBorderedBottom, isBorderedLeft } = props
    return (
        <div
            className={ classes(
                styles.container,
                (isBordered || isBorderedTop) && styles.borderedTop,
                (isBordered || isBorderedRight) && styles.borderedRight,
                (isBordered || isBorderedBottom) && styles.borderedBottom,
                (isBordered || isBorderedLeft) && styles.borderedLeft,
                className
            ) }
        >
            { children }
        </div>
    )
}

export default Container
