import * as React from 'react'
import { Box } from 'reflexbox'
import { Tooltip, OverflowList, Boundary, Position, Intent } from '@blueprintjs/core'

import { size, isString, isFunction } from 'lodash'

import { api, routing, ui } from '../redux'

import Button from '../components/Button'
import Avatar from '../components/Avatar'

import { useTranslation } from '../hooks'

type Props = {
    items: any[]
    listRoute?: routing.Path
    itemRoute?: (item: any) => routing.Path | routing.Path
    itemTooltipRenderer?: (item: any) => string | JSX.Element
    isRound?: boolean
}

const AvatarsList: React.FC<Props> = (props) => {
    const { items, listRoute, itemRoute, itemTooltipRenderer, isRound } = props
    const [t] = useTranslation()

    const itemMarginRight = isRound ? '-10px' : '5px'
    const listMargin = isRound ? '-3px' : 0

    return (
        <OverflowList
            items={ items }
            collapseFrom={ Boundary.END }
            minVisibleItems={ 2 }
            style={ { marginLeft: listMargin, marginTop: listMargin } }
            overflowRenderer={ (overflowItems: api.AnyResourceInstance[]) => (
                <Tooltip
                    content={ t('View all') }
                    position={ Position.RIGHT }
                >
                    <Button
                        text={ `+${size(overflowItems)}` }
                        href={ listRoute }
                        intent={ Intent.SUCCESS }
                        isRound={ isRound }
                        isSquare={ !isRound }
                        isBordered={ isRound }
                    />
                </Tooltip>
            ) }
            visibleItemRenderer={ (item: api.AnyResourceInstance, index: number) => {
                const route = isFunction(itemRoute) ? itemRoute(item) : isString(itemRoute) ? itemRoute : listRoute

                return (
                    <Box mr={ itemMarginRight } key={ index }>
                        <Tooltip
                            content={
                                isFunction(itemTooltipRenderer)
                                    ? itemTooltipRenderer(item)
                                    : api.displayName(item)
                            }
                            position={ Position.RIGHT }
                        >
                            <Button
                                icon={ (
                                    <Avatar
                                        name={ api.displayName(item) }
                                        imageURL={ ui.avatarURL(item) }
                                    />
                                ) }
                                href={ route }
                                isRound={ isRound }
                                isSquare={ !isRound }
                                isBordered={ isRound }
                            />
                        </Tooltip>
                    </Box>
                )
            } }
        />
    )
}

export default AvatarsList
