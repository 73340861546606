/* eslint-disable no-use-before-define */
import * as React from 'react'
import { ActionType, action as createAction } from 'typesafe-actions'
import { channel as createChannel } from 'redux-saga'
import { takeEvery, fork, put } from 'redux-saga/effects'

import { Position, Toaster, Intent, IActionProps } from '@blueprintjs/core'
import { IconName } from '@presslabs/icons'

import { get, join, startCase, snakeCase, toLower, has } from 'lodash'

import {
    RootState, ActionWithPayload,
    grpc, api, auth, navigation, organizations, projects, sites,
    mysqlClusters, memcacheds, accountBindings, invites, system
} from '../redux'

import { i18n } from '../i18n'
import { Maybe, watchChannel, singular, plural, pastTense, gravatarURL } from '../utils'

import Icon from '../components/Icon'

const DEFAULT_TOAST_TIMEOUT = 3000


//
//  TYPES

export type State = Record<string, unknown>
export type Actions = ActionType<typeof actions>

export type Toast = {
    message: string
    icon?: IconName
    intent?: Intent
    timeout?: number
    isPersistent?: boolean
    action?: IActionProps
}

export enum Action {
    add      = 'add',
    edit     = 'edit',
    copy     = 'copy',
    recreate = 'recreate',
    confirm  = 'confirm',
    discard  = 'discard'
}

export enum Resource {
    link     = 'link',
    draft    = 'draft'
}

//
//  ACTIONS

export const TOAST_DISPLAYED = '@ ui / TOAST_DISPLAYED'
export const TOAST_CLOSED    = '@ ui / TOAST_CLOSED'

export const showToast = (payload: Toast) => createAction(TOAST_DISPLAYED, payload)
export const closeToast = () => createAction(TOAST_CLOSED)

const actions = {
    showToast
}


//
//  REDUCER

const initialState: State = {}

export function reducer(state: State = initialState, action: Actions) {
    return state
}


//
//  SAGA

const channel = createChannel()
const toaster = has(global, 'document') ? Toaster.create({ position: Position.TOP }) : null

export function* saga() {
    yield takeEvery(TOAST_DISPLAYED, dispatchToToaster)
    yield fork(watchChannel, channel)
}

export function* notifyAboutAction(action: ActionWithPayload) {
    const message = createTitleFromAction(action)
    const toastPayload = createToastPayloadFromAction(action)

    if (!message) {
        return
    }

    yield put(showToast({
        message: i18n.t(message),
        ...toastPayload
    }))
}

function dispatchToToaster(action: ActionType<typeof showToast>) {
    const toast = action.payload
    const timeout = toast.isPersistent ? 0 : get(toast, 'timeout', DEFAULT_TOAST_TIMEOUT)
    const icon = <Icon size={ 24 } name={ toast.icon } />

    if (!toaster) {
        return
    }

    toaster.show({
        ...toast,
        icon,
        timeout,
        onDismiss: () => channel.put(closeToast())
    })
}

export function createTitle(
    resource: api.Resource | Resource | navigation.Entry,
    action?: api.Request | Action | navigation.Entry,
    status?: Maybe<api.Status>,
    error?: Maybe<Error | grpc.RequestError>
): string {
    const resourceName = resourceDisplayName(resource, true)

    if (!action) {
        if (api.isResource(resource)) {
            return startCase(resourceName)
        }

        if (navigation.isMenuEntry(resource) || navigation.isTabEntry(resource)) {
            return navigation.entryDisplayName(resource)
        }

        return startCase(resourceDisplayName(resource, true))
    }

    const actionName = actionDisplayName(resource, action)

    if (navigation.isMenuEntry(action) || navigation.isTabEntry(action)) {
        return navigation.entryDisplayName(action)
    }

    if (resource === api.Resource.invite && !status) {
        switch (action) {
            case api.Request.create:
                return 'Invite user'
            case Action.copy:
                return 'Copy invite link'
            case Action.confirm: {
                return `Are you sure you want to ${actionName} this ${resourceName}?`
            }

            default:
                return `${startCase(actionName)} ${resourceName}`
        }
    }

    if (action === Action.confirm) {
        return `Are you sure you want to ${actionName} this ${resourceName}?`
    }

    if (!status) {
        return `${startCase(actionName)} ${resourceName}`
    }
    else {
        switch (status) {
            default:
            case api.Status.success: {
                return `${startCase(resourceName)} ${pastTense(actionName)}`
            }

            case api.Status.failure: {
                const defaultMessage = `${startCase(resourceName)} ${actionName} failed`

                switch (get(error, 'status.code')) {
                    case grpc.StatusCode.AlreadyExists:
                        return `This ${resourceName} already exists`

                    case grpc.StatusCode.Internal:
                        return `Could not ${actionName} ${resourceName}: internal server error`

                    case grpc.StatusCode.PermissionDenied:
                        return `You don't have permissions to ${actionName} ${plural(resourceName)}`

                    case grpc.StatusCode.FailedPrecondition:
                        return join([defaultMessage, error?.message], ': ')

                    default:
                        return defaultMessage
                }
            }
        }
    }
}

export function actionDisplayName(
    resource: api.Resource | Resource | navigation.Entry,
    action: api.Request | Action | navigation.Entry
): string {
    if (resource === api.Resource.invite) {
        switch (action) {
            case api.Request.destroy: {
                return 'revoke'
            }

            case Action.confirm: {
                return actionDisplayName(resource, api.Request.destroy)
            }

            default:
                return toLower(action)
        }
    }

    switch (action) {
        case Action.confirm:
        case api.Request.destroy:
            return resource === Resource.draft ? 'discard' : 'delete'

        default:
            return toLower(action)
    }
}

export function resourceDisplayName(
    resource: api.Resource | Resource | navigation.Entry,
    asSingular: boolean = false
): string {
    const getName = (resource: api.Resource | Resource | navigation.Entry) => {
        switch (resource) {
            case api.Resource.accountBinding:
                return 'accounts'

            case api.Resource.mysqlCluster:
                return 'mysql clusters'

            case api.Resource.memcached:
                return 'caches'

            case api.Resource.mysqlClusterBackup:
                return 'backups'

            case api.Resource.authConfiguration:
                return 'authentication configurations'

            case api.Resource.letsEncryptConfiguration:
                return 'certificates configurations'

            default:
                return resource
        }
    }

    const name = toLower(startCase(snakeCase(getName(resource))))
    return asSingular ? singular(name) : name
}

export function createTitleFromAction(action: ActionWithPayload): Maybe<string> {
    const resource = api.getResourceFromAction(action)
    const request = api.getRequestTypeFromAction(action)
    const status = api.getStatusFromAction(action)

    if (!resource || !request || !status) {
        return null
    }

    return createTitle(resource, request, status, get(action.payload, 'error'))
}

function createToastPayloadFromAction(action: ActionWithPayload): Partial<Toast> {
    const status = api.getStatusFromAction(action)
    switch (status) {
        case api.Status.failure:
            return { intent: Intent.DANGER, icon: 'delete-circle' }

        default:
        case api.Status.success:
            if (action.type === invites.CREATE_SUCCEEDED) {
                return {
                    intent : Intent.SUCCESS,
                    icon   : 'ok-circle',
                    action : {
                        text    : i18n.t(createTitle(api.Resource.invite, Action.copy)),
                        onClick : () => channel.put(invites.copyLink(action.payload.data))
                    }
                }
            }

            return { intent: Intent.SUCCESS, icon: 'ok-circle' }
    }
}

export function getTitleForPayload(payload: Maybe<Record<string, unknown>>, resource: api.Resource): string {
    const request = api.isNewEntry(payload) ? api.Request.create : api.Request.update
    return createTitle(resource, request)
}

export function getIconNameForAction(
    action: Action | navigation.Entry | api.Request | sites.CommandsArticle,
    resource?: Resource | api.Resource
): Maybe<IconName> {
    const ICONS = {
        [navigation.MenuEntry.overview]     : 'view-thumbs',
        [navigation.MenuEntry.settings]     : 'settings',
        [navigation.MenuEntry.general]      : 'wrench',
        [navigation.MenuEntry.sites]        : 'globe',
        [navigation.MenuEntry.projects]     : 'view-list',
        [navigation.MenuEntry.runtime]      : 'settings',
        [navigation.MenuEntry.access]       : 'account-male-ok',
        [navigation.MenuEntry.mysqlCluster] : 'database',
        [navigation.MenuEntry.quotas]       : 'sync-square',
        [navigation.MenuEntry.repos]        : 'git-branch',
        [navigation.MenuEntry.backups]      : 'database-time',
        [navigation.MenuEntry.pods]         : 'pod',
        [navigation.MenuEntry.routing]      : 'globe',
        [navigation.MenuEntry.memcached]    : 'cache',
        [navigation.MenuEntry.prometheus]   : 'prometheus',
        [navigation.MenuEntry.grafana]      : 'grafana',
        [navigation.MenuEntry.resources]    : 'chip',
        [navigation.MenuEntry.scheduling]   : 'calendar-from',
        [navigation.MenuEntry.pageCaching]  : 'cache',
        [navigation.MenuEntry.environment]  : 'code',
        [navigation.MenuEntry.events]       : 'tag',
        [navigation.MenuEntry.metrics]      : 'chart-line',
        [navigation.MenuEntry.logs]         : 'logs',
        [navigation.MenuEntry.smtp]         : 'mail-send',
        [navigation.MenuEntry.commands]     : 'terminal',

        [navigation.TabEntry.adminUsers]      : 'role-super-admin',
        [navigation.TabEntry.certificates]    : 'letsencrypt',
        [navigation.TabEntry.authentication]  : 'accounts',
        [navigation.TabEntry.configConnector] : 'puzzle',
        [navigation.TabEntry.scheduling]      : 'calendar-from',

        [Action.edit]     : 'edit',
        [Action.copy]     : 'clipboard-add',
        [Action.recreate] : 'recycle-ccw',

        [api.Request.destroy]: 'bin-full',

        [sites.CommandsArticle.database]   : 'database',
        [sites.CommandsArticle.code]       : 'code-up',
        [sites.CommandsArticle.mediaFiles] : 'images',
        [sites.CommandsArticle.wpCli]      : 'terminal'
    }

    if (ICONS[action]) {
        return ICONS[action]
    }

    return null
}

export function iconName(entry: any): Maybe<IconName> {
    if (mysqlClusters.isMySQLCluster(entry)) {
        return 'database'
    }

    if (memcacheds.isMemcached(entry)) {
        return 'cache'
    }

    return null
}

export function avatarURL(entry: any): Maybe<string> {
    if (projects.isProject(entry) || organizations.isOrganization(entry)) {
        return get(entry, 'iconUrl')
    }

    if (accountBindings.isAccountBinding(entry)) {
        return accountBindings.avatarURL(entry)
    }

    if (invites.isInvite(entry)) {
        return invites.avatarURL(entry)
    }

    if (auth.isUser(entry)) {
        return auth.avatarURL(entry)
    }

    if (system.isAdminUser(entry)) {
        return gravatarURL(entry.email)
    }

    return null
}

//
//  SELECTORS

export const getState = (state: RootState): State => state.ui
