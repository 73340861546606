import * as React from 'react'
import { connect } from 'react-redux'
import { Popover, Position, Menu, MenuDivider } from '@blueprintjs/core'
import { Box } from 'reflexbox'

import { RootState, DispatchProp, auth, routing, navigation, ui, organizations } from '../redux'
import { Maybe } from '../utils'
import { useTranslation, useDispatch } from '../hooks'

import Icon from '../components/Icon'
import Button from '../components/Button'
import MenuItem from '../components/MenuItem'
import UserTitle from '../components/UserTitle'
import Avatar from '../components/Avatar'

type ReduxProps = {
    currentUser: Maybe<auth.User>
    isAdmin: boolean
    isMember: boolean
}

type Props = ReduxProps & DispatchProp

const UserNav: React.FC<Props> = (props) => {
    const { currentUser, isAdmin, isMember } = props
    const dispatch = useDispatch()
    const [t] = useTranslation()

    if (!currentUser) {
        return null
    }

    return (
        <div>
            <Popover
                content={ (
                    <Menu>
                        <Box p={ 10 }>
                            <UserTitle entry={ currentUser } />
                        </Box>
                        <MenuDivider />
                        { (isMember || isAdmin) && (
                            <>
                                <MenuItem
                                    text={ t('System Status') }
                                    icon={
                                        <Icon
                                            name="semaphore"
                                            size={ 20 }
                                        />
                                    }
                                    href={
                                        routing.routeFor(routing.Key.system, {
                                            action: navigation.MenuEntry.status
                                        })
                                    }
                                />
                                { isAdmin && (
                                    <MenuItem
                                        text={ t('System Settings') }
                                        icon={
                                            <Icon
                                                name={ ui.getIconNameForAction(navigation.MenuEntry.settings) }
                                                size={ 20 }
                                            />
                                        }
                                        href={
                                            routing.routeFor(routing.Key.system, {
                                                action : navigation.MenuEntry.settings,
                                                tab    : navigation.TabEntry.authentication
                                            })
                                        }
                                    />
                                ) }
                                <MenuDivider />
                            </>
                        ) }
                        <MenuItem
                            text={ t('Logout') }
                            icon={
                                <Icon
                                    name="logout"
                                    size={ 20 }
                                />
                            }
                            onClick={ () => dispatch(auth.logout()) }
                        />
                    </Menu>
                ) }
                target={ (
                    <Button
                        icon={
                            <Avatar
                                name={ currentUser.fullName }
                                imageURL={ ui.avatarURL(currentUser) }
                                size={ 24 }
                                isRound
                            />
                        }
                        isSquare
                    />
                ) }
                autoFocus={ false }
                position={ Position.RIGHT }
            />
        </div>
    )
}

function mapStateToProps(state: RootState): ReduxProps {
    const currentUser = auth.getCurrentUser(state)
    const isAdmin = auth.isAdmin(state)
    const isMember = organizations.countAll(state) > 0

    return {
        currentUser,
        isAdmin,
        isMember
    }
}

export default connect(mapStateToProps)(UserNav)
