import * as React from 'react'
import { MenuItem, Intent } from '@blueprintjs/core'

import { Select } from '@blueprintjs/select'

import { map, get, filter, pick, compact, isFunction, isEmpty } from 'lodash'

import { forms } from '../redux'
import { useTranslation } from '../hooks'
import { SelectableItem, SelectableItemParser, parseSelectableItem, matchesQuery } from '../utils'

import FormGroup from '../components/FormGroup'
import Button from '../components/Button'

import styles from '../components/SelectField.module.scss'

const Selector = Select.ofType<SelectableItem>()

export type Props = forms.FieldProps & {
    items: any[]
    itemParser?: SelectableItemParser
    filterable?: boolean
    onChange?: (value: any) => void
}

const SelectField: React.FC<Props> = (props) => {
    const { field, form, label, onChange, filterable } = props
    const { name } = field

    const [t] = useTranslation()

    const errors = forms.getFieldErrors(form.errors, name)
    const intent = props.intent || !isEmpty(errors) ? Intent.DANGER : Intent.NONE
    const itemParser = props.itemParser || parseSelectableItem
    const selectedItem = itemParser(field.value)

    return (
        <FormGroup
            errors={ forms.formatFieldErrors(label, errors) }
            label={ label }
            { ...field }
            { ...pick(props, ['helperText', 'inline', 'isMonospaced']) }
            intent={ intent }
        >
            <Selector
                onItemSelect={ (item: SelectableItem) => {
                    form.setFieldValue(name, item.value)
                    if (isFunction(onChange)) {
                        onChange(item.value)
                    }
                } }
                itemRenderer={ (item: SelectableItem, { handleClick }) => (
                    <MenuItem
                        key={ item.key }
                        onClick={ handleClick }
                        active={ selectedItem && selectedItem.value === item.value }
                        text={ item.label || item.value }
                    />
                ) }
                items={ compact(map(get(props, 'items', []), itemParser)) }
                filterable={ filterable }
                itemListPredicate={ (query: string, items: SelectableItem[]) => (
                    filter(items, (item) => matchesQuery(item.value, query) || matchesQuery(item.label, query))
                ) }
                popoverProps={ { portalClassName: styles.menu, minimal: true } }
                { ...field }
            >
                <Button rightIcon="caret-down" isBordered>
                    { selectedItem ? selectedItem.label : get(props, 'placeholder', t('Select...')) }
                </Button>
            </Selector>
        </FormGroup>
    )
}

export default SelectField
