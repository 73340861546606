import * as React from 'react'
import { connect } from 'react-redux'

import { RootState, grpc, errors } from '../redux'

import MainContainer from '../components/MainContainer'
import ErrorMessage from '../components/ErrorMessage'

type ReduxProps = {
    isOffline: boolean
}

type Props = ReduxProps

const ConnectivityGate: React.FC<Props> = (props) => {
    const { isOffline, children } = props

    if (isOffline) {
        return (
            <MainContainer>
                <ErrorMessage error={ errors.Error.network } />
            </MainContainer>
        )
    }

    return <>{ children }</>
}

function mapStateToProps(state: RootState): ReduxProps {
    return {
        isOffline: grpc.isOffline(state)
    }
}

export default connect(mapStateToProps)(ConnectivityGate)
