/* eslint-disable no-use-before-define */
import { ActionType } from 'typesafe-actions'
import { takeEvery, fork } from 'redux-saga/effects'
import { createSelector } from 'reselect'

import { pick, pickBy, keys, startsWith } from 'lodash'

import { bitpoke } from '@bitpoke/bitpoke-proto'

import {
    RootState, Selector, SelectorCreator, AnyAction,
    api, grpc, forms, projects, ui, routing
} from '../redux'
import { Maybe } from '../utils'

const {
    Memcached,
    ResourceAllocation,
    MemcachedService,
    ListMemcachedsRequest,
    ListMemcachedsResponse,
    GetMemcachedRequest,
    UpdateMemcachedResourcesRequest,
    UpdateMemcachedRequest
} = bitpoke.memcached.v1

export {
    Memcached,
    ResourceAllocation,
    MemcachedService,
    ListMemcachedsRequest,
    ListMemcachedsResponse,
    GetMemcachedRequest,
    UpdateMemcachedResourcesRequest,
    UpdateMemcachedRequest
}


//
//  TYPES

export type MemcachedName = string
export interface IMemcached extends bitpoke.memcached.v1.IMemcached {
    name: MemcachedName
}
export type Memcached                        = bitpoke.memcached.v1.Memcached
export type ResourceAllocation               = bitpoke.memcached.v1.ResourceAllocation
export type IResourceAllocation              = bitpoke.memcached.v1.IResourceAllocation
export type IListMemcachedsRequest           = bitpoke.memcached.v1.IListMemcachedsRequest
export type IListMemcachedsResponse          = bitpoke.memcached.v1.IListMemcachedsResponse
export type IGetMemcachedRequest             = bitpoke.memcached.v1.IGetMemcachedRequest
export type IUpdateMemcachedRequest          = bitpoke.memcached.v1.IUpdateMemcachedRequest
export type IUpdateMemcachedResourcesRequest = bitpoke.memcached.v1.IUpdateMemcachedResourcesRequest

export type State = api.State<IMemcached>

export type Actions = ActionType<typeof actions>

const service = MemcachedService.create(
    grpc.createTransport('bitpoke.memcached.v1.MemcachedService')
)

export const { parseName, buildName } = api.createNameHelpers(api.Resource.memcached)

//
//  ACTIONS

export const get = (payload: IGetMemcachedRequest) => grpc.invoke({
    service,
    method       : 'getMemcached',
    data         : GetMemcachedRequest.create(payload),
    responseType : Memcached
})

export const list = (payload?: IListMemcachedsRequest) => grpc.invoke({
    service,
    method       : 'listMemcacheds',
    data         : ListMemcachedsRequest.create(payload),
    responseType : ListMemcachedsResponse
})

export const update = (payload: IUpdateMemcachedRequest) => grpc.invoke({
    service,
    method       : 'updateMemcached',
    data         : UpdateMemcachedRequest.create(payload),
    responseType : Memcached
})

export const updateResources = (payload: IUpdateMemcachedResourcesRequest) => grpc.invoke({
    service,
    method       : 'updateMemcachedResources',
    data         : UpdateMemcachedResourcesRequest.create(payload),
    responseType : Memcached
})

const actions = {
    get,
    update
}

const apiTypes = api.createActionTypes(api.Resource.memcached)

export const {
    GET_REQUESTED,     GET_SUCCEEDED,     GET_FAILED,
    UPDATE_REQUESTED,  UPDATE_SUCCEEDED,  UPDATE_FAILED
} = apiTypes

//
//  REDUCER

const apiReducer = api.createReducer(api.Resource.memcached, apiTypes)

export function reducer(state: State = api.initialState, action: AnyAction) {
    return apiReducer(state, action)
}


//
//  SAGA

export function* saga() {
    yield fork(api.emitResourceActions, api.Resource.memcached, apiTypes)
    yield fork(forms.takeEverySubmission, keys(FORM_HANDLERS) as forms.Name[], handleFormSubmission)

    yield takeEvery([
        UPDATE_SUCCEEDED,
        UPDATE_FAILED
    ], ui.notifyAboutAction)
}

const FORM_HANDLERS: forms.HandlerMapping = {
    [forms.Name.updateMemcached]          : update,
    [forms.Name.updateMemcachedResources] : updateResources
}

const handleFormSubmission = forms.createHandlers(FORM_HANDLERS, apiTypes)

export function isMemcached(entry: any): entry is IMemcached {
    return api.isOfType(api.Resource.memcached, entry)
}

//
//  SELECTORS

const selectors: api.Selectors<IMemcached> = api.createSelectors(api.Resource.memcached)
export const {
    getState,
    getAll,
    countAll,
    getByName,
    getForURL,
    getForCurrentURL,
    getForOrganization,
    getForCurrentOrganization
} = selectors


export const getForProject: SelectorCreator<projects.ProjectName, api.ResourcesList<IMemcached>> = (
    project: projects.ProjectName
) => createSelector(
    getAll,
    (sites) => pickBy(sites, ({ name }) => startsWith(name, project))
)
export const getResources: SelectorCreator<MemcachedName, Maybe<IResourceAllocation>> = (
    name: MemcachedName
) => createSelector(
    getByName(name),
    (site) => site ? site.resources : {}
)
export const isNotFound: Selector<boolean> = createSelector(
    [routing.getCurrentRoute, (state: RootState) => pick(state, 'grpc')],
    (currentRoute: routing.Route, state) => {
        const parsedName = parseName(currentRoute.url)
        if (!parsedName.name) {
            return false
        }

        const request: grpc.Request = get({ name: parsedName.name }).payload
        return grpc.isFailedRequest(request)(state as RootState)
    }
)
