import * as React from 'react'
import { connect } from 'react-redux'

import { map, max } from 'lodash'

import { RootState, auth, routing, navigation, system, conditions } from '../redux'
import { useTranslation } from '../hooks'
import { Maybe } from '../utils'

import StatusListCard from '../components/StatusListCard'

type OwnProps = {
    title: string
    entry: Maybe<system.IAuthStatus>
}

type ReduxProps = {
    isAdmin: boolean
}

type Props = OwnProps & ReduxProps

const AuthenticationStatusCard: React.FC<Props> = (props) => {
    const { entry, title, isAdmin } = props
    const [t] = useTranslation()

    if (!entry) {
        return null
    }

    return (
        <StatusListCard
            title={ title }
            description={ system.readyMessage(entry) }
            intent={ system.statusIntent(entry) }
            items={ [{
                name       : t('OIDC Credentials'),
                intent     : conditions.severityIntent(max(map(entry.conditions, 'severity'))),
                configLink : isAdmin ? routing.routeFor(routing.Key.system, {
                    action : navigation.MenuEntry.settings,
                    tab    : navigation.TabEntry.authentication
                }) : undefined
            }] }
        />
    )
}

function mapStateToProps(state: RootState): ReduxProps {
    const isAdmin = auth.isAdmin(state)

    return {
        isAdmin
    }
}

export default connect(mapStateToProps)(AuthenticationStatusCard)
