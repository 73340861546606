import * as React from 'react'
import { Field } from 'formik'

import { get } from 'lodash'
import { Box } from 'reflexbox'

import { api, forms, invites, ui } from '../redux'
import { useTranslation } from '../hooks'

import { withForm } from '../components/Form'
import FormContainer from '../components/FormContainer'
import InputField from '../components/InputField'

type Props = forms.FormProps<invites.IInvitePayload>

const InviteForm: React.FC<Props> = (props) => {
    const { initialValues } = props
    const [t] = useTranslation()
    const entry = get(initialValues, 'invite')

    return (
        <FormContainer
            submitText={ t(ui.getTitleForPayload(entry, api.Resource.invite)) }
            isInline
            { ...props }
        >
            <Box w={ 0.45 }>
                <Field
                    name="invite.email"
                    placeholder={ t(invites.FieldName.email) }
                    component={ InputField }
                    autoFocus
                    inline
                />
            </Box>
        </FormContainer>
    )
}

export default withForm({ name: forms.Name.createInvite })(InviteForm)
