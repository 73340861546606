import * as React from 'react'
import { Intent } from '@blueprintjs/core'

import { get, pick } from 'lodash'
import { Flex, Box } from 'reflexbox'

import { forms } from '../redux'
import { useCallback, useDropzone, useTranslation } from '../hooks'

import Avatar from '../components/Avatar'
import Button from '../components/Button'
import FormGroup from '../components/FormGroup'

import styles from './ImageUploadField.module.scss'

const ImageUploadField: React.FC<forms.FieldProps> = (props) => {
    const { field, form, label } = props
    const { name } = field
    const [t] = useTranslation()
    const onDrop = useCallback((files) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
            form.setFieldValue(name, reader.result)
        }, false)

        reader.readAsDataURL(files[0])
    }, [form, name])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
    const errors = forms.getFieldErrors(form.errors, name)

    const value = get(field, 'value', null)

    return (
        <FormGroup
            errors={ forms.formatFieldErrors(label, errors) }
            label={ label }
            { ...field }
            { ...pick(props, ['helperText', 'inline']) }
        >
            <div { ...getRootProps() }>
                <input { ...getInputProps() } />
                { value && !isDragActive && (
                    <Flex>
                        <Box mr={ 1 }>
                            <div className={ styles.currentImage }>
                                <Avatar imageURL={ value } />
                                <Button
                                    icon="edit-square"
                                    intent={ Intent.PRIMARY }
                                    isSquare
                                />
                            </div>
                        </Box>
                        <Box>
                            <Button
                                icon="bin-full"
                                onClick={ (e: React.SyntheticEvent<HTMLElement>) => {
                                    e.stopPropagation()
                                    form.setFieldValue(name, null)
                                } }
                                intent={ Intent.DANGER }
                                minimal
                                isSquare
                            />
                        </Box>
                    </Flex>
                ) }
                { !value && !isDragActive && (
                    <Button
                        icon="cloud-upload"
                        intent={ Intent.PRIMARY }
                        isSquare
                    />
                ) }
                { isDragActive && (
                    <Button
                        icon="down-square"
                        intent={ Intent.SUCCESS }
                        text={ t('Drop here to upload') }
                    />
                ) }
            </div>
        </FormGroup>
    )
}

export default ImageUploadField
