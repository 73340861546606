import { SagaIterator } from 'redux-saga'
import { takeLatest, put, call, all, select } from 'redux-saga/effects'
import { ActionType, isOfType } from 'typesafe-actions'

import { flatMap, compact } from 'lodash'

import {
    auth, api, routing, organizations, projects, sites, mysqlClusters, prometheuses, grafanas, memcacheds
} from '../redux'
import { Maybe } from '../utils'

export function* saga() {
    yield takeLatest([
        auth.ACCESS_GRANTED,
        routing.ROUTE_CHANGED
    ], fetchData)

    yield takeLatest(routing.ROUTE_CHANGED, fetchProjectData)
}

function* fetchData(
    action: ActionType<
        typeof routing.updateRoute
        | typeof organizations.select
        | typeof auth.grantAccess
    >
): SagaIterator {
    yield call(auth.ensureAuthentication)
    yield call(organizations.ensureOrganizationSelected)

    const force = isOfType(auth.ACCESS_GRANTED, action)
    const route = yield select(routing.getCurrentRoute)

    yield all(compact(flatMap([projects, sites, mysqlClusters, memcacheds], (scope) => {
        const parsed = scope.parseName(route.url)

        if (parsed.name && parsed.slug !== '_') {
            return force
                ? put(scope.get({ name: parsed.name }))
                : call(selectOrFetch, scope, parsed.name)
        }

        return null
    })))
}

function* selectOrFetch(scope: any, name: api.ResourceName) {
    const resourceExists: Maybe<api.AnyResourceInstance> = yield select(scope.getByName(name))
    if (resourceExists) {
        return
    }

    yield put(scope.get({ name }))
}

function* fetchProjectData() {
    const currentProject: Maybe<projects.IProject> = yield select(projects.getForCurrentURL)
    if (!currentProject) {
        return
    }

    yield put(sites.list({ project: currentProject.name }))
    yield put(memcacheds.list({ project: currentProject.name }))
    yield put(mysqlClusters.list({ project: currentProject.name }))
    yield put(prometheuses.list({ project: currentProject.name }))
    yield put(grafanas.list({ project: currentProject.name }))
}
