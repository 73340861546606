import * as React from 'react'

import { forms } from '../redux'

import InputField from '../components/InputField'

type Props = forms.FieldProps & {
    mask: (value: string) => string
}

const MaskedInputField: React.FC<Props> = (props) => {
    const { field, mask } = props
    const { onChange } = field

    return (
        <InputField
            { ...props }
            field={ {
                ...field,
                onChange: (e: React.ChangeEvent<any>) => {
                    e.target.value = mask(e.target.value)
                    onChange(e)
                }
            } }
        />
    )
}

export default MaskedInputField
