import * as React from 'react'
import { connect } from 'react-redux'

import { map, compact } from 'lodash'

import { RootState, auth, routing, navigation, system } from '../redux'
import { Maybe } from '../utils'

import StatusListCard from '../components/StatusListCard'

type OwnProps = {
    title: string
    entry: Maybe<system.IApplicationStatus>
}

type ReduxProps = {
    isAdmin: boolean
}

type Props = OwnProps & ReduxProps

const ApplicationStatus: React.FC<Props> = (props) => {
    const { entry, title, isAdmin } = props

    if (!entry) {
        return null
    }

    return (
        <StatusListCard
            title={ title }
            description={ system.readyMessage(entry) }
            intent={ system.statusIntent(entry) }
            link={ entry.link }
            items={ compact(map(entry.components, (componentStatus: system.IComponentStatus) => {
                if (!componentStatus.component) {
                    return null
                }
                return {
                    name       : system.componentDisplayName(componentStatus.component),
                    configLink : (
                        isAdmin && system.isCertificateIssuer(componentStatus.component)
                            ? routing.routeFor(routing.Key.system, {
                                action : navigation.MenuEntry.settings,
                                tab    : navigation.TabEntry.certificates
                            }) : undefined
                    ),
                    link   : componentStatus.component.link,
                    intent : system.componentStatusIntent(componentStatus.status)
                }
            })) }
        />
    )
}

function mapStateToProps(state: RootState): ReduxProps {
    const isAdmin = auth.isAdmin(state)

    return {
        isAdmin
    }
}

export default connect(mapStateToProps)(ApplicationStatus)
