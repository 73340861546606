import * as React from 'react'
import { Field } from 'formik'

import { Intent } from '@blueprintjs/core'

import { get, parseInt, toString, isEmpty } from 'lodash'

import { sites, forms } from '../redux'
import { Maybe } from '../utils'

import RangeSliderField from '../components/RangeSliderField'

type Props = forms.FormProps<any>

const SiteScalingSliderField: React.FC<Props> = (props) => {
    const { initialValues } = props

    const name = 'resources.autoscaler'
    const isDisabled = isEmpty(get(initialValues, name))

    const steps = 128
    const scaleRenderer = (value: number) => {
        if (value === 1
            || value === steps
            || (value < 64 && value % 8 === 7)
            || (value === 73)
            || (value >= 73 && value < 116 && value % 12 === 1)) {
            return toString(value)
        }
        return null
    }

    return (
        <Field
            name={ name }
            component={ RangeSliderField }
            steps={ steps }
            scaleRenderer={ scaleRenderer }
            disabled={ isDisabled }
            intent={ Intent.SUCCESS }
            icon="cube"
            parse={ (allocation: Maybe<sites.IAutoscaler>) => {
                if (!allocation) {
                    return [
                        sites.DEFAULT_AUTOSCALER.minReplicas,
                        sites.DEFAULT_AUTOSCALER.maxReplicas
                    ]
                }

                return [
                    get(allocation, 'minReplicas', sites.DEFAULT_AUTOSCALER.minReplicas),
                    get(allocation, 'maxReplicas', sites.DEFAULT_AUTOSCALER.maxReplicas)
                ]
            } }
            format={ (value: [any, any], rawValue: sites.IAutoscaler) => {
                if (!rawValue) {
                    return {}
                }

                return {
                    ...rawValue,
                    minReplicas : parseInt(value[0], 10),
                    maxReplicas : parseInt(value[1], 10)
                }
            } }
            isFat
        />
    )
}

export default SiteScalingSliderField
