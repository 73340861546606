import * as React from 'react'
import { connect } from 'react-redux'
import { Menu, MenuDivider, PopoverPosition } from '@blueprintjs/core'
import { Select as BaseSelect } from '@blueprintjs/select'

import { map, reduce, flatMap, values, get } from 'lodash'

import { useTranslation, useDispatch } from '../hooks'
import {
    RootState, api, navigation, projects, sites, mysqlClusters, memcacheds, prometheuses, grafanas,
    routing, ui
} from '../redux'
import { Maybe, arePropsEqual, matchesQuery } from '../utils'

import ResourceTitle from '../components/ResourceTitle'
import MenuItem from '../components/MenuItem'
import Avatar from '../components/Avatar'
import Button from '../components/Button'

import styles from './BreadcrumbResourceSelect.module.scss'

type SelectableItem = projects.IProject
    | sites.ISite
    | mysqlClusters.IMySQLCluster
    | memcacheds.IMemcached
    | prometheuses.IPrometheus
    | grafanas.IGrafana

type ReduxProps = {
    peerResources: api.GroupedResourcesList
    action: Maybe<routing.Param>
    tab: Maybe<routing.Param>
    currentRoute: routing.Route
}

type OwnProps = {
    resource: api.Resource
    selectedItem: SelectableItem
}

type Props = OwnProps & ReduxProps

const Select = BaseSelect.ofType<SelectableItem>()

const BreadcrumbResourceSelect: React.FC<Props> = React.memo((props) => {
    const { resource, peerResources, action } = props
    const dispatch = useDispatch()
    const [t] = useTranslation()
    const { Fragment } = React

    const itemGroups = reduce(peerResources, (acc, list, scope) => ({
        ...acc,
        ...reduce(list, (acc, _, name) => ({
            ...acc,
            [name]: scope
        }), {})
    }), {})

    const groupItems = (items: SelectableItem[]): Record<string, SelectableItem[]> => (
        reduce(items, (acc, item) => {
            const group = itemGroups[item?.name]

            if (!group) {
                return acc
            }

            return {
                ...acc,
                [group]: [...get(acc, group, []), item]
            }
        }, {})
    )

    return (
        <Select
            popoverProps={ { minimal: true, position: PopoverPosition.BOTTOM } }
            items={ flatMap(values(peerResources), values) }
            onItemSelect={ (item: SelectableItem) => {
                const resourceToSelect = api.getResourceFromName(item.name)
                const params = api.isResource(resourceToSelect)
                    && navigation.isValidMenuEntry(resourceToSelect, action)
                    ? { action } : {}
                dispatch(routing.push(routing.routeForResource(item, params)))
            } }
            itemPredicate={ (query: string, item: Maybe<SelectableItem>) => {
                if (!item) {
                    return false
                }

                return matchesQuery(item.name, query) || matchesQuery(api.displayName(item), query)
            } }
            itemListRenderer={ ({ items, filteredItems, renderItem }) => {
                const groupedItems = groupItems(filteredItems)

                return (
                    <Menu>
                        { map([
                            api.Resource.organization,
                            api.Resource.project,
                            api.Resource.site,
                            api.Resource.mysqlCluster,
                            api.Resource.memcached,
                            api.Resource.prometheus,
                            api.Resource.grafana
                        ], (scope) => {
                            if (!groupedItems[scope]) {
                                return null
                            }
                            return (
                                <Fragment key={ scope }>
                                    <MenuDivider title={ t(ui.resourceDisplayName(scope, false)) } />
                                    { map(groupedItems[scope], renderItem) }
                                </Fragment>
                            )
                        }) }
                    </Menu>
                )
            } }
            itemRenderer={ (item: SelectableItem, { handleClick }) => (
                <MenuItem
                    key={ item.name }
                    onClick={ handleClick }
                    className={ styles.menuItem }
                    text={
                        <ResourceTitle
                            entry={ item }
                            resource={ api.getResourceFromName(item.name) ?? resource }
                            isThin
                            isCompact
                            withAvatar
                            minimal
                            avatar={ (
                                <Avatar
                                    entry={ item }
                                    imageURL={ ui.avatarURL(item) }
                                    size={ 32 }
                                />
                            ) }
                        />
                    }
                />
            ) }
            inputProps={ {
                placeholder: t('Filter items...')
            } }
            filterable
        >
            <Button
                minimal
                icon="caret-s"
                className={ styles.trigger }
                onClick={ (e: React.SyntheticEvent<HTMLElement>) => {
                    e.preventDefault()
                } }
            />
        </Select>
    )
}, arePropsEqual(['items', 'selectedItem', 'action']))

function mapStateToProps(state: RootState, ownProps: OwnProps): ReduxProps {
    const { resource, selectedItem } = ownProps

    const action = routing.getParam('action')(state)
    const tab = routing.getParam('tab')(state)
    const currentRoute = routing.getCurrentRoute(state)
    const peerResources = navigation.getPeerResources({ name: selectedItem.name, resource })(state)

    return {
        action,
        tab,
        currentRoute,
        peerResources
    }
}

export default connect(mapStateToProps)(BreadcrumbResourceSelect)
