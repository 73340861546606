import * as React from 'react'
import { Intent, Elevation } from '@blueprintjs/core'

import { api, sites, ui, navigation } from '../redux'
import { useDispatch, useTranslation } from '../hooks'

import Container from '../components/Container'
import TitleBar from '../components/TitleBar'
import Card from '../components/Card'
import ConfirmButton from '../components/ConfirmButton'

type Props = {
    entry: sites.ISite
}

const SiteGeneralForm: React.FC<Props> = (props) => {
    const { entry } = props
    const [t] = useTranslation()
    const dispatch = useDispatch()

    return (
        <Container>
            <TitleBar
                title={ t(ui.createTitle(api.Resource.site, navigation.MenuEntry.general)) }
            />
            <Card
                title={ t(ui.createTitle(api.Resource.site, api.Request.destroy)) }
                subtitle={ t('Once you delete a site, all the allocated resources will also be deleted.') }
                elevation={ Elevation.ZERO }
                intent={ Intent.DANGER }
            >
                <ConfirmButton
                    onConfirm={ () => dispatch(sites.destroy(entry)) }
                    confirmationText={ t(ui.createTitle(api.Resource.site, ui.Action.confirm)) }
                    text={ t(ui.createTitle(api.Resource.site, api.Request.destroy)) }
                />
            </Card>
        </Container>
    )
}

export default SiteGeneralForm
