/* eslint-disable no-use-before-define */

import { ActionType, isOfType as isActionOfType } from 'typesafe-actions'
import { takeEvery, fork, call } from 'redux-saga/effects'
import { createSelector } from 'reselect'

import { pick, keys, get as _get } from 'lodash'

import { bitpoke } from '@bitpoke/bitpoke-proto'

import {
    RootState, Selector, AnyAction, ActionWithPayload,
    api, routing, grpc, forms, sites, ui
} from '../redux'

const {
    Project,
    ProjectsService,
    ListProjectsRequest,
    ListProjectsResponse,
    GetProjectRequest,
    CreateProjectRequest,
    UpdateProjectRequest,
    UpdateProjectResourceQuotasRequest,
    DeleteProjectRequest
} = bitpoke.projects.v1

export {
    Project,
    ProjectsService,
    ListProjectsRequest,
    ListProjectsResponse,
    GetProjectRequest,
    CreateProjectRequest,
    UpdateProjectRequest,
    UpdateProjectResourceQuotasRequest,
    DeleteProjectRequest
}


//
//  TYPES

export type ProjectName = string
export interface IProject extends bitpoke.projects.v1.IProject {
    name: ProjectName
}
export type Project                             = bitpoke.projects.v1.Project
export type IProjectPayload                     = bitpoke.projects.v1.IProject
export type Resource                            = bitpoke.projects.v1.Resource
export type IResource                           = bitpoke.projects.v1.IResource
export type ResourceQuotas                      = bitpoke.projects.v1.ResourceQuotas
export type IResourceQuotas                     = bitpoke.projects.v1.IResourceQuotas
export type IListProjectsRequest                = bitpoke.projects.v1.IListProjectsRequest
export type IListProjectsResponse               = bitpoke.projects.v1.IListProjectsResponse
export type ListProjectsResponse                = bitpoke.projects.v1.ListProjectsRequest
export type IGetProjectRequest                  = bitpoke.projects.v1.IGetProjectRequest
export type ICreateProjectRequest               = bitpoke.projects.v1.ICreateProjectRequest
export type IUpdateProjectRequest               = bitpoke.projects.v1.IUpdateProjectRequest
export type IUpdateProjectResourceQuotasRequest = bitpoke.projects.v1.IUpdateProjectResourceQuotasRequest
export type IDeleteProjectRequest               = bitpoke.projects.v1.IDeleteProjectRequest

export type State = api.State<IProject>
export type Actions = ActionType<typeof actions>

export enum FieldName {
    displayName = 'Name',
    iconUrl = 'Icon'
}

export enum QuotaResourceType {
    cpu = 'cpu',
    memory = 'memory',
    pods = 'pods',
    sites = 'sites'
}

export enum QuotaResourceName {
    cpu = 'CPU',
    memory = 'Memory',
    pods = 'Pods',
    sites = 'Sites'
}

const service = ProjectsService.create(
    grpc.createTransport('bitpoke.projects.v1.ProjectsService')
)

export const { parseName, buildName } = api.createNameHelpers(api.Resource.project)


//
//  ACTIONS

export const get = (payload: IGetProjectRequest) => grpc.invoke({
    service,
    method       : 'getProject',
    data         : GetProjectRequest.create(payload),
    responseType : Project
})

export const list = (payload?: IListProjectsRequest) => grpc.invoke({
    service,
    method       : 'listProjects',
    data         : ListProjectsRequest.create(payload),
    responseType : ListProjectsResponse
})

export const create = (payload: ICreateProjectRequest) => grpc.invoke({
    service,
    method       : 'createProject',
    data         : CreateProjectRequest.create(payload),
    responseType : Project
})

export const update = (payload: IUpdateProjectRequest) => grpc.invoke({
    service,
    method       : 'updateProject',
    data         : UpdateProjectRequest.create(payload),
    responseType : Project
})

export const updateResourceQuotas = (payload: IUpdateProjectResourceQuotasRequest) => grpc.invoke({
    service,
    method       : 'updateProjectResourceQuotas',
    data         : UpdateProjectResourceQuotasRequest.create(payload),
    responseType : Project
})

export const destroy = (payload: IProjectPayload) => grpc.invoke({
    service,
    method : 'deleteProject',
    data   : DeleteProjectRequest.create(payload)
})

const actions = {
    get,
    list,
    create,
    update,
    destroy
}

const apiTypes = api.createActionTypes(api.Resource.project)

export const {
    LIST_REQUESTED,    LIST_SUCCEEDED,    LIST_FAILED,
    GET_REQUESTED,     GET_SUCCEEDED,     GET_FAILED,
    CREATE_REQUESTED,  CREATE_SUCCEEDED,  CREATE_FAILED,
    UPDATE_REQUESTED,  UPDATE_SUCCEEDED,  UPDATE_FAILED,
    DESTROY_REQUESTED, DESTROY_SUCCEEDED, DESTROY_FAILED
} = apiTypes


//
//  REDUCER

const apiReducer = api.createReducer(api.Resource.project, apiTypes)

export function reducer(state: State = api.initialState, action: AnyAction) {
    switch (action.type) {
        case sites.CREATE_SUCCEEDED:
        case sites.DESTROY_SUCCEEDED: {
            const siteName: sites.SiteName = isActionOfType(sites.CREATE_SUCCEEDED, action)
                ? _get(action, 'payload.data.name')
                : _get(action, 'payload.request.data.name')

            const projectName = parseName(siteName).name

            if (!projectName) {
                return state
            }

            const project = _get(state.entries, projectName)

            if (!project) {
                return state
            }

            const sitesCount = isActionOfType(sites.CREATE_SUCCEEDED, action)
                ? (project.sitesCount || 0) + 1
                : (project.sitesCount || 1) - 1

            return {
                ...state,
                entries: {
                    ...state.entries,
                    [project.name]: {
                        ...project,
                        sitesCount
                    }
                }
            }
        }

        default:
            return apiReducer(state, action)
    }
}


//
//  SAGA

export function* saga() {
    yield fork(api.emitResourceActions, api.Resource.project, apiTypes)
    yield fork(forms.takeEverySubmission, keys(FORM_HANDLERS) as forms.Name[], handleFormSubmission)
    yield takeEvery([
        CREATE_SUCCEEDED, CREATE_FAILED,
        UPDATE_SUCCEEDED, UPDATE_FAILED,
        DESTROY_SUCCEEDED, DESTROY_FAILED
    ], ui.notifyAboutAction)

    yield takeEvery([
        CREATE_SUCCEEDED,
        UPDATE_SUCCEEDED,
        DESTROY_SUCCEEDED
    ], performRedirect)
}

const FORM_HANDLERS: forms.HandlerMapping = {
    [forms.Name.createProject]               : create,
    [forms.Name.updateProjectSettings]       : update,
    [forms.Name.updateProjectResourceQuotas] : updateResourceQuotas
}

const handleFormSubmission = forms.createHandlers(FORM_HANDLERS, apiTypes)

function* performRedirect(action: ActionWithPayload) {
    switch (action.type) {
        case CREATE_SUCCEEDED:
            yield call(routing.redirectToResource, action)
            break

        default:
            break
    }
}

export function isProject(entry: any): entry is IProject {
    return api.isOfType(api.Resource.project, entry)
}


//
//  SELECTORS

const selectors: api.Selectors<IProject> = api.createSelectors(api.Resource.project)
export const {
    getState,
    getAll,
    countAll,
    getByName,
    getForURL,
    getForCurrentURL,
    getForOrganization,
    getForCurrentOrganization
} = selectors

export const isNotFound: Selector<boolean> = createSelector(
    [routing.getCurrentRoute, (state: RootState) => pick(state, 'grpc')],
    (currentRoute: routing.Route, state) => {
        const parsedName = parseName(currentRoute.url)
        if (!parsedName.name) {
            return false
        }

        const request: grpc.Request = get({ name: parsedName.name }).payload
        return grpc.isFailedRequest(request)(state as RootState)
    }
)
