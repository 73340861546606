import * as React from 'react'
import { FormGroup as BaseFormGroup, Intent, IFormGroupProps } from '@blueprintjs/core'

import { map, isEmpty } from 'lodash'

import styles from './FormGroup.module.scss'

type Props = IFormGroupProps & {
    isMonospaced?: boolean
    errors?: string[]
    name?: string
}

const FormGroup: React.FC<Props> = (props) => {
    const { errors, name, intent, helperText, isMonospaced } = props

    return (
        <BaseFormGroup
            { ...props }
            labelFor={ name }
            helperText={ !errors || isEmpty(errors) ? helperText : renderErrors(errors) }
            intent={ intent ? intent : !isEmpty(errors) ? Intent.DANGER : Intent.PRIMARY }
            className={ isMonospaced ? styles.monospaced : undefined }
        />
    )
}

const renderErrors = (errors: string[]) => (
    <>
        { map(errors, (error: string, index: number) => <span key={ index }>{ error }</span>) }
    </>
)

export default FormGroup
