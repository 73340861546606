import * as React from 'react'
import classes from 'classnames'

import { isString, isNumber } from 'lodash'
import { Tag, Classes } from '@blueprintjs/core'

import { routing } from '../redux'

import Link from '../components/Link'

import styles from './TitleBar.module.scss'

export type Props = {
    title?: React.ReactNode
    subtitle?: React.ReactNode
    leftItem?: React.ReactNode
    rightItem?: React.ReactNode
    tag?: React.ReactNode
    link?: routing.Path | null
    icon?: React.ReactNode
    onClick?: () => void
    className?: string
} & ModifierProps

export type ModifierProps = {
    isCompact?: boolean
    isInteractive?: boolean
    isCentered?: boolean
    isStretched?: boolean
    isPadded?: boolean
    isSmall?: boolean
    isThin?: boolean
    isBordered?: boolean
}

const TitleBar: React.FC<Props> = (props) => {
    const { leftItem, rightItem } = props

    return (
        <Container { ...props }>
            <Title { ...props } />
            { React.isValidElement(leftItem) && <div className={ styles.leftItem }>{ leftItem }</div> }
            { React.isValidElement(rightItem) && <div className={ styles.rightItem }>{ rightItem }</div> }
        </Container>
    )
}

const Container: React.FC<Props> = (props) => {
    const {
        isCompact, isInteractive, isCentered, isStretched,
        isPadded, isSmall, isThin, isBordered,
        className, children
    } = props
    const containerStyles = classes(
        className,
        styles.container,
        isCompact && styles.compact,
        isCentered && styles.centered,
        isInteractive && styles.interactive,
        isStretched && styles.stretched,
        isBordered && styles.bordered,
        isPadded && styles.padded,
        isSmall && styles.small,
        isThin && styles.thin
    )

    return <div className={ containerStyles }>{ children }</div>
}

const Title: React.FC<Props> = (props) => {
    const { title, subtitle, icon, tag, link, onClick } = props

    const content = (
        <>
            { React.isValidElement(icon) && <div className={ styles.icon }>{ icon }</div> }
            <div className={ styles.title }>
                <h2>
                    { title }
                    { (isString(tag) || isNumber(tag)) && <Tag minimal round>{ tag }</Tag> }
                    { React.isValidElement(tag) && tag }
                </h2>
                { subtitle && <h4 className={ Classes.TEXT_MUTED }>{ subtitle }</h4> }
            </div>
        </>
    )

    return isString(link) || onClick
        ? (
            <Link
                className={ styles.wrapper }
                to={ isString(link) ? link : undefined }
                onClick={ onClick }
            >
                { content }
            </Link>
        ) : <div className={ styles.wrapper }>{ content }</div>
}

TitleBar.defaultProps = {
    isCompact     : false,
    isInteractive : false,
    isCentered    : false,
    isStretched   : false,
    isSmall       : false
}

export default TitleBar
