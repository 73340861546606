import * as React from 'react'
import { Flex, Box } from 'reflexbox'

import { get } from 'lodash'

import { resources } from '../redux'
import { useTranslation } from '../hooks'
import { formatQuantity } from '../utils'

import styles from './TotalResourceUsageLabel.module.scss'

type Props = {
    entry: unknown
}

const TotalResourceUsageLabel: React.FC<Props> = (props) => {
    const { entry } = props
    const [t] = useTranslation()

    const memory = resources.computeTotalResourceUsage(entry, resources.ResourceType.memoryPerPod)
    const cpu = resources.computeTotalResourceUsage(entry, resources.ResourceType.cpuPerPod)

    return (
        <div className={ styles.container }>
            <Flex justify="space-between">
                <Flex column w={ 0.6 }>
                    <Box>
                        <ResourceUsageLabel resource={ memory } />
                    </Box>
                    <Box>
                        <strong>{ t('Total memory allocation') }</strong>
                    </Box>
                </Flex>
                <Flex column w={ 0.4 }>
                    <Box>
                        <ResourceUsageLabel resource={ cpu } omitUnit />
                    </Box>
                    <Box>
                        <strong>{ t('Total CPU allocation') }</strong>
                    </Box>
                </Flex>
            </Flex>
        </div>
    )
}

type LabelProps = {
    resource: resources.ResourceUsage
    omitUnit?: boolean
}

const ResourceUsageLabel: React.FC<LabelProps> = (props) => {
    const { resource } = props
    const omitUnit = get(props, 'omitUnit', false)
    const [t] = useTranslation()

    if (resource.minimum === resource.maximum) {
        return (
            <div className={ styles.resource }>
                <span>{ t('max') } </span>
                <strong>{ formatQuantity(resource.minimum, { omitUnit }) }</strong>
            </div>
        )
    }

    return (
        <div className={ styles.resource }>
            <span>{ t('min') } </span>
            <strong>{ formatQuantity(resource.minimum, { omitUnit }) }</strong>
            <span>&nbsp;&mdash;&nbsp;</span>
            <span>{ t('max') } </span>
            <strong>{ formatQuantity(resource.maximum, { omitUnit }) }</strong>
        </div>
    )
}

export default TotalResourceUsageLabel
