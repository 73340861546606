import * as React from 'react'

import { Switch, ISwitchProps } from '@blueprintjs/core'

import { get, pick } from 'lodash'

import { forms } from '../redux'

import FormGroup from '../components/FormGroup'

const SwitchField: React.FC<forms.FieldProps> = (props) => {
    const { field, form, label } = props
    const { name } = field

    const errors = forms.getFieldErrors(form.errors, name)

    const inputProps = pick(props, [
        'autoFocus', 'style', 'className', 'inline', 'label'
    ]) as Partial<ISwitchProps>

    return (
        <FormGroup
            errors={ forms.formatFieldErrors(label, errors) }
            { ...field }
            { ...pick(props, ['helperText']) }
        >
            <Switch
                id={ name }
                { ...inputProps }
                { ...field }
                checked={ get(field, 'value', false) }
            />
        </FormGroup>
    )
}

export default SwitchField
