import * as React from 'react'
import { Flex, Box } from 'reflexbox'
import { CircularProgressbar } from 'react-circular-progressbar'
import { Tooltip, Intent, Classes } from '@blueprintjs/core'
import classes from 'classnames'

import TitleBar from '../components/TitleBar'

import styles from './ScalingGauge.module.scss'

type Props = {
    title?: string
    subtitle?: string
    maxValue: number
    currentValue: number
    intent?: Intent
}

const ScalingGauge: React.FC<Props> = (props) => {
    const { title, subtitle, maxValue, currentValue, intent } = props
    return (
        <div className={ styles.container }>
            <Tooltip content={ subtitle }>
                <Flex align="center">
                    <Box w={ 120 }>
                        <CircularProgressbar
                            value={ (currentValue / maxValue) * 100 }
                            text={ `${currentValue}/${maxValue}` }
                            strokeWidth={ 20 }
                            className={ classes(
                                styles.indicator,
                                Classes.intentClass(intent),
                                currentValue < maxValue && styles.error
                            ) }
                        />
                    </Box>
                    <Box mx={ 2 } mb={ -2 }>
                        <TitleBar title={ title } isSmall />
                    </Box>
                </Flex>
            </Tooltip>
        </div>
    )
}

export default ScalingGauge
