import * as React from 'react'

import { api, routing, sites, projects } from '../redux'
import { useDispatch, useTranslation } from '../hooks'
import { formatDistance } from '../utils'

import Table, { TableColumn, TableCell, TableCellProps, CellGetterProps, getCellData } from '../components/Table'
import ContextMenu from '../components/ContextMenu'
import BlankSlate from '../components/BlankSlate'
import SiteTitle from '../components/SiteTitle'
import SiteIcon from '../assets/icons/SiteIcon'

type Props = {
    project: projects.ProjectName
}

const SitesList: React.FC<Props> = (props) => {
    const { project } = props
    const dispatch = useDispatch()
    const [t] = useTranslation()

    if (!project) {
        return null
    }

    const columns: TableColumn[] = [
        {
            label        : t('Name'),
            dataKey      : 'name',
            cellRenderer : (props: TableCellProps) => (
                <TableCell>
                    <SiteTitle
                        entry={ props.rowData }
                        isCompact
                        isStretched
                        withAvatar
                    />
                </TableCell>
            )
        },
        {
            label          : t('Created'),
            dataKey        : 'createdAt',
            cellDataGetter : (props: CellGetterProps) => (
                formatDistance(getCellData(props))
            )
        },
        {
            dataKey      : 'actions',
            width        : 100,
            flexGrow     : 0,
            cellRenderer : (props: TableCellProps) => (
                <TableCell align="flex-end" onClick={ (e) => e.stopPropagation() }>
                    <ContextMenu
                        entry={ props.rowData }
                        resource={ api.Resource.site }
                    />
                </TableCell>
            )
        }
    ]

    return (
        <Table
            itemsRequest={ sites.list({ project }) }
            itemsSelector={ sites.getForProject(project) }
            onRowClick={ ({ rowData }) => {
                dispatch(routing.push(routing.routeForResource(rowData)))
            } }
            emptyRenderer={ () => (
                <BlankSlate
                    icon={ <SiteIcon layers={ 3 } /> }
                    title={ t('You don\'t have any sites yet.') }
                    description={
                        t('Create your first site by clicking the + button at the upper right of the project pane')
                    }
                />
            ) }
            columns={ columns }
        />
    )
}

export default SitesList
