import * as React from 'react'
import { Intent, Tag } from '@blueprintjs/core'

import { MultiSelect } from '@blueprintjs/select'

import {
    map, filter, find, get, pick, identity,
    concat, without, compact, sortBy,
    includes, isEmpty, isEqual
} from 'lodash'

import { forms } from '../redux'
import { useCallback } from '../hooks'
import { SelectableItem, SelectableItemParser, parseSelectableItem, arePropsEqual, matchesQuery } from '../utils'

import FormGroup from '../components/FormGroup'
import MenuItem from '../components/MenuItem'
import StatusIcon from '../components/StatusIcon'

import styles from './MultiSelectField.module.scss'

const Selector = MultiSelect.ofType<SelectableItem>()

export type Props = forms.FieldProps & {
    items: any[]
    itemParser?: SelectableItemParser
    allowCreate?: boolean
    itemCreator?: (query: string) => any
    createItemRenderer?: (
        query: string,
        active: boolean,
        handleClick: React.MouseEventHandler<HTMLElement>
    ) => JSX.Element | undefined
}

const defaultCreateItemRenderer = (
    query: string,
    active: boolean,
    handleClick: React.MouseEventHandler<HTMLElement>
) => (
    <MenuItem
        icon="add"
        text={ `Add "${query}"` }
        active={ active }
        onClick={ handleClick }
    />
)

const MultiSelectField: React.FC<Props> = React.memo((props) => {
    const { field, form, label, placeholder } = props
    const { name } = field

    const errors = forms.getFieldErrors(form.errors, name)
    const intent = props.intent || !isEmpty(errors) ? Intent.DANGER : Intent.NONE
    const itemParser = props.itemParser || parseSelectableItem
    const selectedItems = sortBy(map(field.value, itemParser), 'label')

    const { allowCreate } = props
    const createItemRenderer = allowCreate ? props.createItemRenderer || defaultCreateItemRenderer : undefined
    const itemCreator = allowCreate ? (query: string) => {
        const create = props.itemCreator || identity
        return itemParser(create(query))
    } : undefined

    const items = sortBy(compact(map(get(props, 'items', []), itemParser)), 'label')

    const isSelected = useCallback((item: SelectableItem) => (
        !!find(selectedItems, { value: item.value })
    ), [selectedItems])

    return (
        <FormGroup
            errors={ forms.formatFieldErrors(label, errors) }
            label={ label }
            { ...field }
            { ...pick(props, ['helperText', 'inline', 'isMonospaced']) }
            intent={ intent }
        >
            <Selector
                onItemSelect={ (item: SelectableItem) => {
                    !includes(items, item) || !isSelected(item)
                        ? form.setFieldValue(name, concat(field.value, item.value))
                        : form.setFieldValue(name, without(field.value, item.value))
                } }
                itemRenderer={ (item: SelectableItem, { handleClick, modifiers }) => {
                    const isItemSelected = isSelected(item)
                    return (
                        <MenuItem
                            key={ item.value }
                            onClick={ handleClick }
                            active={ modifiers.active }
                            labelElement={
                                isItemSelected
                                    ? <StatusIcon intent={ Intent.SUCCESS } />
                                    : null
                            }
                            text={ item.label || item.value }
                        />
                    )
                } }
                createNewItemRenderer={ createItemRenderer }
                createNewItemFromQuery={ itemCreator }
                placeholder={ placeholder }
                items={ items }
                itemsEqual={ (a, b) => isEqual(a?.value, b?.value) }
                tagRenderer={ (item: SelectableItem) => item ? <Tag>{ item.label }</Tag> : null }
                selectedItems={ selectedItems }
                itemListPredicate={ (query: string, items: SelectableItem[]) => (
                    filter(items, (item) => matchesQuery(item.value, query) || matchesQuery(item.label, query))
                ) }
                tagInputProps={ {
                    onRemove: (tag: unknown, index: number) => {
                        const itemToRemove = selectedItems[index]
                        if (itemToRemove) {
                            form.setFieldValue(name, without(field.value, itemToRemove.value))
                        }
                    },
                    onKeyDown: (event: any) => {
                        if (event && event.keyCode === 13) {
                            event.preventDefault()
                        }
                    }
                } }
                popoverProps={ {
                    popoverClassName: styles.popover
                } }
                openOnKeyDown
                resetOnSelect
                { ...field }
            />
        </FormGroup>
    )
}, arePropsEqual(['field.name', 'field.value']))

export default MultiSelectField
