import * as React from 'react'
import { Field } from 'formik'
import { Flex, Box } from 'reflexbox'

import { isEmpty } from 'lodash'

import { api, forms, sites, ui, navigation, errors } from '../redux'
import { useTranslation, useDispatch, useInterval } from '../hooks'
import { POLL_INTERVAL } from '../utils'

import { withForm } from '../components/Form'
import TitleBar from '../components/TitleBar'
import Container from '../components/Container'
import FormContainer from '../components/FormContainer'
import ErrorMessage from '../components/ErrorMessage'
import InputField from '../components/InputField'
import CheckboxField from '../components/CheckboxField'

type Props = forms.FormProps<sites.ISite>

const SiteSMTPForm: React.FC<Props> = (props) => {
    const { initialValues } = props
    const [t] = useTranslation()
    const dispatch = useDispatch()

    const isEditable = !isEmpty(initialValues.smtp)

    useInterval(() => {
        if (!isEditable) {
            dispatch(sites.get({ name: initialValues.name }))
        }
    }, POLL_INTERVAL.default)

    if (!isEditable) {
        return <ErrorMessage error={ errors.Error.siteProvisioning } />
    }

    return (
        <Container>
            <FormContainer
                header={ (
                    <TitleBar
                        title={ t(ui.createTitle(api.Resource.site, navigation.MenuEntry.smtp)) }
                    />
                ) }
                { ...props }
            >
                <Flex w={ 0.5 } column>
                    <Flex justify="space-between" mb={ 2 }>
                        <Box w={ 0.5 } mr={ 3 }>
                            <Field
                                name="smtp.user"
                                label={ t(sites.SMTPFieldName.user) }
                                helperText={ t(sites.SMTPFieldDescription.user) }
                                component={ InputField }
                                autoFocus
                            />
                        </Box>
                        <Box w={ 0.5 }>
                            <Field
                                name="smtp.password"
                                label={ t(sites.SMTPFieldName.password) }
                                helperText={ t(sites.SMTPFieldDescription.password) }
                                component={ InputField }
                                type="password"
                            />
                        </Box>
                    </Flex>
                    <Flex justify="space-between" mb={ 2 }>
                        <Box w={ 0.5 } mr={ 3 }>
                            <Field
                                name="smtp.host"
                                label={ t(sites.SMTPFieldName.host) }
                                helperText={ t(sites.SMTPFieldDescription.host) }
                                component={ InputField }
                            />
                        </Box>
                        <Box w={ 0.5 }>
                            <Field
                                name="smtp.port"
                                label={ t(sites.SMTPFieldName.port) }
                                helperText={ t(sites.SMTPFieldDescription.port) }
                                component={ InputField }
                            />
                        </Box>
                    </Flex>
                    <Flex justify="space-between">
                        <Box w={ 0.5 } mr={ 3 }>
                            <Field
                                name="smtp.useTls"
                                label={ t(sites.SMTPFieldName.useTLS) }
                                helperText={ t(sites.SMTPFieldDescription.useTLS) }
                                component={ CheckboxField }
                                inline
                            />
                        </Box>
                        <Box w={ 0.5 }>
                            <Field
                                name="smtp.useStartTls"
                                label={ t(sites.SMTPFieldName.startTLS) }
                                helperText={ t(sites.SMTPFieldDescription.startTLS) }
                                component={ CheckboxField }
                                inline
                            />
                        </Box>
                    </Flex>
                </Flex>
            </FormContainer>
        </Container>
    )
}

export default withForm({ name: forms.Name.updateSiteSMTP })(SiteSMTPForm)
