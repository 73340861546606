import * as React from 'react'
import { Intent } from '@blueprintjs/core'
import { Flex } from 'reflexbox'

import {
    map, filter, find, sortBy, difference, without, compact,
    get, head, indexOf, includes, isEqual, isObject
} from 'lodash'

import Table, { TableColumn, TableCellProps, TableCell } from '../components/Table'
import Button from '../components/Button'
import FormGroup from '../components/FormGroup'
import DiscardButton from '../components/DiscardButton'
import NodeLabelSelect from '../components/NodeLabelSelect'
import SchedulingPreferenceButtonGroupSelect from '../components/SchedulingPreferenceButtonGroupSelect'

import { forms, nodes, affinities } from '../redux'
import { useTranslation, useState, useEffect } from '../hooks'
import { Maybe, Modify, splice } from '../utils'

type Props = forms.FieldProps<affinities.INodeAffinity> & {
    disabled?: boolean
}
type EmptyRuleRow = Modify<affinities.SchedulingRule, { label: Maybe<nodes.Label> }>
type RuleRow = (affinities.SchedulingRule | EmptyRuleRow) & {
    isUnsaved?: boolean
}
const EMPTY_RULE: EmptyRuleRow = {
    label      : null,
    preference : affinities.SchedulingPreference.always
}

const NodeAffinityField: React.FC<Props> = (props) => {
    const { field, form } = props
    const { value } = field

    const initialValue = get(form.initialValues, field.name)
    const initialRules = affinities.parseNodeAffinity(initialValue)

    const [rules, setRules] = useState(initialRules)
    const [ruleOrdering, setRuleOrdering] = useState(map(initialRules, 'label.key'))

    const ruleLabels = map(rules, 'label.key')

    useEffect(() => {
        const initialRules = affinities.parseNodeAffinity(initialValue)
        const rules = map(affinities.parseNodeAffinity(value), (rule, index) => ({
            ...rule,
            isUnsaved: !isEqual(rule, find(initialRules, ['label.key', rule.label.key]))
        }))

        const ruleLabels = map(rules, 'label.key')
        const addedRule = head(difference(ruleLabels, ruleOrdering))
        const removedRule = head(difference(ruleOrdering, ruleLabels))

        if (addedRule && removedRule) {
            const newOrder = splice(ruleOrdering, indexOf(ruleLabels, removedRule), addedRule)
            const sortedRules = sortBy(rules, (rule) => indexOf(newOrder, rule.label.key))
            setRuleOrdering(newOrder)
            setRules(sortedRules)
        }
        else if (addedRule || removedRule) {
            const newOrder = compact([...without(ruleOrdering, removedRule), addedRule])
            const sortedRules = sortBy(rules, (rule) => indexOf(newOrder, rule.label.key))
            setRuleOrdering(newOrder)
            setRules(sortedRules)
        }
        else {
            const sortedRules = sortBy(rules, (rule) => indexOf(ruleOrdering, rule.label.key))
            setRules(sortedRules)
        }
    }, [value, initialValue, ruleOrdering])

    const [t] = useTranslation()

    const updateRules = (index: number, updates?: any) => {
        const rule: RuleRow = get(rules, index, EMPTY_RULE)
        const updatedRule = { ...rule, ...updates }
        const updatedRules = updates
            ? splice(rules, index, updatedRule)
            : filter(rules, (value, currentIndex) => index !== currentIndex)

        const validRules = filter(updatedRules, (rule) => isObject(rule.label))
        form.setFieldValue(field.name, affinities.buildNodeAffinity(validRules))
    }

    const columns: TableColumn[] = [
        {
            label        : t('Node Label Selector'),
            dataKey      : 'label',
            cellRenderer : (props: TableCellProps) => (
                <TableCell>
                    <NodeLabelSelect
                        selectedItem={ props.cellData }
                        itemPredicate={ (label: nodes.Label) => !includes(ruleLabels, label.key) }
                        onChange={ (label: nodes.Label) => (
                            updateRules(props.rowIndex, { label })
                        ) }
                        trigger={
                            props.rowData === EMPTY_RULE ? (
                                <Button
                                    text={ t('Add a new rule') }
                                    icon="add"
                                    intent={ Intent.SUCCESS }
                                />
                            ) : null
                        }
                    />
                </TableCell>
            )
        },
        {
            label        : t('Scheduling Mode'),
            dataKey      : 'preference',
            cellRenderer : (props: TableCellProps) => (
                <TableCell>
                    { props.rowData !== EMPTY_RULE && (
                        <SchedulingPreferenceButtonGroupSelect
                            selectedItem={ props.cellData }
                            onChange={ (preference: affinities.SchedulingPreference) => (
                                updateRules(props.rowIndex, { preference })
                            ) }
                        />
                    ) }
                </TableCell>
            )
        },
        {
            dataKey      : 'actions',
            width        : 110,
            cellRenderer : (props: TableCellProps) => (
                <TableCell>
                    <Flex>
                        { props.rowData !== EMPTY_RULE && (
                            <Button
                                icon="bin-full"
                                minimal
                                intent={ Intent.DANGER }
                                onClick={ () => updateRules(props.rowIndex) }
                                isSquare
                            />
                        ) }
                        { props.rowData.isUnsaved && (
                            <DiscardButton
                                onClick={ () => {
                                    const initialRule = find(initialRules, ['label.key', props.rowData.label.key])
                                        || get(initialRules, props.rowIndex)
                                    updateRules(props.rowIndex, initialRule)
                                } }
                            />
                        ) }
                    </Flex>
                </TableCell>
            )
        }
    ]

    return (
        <FormGroup>
            <Table
                items={ [...rules, EMPTY_RULE] }
                columns={ columns }
                isSortable={ false }
            />
        </FormGroup>
    )
}

export default NodeAffinityField
