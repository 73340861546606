import * as React from 'react'
import { connect } from 'react-redux'
import { Tag, Tooltip, Intent, Position } from '@blueprintjs/core'
import { Flex, Box } from 'reflexbox'

import { get, size } from 'lodash'

import { useTranslation } from '../hooks'
import { RootState, nodes } from '../redux'

type ReduxProps = {
    schedulableCount: number
    unschedulableCount: number
}

type OwnProps = {
    label: nodes.Label
}

type Props = OwnProps & ReduxProps

const NodeCounters: React.FC<Props> = (props) => {
    const { schedulableCount, unschedulableCount } = props
    const [t] = useTranslation()

    return (
        <Flex>
            <Box mr={ 5 }>
                <Tooltip
                    content={ t('Schedulable nodes') }
                    position={ Position.LEFT }
                >
                    <Tag
                        intent={
                            schedulableCount > 0
                                ? Intent.SUCCESS
                                : schedulableCount === 0
                                    ? Intent.DANGER
                                    : Intent.NONE
                        }
                        minimal
                    >
                        { schedulableCount }
                    </Tag>
                </Tooltip>
            </Box>
            <Box>
                <Tooltip
                    content={ t('Unschedulable nodes') }
                    position={ Position.RIGHT }
                >
                    <Tag
                        intent={ unschedulableCount > 0 ? Intent.WARNING : Intent.NONE }
                        minimal
                    >
                        { unschedulableCount }
                    </Tag>
                </Tooltip>
            </Box>
        </Flex>
    )
}

function mapStateToProps(state: RootState, ownProps: OwnProps): ReduxProps {
    const { label } = ownProps
    const schedulableNodes   = nodes.getSchedulableByLabel(state)
    const unschedulableNodes = nodes.getUnschedulableByLabel(state)

    const schedulableCount = size(get(schedulableNodes, label.key))
    const unschedulableCount = size(get(unschedulableNodes, label.key))

    return {
        schedulableCount,
        unschedulableCount
    }
}

export default connect(mapStateToProps)(NodeCounters)
