import * as React from 'react'
import { FieldArray } from 'formik'

import { map, get, last, slice, isEmpty, isEqual } from 'lodash'
import { Flex } from 'reflexbox'

import { api, forms, sites, ui, navigation } from '../redux'
import { useTranslation } from '../hooks'

import { withForm } from '../components/Form'
import Container from '../components/Container'
import TitleBar from '../components/TitleBar'
import FormContainer from '../components/FormContainer'
import SiteRoutesFieldList from '../components/SiteRoutesFieldList'
import CopyToClipboardLabel from '../components/CopyToClipboardLabel'

type Props = forms.FormProps<sites.ISitePayload>

const SiteRoutingForm: React.FC<Props> = (props) => {
    const { initialValues } = props
    const [t] = useTranslation()

    const endpoints = get(initialValues, 'status.endpoints')
    const hasIp = !isEmpty(map(endpoints, 'ip'))

    return (
        <Container>
            <FormContainer
                header={
                    <TitleBar title={ t(ui.createTitle(api.Resource.site, navigation.MenuEntry.routing)) } />
                }
                { ...props }
            >
                <Flex mb={ 2 } w={ 0.3 } column>
                    { hasIp && map(endpoints, (endpoint) => endpoint.ip && (
                        <TitleBar
                            key={ endpoint.ip }
                            title={ t('IP Address') }
                            subtitle={ <CopyToClipboardLabel text={ endpoint.ip } /> }
                            isSmall
                        />
                    )) }
                    { !hasIp && (
                        <TitleBar
                            title={ t('IP Address') }
                            subtitle={ t('Provisioning...') }
                            isSmall
                        />
                    ) }
                </Flex>
                <FieldArray
                    name="routes"
                    component={ SiteRoutesFieldList }
                />
            </FormContainer>
        </Container>
    )
}

export default withForm({
    name            : forms.Name.updateSiteRoutes,
    valuesFormatter : (values: sites.ISitePayload) => ({
        ...values,
        routes: isEqual(last(values.routes), sites.EMPTY_ROUTE)
            ? slice(values.routes, 0, -1)
            : values.routes
    })
})(SiteRoutingForm)
