import * as React from 'react'

type Props = {
    to: string
    text?: string
}

const ExternalLink: React.FC<Props> = (props) => {
    const { to, children, text } = props
    return (
        <a
            href={ to }
            target="_blank"
            rel="noopener noreferrer"
        >
            { children }
            { text }
        </a>
    )
}

export default ExternalLink
