import * as React from 'react'
import { connect } from 'react-redux'

import MainContainer from '../components/MainContainer'
import AuthConfigurationForm from '../components/AuthConfigurationForm'

import { RootState, system } from '../redux'

type RedxProps = {
    config: system.IConfiguration
}

type Props = RedxProps

const ConfigurationContainer: React.FC<Props> = (props) => {
    const { config } = props

    return (
        <MainContainer>
            <AuthConfigurationForm
                initialValues={ config.auth }
            />
        </MainContainer>
    )
}

function mapStateToProps(state: RootState) {
    return {
        config: system.getConfiguration(state)
    }
}

export default connect(mapStateToProps)(ConfigurationContainer)
