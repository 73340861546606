import * as React from 'react'
import { connect } from 'react-redux'
import { Popover, Position, Menu, ButtonGroup, Tooltip } from '@blueprintjs/core'

import { map, size, noop, isFunction } from 'lodash'

import {
    RootState, DispatchProp, ActionWithPayload, isActionWithPayload,
    api, routing, system, sites, projects, organizations, pods,
    accountBindings, invites, ui, navigation
} from '../redux'
import { useTranslation } from '../hooks'
import { Maybe, arePropsEqual } from '../utils'

import Icon from '../components/Icon'
import Button from '../components/Button'
import ResourceMenuItem from '../components/ResourceMenuItem'

import styles from './ContextMenu.module.scss'

type Action = ui.Action | navigation.MenuEntry | api.Request
type Resource = ui.Resource | api.Resource
type OwnProps = {
    resource: Resource
    entry?: api.AnyResourceInstance | null
}
type ReduxProps = {
    currentProject: Maybe<projects.IProject>
    currentOrganization: Maybe<organizations.IOrganization>
    activeMenuEntry: Maybe<navigation.MenuEntry>
}

type Props = OwnProps & ReduxProps & DispatchProp

const ContextMenu: React.FC<Props> = React.memo((props) => {
    const { entry, resource } = props

    if (!entry) {
        return null
    }

    const menuEntries = navigation.getContextMenuForResource(resource, entry)
    const isButtonGroup = size(menuEntries) <= 2

    if (isButtonGroup) {
        return (
            <ButtonGroup className={ styles.buttons }>
                <MenuEntries { ...props } />
            </ButtonGroup>
        )
    }

    return (
        <Popover
            content={ (
                <Menu>
                    <MenuEntries { ...props } />
                </Menu>
            ) }
            target={ <Button icon={ <Icon name="ellipsis" size={ 24 } /> } isSquare /> }
            position={ Position.RIGHT }
        />
    )
}, arePropsEqual(['entry', 'currentOrganization', 'currentProject', 'activeMenuEntry']))

const MenuEntries: React.FC<Props> = (props) => {
    const {
        entry, resource, activeMenuEntry, dispatch
    } = props
    const [t] = useTranslation()

    if (!entry) {
        return null
    }

    const menuEntries = navigation.getContextMenuForResource(resource, entry)
    const isButtonGroup = size(menuEntries) <= 2

    return (
        <>
            { map(menuEntries, (action, index) => {
                const handler = actionHandler(action, resource, entry)
                const onClick = isActionWithPayload(handler)
                    ? () => dispatch(handler)
                    : isFunction(handler) ? handler : noop

                const isActive = action === activeMenuEntry

                return (
                    <Tooltip
                        key={ action }
                        content={ t(ui.createTitle(resource, action)) }
                    >
                        <ResourceMenuItem
                            action={ action }
                            onClick={ onClick }
                            isActive={ isActive }
                            isButton={ isButtonGroup }
                            { ...props }
                        />
                    </Tooltip>
                )
            }) }
        </>
    )
}

type ActionHandler = () => void
const actionHandler = (
    action: Action,
    resource: Resource,
    entry: api.AnyResourceInstance
): Maybe<ActionWithPayload | ActionHandler> => {
    switch (action) {
        case api.Request.destroy: {
            return destroyEntry(resource, entry)
        }

        case ui.Action.edit: {
            return routing.push(routing.routeForResource(entry, { action: navigation.MenuEntry.general }))
        }

        case navigation.MenuEntry.logs: {
            if (resource === api.Resource.pod && pods.isPod(entry)) {
                return () => window.open(entry.logsUrl as string, '_blank')
            }

            return null
        }

        case ui.Action.copy: {
            return invites.copyLink(entry)
        }

        case ui.Action.recreate: {
            return invites.recreate(entry)
        }

        default:
            return null
    }
}

const destroyEntry = (resource: Resource, entry: api.AnyResourceInstance): Maybe<ActionWithPayload> => {
    switch (resource) {
        case api.Resource.site:
            return sites.destroy(entry)

        case api.Resource.project:
            return projects.destroy(entry)

        case api.Resource.organization:
            return organizations.destroy(entry)

        case api.Resource.pod:
            return pods.destroy(entry)

        case api.Resource.accountBinding:
            return accountBindings.destroy(entry)

        case api.Resource.invite:
            return invites.destroy(entry)

        case api.Resource.adminUser:
            return system.destroyAdminUser(entry as any as system.IAdminUser)


        default:
            return null
    }
}

function mapStateToProps(state: RootState) {
    const currentProject = projects.getForCurrentURL(state)
    const currentOrganization = organizations.getCurrent(state)
    const activeMenuEntry = navigation.getActiveMenuEntry(state)

    return {
        currentProject,
        currentOrganization,
        activeMenuEntry
    }
}

export default connect(mapStateToProps)(ContextMenu)
