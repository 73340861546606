import * as React from 'react'
import { Field } from 'formik'

import { upperFirst, toUpper } from 'lodash'

import { forms, mysqlClusters } from '../redux'
import { useTranslation } from '../hooks'
import { StorageClass } from '../utils'

import ButtonGroupSelectField from '../components/ButtonGroupSelectField'

const DESCRIPTION: Record<StorageClass, mysqlClusters.FieldDescription> = {
    [StorageClass.standard] : mysqlClusters.FieldDescription.storageClassHDD,
    [StorageClass.ssd]      : mysqlClusters.FieldDescription.storageClassSSD
}

const MySQLClusterStorageClassField: React.FC<forms.FieldProps> = (props) => {
    const { field } = props
    const [t] = useTranslation()

    return (
        <Field
            name={ field.name }
            label={ t(mysqlClusters.FieldName.storageClass) }
            component={ ButtonGroupSelectField }
            items={ StorageClass }
            helperText={ t(DESCRIPTION[field.value]) }
            itemParser={ (value: StorageClass) => {
                switch (value) {
                    default:
                    case StorageClass.standard:
                        return {
                            value,
                            label: upperFirst(value)
                        }

                    case StorageClass.ssd:
                        return {
                            value,
                            label: toUpper(value)
                        }
                }
            } }
        />
    )
}

export default MySQLClusterStorageClassField
