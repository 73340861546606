import { fork } from 'redux-saga/effects'
import { createSelector } from 'reselect'

import { reduce, filter, get, uniq, concat, includes } from 'lodash'

import { bitpoke } from '@bitpoke/bitpoke-proto'

import { SelectorCreator, ActionWithPayload, api, sites } from '../redux'
import { Maybe } from '../utils'

const { Event } = bitpoke.api.event.v1
const { Kind } = Event
export { Event, Kind }


//
//  TYPES

export type SourceName = string
export type EventName = string
export interface IEvent extends bitpoke.api.event.v1.IEvent {
    name: EventName
    sources?: string[]
}
export type Event = bitpoke.api.event.v1.Event
export type Kind = bitpoke.api.event.v1.Event.Kind

export type State = api.State<IEvent>

const apiTypes = api.createActionTypes(api.Resource.event)

export const { LIST_REQUESTED, LIST_SUCCEEDED, LIST_FAILED } = apiTypes


//
//  REDUCER

export function reducer(state: State = api.initialState, action: ActionWithPayload) {
    switch (action.type) {
        case LIST_SUCCEEDED: {
            const fetchedEvents = get(action.payload, ['data', api.Resource.event])
            const source = get(action.payload, 'request.data.name')

            return {
                ...state,
                entries: reduce(fetchedEvents, (acc, event) => ({
                    ...acc,
                    [event.name]: {
                        ...get(acc, event.name, {}),
                        ...event,
                        sources: source ? uniq(concat(event.sources, source)) : event.sources
                    }
                }), state.entries)
            }
        }

        default:
            return state
    }
}


//
//  SAGA

export function* saga() {
    yield fork(api.emitResourceActions, api.Resource.event, apiTypes)
}


export function isEvent(entry: Maybe<api.AnyResourceInstance>): entry is IEvent {
    return api.isOfType(api.Resource.event, entry)
}


//
//  SELECTORS

const selectors: api.Selectors<IEvent> = api.createSelectors(api.Resource.event)
export const {
    getState,
    getAll,
    countAll,
    getByName,
    getForURL,
    getForCurrentURL,
    getForOrganization,
    getForCurrentOrganization
} = selectors

export const getForSource: SelectorCreator<SourceName, IEvent[]> = (sourceName: SourceName) => createSelector(
    getForCurrentOrganization,
    (events) => filter(events, (event) => includes(event.sources, sourceName))
)
export const getForSite: SelectorCreator<sites.SiteName, IEvent[]> = (site: sites.SiteName) => getForSource(site)

export const getScalingEventsForSource: SelectorCreator<SourceName, IEvent[]> = (
    sourceName: SourceName
) => createSelector(
    getForSource(sourceName),
    (events) => filter(events, { reason: 'ScalingReplicaSet' })
)
