import * as React from 'react'
import { connect } from 'react-redux'
import { Flex, Box } from 'reflexbox'
import { Tag } from '@blueprintjs/core'

import { last, sortBy } from 'lodash'

import { RootState, routing, sites, events, navigation } from '../redux'
import { useTranslation, useInterval, useEffect, useDispatch } from '../hooks'
import { POLL_INTERVAL, Maybe, arePropsEqual, formatDistance } from '../utils'

import Button from '../components/Button'
import styles from './SiteLatestEvent.module.scss'

type OwnProps = {
    entry: sites.ISite | null
}

type ReduxProps = {
    latestEvent: Maybe<events.IEvent>
}

type Props = OwnProps & ReduxProps

const SiteLatestEvent: React.FC<Props> = React.memo(({ entry, latestEvent }) => {
    const [t] = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        if (entry) {
            dispatch(sites.listEvents({ name: entry.name }))
        }
    }, [entry, dispatch])

    useInterval(() => {
        if (entry) {
            dispatch(sites.listEvents({ name: entry.name }))
        }
    }, POLL_INTERVAL.slow)

    if (!entry) {
        return null
    }

    return (
        <Button
            className={ styles.button }
            href={ routing.routeForResource(entry, { action: navigation.MenuEntry.events }) }
        >
            { latestEvent ? (
                <Flex justify="space-between" align="center">
                    <div className={ styles.body }>
                        <strong>{ latestEvent.lastTimestamp && formatDistance(latestEvent.lastTimestamp) }</strong>
                        <span> { latestEvent.message }</span>
                    </div>
                    <Box align="flex-end">
                        <Tag>{ t('All events') }</Tag>
                    </Box>
                </Flex>
            ) : t('No recent events') }
        </Button>
    )
}, arePropsEqual(['entry', 'latestEvent']))


function mapStateToProps(state: RootState, ownProps: OwnProps): ReduxProps {
    const { entry } = ownProps
    const recentEvents: events.IEvent[] = entry
        ? events.getForSource(entry.name)(state)
        : []

    return {
        latestEvent: last(sortBy(recentEvents, 'lastTimestamp.seconds'))
    }
}

export default connect(mapStateToProps)(SiteLatestEvent)
