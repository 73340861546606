import * as React from 'react'

import { join } from 'lodash'

import { useTranslation } from '../hooks'
import { formatDate, DATETIME_FORMAT } from '../utils'
import config from '../config'

import SupportMenu from '../components/SupportMenu'

import styles from './Footer.module.scss'

const Footer: React.FC = () => {
    const [t] = useTranslation()

    return (
        <div className={ styles.container }>
            <div>
                <span>
                    Copyright &copy; 2023 / build&nbsp;
                    { join([
                        config.REACT_APP_BUILD_INFO.GIT_VERSION,
                        formatDate(config.REACT_APP_BUILD_INFO.BUILD_DATE, DATETIME_FORMAT.human)
                    ], ' @ ') }
                </span>
                <SupportMenu />
            </div>
            <span className={ styles.by }>
                { t('Powered by Bitpoke.') }
            </span>
        </div>
    )
}

export default Footer
