import * as React from 'react'
import { Field } from 'formik'

import { Flex } from 'reflexbox'

import { get } from 'lodash'

import { api, forms, sites, ui, navigation } from '../redux'
import { useTranslation } from '../hooks'

import { withForm } from '../components/Form'
import TitleBar from '../components/TitleBar'
import Container from '../components/Container'
import FormContainer from '../components/FormContainer'
import InputField from '../components/InputField'
import CopyToClipboardLabel from '../components/CopyToClipboardLabel'

type Props = forms.FormProps<{ site: sites.ISite }>

const SiteRuntimeForm: React.FC<Props> = (props) => {
    const { initialValues } = props
    const [t] = useTranslation()
    const entry = get(initialValues, 'site')

    return (
        <Container>
            <FormContainer
                header={ <TitleBar title={ t(ui.createTitle(api.Resource.site, navigation.MenuEntry.runtime)) } /> }
                { ...props }
            >
                <Flex w={ 0.5 } column>
                    <Field
                        name="site.wordpressImage"
                        label={ t(sites.FieldName.wordpressImage) }
                        helperText={ t(sites.FieldDescription.wordpressImage) }
                        placeholder={ t('Managed') }
                        component={ InputField }
                        autoFocus
                    />
                    <TitleBar
                        title={ t('Kubernetes Project Namespace') }
                        subtitle={ <CopyToClipboardLabel text={ entry.kubernetesNamespace } /> }
                        isSmall
                    />
                    <TitleBar
                        title={ t('Kubernetes Site Name') }
                        subtitle={ <CopyToClipboardLabel text={ entry.kubernetesName } /> }
                        isSmall
                    />
                </Flex>
            </FormContainer>
        </Container>
    )
}

export default withForm({ name: forms.Name.updateSiteRuntime })(SiteRuntimeForm)
