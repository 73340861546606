import * as React from 'react'

import { projects, mysqlClusterBackups } from '../redux'
import { useTranslation } from '../hooks'
import { POLL_INTERVAL, formatDistance } from '../utils'

import Table, { TableColumn, TableCell, TableCellProps } from '../components/Table'
import BackupStatusTag from '../components/BackupStatusTag'
import TitleBar from '../components/TitleBar'
import Container from '../components/Container'
import CopyToClipboardLabel from '../components/CopyToClipboardLabel'

type Props = {
    project: projects.ProjectName
}

const MySQLClusterBackupsList: React.FC<Props> = (props) => {
    const { project } = props
    const [t] = useTranslation()

    const columns: TableColumn[] = [
        {
            label        : t('Status'),
            dataKey      : 'status',
            width        : 120,
            cellRenderer : (props: TableCellProps) => (
                <TableCell align="flex-start">
                    <BackupStatusTag entry={ props.rowData } />
                </TableCell>
            )
        },
        {
            label        : t('Download URL'),
            dataKey      : 'url',
            cellRenderer : (props: TableCellProps) => (
                <TableCell>
                    <CopyToClipboardLabel text={ props.cellData } />
                </TableCell>
            )
        },
        {
            label        : t('Requested'),
            dataKey      : 'requestedAt',
            cellRenderer : (props: TableCellProps) => (
                <TableCell>
                    { props.cellData && formatDistance(props.cellData) }
                </TableCell>
            )
        },
        {
            label        : t('Completed'),
            dataKey      : 'completedAt',
            cellRenderer : (props: TableCellProps) => (
                <TableCell>
                    { props.cellData && formatDistance(props.cellData) }
                </TableCell>
            )
        }
    ]

    return (
        <>
            <TitleBar
                title={ t('Recent Backups') }
                subtitle={ t('A list of your most recent backups') }
                isBordered
                isPadded
            />
            <Container>
                <Table
                    columns={ columns }
                    sortBy="requestedAt"
                    itemsRequest={ mysqlClusterBackups.list({ project }) }
                    itemsSelector={ mysqlClusterBackups.getForProject(project) }
                    pollInterval={ POLL_INTERVAL.slow }
                    emptyRenderer={ () => (
                        <TitleBar subtitle={ t('There are no recent backups to show') } />
                    ) }
                    errorRenderer={ () => (
                        <TitleBar subtitle={ t('Failed to load backups') } />
                    ) }
                />
            </Container>
        </>
    )
}

export default MySQLClusterBackupsList
