import * as React from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { Flex, Box } from 'reflexbox'

import { get, sortBy, concat, pick, isEmpty } from 'lodash'

import TabBar from '../components/TabBar'
import PageMeta from '../components/PageMeta'
import MainContainer from '../components/MainContainer'
import Container from '../components/Container'
import MainMenu from '../components/MainMenu'
import SiteWizardForm from '../components/SiteWizardForm'
import SiteRoutingForm from '../components/SiteRoutingForm'
import SiteGeneralForm from '../components/SiteGeneralForm'
import SiteResourcesForm from '../components/SiteResourcesForm'
import SiteSchedulingForm from '../components/SiteSchedulingForm'
import SiteSchedulingStatus from '../components/SiteSchedulingStatus'
import SiteCachingForm from '../components/SiteCachingForm'
import SiteRuntimeForm from '../components/SiteRuntimeForm'
import SiteOverview from '../components/SiteOverview'
import ErrorMessage from '../components/ErrorMessage'
import EventsList from '../components/EventsList'
import ResourcePods from '../components/ResourcePods'
import SiteSMTPForm from '../components/SiteSMTPForm'
import SiteEnvVarsForm from '../components/SiteEnvVarsForm'
import SiteCommands from '../components/SiteCommands'

import {
    RootState, DispatchProp, routing, sites, projects, organizations, api, wizard, navigation, errors
} from '../redux'
import { Maybe } from '../utils'


type ReduxProps = {
    site: Maybe<sites.ISite>
    wizardValues: wizard.Values
    project: Maybe<projects.IProject>
    activeMenuEntry: Maybe<navigation.MenuEntry>
    organization: Maybe<organizations.OrganizationName>
    isCreateEntryRoute: boolean
    isNotFound: boolean
}

type Props = ReduxProps & DispatchProp

const SitesContainer: React.FC<Props> = (props) => {
    const { site, project, wizardValues, activeMenuEntry, organization, isNotFound, isCreateEntryRoute } = props

    if (organization && project && !site) {
        return (
            <>
                <MainMenu
                    resource={ api.Resource.project }
                    entry={ project }
                />
                <MainContainer>
                    { isCreateEntryRoute && (
                        <Route
                            path={ routing.routeFor(routing.Key.sites, {
                                organization : organizations.parseName(organization).slug,
                                project      : projects.parseName(project.name).slug,
                                slug         : '_',
                                action       : 'new'
                            }) }
                            render={ () => (
                                <SiteWizardForm
                                    initialValues={ {
                                        ...wizardValues,
                                        site: {
                                            ...wizardValues.site,
                                            project: project.name
                                        }
                                    } }
                                />
                            ) }
                        />
                    ) }
                    { isNotFound && <ErrorMessage error={ errors.Error.notFound } /> }
                </MainContainer>
            </>
        )
    }

    if (!project || !organization || !site) {
        return <MainContainer />
    }

    return (
        <>
            <PageMeta
                entry={ site }
                section={ activeMenuEntry }
            />
            <MainMenu
                resource={ api.Resource.site }
                entry={ site }
            />
            <MainContainer>
                <TabBar resource={ api.Resource.site } />
                <Switch>
                    <Route
                        key={ navigation.MenuEntry.routing }
                        path={ routing.routeForResource(site, { action: navigation.MenuEntry.routing }) }
                        render={ () => (
                            <SiteRoutingForm
                                initialValues={ {
                                    name   : site.name,
                                    status : site.status,
                                    routes : concat(site.routes || [], sites.EMPTY_ROUTE)
                                } }
                            />
                        ) }
                    />
                    <Route
                        key={ navigation.MenuEntry.runtime }
                        path={ routing.routeForResource(site, { action: navigation.MenuEntry.runtime }) }
                        render={ () => (
                            <SiteRuntimeForm initialValues={ { site } } />
                        ) }
                    />
                    <Route
                        key={ navigation.MenuEntry.resources }
                        path={ routing.routeForResource(site, { action: navigation.MenuEntry.resources }) }
                        render={ () => (
                            <Container>
                                <SiteResourcesForm
                                    initialValues={ pick(site, ['name', 'resources']) }
                                />
                            </Container>
                        ) }
                    />
                    <Route
                        key={ navigation.MenuEntry.scheduling }
                        path={ routing.routeForResource(site, { action: navigation.MenuEntry.scheduling }) }
                        render={ () => (
                            <Container>
                                <Flex column>
                                    <Box>
                                        <SiteSchedulingForm
                                            initialValues={ { site } }
                                        />
                                    </Box>
                                    <Box mt={ 50 }>
                                        <SiteSchedulingStatus
                                            entry={ site }
                                        />
                                    </Box>
                                </Flex>
                            </Container>
                        ) }
                    />
                    <Route
                        key={ navigation.MenuEntry.pageCaching }
                        path={ routing.routeForResource(site, { action: navigation.MenuEntry.pageCaching }) }
                        render={ () => (
                            <SiteCachingForm
                                initialValues={ pick(site, ['name', 'pageCache']) }
                            />
                        ) }
                    />
                    <Route
                        key={ navigation.MenuEntry.environment }
                        path={ routing.routeForResource(site, { action: navigation.MenuEntry.environment }) }
                        render={ () => (
                            <SiteEnvVarsForm
                                initialValues={ {
                                    name    : site.name,
                                    envVars : !isEmpty(site.envVars) ? {
                                        items: concat(
                                            sortBy(get(site.envVars, 'items', []), 'name'),
                                            sites.EMPTY_ENV_VAR
                                        )
                                    } : {}
                                } }
                            />
                        ) }
                    />
                    <Route
                        key={ navigation.MenuEntry.smtp }
                        path={ routing.routeForResource(site, { action: navigation.MenuEntry.smtp }) }
                        render={ () => (
                            <SiteSMTPForm
                                initialValues={ pick(site, ['name', 'smtp']) }
                            />
                        ) }
                    />
                    <Route
                        key={ navigation.MenuEntry.events }
                        path={ routing.routeForResource(site, { action: navigation.MenuEntry.events }) }
                        render={ () => (
                            <EventsList
                                source={ site.name }
                                itemsRequest={ sites.listEvents({ name: site.name }) }
                            />
                        ) }
                    />
                    <Route
                        key={ navigation.MenuEntry.pods }
                        path={ routing.routeForResource(site, { action: navigation.MenuEntry.pods }) }
                        render={ () => (
                            <ResourcePods
                                entry={ site }
                            />
                        ) }
                    />
                    <Route
                        key={ navigation.MenuEntry.commands }
                        path={ routing.routeForResource(site, { action: navigation.MenuEntry.commands }) }
                        render={ () => <SiteCommands entry={ site } /> }
                    />
                    <Route
                        key={ navigation.MenuEntry.general }
                        path={ routing.routeForResource(site, { action: navigation.MenuEntry.general }) }
                        render={ () => <SiteGeneralForm entry={ site } /> }
                    />
                    <Route
                        key={ navigation.MenuEntry.overview }
                        path={ routing.routeForResource(site) }
                        render={ () => <SiteOverview entry={ site } /> }
                    />
                </Switch>
            </MainContainer>
        </>
    )
}

function mapStateToProps(state: RootState): ReduxProps {
    const project = projects.getForCurrentURL(state)
    const site = sites.getForCurrentURL(state)
    const isNotFound = sites.isNotFound(state)
    const wizardValues = wizard.getValues(wizard.Key.site)(state)
    const activeMenuEntry = navigation.getActiveMenuEntry(state)
    const isCreateEntryRoute = navigation.isCreateEntryRoute(state)
    const organization = organizations.getCurrentName(state)

    return {
        project,
        site,
        wizardValues,
        activeMenuEntry,
        isCreateEntryRoute,
        organization,
        isNotFound
    }
}

export default connect(mapStateToProps)(SitesContainer)
