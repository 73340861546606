import * as React from 'react'
import { Tag, Tooltip, Intent, Position } from '@blueprintjs/core'

import { arePropsEqual } from '../utils'

import StatusIcon from '../components/StatusIcon'

import styles from './StatusTag.module.scss'

type Props = {
    name: string
    intent: Intent
    tooltip?: JSX.Element
    onClick?: () => void
    large?: boolean
    withIcon?: boolean
    minimal?: boolean
}

const StatusTag: React.FC<Props> = React.memo((props) => {
    const { name, intent, tooltip, onClick, large, minimal, withIcon } = props

    if (minimal) {
        return (
            <Tooltip content={ tooltip } position={ Position.BOTTOM }>
                <StatusIcon
                    intent={ intent }
                    size={ 12 }
                />
            </Tooltip>
        )
    }

    return (
        <Tooltip content={ tooltip } position={ Position.RIGHT }>
            <Tag
                className={ styles.tag }
                icon={ withIcon ? <StatusIcon intent={ intent } /> : null }
                intent={ intent }
                onClick={ onClick }
                interactive={ !!onClick }
                large={ large }
                minimal
            >
                { name }
            </Tag>
        </Tooltip>
    )
}, arePropsEqual(['intent', 'name', 'tooltip']))

export default StatusTag
