import * as React from 'react'
import { Field } from 'formik'
import { Intent } from '@blueprintjs/core'
import { Flex } from 'reflexbox'

import { get, size } from 'lodash'

import { forms, tolerations } from '../redux'
import { useTranslation } from '../hooks'

import Table, { TableColumn, TableCellProps, TableCell } from '../components/Table'
import Button from '../components/Button'
import DiscardButton from '../components/DiscardButton'
import FormGroup from '../components/FormGroup'
import InputField from '../components/InputField'
import TolerationEffectSelect from '../components/TolerationEffectSelect'

const NodeTolerationsFieldList: React.FC<any> = (props) => {
    const { name, form, push, remove, replace } = props
    const [t] = useTranslation()
    const entries: tolerations.IToleration[] = get(form.values, name)
    const errors = forms.getFieldErrors(form.errors, name)
    const initialEntries = get(form.initialValues, name, [])

    const columns: TableColumn[] = [
        {
            label        : t('Key'),
            dataKey      : 'key',
            minWidth     : 210,
            cellRenderer : (props: TableCellProps) => {
                const { rowIndex } = props
                return (
                    <TableCell>
                        <Field
                            name={ `${name}.${rowIndex}.key` }
                            component={ InputField }
                        />
                    </TableCell>
                )
            }
        },
        {
            label        : t('Value'),
            dataKey      : 'value',
            minWidth     : 210,
            cellRenderer : (props: TableCellProps) => {
                const { rowIndex } = props
                return (
                    <TableCell>
                        <Field
                            name={ `${name}.${rowIndex}.value` }
                            component={ InputField }
                        />
                    </TableCell>
                )
            }
        },
        {
            label        : t('Effect'),
            dataKey      : 'effect',
            cellRenderer : (props: TableCellProps) => {
                const { rowIndex, rowData } = props
                return (
                    <TableCell>
                        <Field
                            name={ `${name}.${rowIndex}.effect` }
                            component={ TolerationEffectSelect }
                            selectedItem={ props.cellData }
                            onChange={ (effect: tolerations.Effect) => (
                                replace(rowIndex, {
                                    ...rowData,
                                    effect
                                })
                            ) }
                        />
                    </TableCell>
                )
            }
        },
        {
            dataKey      : 'actions',
            width        : 110,
            cellRenderer : (props: TableCellProps) => {
                const isUnsaved = props.rowData !== initialEntries[props.rowIndex]
                return (
                    <TableCell>
                        <Flex>
                            <Button
                                icon="bin-full"
                                minimal
                                intent={ Intent.DANGER }
                                onClick={ () => remove(props.rowIndex) }
                                isSquare
                            />
                            { isUnsaved && (
                                <DiscardButton
                                    onClick={ () => {
                                        size(initialEntries) > props.rowIndex
                                            ? replace(props.rowIndex, get(initialEntries, props.rowIndex))
                                            : remove(props.rowIndex)
                                    } }
                                />
                            ) }
                        </Flex>
                    </TableCell>
                )
            }
        }
    ]

    return (
        <FormGroup errors={ errors }>
            <Table
                items={ entries }
                columns={ columns }
                isSortable={ false }
                footerRenderer={ () => (
                    <Button
                        text={ t('Add a new rule') }
                        icon="add"
                        intent={ Intent.SUCCESS }
                        onClick={ () => push(tolerations.EMPTY_TOLERATION) }
                    />
                ) }
            />
        </FormGroup>
    )
}

export default NodeTolerationsFieldList
