import { get, times, round } from 'lodash'
import { bitpoke } from '@bitpoke/bitpoke-proto'

import { sites } from '../redux'
import { parseQuantity } from '../utils'

const {
    Resource
} = bitpoke.resources.v1

export {
    Resource
}

export type Resource  = bitpoke.resources.v1.Resource
export type IResource = bitpoke.resources.v1.IResource


export const EMPTY_RESOURCE_USAGE: ResourceUsage = {
    minimum : 0,
    maximum : 0
}

const MB = 1024 ** 2
const GB = 1024 ** 3
export const RESOURCE_SCALES = {
    memory: {
        small : times(64, (i) => (i + 1) * 16 * MB),
        large : [
            128 * MB,
            ...times(8 * 4, (i) => ((i + 1) * 0.25) * GB),
            ...times(56, (i) => (i + 9) * GB)
        ]
    },
    cpu: {
        default: [
            ...times(10, (i) => round((i + 1) * 0.1, 3)),
            ...times(16 * 2, (i) => (i + 2) * 0.5),
            ...times(16, (i) => i + 17)
        ]
    },
    storage: {
        default: [
            ...times(499, (i) => (i + 2) * GB)
        ]
    }
}

export type ResourceUsage = {
    minimum: number
    maximum: number
}

export type ResourceOld = {
    requested: string
    limit: string
}

export enum ResourceType {
    cpuPerPod    = 'cpuPerPod',
    memoryPerPod = 'memoryPerPod'
}

export enum ResourceName {
    cpuPerPod    = 'CPU / Pod',
    memoryPerPod = 'Memory / Pod'
}

export enum ResourceDescription {
    cpuPerPod = 'The amount of CPU allocated for each pod instance',
    memoryPerPod = 'The amount of memory allocated for each pod instance'
}

export function computeTotalResourceUsage(entry: any, resource: ResourceType): ResourceUsage {
    if (sites.isSite(entry)) {
        return sites.computeTotalResourceUsage(entry, resource)
    }

    const replicas = get(entry, 'resources.desiredReplicas', 0)
    const allocationPerPod = get(entry, ['resources', resource])

    if (!allocationPerPod) {
        return EMPTY_RESOURCE_USAGE
    }

    const minAllocationPerPod = parseQuantity(allocationPerPod.requested)
    const maxAllocationPerPod = resource === ResourceType.cpuPerPod
        ? parseQuantity(allocationPerPod.requested)
        : parseQuantity(allocationPerPod.limit)

    return {
        minimum : replicas * minAllocationPerPod,
        maximum : replicas * maxAllocationPerPod
    }
}
