import * as React from 'react'
import { Field } from 'formik'

import { get } from 'lodash'

import { api, forms, organizations, routing, ui } from '../redux'
import { useDispatch, useTranslation } from '../hooks'

import { withForm } from '../components/Form'
import FormContainer from '../components/FormContainer'
import InputField from '../components/InputField'
import OrganizationTitle from '../components/OrganizationTitle'
import OrganizationIcon from '../assets/icons/OrganizationIcon'

type Props = forms.FormProps<organizations.IOrganizationPayload>

const OrganizationCreateForm: React.FC<Props> = (props) => {
    const { initialValues } = props
    const dispatch = useDispatch()
    const [t] = useTranslation()
    const entry = get(initialValues, 'organization')

    return (
        <>
            <FormContainer
                submitText={ t(ui.getTitleForPayload(entry, api.Resource.organization)) }
                header={
                    <>
                        <OrganizationIcon />
                        <OrganizationTitle entry={ entry } withActions={ false } />
                    </>
                }
                onCancel={ () => dispatch(routing.goBack()) }
                isCentered
                { ...props }
            >
                <Field
                    name="organization.displayName"
                    label={ t(organizations.FieldName.displayName) }
                    component={ InputField }
                    autoFocus
                />
            </FormContainer>
        </>
    )
}

export default withForm({ name: forms.Name.createOrganization })(OrganizationCreateForm)
