import * as React from 'react'
import classes from 'classnames'

import styles from './TextBox.module.scss'

type Props = {
    isTruncated?: boolean
}

const TextBox: React.FC<Props> = (props) => {
    const { children, isTruncated } = props
    return (
        <div className={ classes(styles.text, isTruncated && styles.clipped) }>
            { children }
        </div>
    )
}

export default TextBox
