import * as React from 'react'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'

import { RootState, memcacheds, sites } from '../redux'
import { useDispatch, useTranslation } from '../hooks'
import { Maybe, createUpdateMask } from '../utils'

import ConfirmButton from '../components/ConfirmButton'
import Button from '../components/Button'

type OwnProps = {
    entry?: memcacheds.IMemcached
}

type ReduxProps = {
    site: Maybe<sites.ISite>
}

type Props = OwnProps & ReduxProps

const MemcachedDisableButton: React.FC<Props> = (props) => {
    const { entry, site } = props
    const dispatch = useDispatch()
    const [t] = useTranslation()

    if (!entry) {
        return null
    }

    const setEnabled = (value: boolean) => {
        if (value === false && site?.pageCache?.backend === sites.PageCacheBackend.MEMCACHED) {
            dispatch(sites.updatePageCache({
                name      : site.name,
                pageCache : {
                    enabled: false
                },
                updateMask: createUpdateMask(['pageCache.enabled'])
            }))
        }

        dispatch(memcacheds.updateResources({
            name      : entry.name,
            resources : {
                paused: !value
            },
            updateMask: createUpdateMask(['resources.paused'])
        }))
    }

    return entry.resources?.paused ? (
        <Button
            text={ t('Enable Memcached') }
            intent={ Intent.SUCCESS }
            onClick={ () => setEnabled(true) }
        />
    ) : (
        <ConfirmButton
            button={ (
                <Button
                    text={ t('Disable Memcached') }
                    intent={ Intent.WARNING }
                />
            ) }
            onConfirm={ () => setEnabled(false) }
            confirmationIcon="issue"
            confirmationText={ (
                <>
                    { t('Disabling Memcached will also disable: ') }
                    <ul>
                        <li>{ t('Site page caching (if using Memcached backend)') }</li>
                        <li>{ t('PHP object caching') }</li>
                    </ul>
                    { t('Are you sure you want to continue?') }
                </>
            ) }
        />
    )
}

function mapStateToProps(state: RootState, ownProps: OwnProps) {
    const site = ownProps?.entry?.name
        ? sites.getForMemcached(ownProps.entry.name)(state)
        : undefined

    return {
        site
    }
}

export default connect(mapStateToProps)(MemcachedDisableButton)
