import * as React from 'react'
import { connect } from 'react-redux'
import { Flex } from 'reflexbox'
import { Intent } from '@blueprintjs/core'

import { join } from 'lodash'

import { RootState, api, organizations, accountBindings, invites } from '../redux'
import { Maybe, formatDistance } from '../utils'
import { useTranslation } from '../hooks'

import Table, { TableColumn, TableCell, TableCellProps, SortDirection } from '../components/Table'
import TitleBar from '../components/TitleBar'
import ContextMenu from '../components/ContextMenu'
import InviteTitle from '../components/InviteTitle'
import AccountBindingTitle from '../components/AccountBindingTitle'
import InviteForm from '../components/InviteForm'
import Bullet from '../components/Bullet'


type ReduxProps = {
    organization: Maybe<organizations.OrganizationName>
}
type Props = ReduxProps

const AccessManagementList: React.FC<Props> = (props) => {
    const { organization } = props
    const [t] = useTranslation()

    const columns: TableColumn[] = [
        {
            label        : t('Member'),
            dataKey      : 'name',
            cellRenderer : (props: TableCellProps) => (
                <TableCell>
                    { invites.isInvite(props.rowData) ? (
                        <InviteTitle
                            entry={ props.rowData }
                            isCompact
                            isStretched
                            withAvatar
                        />
                    ) : (
                        <AccountBindingTitle
                            entry={ props.rowData }
                            isCompact
                            isStretched
                            withAvatar
                        />
                    ) }
                </TableCell>
            )
        },
        {
            label        : t('Joined'),
            dataKey      : 'createdAt',
            cellRenderer : (props: TableCellProps) => {
                if (!props.cellData) {
                    return <TableCell />
                }

                if (invites.isInvite(props.rowData)) {
                    const [title, intent] = invites.isExpired(props.rowData)
                        ? [t('Invitation expired'), Intent.DANGER]
                        : [t('Pending registration'), Intent.WARNING]

                    return (
                        <TableCell>
                            <TitleBar
                                title={ title }
                                subtitle={ join([t('Invitation created: '), formatDistance(props.cellData)], ' ') }
                                icon={ <Bullet intent={ intent } /> }
                                isCompact
                                isSmall
                            />
                        </TableCell>
                    )
                }

                return (
                    <TableCell>
                        { formatDistance(props.cellData) }
                    </TableCell>
                )
            }
        },
        {
            dataKey      : 'actions',
            width        : 100,
            flexGrow     : 0,
            cellRenderer : (props: TableCellProps) => (
                <TableCell align="flex-end" onClick={ (e) => e.stopPropagation() }>
                    <ContextMenu
                        entry={ props.rowData }
                        resource={
                            invites.isInvite(props.rowData)
                                ? api.Resource.invite
                                : api.Resource.accountBinding
                        }
                    />
                </TableCell>
            )
        }
    ]

    return (
        <>
            <Table
                itemsRequest={ [
                    accountBindings.list({ organization }),
                    invites.list({ organization })
                ] }
                itemsSelector={ [accountBindings.getForCurrentOrganization, invites.getForCurrentOrganization] }
                columns={ columns }
                sortDirection={ SortDirection.ASC }
            />
            <Flex w={ 1 }>
                <InviteForm initialValues={ { invite: { organization } } } />
            </Flex>
        </>
    )
}

function mapStateToProps(state: RootState) {
    return {
        organization: organizations.getCurrentName(state)
    }
}

export default connect(mapStateToProps)(AccessManagementList)
