import { fork, takeEvery } from 'redux-saga/effects'
import { createSelector } from 'reselect'

import { find, pick, keys, startsWith } from 'lodash'

import { bitpoke } from '@bitpoke/bitpoke-proto'

import {
    RootState, Selector, SelectorCreator, AnyAction,
    api, grpc, forms, projects, ui, routing
} from '../redux'
import { Maybe } from '../utils'

const {
    MySQLCluster,
    ResourceAllocation,
    MySQLClusterService,
    ListMySQLClustersRequest,
    ListMySQLClustersResponse,
    GetMySQLClusterRequest,
    UpdateMySQLClusterRequest,
    UpdateMySQLClusterResourcesRequest
} = bitpoke.mysqlclusters.v1

export {
    MySQLCluster,
    ResourceAllocation,
    MySQLClusterService,
    ListMySQLClustersRequest,
    ListMySQLClustersResponse,
    GetMySQLClusterRequest,
    UpdateMySQLClusterRequest,
    UpdateMySQLClusterResourcesRequest
}


//
//  TYPES

export type MySQLClusterName = string
export interface IMySQLCluster extends bitpoke.mysqlclusters.v1.IMySQLCluster {
    name: MySQLClusterName
}
export type MySQLCluster                        = bitpoke.mysqlclusters.v1.MySQLCluster
export type ResourceAllocation                  = bitpoke.mysqlclusters.v1.ResourceAllocation
export type IResourceAllocation                 = bitpoke.mysqlclusters.v1.IResourceAllocation
export type IListMySQLClustersRequest           = bitpoke.mysqlclusters.v1.IListMySQLClustersRequest
export type IListMySQLClustersResponse          = bitpoke.mysqlclusters.v1.IListMySQLClustersResponse
export type GetMySQLClusterRequest              = bitpoke.mysqlclusters.v1.GetMySQLClusterRequest
export type IGetMySQLClusterRequest             = bitpoke.mysqlclusters.v1.IGetMySQLClusterRequest
export type UpdateMySQLClusterRequest           = bitpoke.mysqlclusters.v1.UpdateMySQLClusterRequest
export type IUpdateMySQLClusterRequest          = bitpoke.mysqlclusters.v1.IUpdateMySQLClusterRequest
export type UpdateMySQLClusterResourcesRequest  =
    bitpoke.mysqlclusters.v1.UpdateMySQLClusterResourcesRequest
export type IUpdateMySQLClusterResourcesRequest =
    bitpoke.mysqlclusters.v1.IUpdateMySQLClusterResourcesRequest

export type State = api.State<IMySQLCluster>

export enum FieldName {
    backupsCron = 'MySQL Backup Frequency',
    backupsRetainCount = 'Number of MySQL backups to keep',
    scaling = 'Pod allocation',
    desiredStorageSizePerPod = 'MySQL Cluster storage size',
    storageClass = 'MySQL Cluster storage type'
}

export enum FieldDescription {
    backupsCron = 'The time entered here should be in UTC timezone',
    backupsRetainCount = '0 = keep all',
    storageClassHDD = 'It uses a standard storage type backed by hard disks',
    storageClassSSD = 'It uses a faster storage type backed by SSD'
}

const service = MySQLClusterService.create(
    grpc.createTransport('bitpoke.mysqlclusters.v1.MySQLClusterService')
)

export const { parseName, buildName } = api.createNameHelpers(api.Resource.mysqlCluster)

export const DEFAULT_STORAGE_SIZE = '8Gi'

//
//  ACTIONS

export const get = (payload: IGetMySQLClusterRequest) => grpc.invoke({
    service,
    method       : 'getMySQLCluster',
    data         : GetMySQLClusterRequest.create(payload),
    responseType : MySQLCluster
})

export const list = (payload?: IListMySQLClustersRequest) => grpc.invoke({
    service,
    method       : 'listMySQLClusters',
    data         : ListMySQLClustersRequest.create(payload),
    responseType : ListMySQLClustersResponse
})

export const update = (payload: IUpdateMySQLClusterRequest) => grpc.invoke({
    service,
    method       : 'updateMySQLCluster',
    data         : UpdateMySQLClusterRequest.create(payload),
    responseType : MySQLCluster
})

export const updateResources = (payload: IUpdateMySQLClusterResourcesRequest) => grpc.invoke({
    service,
    method       : 'updateMySQLClusterResources',
    data         : UpdateMySQLClusterResourcesRequest.create(payload),
    responseType : MySQLCluster
})

const apiTypes = api.createActionTypes(api.Resource.mysqlCluster)

export const {
    GET_REQUESTED,
    GET_SUCCEEDED,
    GET_FAILED,

    UPDATE_REQUESTED,
    UPDATE_SUCCEEDED,
    UPDATE_FAILED
} = apiTypes

//
//  REDUCER

const apiReducer = api.createReducer(api.Resource.mysqlCluster, apiTypes)

export function reducer(state: State = api.initialState, action: AnyAction) {
    return apiReducer(state, action)
}


//
//  SAGA

export function* saga() {
    yield fork(api.emitResourceActions, api.Resource.mysqlCluster, apiTypes)
    yield fork(forms.takeEverySubmission, keys(FORM_HANDLERS) as forms.Name[], handleFormSubmission)

    yield takeEvery([
        UPDATE_SUCCEEDED, UPDATE_FAILED
    ], ui.notifyAboutAction)
}

const FORM_HANDLERS: forms.HandlerMapping = {
    [forms.Name.updateMySQLCluster]          : update,
    [forms.Name.updateMySQLClusterResources] : updateResources
}

const handleFormSubmission = forms.createHandlers(FORM_HANDLERS, apiTypes)


export function isMySQLCluster(entry: any): entry is IMySQLCluster {
    return api.isOfType(api.Resource.mysqlCluster, entry)
}


//
//  SELECTORS

const selectors: api.Selectors<IMySQLCluster> = api.createSelectors(api.Resource.mysqlCluster)
export const {
    getState,
    getAll,
    countAll,
    getByName,
    getForURL,
    getForCurrentURL,
    getForOrganization,
    getForCurrentOrganization
} = selectors

export const getForProject: SelectorCreator<projects.ProjectName, Maybe<IMySQLCluster>> = (
    project: projects.ProjectName
) => createSelector(
    getForCurrentOrganization,
    (clusters) => find(clusters, ({ name }) => startsWith(name, project))
)
export const isNotFound: Selector<boolean> = createSelector(
    [routing.getCurrentRoute, (state: RootState) => pick(state, 'grpc')],
    (currentRoute: routing.Route, state) => {
        const parsedName = parseName(currentRoute.url)
        if (!parsedName.name) {
            return false
        }

        const request: grpc.Request = get({ name: parsedName.name }).payload
        return grpc.isFailedRequest(request)(state as RootState)
    }
)
