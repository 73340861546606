import * as React from 'react'

import { map, join } from 'lodash'

import { system, conditions } from '../redux'
import { useTranslation } from '../hooks'
import { Maybe } from '../utils'

import StatusListCard, { Item } from '../components/StatusListCard'

type Props = {
    title: string
    entry: Maybe<system.ICRDStatus>
}

const CRDStatusCard: React.FC<Props> = (props) => {
    const { entry, title } = props
    const [t] = useTranslation()

    if (!entry) {
        return null
    }

    const isReady = system.isReady(entry)
    const severity = isReady
        ? conditions.Severity.OK
        : conditions.Severity.WARNING

    const items = isReady ? [{
        name   : t('Installed'),
        intent : conditions.severityIntent(severity)
    }] : [
        ...map(entry.notFound, (name: string) => ({
            name,
            intent  : conditions.severityIntent(severity),
            tooltip : t('Resource not found')
        })),
        ...map(entry.mismatchingVersions, (crd: system.ICRD) => ({
            name    : crd.name,
            intent  : conditions.severityIntent(severity),
            tooltip : t(`Version mismatch: installed ${crd.version}; required: ${
                join([crd.lowerVersionBound, crd.upperVersionBound], ' - ')
            }`)
        }))
    ]

    return (
        <StatusListCard
            title={ title }
            description={ system.readyMessage(entry) }
            intent={ system.statusIntent(entry) }
            items={ items as Item[] }
        />
    )
}

export default CRDStatusCard
