import * as React from 'react'
import { Field } from 'formik'
import { Flex } from 'reflexbox'
import { Intent, Elevation } from '@blueprintjs/core'

import { get } from 'lodash'

import { api, forms, projects, ui, navigation } from '../redux'
import { useDispatch, useTranslation } from '../hooks'

import { withForm } from '../components/Form'
import Container from '../components/Container'
import TitleBar from '../components/TitleBar'
import FormContainer from '../components/FormContainer'
import InputField from '../components/InputField'
import ImageUploadField from '../components/ImageUploadField'
import Card from '../components/Card'
import ConfirmButton from '../components/ConfirmButton'

type Props = forms.FormProps<{ project: projects.IProject }>

const ProjectGeneralForm: React.FC<Props> = (props) => {
    const { initialValues } = props
    const [t] = useTranslation()
    const dispatch = useDispatch()
    const entry = get(initialValues, 'project')

    return (
        <Container>
            <FormContainer
                header={ (
                    <TitleBar title={ t(ui.createTitle(api.Resource.project, navigation.MenuEntry.general)) } />
                ) }
                footer={ (
                    <Card
                        title={ t(ui.createTitle(api.Resource.project, api.Request.destroy)) }
                        subtitle={ t('Once you delete a project, all sites that belong to it will also be deleted.') }
                        elevation={ Elevation.ZERO }
                        intent={ Intent.DANGER }
                    >
                        <ConfirmButton
                            onConfirm={ () => dispatch(projects.destroy(entry)) }
                            confirmationText={ t(ui.createTitle(api.Resource.project, ui.Action.confirm)) }
                            text={ t(ui.createTitle(api.Resource.project, api.Request.destroy)) }
                        />
                    </Card>
                ) }
                { ...props }
            >
                <Flex w={ 0.5 } column>
                    <Field
                        name="project.displayName"
                        label={ t(projects.FieldName.displayName) }
                        component={ InputField }
                        autoFocus
                    />
                    <Field
                        name="project.iconUrl"
                        label={ t(projects.FieldName.iconUrl) }
                        component={ ImageUploadField }
                        autoFocus
                    />
                </Flex>
            </FormContainer>
        </Container>
    )
}

export default withForm({ name: forms.Name.updateProjectSettings })(ProjectGeneralForm)
