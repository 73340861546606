/* eslint-disable no-use-before-define */

import { takeLatest, takeEvery, all, put, fork, select, take, race, delay } from 'redux-saga/effects'
import { channel as createChannel } from 'redux-saga'
import { createSelector } from 'reselect'
import { ActionType, action as createAction } from 'typesafe-actions'
import { SearchIndex, create as createIndex } from 'flexsearch'

import { map, reduce, forEach, get, head, values, uniq, concat, size, toArray } from 'lodash'

import { RootState, grpc, api, auth, organizations, projects, sites } from '../redux'

import { Maybe, watchChannel } from '../utils'

export type State = {
    results: ResultsList
    query: Query | null
    isPerformed: boolean
    isSearching: boolean
}

export type Actions = ActionType<typeof actions>

export type IndexedResource = api.Resource.project | api.Resource.site
export type Result = projects.IProject | sites.ISite

export type Query = string
export type Payload = {
    query: Query
    keys?: string[]
}

export type ResourceMap<T> = {
    [key in IndexedResource]: T;
}

export type ResultsList = ResourceMap<Result[]>

export type Response = Payload & {
    results: ResultsList
}

const KEYS: ResourceMap<string[]> = {
    [api.Resource.project] : ['name', 'displayName'],
    [api.Resource.site]    : ['name', 'routes:0:domain', 'routes:0:pathPrefix']
}

const EMPTY_RESULT: ResultsList = {
    [api.Resource.project] : [],
    [api.Resource.site]    : []
}

export const MIN_QUERY_LENGTH = 3
export const DEBOUNCE_DELAY = 600
export const SERVER_SEARCH_TIMEOUT = 10000


//
//  ACTIONS

export const REQUESTED        = '@ search / REQUESTED'
export const STARTED          = '@ search / STARTED'
export const SKIPPED          = '@ search / SKIPPED'
export const COMPLETED        = '@ search / COMPLETED'

export const LOCAL_STARTED    = '@ search / LOCAL_STARTED'
export const LOCAL_SUCCEEDED  = '@ search / LOCAL_SUCCEEDED'
export const LOCAL_FAILED     = '@ search / LOCAL_FAILED'
export const LOCAL_SKIPPED    = '@ search / LOCAL_SKIPPED'

export const SERVER_STARTED   = '@ search / SERVER_STARTED'
export const SERVER_SUCCEEDED = '@ search / SERVER_SUCCEEDED'
export const SERVER_FAILED    = '@ search / SERVER_FAILED'
export const SERVER_SKIPPED   = '@ search / SERVER_SKIPPED'

export const INDEX_UPDATED    = '@ search / INDEX_UPDATED'

export const request = (payload: Payload) => createAction(REQUESTED, payload)

export const start = (payload: Payload) => createAction(STARTED, payload)
export const startLocal = (payload: Payload) => createAction(LOCAL_STARTED, payload)
export const startServer = (payload: Payload) => createAction(SERVER_STARTED, payload)

export const skip = (payload: Payload) => createAction(SKIPPED, payload)
export const skipLocal = (payload: Payload) => createAction(LOCAL_SKIPPED, payload)
export const skipServer = (payload: Payload) => createAction(SERVER_SKIPPED, payload)

export const failLocal = (payload: Payload) => createAction(LOCAL_FAILED, payload)
export const failServer = (payload: Payload) => createAction(SERVER_FAILED, payload)

export const complete = (payload: Response) => createAction(COMPLETED, payload)
export const completeLocal = (payload: Response) => createAction(LOCAL_SUCCEEDED, payload)
export const completeServer = (payload: Response) => createAction(SERVER_SUCCEEDED, payload)

export const updateIndex = (payload: api.Resource) => createAction(INDEX_UPDATED, payload)

const actions = {
    request,

    start,
    startLocal,
    startServer,

    complete,
    completeLocal,
    completeServer,

    failLocal,
    failServer,

    skip,
    skipLocal,
    skipServer
}


//
//  REDUCER

const initialState: State = {
    isPerformed : false,
    isSearching : false,
    query       : null,
    results     : EMPTY_RESULT
}

export function reducer(
    state: State = initialState,
    action: Actions | ActionType<typeof organizations.select> | ActionType<typeof auth.logout>
) {
    switch (action.type) {
        case REQUESTED: {
            const { query } = action.payload

            return {
                ...state,
                isPerformed : false,
                isSearching : false,
                results     : EMPTY_RESULT,
                query
            }
        }

        case STARTED:
        case LOCAL_STARTED:
        case SERVER_STARTED: {
            return {
                ...state,
                isSearching: true
            }
        }

        case COMPLETED: {
            const { results } = action.payload

            return {
                ...state,
                isSearching : false,
                isPerformed : true,
                results
            }
        }

        case LOCAL_SUCCEEDED: {
            const { results } = action.payload

            return {
                ...state,
                results
            }
        }

        case LOCAL_FAILED:
        case LOCAL_SKIPPED:
        case SERVER_FAILED:
        case SERVER_SKIPPED:
        case SERVER_SUCCEEDED: {
            return {
                ...state,
                isSearching: false
            }
        }

        case auth.LOGOUT_REQUESTED:
        case organizations.SELECTED: {
            return initialState
        }

        default:
            return state
    }
}

const indexes: Record<api.Resource, SearchIndex> = createIndexes([
    api.Resource.project,
    api.Resource.site
])

//
//  SAGA

const channel = createChannel()

export function* saga() {
    yield takeLatest(REQUESTED, waitAndSearch)
    yield takeLatest(STARTED, performSearch)
    yield takeLatest(SERVER_STARTED, performServerSearch)
    yield takeLatest(LOCAL_STARTED, performLocalSearch)
    yield takeLatest([organizations.SELECTED, auth.LOGOUT_REQUESTED], purgeIndexes)
    yield fork(watchForIndexUpdates)
    yield fork(watchChannel, channel)
}

function* waitAndSearch(action: ActionType<typeof request>) {
    const { query } = action.payload
    yield delay(DEBOUNCE_DELAY)

    if (size(query) >= MIN_QUERY_LENGTH) {
        yield put(start(action.payload))
    }
    else {
        yield put(skip(action.payload))
    }
}

function* performSearch(action: ActionType<typeof start>) {
    const { server, local } = yield all({
        spawnL : put(startLocal(action.payload)),
        spanwS : put(startServer(action.payload)),
        server : race({
            ok      : take(SERVER_SUCCEEDED),
            fail    : take([SERVER_FAILED, SERVER_SKIPPED]),
            timeout : delay(SERVER_SEARCH_TIMEOUT)
        }),
        local: race({
            ok   : take(LOCAL_SUCCEEDED),
            fail : take([LOCAL_FAILED, LOCAL_SKIPPED])
        })
    })

    if (server.ok) {
        yield put(startLocal(action.payload))
        const newLocal: {
            ok   : ActionType<typeof completeLocal>
            fail : ActionType<typeof failLocal>
        } = yield race({
            ok   : take(LOCAL_SUCCEEDED),
            fail : take([LOCAL_FAILED, LOCAL_SKIPPED])
        })

        if (newLocal.ok) {
            const { results } = newLocal.ok.payload
            yield put(complete({ ...action.payload, results }))
        }
        else if (local.ok) {
            const { results } = local.ok.payload
            yield put(complete({ ...action.payload, results }))
        }
    }
    else if (local.ok) {
        const { results } = local.ok.payload
        yield put(complete({ ...action.payload, results }))
    }
    else {
        yield put(complete({ ...action.payload, results: EMPTY_RESULT }))
    }
}

function* performLocalSearch(action: ActionType<typeof startLocal>) {
    const { query } = action.payload

    const results = reduce(indexes, (acc, index: SearchIndex, resource) => ({
        ...acc,
        [resource]: index.search(query)
    }), {}) as ResultsList

    yield put(completeLocal({ ...action.payload, results }))
}

function* performServerSearch(action: ActionType<typeof startServer>) {
    //
    // TODO: perform an actual server search. for now, just list all resources
    const currentOrganization: Maybe<organizations.IOrganization> = yield select(organizations.getCurrent)

    if (!currentOrganization) {
        return
    }

    yield put(projects.list({ organization: currentOrganization.name }))
    yield put(sites.list())

    const responsesList: api.ResourcesList<projects.IProject | sites.ISite> = yield all({
        [api.Resource.project]: race({
            ok   : take(projects.LIST_SUCCEEDED),
            fail : take(projects.LIST_FAILED)
        }),
        [api.Resource.site]: race({
            ok   : take(sites.LIST_SUCCEEDED),
            fail : take(sites.LIST_FAILED)
        })
    })

    const results = reduce(responsesList, (acc, response, resource) => ({
        [resource]: toArray(get(resource, 'ok.payload.data'))
    }), {}) as ResultsList

    yield put(completeServer({ ...action.payload, results }))
}

function* watchForIndexUpdates() {
    yield all(map(indexes, (index: SearchIndex, resource: api.Resource) => {
        const types = api.createActionTypes(resource)
        return takeEvery([
            types.GET_SUCCEEDED,
            types.LIST_SUCCEEDED,
            types.CREATE_SUCCEEDED,
            types.UPDATE_SUCCEEDED,
            types.DESTROY_SUCCEEDED
        ], handleIndexUpdate, index)
    }))
}

function purgeIndexes() {
    return map(indexes, (index: SearchIndex, resource: api.Resource) => {
        index.clear()
        index.init(getIndexerOptionsForResource(resource))
        return index
    })
}

function* handleIndexUpdate(index: SearchIndex, action: grpc.ResponseAction) {
    const requestType = api.getRequestTypeFromAction(action)
    const resource = api.getResourceFromAction(action)

    if (!resource) {
        return
    }

    const addOrUpdate = (searchIndex: SearchIndex, entry: api.AnyResourceInstance) => {
        try {
            searchIndex.find(entry.name) // eslint-disable-line
                ? searchIndex.update(entry)
                : searchIndex.add(entry)
        }
        catch (e) {
            searchIndex.add(entry)
        }
    }

    switch (requestType) {
        case api.Request.list: {
            const data = head(values(action.payload.data))
            forEach(data, (entry: api.AnyResourceInstance) => addOrUpdate(index, entry))
            break
        }

        case api.Request.destroy: {
            const entry = action.payload.request.data as api.AnyResourceInstance
            index.remove(entry)

            if (resource === api.Resource.project) {
                indexes[api.Resource.project].clear()
                indexes[api.Resource.site].clear()
            }
            break
        }

        default: {
            const data = action.payload.data as api.AnyResourceInstance
            addOrUpdate(index, data)
            break
        }
    }

    yield put(updateIndex(resource))
}

function createIndexes(resources: api.Resource[]) {
    return reduce(resources, (acc, resource: api.Resource) => {
        const index = createIndex(getIndexerOptionsForResource(resource))

        return {
            ...acc,
            [resource]: index
        }
    }, {}) as Record<api.Resource, SearchIndex>
}

function getKeysForResource(resource: api.Resource | string) {
    return uniq(concat(get(KEYS, resource, []), 'name'))
}

function getIndexerOptionsForResource(resource: api.Resource | string) {
    return {
        doc: {
            id    : 'name',
            field : getKeysForResource(resource)
        }
    }
}

//
//  SELECTORS

export const getState = (state: RootState) => state.search
export const getQuery = createSelector(
    getState,
    (state) => state.query
)
export const getResults = createSelector(
    getState,
    (state) => state.results
)
export const isSearching = createSelector(
    getState,
    (state) => state.isSearching
)
export const isPerformed = createSelector(
    getState,
    (state) => state.isPerformed
)
