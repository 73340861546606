import { ActionType } from 'typesafe-actions'
import { put, fork, takeEvery } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { IconName } from '@presslabs/icons'

import { map, get, sum, pickBy, startsWith, isEmpty } from 'lodash'
import { bitpoke } from '@bitpoke/bitpoke-proto'

import { SelectorCreator, projects, sites, mysqlClusters, memcacheds, api, grpc, operations } from '../redux'
import { Maybe } from '../utils'

const {
    Pod,
    ContainerStatus,
    PodsService,
    ListPodsRequest,
    ListPodsResponse,
    DeletePodRequest
} = bitpoke.pod.v1

const {
    ContainerState
} = ContainerStatus

export {
    Pod,
    ContainerStatus,
    ContainerState,
    PodsService,
    ListPodsRequest,
    ListPodsResponse,
    DeletePodRequest
}

export type Parent = projects.IProject | sites.ISite | mysqlClusters.IMySQLCluster | memcacheds.IMemcached

export type PodName = string
export interface IPod extends bitpoke.pod.v1.IPod {
    name: PodName
}
export type Pod               = bitpoke.pod.v1.Pod
export type ContainerStatus   = bitpoke.pod.v1.ContainerStatus
export type IContainerStatus  = bitpoke.pod.v1.IContainerStatus
export type ContainerState    = bitpoke.pod.v1.ContainerStatus.ContainerState
export type ListPodsRequest   = bitpoke.pod.v1.ListPodsRequest
export type IListPodsRequest  = bitpoke.pod.v1.IListPodsRequest
export type ListPodsResponse  = bitpoke.pod.v1.ListPodsResponse
export type IListPodsResponse = bitpoke.pod.v1.IListPodsResponse
export type DeletePodRequest  = bitpoke.pod.v1.DeletePodRequest
export type IDeletePodRequest = bitpoke.pod.v1.IDeletePodRequest

export type State = api.State<IPod>
export type Actions = ActionType<typeof actions>
export enum LabelKey {
    name      = 'app.kubernetes.io/name',
    component = 'app.kubernetes.io/component',
    jobName   = 'job-name',
    app       = 'app'
}
export type Label = {
    key: LabelKey | string
    value: LabelValue
    name: string
    displayName?: string
}
export type LabelValue = string | boolean

const service = PodsService.create(
    grpc.createTransport('bitpoke.pod.v1.PodsService')
)

export const { parseName, buildName } = api.createNameHelpers(api.Resource.pod)


//
//  ACTIONS

export const list = (payload?: IListPodsRequest) => grpc.invoke({
    service,
    method       : 'listPods',
    data         : ListPodsRequest.create(payload),
    responseType : ListPodsResponse
})

export const destroy = (payload: IPod) => grpc.invoke({
    service,
    method       : 'deletePod',
    data         : DeletePodRequest.create(payload),
    responseType : operations.OperationResponse
})

const actions = {
    list,
    destroy
}

const apiTypes = api.createActionTypes(api.Resource.pod)

export const {
    LIST_REQUESTED,    LIST_SUCCEEDED,    LIST_FAILED,
    GET_REQUESTED,     GET_SUCCEEDED,     GET_FAILED,
    DESTROY_REQUESTED, DESTROY_SUCCEEDED, DESTROY_FAILED
} = apiTypes

//
//  REDUCER

const apiReducer = api.createReducer(api.Resource.pod, apiTypes)

export function reducer(state: State = api.initialState, action: Actions) {
    switch (action.type) {
        case DESTROY_SUCCEEDED: {
            const operationResponse = action.payload.data as operations.IOperationResponse
            if (!operationResponse.finished) {
                return state
            }

            return apiReducer(state, action)
        }

        default:
            return apiReducer(state, action)
    }
}

//
//  SAGA

export function* saga() {
    yield fork(api.emitResourceActions, api.Resource.pod, apiTypes)
    yield takeEvery(DESTROY_SUCCEEDED, fetchPods)
}

export function restartCount(pod: IPod): number {
    return sum(map(pod?.status?.containerStatuses, 'restartCount'))
}

export function* fetchPods(action: grpc.ResponseAction) {
    const organization = get(action.payload, 'request.data.organization')
    yield put(list({ organizations: [organization] }))
}


//
//  HELPERS

export function isPod(entry: any): entry is IPod {
    return api.isOfType(api.Resource.pod, entry)
}

export function getLabel(name: LabelKey, pod: IPod) {
    return get(pod?.labels, name)
}

export function kind(pod: IPod): Maybe<string> {
    if (isJob(pod)) {
        return 'job'
    }

    const componentName = getLabel(LabelKey.component, pod)
    const podName = getLabel(LabelKey.name, pod)
    const appName = getLabel(LabelKey.app, pod)

    return componentName || podName || appName
}

export function iconName(pod: IPod): IconName {
    const podKind = kind(pod)
    const ICONS = {
        web        : 'wordpress',
        cache      : 'cache',
        database   : 'database',
        grafana    : 'grafana',
        prometheus : 'prometheus'
    }

    if (!podKind || !ICONS[podKind]) {
        return 'pod'
    }

    return ICONS[podKind]
}

export function isJob(pod: IPod): boolean {
    return !isEmpty(getLabel(LabelKey.jobName, pod))
}

//
//  SELECTORS

const selectors: api.Selectors<IPod> = api.createSelectors(api.Resource.pod)
export const { getState, getAll, countAll, getByName, getForURL, getForCurrentURL } = selectors
export const getForParent: SelectorCreator<PodName, api.ResourcesList<IPod>> = (
    parent: api.ResourceName
) => createSelector(
    getAll,
    (pods) => pickBy(pods, (pod) => pod.parent && startsWith(pod.parent, parent))
)
export const getForProject: SelectorCreator<projects.ProjectName, api.ResourcesList<IPod>> = (
    project: projects.ProjectName
) => createSelector(
    getAll,
    (pods) => pickBy(pods, ({ name }) => startsWith(name, project))
)
