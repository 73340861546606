import * as React from 'react'
import { connect } from 'react-redux'
import { Menu } from '@blueprintjs/core'

import { map, get, head, values } from 'lodash'

import { RootState, sites, routing, ui, navigation } from '../redux'
import { useTranslation } from '../hooks'

import MenuItem from '../components/MenuItem'
import Icon from '../components/Icon'
import Card from '../components/Card'

type OwnProps = {
    entry: sites.ISite | null
}

type ReduxProps = {
    article: sites.CommandsArticle
}

type Props = OwnProps & ReduxProps

const SiteCommandsMenu: React.FC<Props> = ({ entry, article }) => {
    const [t] = useTranslation()

    if (!entry) {
        return null
    }

    return (
        <Card
            title={ t('Operations') }
            subtitle={ t('Concise steps and pre-filled commands to perform common operations.') }
            isBorderedLeft
            isBorderedBottom
        >
            <Menu>
                { map(sites.CommandsArticle, (currentArticle) => {
                    const isSelected = currentArticle === article
                        || (!article && currentArticle === head(values(sites.CommandsArticle)))
                    return (
                        <MenuItem
                            key={ currentArticle }
                            text={ t(sites.CommandsArticleTitle[currentArticle]) }
                            icon={
                                <Icon
                                    name={ ui.getIconNameForAction(currentArticle) }
                                    size={ 20 }
                                />
                            }
                            active={ isSelected }
                            href={
                                routing.routeForResource(entry, {
                                    action : navigation.MenuEntry.commands,
                                    tab    : currentArticle
                                })
                            }
                        />
                    )
                }) }
            </Menu>
        </Card>
    )
}

function mapStateToProps(state: RootState) {
    const params = routing.getParams(state)
    const article = get(sites.CommandsArticle, get(params, 'tab') as sites.CommandsArticle)

    return {
        article
    }
}

export default connect(mapStateToProps)(SiteCommandsMenu)
