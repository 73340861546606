import * as React from 'react'
import { Text, Tooltip, Intent, Alignment } from '@blueprintjs/core'
import { Flex, Box } from 'reflexbox'
import classes from 'classnames'

import { map, isString } from 'lodash'

import { routing } from '../redux'
import { useState } from '../hooks'
import { Maybe } from '../utils'

import Button from '../components/Button'
import Card from '../components/Card'
import Icon from '../components/Icon'
import TitleBar from '../components/TitleBar'
import StatusIcon from '../components/StatusIcon'
import { Props as ContainerProps } from '../components/Container'

import styles from './StatusListCard.module.scss'

type Props = {
    title: string
    description?: Maybe<string>
    intent: Intent
    items: Item[]
    link?: Maybe<routing.Path>
} & ContainerProps

export type Item = {
    name: Maybe<string>
    intent: Intent
    link?: Maybe<routing.Path>
    configLink?: Maybe<routing.Path>
    tooltip?: string
}

const StatusListCard: React.FC<Props> = (props) => {
    const { title, description, intent, items, link } = props
    const [isOpen, setOpen] = useState(true)

    return (
        <Card
            title={
                description ? (
                    <Tooltip content={ description }>
                        <TitleBar
                            title={ title }
                            icon={ <StatusIcon size={ 40 } intent={ intent } /> }
                            onClick={ link ? () => window.open(link, '_blank') : undefined }
                            isStretched
                            isSmall
                        />
                    </Tooltip>
                ) : (
                    <TitleBar
                        title={ title }
                        icon={ <StatusIcon size={ 40 } intent={ intent } /> }
                        onClick={ link ? () => window.open(link, '_blank') : undefined }
                        isStretched
                        isSmall
                    />
                )
            }
            rightItem={
                <Button
                    icon={ <Icon size={ 22 } name={ isOpen ? 'care-n' : 'caret-s' } /> }
                    onClick={ () => setOpen(!isOpen) }
                    minimal
                />
            }
            className={ styles.container }
            isBorderedBottom
            isBorderedLeft
        >
            <Flex column>
                { isOpen && map(items, (item: Item, index: number) => {
                    if (!item) {
                        return null
                    }

                    return (
                        <Flex key={ index } align="center" justify="space-between">
                            <Box w={ 1 }>
                                { item.tooltip ? (
                                    <Tooltip content={ item.tooltip }>
                                        <ItemButton item={ item } />
                                    </Tooltip>
                                ) : (
                                    <ItemButton item={ item } />
                                ) }
                            </Box>
                            <Box w={ 40 } mr={ -15 }>
                                { isString(item.configLink) && (
                                    <Button
                                        icon={ <Icon name="tune" size={ 18 } /> }
                                        href={ item.configLink }
                                        minimal
                                    />
                                ) }
                            </Box>
                        </Flex>
                    )
                }) }
            </Flex>
        </Card>
    )
}

const ItemButton: React.FC<{ item: Item }> = (props) => {
    const { item } = props
    return (
        <Button
            text={ <Text ellipsize>{ item.name }</Text> }
            onClick={ item.link ? () => window.open(item.link as string, '_blank') : undefined }
            alignText={ Alignment.LEFT }
            className={ classes(styles.itemButton, item.link && styles.interactive) }
            intent={ item.intent }
            icon={
                <StatusIcon
                    intent={ item.intent }
                    size={ 24 }
                    minimal
                />
            }
            rightIcon={ item.link ? (
                <Icon className={ styles.externalLink } name="external-alt" />
            ) : undefined }
            minimal
        />
    )
}

export default StatusListCard
