import * as React from 'react'
import { connect } from 'react-redux'

import { RootState, organizations, sites, projects, mysqlClusters, memcacheds, pods, nodes } from '../redux'
import { Maybe } from '../utils'

import PodsList from '../components/PodsList'

type Entry = sites.ISite | projects.IProject | mysqlClusters.IMySQLCluster | memcacheds.IMemcached

type OwnProps = {
    entry: Entry
}

type ReduxProps = {
    pod: Maybe<pods.IPod>
}

type Props = OwnProps & ReduxProps

const ResourcePods: React.FC<Props> = (props) => {
    const { entry } = props

    const listRequest = (projects.isProject(entry) && pods.list({ projects: [entry.name] }))
        || (organizations.isOrganization(entry) && pods.list({ organizations: [entry.name] }))
        || (nodes.isNode(entry) && pods.list({ nodes: [entry.name] }))
        || (entry && pods.list({ parents: [entry.name] }))

    return (
        <PodsList
            parents={ [entry.name] }
            itemsRequest={ listRequest }
        />
    )
}

function mapStateToProps(state: RootState, ownProps: OwnProps): ReduxProps {
    const pod = pods.getForCurrentURL(state)
    return {
        pod
    }
}

export default connect(mapStateToProps)(ResourcePods)
