import * as React from 'react'
import { Field } from 'formik'
import { Flex, Box } from 'reflexbox'

import { get, join, identity, round, times, min, size } from 'lodash'

import { projects, forms } from '../redux'
import { useTranslation } from '../hooks'
import { parseQuantity, formatQuantity } from '../utils'

import SliderField, { Scale, Value } from '../components/SliderField'
import ProgressIndicator from '../components/ProgressIndicator'

type Props = forms.FormProps<any> & {
    resource: projects.QuotaResourceType
    isDisabled?: boolean
}

const MEMORY_SCALE = times(512, (i) => (i + 1) * (1024 ** 3))
const CPU_SCALE = times(256, (i) => i + 1)

const STEPS: Record<projects.QuotaResourceType, number> = {
    [projects.QuotaResourceType.pods]   : 9,
    [projects.QuotaResourceType.memory] : size(MEMORY_SCALE),
    [projects.QuotaResourceType.cpu]    : size(CPU_SCALE),
    [projects.QuotaResourceType.sites]  : 32
}

const SCALES: Record<projects.QuotaResourceType, Scale> = {
    [projects.QuotaResourceType.pods]   : (step: number) => 2 ** (step - 1),
    [projects.QuotaResourceType.memory] : (step: number) => MEMORY_SCALE[step - 1],
    [projects.QuotaResourceType.cpu]    : (step: number) => CPU_SCALE[step - 1],
    [projects.QuotaResourceType.sites]  : (step: number) => step
}

const ResourceQuotaSliderField: React.FC<Props> = (props) => {
    const { resource, values, isDisabled } = props
    const [t] = useTranslation()

    const name = join(['resourceQuotas', resource, 'limit'], '.')

    const usage = get(values, join(['resourceQuotas', resource, 'used'], '.'), 0)
    const quota = get(values, join(['resourceQuotas', resource, 'limit'], '.'), 0)

    const usagePercent = min([
        parseQuantity(quota) > 0 ? round((parseQuantity(usage) / parseQuantity(quota)) * 100, 1) : 0,
        100
    ]) as number

    const omitUnit = resource !== projects.QuotaResourceType.memory

    return (
        <Flex align="center">
            <Box w={ 1 } mr={ 20 }>
                <Field
                    label={ t(projects.QuotaResourceName[resource]) }
                    name={ name }
                    component={ SliderField }
                    scale={ SCALES[resource] || identity }
                    steps={ STEPS[resource] }
                    disabled={ isDisabled }
                    parse={ parseQuantity }
                    format={ formatQuantity }
                    labelRenderer={ (value: Value) => formatQuantity(value, { omitUnit }) }
                />
            </Box>
            <Box w={ 110 }>
                <ProgressIndicator
                    percent={ usagePercent }
                    label={ t('Usage: {{percent}}%', { percent: usagePercent }) }
                />
            </Box>
        </Flex>
    )
}

export default ResourceQuotaSliderField
