import * as React from 'react'
import { Field } from 'formik'
import { Box } from 'reflexbox'

import { forms, system, navigation, ui } from '../redux'
import { useTranslation } from '../hooks'
import { T } from '../i18n'

import { withForm } from '../components/Form'
import TitleBar from '../components/TitleBar'
import Container from '../components/Container'
import FormContainer from '../components/FormContainer'
import InputField from '../components/InputField'
import ExternalLink from '../components/ExternalLink'
import AuthVerificationCard from '../components/AuthVerificationCard'

type Props = forms.FormProps<{ configuration: system.IAuthConfiguration }>

const AuthConfigurationForm: React.FC<Props> = (props) => {
    const { values } = props
    const [t] = useTranslation()

    return (
        <Container>
            <Box w={ 0.45 }>
                <FormContainer
                    header={ (
                        <TitleBar
                            title={ t(ui.createTitle(navigation.TabEntry.authentication)) }
                            subtitle={
                                <T
                                    defaults={ system.SectionDescription.authentication }
                                    components={ [
                                        <ExternalLink to="https://auth0.com/docs/getting-started" />,
                                        <ExternalLink to="https://developers.google.com/identity/protocols/oauth2/openid-connect" /> // eslint-disable-line max-len
                                    ] }
                                />
                            }
                        />
                    ) }
                    { ...props }
                >
                    <Field
                        name="configuration.oidcIssuer"
                        label={ t(system.FieldName.oidcIssuer) }
                        helperText={ t(system.FieldDescription.oidcIssuer) }
                        component={ InputField }
                        autoFocus
                    />
                    <Field
                        name="configuration.oidcClientId"
                        label={ t(system.FieldName.oidcClientId) }
                        component={ InputField }
                    />
                    <AuthVerificationCard
                        config={ values.configuration }
                    />
                </FormContainer>
            </Box>
        </Container>
    )
}

export default withForm({ name: forms.Name.updateAuthConfiguration })(AuthConfigurationForm)
