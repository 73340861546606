import * as React from 'react'
import { Flex, Box } from 'reflexbox'

import { Breadcrumb as BaseBreadcrumb } from '@blueprintjs/core'

import { get, has, isString } from 'lodash'

import {
    routing, navigation, projects, sites, mysqlClusters, memcacheds, prometheuses, grafanas
} from '../redux'

import Icon, { IconName } from '../components/Icon'
import ComponentStatusTag from '../components/ComponentStatusTag'
import BreadcrumbResourceSelect from '../components/BreadcrumbResourceSelect'

export type Props = navigation.Breadcrumb

const Breadcrumb = (props: Props) => {
    const { text, href, title, icon, resource, entry } = props
    const hasStatus = has(entry, 'status.componentStatus')

    return (
        <BaseBreadcrumb href={ href ? routing.toFragment(href) : undefined }>
            <Flex column>
                <Box>
                    { title && <h4>{ title }</h4> }
                </Box>
                <Box>
                    { isString(icon) ? <Icon name={ icon as IconName } size={ 16 } /> : null }
                    <strong>{ text }</strong>
                    { hasStatus && (
                        <ComponentStatusTag
                            entry={ get(entry, 'status.componentStatus') }
                            minimal
                        />
                    ) }
                    { resource && (
                        sites.isSite(entry)
                        || projects.isProject(entry)
                        || mysqlClusters.isMySQLCluster(entry)
                        || memcacheds.isMemcached(entry)
                        || prometheuses.isPrometheus(entry)
                        || grafanas.isGrafana(entry)
                    ) && (
                        <BreadcrumbResourceSelect
                            resource={ resource }
                            selectedItem={ entry }
                        />
                    ) }
                </Box>
            </Flex>
        </BaseBreadcrumb>
    )
}

export default Breadcrumb
