import * as React from 'react'

import { Intent } from '@blueprintjs/core'

import { accountBindings } from '../redux'
import { useTranslation, useDispatch, useEffect } from '../hooks'

import BlankSlate from '../components/BlankSlate'
import Button from '../components/Button'
import Icon from '../components/Icon'

const AuthVerificationStatusCard: React.FC = () => {
    const [t] = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(accountBindings.ping())
    })

    return (
        <BlankSlate
            icon={ <Icon name="account-male-ok" size={ 64 } /> }
            title={ t('The authentication was successful.') }
            description={ t('This window can now be closed.') }
            action={
                <Button
                    intent={ Intent.PRIMARY }
                    text={ t('Close Window') }
                    onClick={ () => window.close() }
                />
            }
        />
    )
}

export default AuthVerificationStatusCard
