import * as React from 'react'
import { Tag } from '@blueprintjs/core'
import { Flex, Box } from 'reflexbox'

import { map } from 'lodash'

import { nodes } from '../redux'

type Props = {
    items: nodes.Label[]
}

const LabelsList: React.FC<Props> = (props) => {
    const { items } = props
    return (
        <Flex wrap>
            { map(items, (item: nodes.Label) => (
                <Box key={ item.key } mr={ 10 } mb={ 10 }>
                    <Tag>
                        { nodes.labelDisplayName(item) }
                    </Tag>
                </Box>
            )) }
        </Flex>
    )
}

export default LabelsList
