import { isOfType as isActionOfType } from 'typesafe-actions'
import { createSelector } from 'reselect'
import { takeEvery, put } from 'redux-saga/effects'
import { matchPath } from 'react-router'
import { compile } from 'path-to-regexp'
import URI from 'urijs'

import {
    map, reduce, filter, flatMap, find, omit, compact, concat, intersection, reverse,
    get, pick, pickBy, head, tail, last, join, split, dropRight, keys, values as _values,
    some, uniq, size, replace, startCase, camelCase, snakeCase, toLower, toUpper, identity,
    has, includes, startsWith, endsWith, isEqual, isEmpty, isString, isArray, isFunction
} from 'lodash'

import {
    RootState, Selector, SelectorCreator, AnyAction, ActionWithPayload,
    grpc, auth, routing, forms, sites
} from '../redux'

import { Maybe, singular, plural } from '../utils'


export enum Request {
    list    = 'LIST',
    get     = 'GET',
    create  = 'CREATE',
    update  = 'UPDATE',
    destroy = 'DESTROY'
}

export enum Status {
    request = 'REQUESTED',
    success = 'SUCCEEDED',
    failure = 'FAILED'
}

export enum Resource {
    organization                 = 'organizations',
    project                      = 'projects',
    site                         = 'sites',
    event                        = 'events',
    memcached                    = 'memcacheds',
    mysqlCluster                 = 'mysqlClusters',
    mysqlClusterBackup           = 'mysqlClusterBackups',
    prometheus                   = 'prometheuses',
    grafana                      = 'grafanas',
    accountBinding               = 'accountBindings',
    invite                       = 'invites',
    node                         = 'nodes',
    pod                          = 'pods',
    adminUser                    = 'adminUser',
    systemStatus                 = 'systemStatus',
    componentScheduling          = 'componentScheduling',
    authConfiguration            = 'authConfiguration',
    letsEncryptConfiguration     = 'letsEncryptConfiguration',
    configConnectorConfiguration = 'configConnectorConfiguration'
}

export type ResourceName = routing.Path

export type AnyResourceInstance = {
    name: ResourceName
}

export type Selectors<T> = {
    getState: Selector<State<T>>
    getAll: Selector<ResourcesList<T>>
    countAll: Selector<number>
    getByName: SelectorCreator<ResourceName, Maybe<T>>
    getForURL: SelectorCreator<routing.Path, Maybe<T>>
    getForCurrentURL: Selector<Maybe<T>>
    getForOrganization: SelectorCreator<string, ResourcesList<T>>
    getForCurrentOrganization: Selector<ResourcesList<T>>
    getForFilters: SelectorCreator<ListFilters, ResourcesList<T>>
}

export type ActionTypes = {
    LIST_REQUESTED: string
    LIST_SUCCEEDED: string
    LIST_FAILED: string

    GET_REQUESTED: string
    GET_SUCCEEDED: string
    GET_FAILED: string

    CREATE_REQUESTED: string
    CREATE_SUCCEEDED: string
    CREATE_FAILED: string

    UPDATE_REQUESTED: string
    UPDATE_SUCCEEDED: string
    UPDATE_FAILED: string

    DESTROY_REQUESTED: string
    DESTROY_SUCCEEDED: string
    DESTROY_FAILED: string
}

export type ResourcesList<T> = Record<ResourceName, T>

export type GroupedResourcesList<T = AnyResourceInstance> = Partial<Record<
    Resource,
    ResourcesList<T>
>>

export type State<T> = {
    entries: ResourcesList<T>
}

export type Actions = {
    list: (payload: any) => AnyAction
    get: (payload: any) => AnyAction
    create: (payload: any) => AnyAction
    update: (payload: any) => AnyAction
    destroy: (payload: any) => AnyAction
}

export enum NamePaths {
    organizations   = 'organizations/:slug',
    projects        = 'organizations/:organization/projects/:slug',
    sites           = 'organizations/:organization/projects/:project/sites/:slug',
    pods            = 'organizations/:organization/projects/:project/pods/:slug',
    mysqlClusters   = 'organizations/:organization/projects/:project/mysql-clusters/:slug',
    memcacheds      = 'organizations/:organization/projects/:project/memcacheds/:slug',
    prometheuses    = 'organizations/:organization/projects/:project/prometheuses/:slug',
    grafanas        = 'organizations/:organization/projects/:project/grafanas/:slug',
    accountBindings = 'organizations/:organization/iam/account-bindings/:slug',
    invites         = 'organizations/:organization/iam/invites/:slug'
}

export type NameHelpers = {
    parseName: (name: ResourceName | routing.Path) => ParsedNamePayload
    buildName: (payload: Record<string, string>) => ResourceName | null
}

export type ParsedNamePayload = {
    type: Resource | null
    name: ResourceName | null
    parent: ResourceName | null
    url: routing.Path
    slug: string
    isResourceName: boolean
    params: {
        organization?: string
        project?: string
        parent?: string
    }
}

type ParsedMethodPayload = {
    request: Maybe<string>
    resource: Maybe<string>
}

export type EntryParser<T = AnyResourceInstance> = (entry: any) => T

export type ListFilters = Record<string, string | string[]>

export const initialState: State<AnyResourceInstance> = {
    entries: {}
}

export function createReducer(
    resource: Resource,
    actionTypes: ActionTypes,
    payloadParser: EntryParser = identity
) {
    return (state: State<AnyResourceInstance>, action: AnyAction) => {
        const response = get(action, 'payload')

        if (isActionOfType(actionTypes.LIST_SUCCEEDED, action)) {
            const existingEntries = state.entries
            const fetchedEntries = get(response, ['data', resource])
            const filters = get(action, 'payload.request.data', {})

            const entriesToKeep = isEmpty(filters) ? {} : pickBy(existingEntries, (entry: AnyResourceInstance) => (
                !matchesFilters(entry, filters)
            ))

            return {
                ...state,
                entries: reduce(fetchedEntries, (acc, entry) => ({
                    ...acc,
                    [entry.name]: payloadParser({
                        ...get(acc, entry.name, {}),
                        ...entry
                    })
                }), entriesToKeep)
            }
        }

        if (isActionOfType([
            actionTypes.GET_SUCCEEDED,
            actionTypes.CREATE_SUCCEEDED,
            actionTypes.UPDATE_SUCCEEDED
        ], action)) {
            const entry = response.data

            if (!entry.name) {
                return state
            }

            return {
                ...state,
                entries: {
                    ...state.entries,
                    [entry.name]: entry
                }
            }
        }

        if (isActionOfType(actionTypes.DESTROY_SUCCEEDED, action)) {
            const entry = response.request.data

            if (!entry.name) {
                return state
            }

            return {
                ...state,
                entries: omit(state.entries, entry.name)
            }
        }

        if (isActionOfType(actionTypes.GET_FAILED, action)) {
            const errorCode = get(action, 'payload.error.code')
            const name = get(action, 'payload.request.data.name')
            if (errorCode === grpc.StatusCode.NotFound) {
                return {
                    ...state,
                    entries: omit(state.entries, name)
                }
            }
        }

        if (isActionOfType(auth.LOGOUT_REQUESTED, action) || isActionOfType(auth.TOKEN_INVALID, action)) {
            return initialState
        }

        return state
    }
}

export function createActionTypes(resource: Resource, namespace?: string): ActionTypes {
    return reduce(Request, (accTypes, action) => ({
        ...accTypes,
        ...reduce(Status, (acc, status) => {
            const key = createActionDescriptor(action, status)
            return {
                ...acc,
                [key]: `@ ${join(map(compact([namespace, resource]), snakeCase), '.')} / ${key}`
            }
        }, {})
    }), {} as any as ActionTypes)
}

export function createSelectors(resource: Resource): Selectors<AnyResourceInstance> {
    const getResourceState: Selector<State<AnyResourceInstance>> = (state) => get(state, resource)
    const getAll: Selector<ResourcesList<AnyResourceInstance>> = createSelector(
        getResourceState,
        (state) => get(state, 'entries', {})
    )
    const getByName: SelectorCreator<ResourceName, Maybe<AnyResourceInstance>> = (name) => createSelector(
        getAll,
        (entries) => get(entries, name, null)
    )
    const countAll: Selector<number> = createSelector(
        getAll,
        (entries) => size(keys(entries))
    )
    const getForURL: SelectorCreator<routing.Path, Maybe<AnyResourceInstance>> = (url) => createSelector(
        getAll,
        (entries) => find(entries, (entry: AnyResourceInstance) => startsWith(toName(url), entry.name)) || null
    )
    const getForCurrentURL: Selector<Maybe<AnyResourceInstance>> = createSelector(
        [routing.getCurrentRoute, (state: RootState) => pick(state, resource)],
        (currentRoute: routing.Route, state) => getForURL(currentRoute.url)(state as RootState)
    )
    const getForOrganization: SelectorCreator<
        string,
        ResourcesList<AnyResourceInstance>
    > = (
        organization: string
    ) => createSelector(
        getAll,
        (entries) => pickBy(entries, (entry: AnyResourceInstance) => startsWith(entry.name, organization))
    )
    const getForCurrentOrganization: Selector<ResourcesList<AnyResourceInstance>> = createSelector(
        [(state: RootState) => get(state, 'organizations.current', null), getState([resource])],
        (currentOranization, state) => (
            currentOranization ? getForOrganization(currentOranization)(state as RootState) : {}
        )
    )
    const getForFilters: SelectorCreator<
        ListFilters,
        ResourcesList<AnyResourceInstance>
    > = (filters: ListFilters) => createSelector(
        getAll,
        (entries) => pickBy(entries, (entry: AnyResourceInstance) => matchesFilters(entry, filters))
    )
    return {
        getState: getResourceState,
        getAll,
        getByName,
        countAll,
        getForURL,
        getForCurrentURL,
        getForOrganization,
        getForCurrentOrganization,
        getForFilters
    }
}

export function* emitResourceActions(resource: Resource, actionTypes: ActionTypes) {
    yield takeEvery([
        grpc.INVOKED,
        grpc.SUCCEEDED,
        grpc.FAILED
    ], emitResourceAction, resource, actionTypes)
}

export function isEmptyResponse(action: grpc.ResponseAction) {
    const { data, request } = action.payload

    if (!data || isEmpty(data)) {
        return true
    }

    const requestType = getRequestTypeFromMethodName(request.method)

    if (includes([Request.list, Request.get], requestType) && isEmpty(head(_values(data)))) {
        return true
    }

    return false
}

export function* emitResourceAction(
    resource: Resource,
    actionTypes: ActionTypes,
    action: grpc.Actions
) {
    if (isActionOfType(grpc.METADATA_SET, action)) {
        return
    }

    const method = getMethodNameFromAction(action)

    if (!method) {
        return
    }

    const requestResource = getResourceFromMethodName(method)
    const request = getRequestTypeFromMethodName(method)
    const status = getStatusFromAction(action)

    if (requestResource !== resource) {
        return
    }

    if (!status) {
        return
    }

    if (!request) {
        const customDescriptor = getCustomActionDescriptor(method, status, actionTypes)
        if (!customDescriptor) {
            return
        }

        yield put({ type: customDescriptor, payload: action.payload })
        return
    }

    const descriptor = createActionDescriptor(request, status)

    if (has(actionTypes, descriptor)) {
        yield put({ type: actionTypes[descriptor], payload: action.payload })
    }
}

export function createActionDescriptor(request: Request, status: Status) {
    return join([request, status], '_')
}

export function getCustomActionDescriptor(method: string, status: Status, actionTypes: ActionTypes): Maybe<string> {
    const { request } = parseMethodName(method)
    if (!request) {
        return null
    }
    return find(actionTypes, (action, descriptor) => (
        startsWith(descriptor, toUpper(request)) && endsWith(descriptor, status)
    )) || null
}

export function isOfType(resource: Resource, entry: any) {
    if (!isEntry(entry)) {
        return false
    }

    const { parseName } = createNameHelpers(resource)
    const { type, isResourceName } = parseName(entry.name)
    return type === resource && isResourceName
}

export function isEntry(entry: any): entry is AnyResourceInstance {
    return has(entry, 'name')
}

export function isNewEntry(entry: any) {
    return !isEntry(entry)
}

export function isResource(entry: any): entry is Resource {
    return entry in Resource || includes(_values(Resource), entry)
}

export function displayName(entry: AnyResourceInstance): string {
    const displayNameFromName = (name: string) => startCase(last(split(name, '/')))

    if (isString(get(entry, 'displayName'))) {
        return get(entry, 'displayName')
    }

    if (isOfType(Resource.site, entry)) {
        const route = head((entry as sites.ISite).routes)

        if (!route) {
            return displayNameFromName(entry.name)
        }

        if (route.pathPrefix !== '/') {
            return join([route.domain, route.pathPrefix], '')
        }

        return route.domain as string
    }

    if (isOfType(Resource.accountBinding, entry)) {
        return get(entry, 'fullName', get(entry, 'email', displayNameFromName(entry.name)))
    }

    if (isOfType(Resource.invite, entry)) {
        return get(entry, 'email', displayNameFromName(entry.name))
    }

    if (isOfType(Resource.node, entry)) {
        return parseName(entry.name, Resource.node).slug
    }

    if (isOfType(Resource.pod, entry)) {
        return parseName(entry.name, Resource.pod).slug
    }

    if (isOfType(Resource.mysqlCluster, entry)) {
        return 'MySQL Cluster'
    }

    if (isOfType(Resource.memcached, entry)) {
        return 'Memcached'
    }

    return get(entry, 'displayName', displayNameFromName(entry.name))
}

function toName(pathOrURL: string) {
    return replace(new URI(pathOrURL).pathname(), /^\//, '')
}

export function matchesFilters(entry: AnyResourceInstance, filters: ListFilters): boolean {
    const cleanFilters = reduce(filters, (acc, value, key) => {
        if ((isArray(value) && isEmpty(value))) {
            return acc
        }

        return {
            ...acc,
            [key]: isFunction(value) ? value() : value
        }
    }, {})

    const matches = map(cleanFilters, (filterValue: string | string[], filterKey: string) => {
        if (isArray(filterValue)) {
            const entryValue = get(entry, singular(filterKey))
            return some(map(filterValue, (value) => isEqual(entryValue, value)))
        }
        else {
            const entryValue  = get(entry, filterKey)
            return isEqual(entryValue, filterValue)
        }
    })

    return isEqual(uniq(matches), [true])
}

export function parseName(nameOrURL: ResourceName | routing.Path, resource: Resource): ParsedNamePayload {
    const path = get(NamePaths, resource, `${resource}/:slug`)
    const pathname = toName(nameOrURL)
    const matched = matchPath(pathname, { path, exact: false })
    const name = get(matched, 'url', null)
    const segments = name ? split(name, '/') : []
    const parent = size(segments) > 2 ? join(dropRight(segments, 2), '/') : null

    return {
        name,
        parent,
        url            : `/${name || ''}`,
        slug           : get(matched, 'params.slug', ''),
        params         : get(matched, 'params', {}),
        isResourceName : get(matched, 'isExact', false),
        type           : matched ? resource : null
    }
}

export function buildName(payload: Record<string, string>, resource: Resource): ResourceName | null {
    const path = NamePaths[resource]
    try {
        return compile(path)(payload)
    }
    catch (e) {
        return null
    }
}

export function createNameHelpers(resource: Resource): NameHelpers {
    return {
        parseName : (nameOrURL: ResourceName | routing.Path) => parseName(nameOrURL, resource),
        buildName : (params: Record<string, string>) => buildName(params, resource)
    }
}

export function parseMethodName(methodName: string): ParsedMethodPayload {
    const parts = map(split(snakeCase(methodName), '_'), toLower)
    const suffix = toLower(camelCase(join(tail(parts), '_')))
    const matchedRequestName = toUpper(find(Request, (request) => startsWith(methodName, toLower(request))))
    const matchedResourceName = last(filter(Resource, (resource) => ( // eslint-disable-line
        !isEmpty(intersection(parts, [resource, singular(resource)]))
            || endsWith(suffix, toLower(resource))
            || endsWith(suffix, toLower(singular(resource)))
            || includes(toLower(methodName), toLower(singular(resource)))
    )))
    const fallbackRequestName = head(parts) && head(parts) === 'delete' ? Request.destroy : head(parts)
    return {
        request  : matchedRequestName || fallbackRequestName,
        resource : matchedResourceName || last(parts)
    }
}

export function getRequestTypeFromMethodName(methodName: string): Request | null {
    const { request } = parseMethodName(methodName)

    if (!includes(_values(Request), request)) {
        return null
    }

    return request as Request
}

export function getResourceFromMethodName(methodName: string): Resource | null {
    const { resource } = parseMethodName(methodName)

    if (!resource) {
        return null
    }

    return find(Resource, (resourceName) => (
        resourceName === plural(resource)
        || resourceName === singular(resource)
    )) || null
}

export function getResourceFromName(name: string): Resource | null {
    const matched = find(reverse(keys(NamePaths)), (resource) => (
        isResource(resource) && parseName(name, resource).isResourceName
    ))

    return matched ? matched as Resource : null
}

export function getStatusFromActionType(type: string): Status | null {
    switch (type) {
        case grpc.INVOKED:   return Status.request
        case grpc.SUCCEEDED: return Status.success
        case grpc.FAILED:    return Status.failure

        default: {
            if (endsWith(type, Status.request)) return Status.request
            if (endsWith(type, Status.success)) return Status.success
            if (endsWith(type, Status.failure)) return Status.failure

            return null
        }
    }
}

export function getStatusFromAction(action: ActionWithPayload): Status | null {
    return getStatusFromActionType(action.type)
}

export function getMethodNameFromAction(action: ActionWithPayload): string | null {
    const status = getStatusFromAction(action)
    if (!status) {
        return null
    }

    const method = status === Status.request
        ? get(action, 'payload.method')
        : get(action, 'payload.request.method')

    if (!method) {
        return null
    }

    return method
}

export function getRequestTypeFromAction(action: ActionWithPayload): Request | null {
    const method = getMethodNameFromAction(action)
    return method ? getRequestTypeFromMethodName(method) : null
}

export function getRequestTypeFromActionType(type: string): Request | null {
    if (startsWith(type, Request.list))    return Request.list
    if (startsWith(type, Request.get))     return Request.get
    if (startsWith(type, Request.create))  return Request.create
    if (startsWith(type, Request.update))  return Request.update
    if (startsWith(type, Request.destroy)) return Request.destroy

    return null
}

export function getResourceFromAction(action: ActionWithPayload): Resource | null {
    const method = getMethodNameFromAction(action)
    return method ? getResourceFromMethodName(method) : null
}

export function getFieldErrorsFromAction(action: ActionWithPayload): forms.Errors {
    const errorDetails = get(action.payload, 'error.status.details')
    const fieldViolations = compact(flatMap(errorDetails, 'fieldViolations'))
    const normalizeFieldName = (fieldName: string) => join(map(split(fieldName, '.'), camelCase), '.')

    return reduce(fieldViolations, (acc, violation: grpc.FieldViolation) => {
        const fieldName = normalizeFieldName(violation.field)
        return {
            ...acc,
            [fieldName]: concat(get(acc, fieldName, []), violation.description)
        }
    }, {})
}

export const getState = (resources: ResourceName[] = keys(Resource)) => createSelector(
    (state: RootState) => state,
    (state) => pick(state, resources) as Partial<RootState>
)
