import { put, take } from 'redux-saga/effects'
import { Channel } from 'redux-saga'
import { parse, format, formatDistance as baseFormatDistance } from 'date-fns'
import pluralize from 'pluralize'
import md5 from 'md5'
import URI from 'urijs'
import { Unit, unit } from 'mathjs'
import Validator, { ValidationRuleName } from 'fastest-validator'

import { google } from '@bitpoke/bitpoke-proto'

import {
    map, reduce, find, findKey, findIndex, get, head, tail, slice, values,
    uniq, split, join, flatten, take as _take, takeRight, size, trim, truncate,
    toString, toUpper, toLower, snakeCase, parseInt, replace, reverse,
    has, includes, startsWith, isNumber, isDate, isString,
    isObject, isEmpty, isEqual, isPlainObject, isArray, isBoolean
} from 'lodash'

import { routing } from '../redux'

import * as i18n from '../i18n'

export const DATETIME_FORMAT = {
    default : 'dd-MM-yyyy HH:mm:ss',
    human   : 'd LLL Y HH:mm'
}

export const POLL_INTERVAL = {
    default : 5000,
    fast    : 5000,
    slow    : 10000
}

export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>
export type Maybe<T> = T | null | undefined
export type Modify<T, R> = Pick<T, Exclude<keyof T, keyof R>> & R

export type Timestamp = Modify<google.protobuf.ITimestamp, { seconds: number }>

export type SelectableItem = {
    value: any
    key: string
    label?: string
}

export enum StorageClass {
    standard = 'standard',
    ssd = 'ssd'
}

export enum CronFrequency {
    never = 'never',
    hourly = 'hourly',
    daily = 'daily',
    weekly = 'weekly',
    monthly = 'monthly',
    custom = 'custom'
}

export type Cron = {
    frequency: CronFrequency
    second?: string
    minute?: string
    hour?: string
    dayOfMonth?: string
    month?: string
    dayOfWeek?: string
}

const validator = new Validator()

export function validate(input: unknown, type: ValidationRuleName): boolean {
    return isBoolean(validator.validate({ input }, { input: { type } }))
}

export function isEmail(email: unknown): boolean {
    return validate(email, 'email')
}

export function isURL(email: unknown): boolean {
    return validate(email, 'url')
}

export function keyOfEnum(enumType: Record<string, unknown>, value: Maybe<string | number | symbol>): Maybe<string> {
    return findKey(enumType, (v) => v === value) || null
}

export function isTimestamp(value: unknown): value is Timestamp {
    return isObject(value) && isNumber(get(value, 'seconds'))
}

export function isBooleanLike(value: unknown): boolean {
    return includes(['true', 'false', 't', 'f', true, false], value)
}

export function toBoolean(value: unknown): boolean {
    return includes(['True', 'true', 't', '1', 1, true], value)
}

export function parseDate(
    date: Date | google.protobuf.ITimestamp | string,
    format: string = DATETIME_FORMAT.default,
    baseDate: Date | google.protobuf.ITimestamp | string = new Date()
): Date {
    if (isDate(date)) {
        return date
    }

    if (isObject(date) && isNumber(date.seconds)) {
        return new Date(date.seconds * 1000)
    }

    if (isObject(date) && isString(date.seconds)) {
        return new Date(parseInt(date.seconds) * 1000)
    }

    return parse(date as string, format, parseDate(baseDate, format))
}

export function formatDate(
    date: Date | google.protobuf.ITimestamp | string,
    dateFormat: string = DATETIME_FORMAT.default,
    options: { language?: i18n.Language } = {}
): string {
    const locale = i18n.getDateLocale(options.language)
    const parsedDate = parseDate(date)
    return format(parsedDate, dateFormat, { locale })
}

export function formatDistance(
    date: Date | google.protobuf.ITimestamp,
    baseDate: Date | google.protobuf.ITimestamp = new Date(),
    options: { language?: i18n.Language } = {}
): string {
    const language = options.language || i18n.Language[i18n.i18n.language]
    const t = i18n.i18n.getFixedT(language)
    const locale = i18n.getDateLocale(language)
    const adverb = t('ago')
    const toDate = parseDate(date)
    const fromDate = parseDate(baseDate)
    const formattedDistance = baseFormatDistance(toDate, fromDate, { locale, includeSeconds: true })

    return language === i18n.Language.ro
        ? `${adverb} ${formattedDistance}`
        : `${formattedDistance} ${adverb}`
}

export function weekDayName(
    day: number,
    options: { language?: i18n.Language } = {}
): Maybe<string> {
    const locale = i18n.getDateLocale(options.language)
    return locale.localize.day(day) || null
}

export function* watchChannel(actionChannel: Channel<unknown>): unknown {
    while (true) {
        yield put(yield take(actionChannel))
    }
}

export function parseCron(cronExpression: string = ''): Cron {
    const parts = split(cronExpression, ' ')
    const partsCount = size(parts)
    if (partsCount !== 5 && partsCount !== 6) {
        const frequency = CronFrequency.never
        return {
            frequency
        }
    }

    const tokens = partsCount === 5 ? ['0', ...parts] : parts
    const [second, minute, hour, dayOfMonth, month, dayOfWeek] = tokens

    const deduceFrequency = (tokens: string[]) => {
        const [second, , , , month, dayOfWeek] = tokens

        if (isEqual(uniq(takeRight(tokens, 4)),  ['*']) && !includes(_take(tokens, 2), '*')) {
            return CronFrequency.hourly
        }

        if (isEqual(uniq(takeRight(tokens, 3)),  ['*']) && !includes(_take(tokens, 3), '*')) {
            return CronFrequency.daily
        }

        if (second !== '*' && dayOfMonth === '*' && month === '*' && dayOfWeek !== '*') {
            return CronFrequency.weekly
        }

        if (second !== '*' && month === '*' && dayOfWeek === '*') {
            return CronFrequency.monthly
        }

        return CronFrequency.custom
    }

    const frequency = deduceFrequency(tokens)

    return {
        frequency, second, minute, hour, dayOfMonth, month, dayOfWeek
    }
}

export function formatCron(cron: Cron): string {
    const getIfNotAny = (value: Maybe<string>, fallback: string = '0') => (
        value && value !== '*' ? value : fallback
    )

    switch (cron.frequency) {
        case CronFrequency.hourly: {
            const second = getIfNotAny(cron.second)
            return join([second, 0, '*', '*', '*', '*'], ' ')
        }

        case CronFrequency.daily: {
            const second = getIfNotAny(cron.second)
            const minute = getIfNotAny(cron.minute)
            const hour = getIfNotAny(cron.hour)
            return join([second, minute, hour, '*', '*', '*'], ' ')
        }

        case CronFrequency.weekly: {
            const second = getIfNotAny(cron.second)
            const minute = getIfNotAny(cron.minute)
            const hour = getIfNotAny(cron.hour)
            const dayOfWeek = getIfNotAny(cron.dayOfWeek)
            return join([second, minute, hour, '*', '*', dayOfWeek], ' ')
        }

        case CronFrequency.monthly: {
            const second = getIfNotAny(cron.second)
            const hour = getIfNotAny(cron.hour)
            const minute = getIfNotAny(cron.minute)
            const dayOfMonth = getIfNotAny(cron.dayOfMonth, '1')
            return join([second, minute, hour, dayOfMonth, '*', '*'], ' ')
        }

        case CronFrequency.never: {
            return ''
        }

        default: {
            const { second, minute, hour, month, dayOfMonth, dayOfWeek } = cron
            return join([second, minute, hour, dayOfMonth, month, dayOfWeek], ' ')
        }
    }
}

export function initials(name: Maybe<string>, maxLength: number = 2): string {
    if (!name || !isString(name)) {
        return ''
    }

    if (includes(name, '@')) {
        return initials(head(split(name, '@')) as string)
    }

    const limitToLength = (input: string, length: number) => truncate(input, { length, omission: '' })

    const splitName = (name: string, separator: string) => {
        if (!includes(name, separator)) {
            return null
        }

        const words = split(name, separator)
        const initials = map(words, (word) => limitToLength(word, 1))

        return limitToLength(join(initials, ''), maxLength)
    }

    const letters = splitName(name, ' - ')
        || splitName(name, ' ')
        || splitName(name, '.')
        || splitName(name, '_')
        || splitName(name, '-')
        || limitToLength(name, maxLength)

    return toUpper(trim(join(letters, '')))
}

pluralize.addSingularRule(/caches$/i, 'cache')

export function singular(word: string): string {
    return pluralize.singular(trim(word))
}

export function plural(word: string): string {
    return pluralize.plural(trim(word))
}

export function pastTense(word: string): string {
    const firstPass = replace(word, /([^aeiou])y$/, '$1i')
    const secondPass = replace(firstPass, /([^aeiou][aeiou])([^aeiouy])$/, '$1$2$2')
    const thirdPass = replace(secondPass, /e?$/, 'ed')
    return thirdPass
}

export function compactObject(input: Record<string, unknown>): Record<string, unknown> {
    return reduce(input, (acc, value, key) => {
        if (isString(value) && isEmpty(value)) {
            return acc
        }

        if (isPlainObject(value) || (isObject(value) && !isArray(value))) {
            return {
                ...acc,
                [key]: compactObject(value as Record<string, unknown>)
            }
        }

        return {
            ...acc,
            [key]: value
        }
    }, {})
}

export function gravatarURL(email: string): string {
    const fallbackURL = encodeURI(initialsAvatarURL(email))
    return `https://www.gravatar.com/avatar/${md5(toLower(trim(email)))}?d=${fallbackURL}`
}

export function initialsAvatarURL(input: string): string {
    return `https://cdn.auth0.com/avatars/${toLower(initials(input))}.png?ssl=1`
}

export function cloudConsoleURL(pathname: string, projectName: Maybe<string>, params: routing.Params = {}): string {
    const project = projectName || ''
    return new URI('https://console.cloud.google.com/')
        .pathname(pathname)
        .search({ ...params, project })
        .toString()
}

export function isAbsoluteURL(url: string): boolean {
    return startsWith(url, 'https://') || startsWith(url, 'http://')
}

export function arePropsEqual<Props = Record<string, unknown>>(
    props: string | string[]
): (
    prevProps: Props,
    nextProps: Props
) => boolean {
    return (prevProps: Props, nextProps: Props) => (
        !isString(find(flatten([props]), (prop) => (
            !isEqual(get(nextProps, prop), get(prevProps, prop))
        )))
    )
}

export function objectKeysDeep(object: Record<string, unknown>, path?: string): string[] {
    return reduce(object, (acc: string[], value: unknown, key: string) => {
        const fullKey = isString(path) ? join([path, key], '.') : key
        if (isPlainObject(value) && !isEmpty(value)) {
            return flatten([...acc, objectKeysDeep(value as Record<string, unknown>, fullKey)])
        }
        return [...acc, fullKey]
    }, [])
}

export function objectDifference(
    object: Record<string, unknown>,
    base: Record<string, unknown>
): Record<string, unknown> {
    return reduce(object, (acc: Record<string, unknown>, value: unknown, key: string) => {
        if (!isEqual(value, base[key])) {
            return {
                ...acc,
                [key]: isPlainObject(value) && isPlainObject(base[key])
                    ? objectDifference(value as Record<string, unknown>, base[key] as Record<string, unknown>)
                    : value
            }
        }

        return acc
    }, {})
}

export function objectHash(input: unknown): string {
    return md5(JSON.stringify(input))
}

export function splice<T=unknown>(array: T[], index: number, replacement: T): T[] {
    return [
        ...array.slice(0, index),
        replacement,
        ...array.slice(index + 1)
    ]
}

export function parseQuantity(value: string): number {
    const parseWithUnit = (value: string, unitName: string) => {
        try {
            const parsedUnit = unit(`${value}${unitName}`)
            return parsedUnit.toNumber(unitName)
        }
        catch (e) {
            return 0
        }
    }

    return parseWithUnit(value, 'B') || parseWithUnit(value, 'm')
}

type UnitPrefix = {
    name: string
    value: number
    scientific: boolean
}
type Base = 10 | 2
type FormatOptions = {
    base?: Base
    omitUnit?: boolean
}
export function formatQuantity(value: number | Unit, options?: FormatOptions): string {
    const base = get(options, 'base', 2)
    const omitUnitName = get(options, 'omitUnit', true)
    const unitName = base === 2 ? 'B' : 'm'

    if (isNumber(value)) {
        const parsedUnit = unit(value, unitName)
        const unitPrefixes = tail(values(get(head((parsedUnit as any).units), 'unit.prefixes')))
        const matchPrefix = (value: number, unitPrefixes: UnitPrefix[], base: Base) => {
            const prefixes = base === 10
                ? value >= 1 ? unitPrefixes : reverse(unitPrefixes)
                : slice(unitPrefixes, 8, 16)

            const index = findIndex(prefixes, (prefix) => prefix.value > value)
            const matchedPrefix = prefixes[index - 1]
            return matchedPrefix
        }

        const convertUnit = (unit: Unit, prefix: UnitPrefix) => unit.to(`${prefix.name}${unitName}`)

        const prefix = matchPrefix(value, unitPrefixes, base)
        if (prefix) {
            return formatQuantity(convertUnit(parsedUnit, prefix), options)
        }

        return formatQuantity(parsedUnit, options)
    }

    const formattedValue = replace(value.format({ precision: 3 }).toString(), ' ', '')

    return omitUnitName
        ? replace(formattedValue, new RegExp(`${unitName}$`), '')
        : formattedValue
}

export type SelectableItemParser = (value: unknown) => SelectableItem
export function parseSelectableItem(value: unknown): SelectableItem {
    if (isObject(value) && has(value, 'value')) {
        return value as SelectableItem
    }

    if (isString(value)) {
        return {
            value,
            key   : value,
            label : value
        }
    }

    return {
        value,
        key   : get(value, 'key', toString(value)),
        label : toString(value)
    }
}

export function matchesQuery(value: unknown, query: string): boolean {
    if (isNumber(value)) {
        return matchesQuery(toString(value), query)
    }

    if (!isString(value)) {
        return false
    }

    if (isEmpty(query)) {
        return true
    }

    const needles = split(toLower(query), ' ')
    const haystack = toLower(toString(value))
    const matchings = map(needles, (needle) => includes(haystack, needle))

    return !includes(matchings, false)
}

export function createUpdateMask(paths: string[]): google.protobuf.IFieldMask {
    const updateMask: google.protobuf.IFieldMask = {
        paths: map(paths, (path) => join(map(split(path, '.'), snakeCase), '.'))
    }
    return updateMask
}
