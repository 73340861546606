/* eslint-disable no-use-before-define */

import { ActionType, action as createAction } from 'typesafe-actions'
import { takeEvery, takeLatest, fork, select, take, race, delay, put, call } from 'redux-saga/effects'
import { channel as createChannel } from 'redux-saga'
import { User as OIDCUser, Profile as OIDCProfile, UserManager } from 'oidc-client'
import { createSelector } from 'reselect'
import jwtDecode from 'jwt-decode'
import Cookies from 'js-cookie'

import {
    map, get, pick, join, split, noop, replace, head,
    includes, has, startsWith, isArray, isFunction, isString, isEmpty, isEqual
} from 'lodash'

import { RootState, app, grpc } from '../redux'
import * as system from '../redux/system'
import * as routing from '../redux/routing'
import { Maybe, Omit, watchChannel, gravatarURL, isURL } from '../utils'


//
//  TYPES

export type Token = Omit<OIDCUser, 'toStorageString' | 'fromStorageString' | 'expired' | 'expires_in' | 'state'>
export type InstallToken = {
    exp: number
    iss: string
    sub: string
}
export type State = {
    provider: UserManager | null
    token: Token | null
    isAccessGranted: boolean
    isLoggedOut: boolean
    hasConfigurationError: boolean
    redirectURL: string | null
}

export type Configuration = system.IAuthConfiguration

export type Actions = ActionType<typeof actions>

export type User = {
    id: string
    email?: string
    isEmailVerified: boolean
    fullName?: string
    nickname?: string
    avatarURL?: string
}

const INSTALLER_CLAIMS = {
    iss : 'DashboardIssuer',
    sub : 'dashboard-installer'
}

export const DEFAULT_OIDC_SETTINGS = {
    scope                : 'openid email profile',
    response_type        : 'token id_token',
    redirect_uri         : app.BASE_URL,
    silent_redirect_uri  : app.BASE_URL,
    automaticSilentRenew : false
}

//
//  ACTIONS

export const BOOTSTRAP_REQUESTED     = '@ auth / BOOTSTRAP_REQUESTED'
export const BOOTSTRAP_SUCCEEDED     = '@ auth / BOOTSTRAP_SUCCEEDED'
export const BOOTSTRAP_FAILED        = '@ auth / BOOTSTRAP_FAILED'
export const LOGIN_SUCCEEDED         = '@ auth / LOGIN_SUCCEEDED'
export const LOGIN_FAILED            = '@ auth / LOGIN_FAILED'
export const LOGOUT_REQUESTED        = '@ auth / LOGOUT_REQUESTED'
export const TOKEN_REFRESH_REQUESTED = '@ auth / TOKEN_REFRESH_REQUESTED'
export const TOKEN_INVALID           = '@ auth / TOKEN_INVALID'
export const ACCESS_GRANTED          = '@ auth / ACCESS_GRANTED'
export const REDIRECT_URL_SET        = '@ auth / REDIRECT_URL_SET'

export const bootstrap        = () => createAction(BOOTSTRAP_REQUESTED)
export const bootstrapSuccess = (provider: UserManager) => createAction(BOOTSTRAP_SUCCEEDED, provider)
export const bootstrapFailure = (error: Error) => createAction(BOOTSTRAP_FAILED, error)
export const loginSuccess     = (token: Token) => createAction(LOGIN_SUCCEEDED, token)
export const loginFailure     = (error: Error) => createAction(LOGIN_FAILED, error)
export const logout           = () => createAction(LOGOUT_REQUESTED)
export const refreshToken     = () => createAction(TOKEN_REFRESH_REQUESTED)
export const invalidateToken  = () => createAction(TOKEN_INVALID)
export const grantAccess      = () => createAction(ACCESS_GRANTED)
export const setRedirectURL   = (url: routing.Path) => createAction(REDIRECT_URL_SET, url)

const actions = {
    bootstrap,
    bootstrapSuccess,
    bootstrapFailure,
    logout,
    loginSuccess,
    loginFailure,
    refreshToken,
    invalidateToken,
    grantAccess,
    setRedirectURL
}


//
//  REDUCER

const initialState: State = {
    provider              : null,
    token                 : null,
    isAccessGranted       : false,
    isLoggedOut           : false,
    hasConfigurationError : false,
    redirectURL           : '/'
}

export function reducer(
    state: State = initialState,
    action: Actions | ActionType<typeof grpc.fail>
) {
    switch (action.type) {
        case BOOTSTRAP_SUCCEEDED: {
            return {
                ...state,
                provider              : action.payload,
                hasConfigurationError : false
            }
        }

        case LOGIN_SUCCEEDED: {
            return {
                ...state,
                token: action.payload
            }
        }

        case LOGOUT_REQUESTED: {
            return {
                ...initialState,
                provider    : state.provider,
                isLoggedOut : true
            }
        }

        case LOGIN_FAILED: {
            const error = action.payload
            const hasConfigurationError = error && (
                isConfigurationError(error)
                || grpc.isNetworkError(error)
            )
            return {
                ...initialState,
                hasConfigurationError,
                provider: state.provider
            }
        }

        case ACCESS_GRANTED: {
            return {
                ...state,
                isAccessGranted : true,
                isLoggedOut     : false
            }
        }

        case TOKEN_REFRESH_REQUESTED: {
            return {
                ...state,
                isLoggedOut: false
            }
        }

        case REDIRECT_URL_SET: {
            return {
                ...state,
                redirectURL: action.payload
            }
        }

        case grpc.FAILED: {
            const error = action.payload.error
            if (!error || !isConfigurationError(error)) {
                return state
            }

            return {
                ...initialState,
                hasConfigurationError: true
            }
        }

        default:
            return state
    }
}


//
//  SAGA

const channel = createChannel()

export function* saga() {
    yield takeEvery([
        app.INITIALIZED
    ], fetchConfiguration)

    yield takeEvery([
        system.authConfiguration.GET_SUCCEEDED,
        system.authConfiguration.GET_FAILED
    ], triggerBootstrap)

    yield takeLatest([
        app.INITIALIZED,
        LOGIN_SUCCEEDED
    ], grantAccessIfRequired)

    yield takeLatest([
        BOOTSTRAP_REQUESTED
    ], performBootstrap)

    yield takeLatest([
        routing.ROUTE_CHANGED
    ], performAuthentication)

    yield takeEvery([
        BOOTSTRAP_SUCCEEDED
    ], performAuthentication)

    yield takeEvery([
        LOGIN_SUCCEEDED,
        LOGIN_FAILED
    ], handleRedirect)

    yield takeEvery([
        routing.ROUTE_CHANGED
    ], readAuthenticationPayloadIfPresent)

    yield takeEvery(LOGIN_FAILED, clearProviderState)
    yield takeEvery(TOKEN_REFRESH_REQUESTED, handleTokenRefresh)
    yield takeEvery(grpc.FAILED, handleAuthenticationErrors)
    yield fork(watchChannel, channel)
}

function* fetchConfiguration() {
    yield put(system.fetchAuthConfiguration())
}

function* triggerBootstrap() {
    yield put(bootstrap())
}

function* performBootstrap() {
    const configuration: Configuration = yield select(getConfiguration)
    if (!configuration) {
        return
    }

    const existingProvider: Maybe<UserManager> = yield select(getProvider)
    if (!existingProvider || !providerHasConfiguration(existingProvider, configuration)) {
        if (existingProvider) {
            unbindProviderEvents(existingProvider)
        }

        const provider = createProvider(configuration)
        if (!provider) {
            const error = new Error('Cannot create OIDC provider')
            yield put(bootstrapFailure(error))
            return
        }

        yield put(bootstrapSuccess(provider))
    }
}

function* performAuthentication() {
    const route: routing.Route = yield select(routing.getCurrentRoute)
    if (isInstallationRoute(route)) {
        const idToken = get(route, 'params.token')
        if (!isEmpty(idToken)) {
            const token = decodeInstallToken(idToken)
            yield put(loginSuccess(token))
        }
    }

    const provider: Maybe<UserManager> = yield select(getProvider)
    if (!provider) {
        return
    }

    const isOIDCConfigured: boolean = yield select(isConfigured)
    if (!isOIDCConfigured) {
        return
    }

    const isRecentlyLoggedOut: boolean = yield select(isLoggedOut)
    if (isRecentlyLoggedOut) {
        return
    }

    if (isAuthenticationRoute(route)) {
        provider.signinCallback(normalizeAuthenticationRoute(route)).catch((error: Error) => {
            channel.put(loginFailure(error))
        })
        return
    }

    const hasToken: boolean = yield select(hasValidToken)
    if (hasToken) {
        provider.startSilentRenew()
    }
    else {
        if (route) {
            yield put(setRedirectURL(route.url))
        }
        yield put(invalidateToken())
        provider.signinRedirect().catch((error: Error) => {
            channel.put(loginFailure(error))
        })
    }
}

function* grantAccessIfRequired() {
    const hasToken: boolean = yield select(hasValidToken)
    if (hasToken) {
        yield call(setGRPCAuthorizationMetadata)
        yield call(setCookie)
        yield race({
            success : take(grpc.METADATA_SET),
            timeout : delay(1000)
        })
        yield put(grantAccess())
    }
}

function* readAuthenticationPayloadIfPresent() {
    const provider: Maybe<UserManager> = yield select(getProvider)
    if (!provider) {
        return
    }

    const route: routing.Route = yield select(routing.getCurrentRoute)
    if (isAuthenticationRoute(route)) {
        provider.signinRedirectCallback(normalizeAuthenticationRoute(route)).catch((error: Error) => {
            channel.put(loginFailure(error))
        })
    }
}

function* handleTokenRefresh(action: ActionType<typeof refreshToken>) {
    const provider: Maybe<UserManager> = yield select(getProvider)
    if (!provider) {
        return
    }

    provider.signinSilent().catch((error: Error) => {
        provider.signinRedirect()
    })
}

function* handleAuthenticationErrors(action: ActionType<typeof grpc.fail>) {
    const { error } = action.payload
    if (get(error, 'status.code') === grpc.StatusCode.Unauthenticated) {
        yield put(logout())
    }
}

function* handleRedirect() {
    const redirectURL: Maybe<string> = yield select(getRedirectURL)
    if (redirectURL) {
        yield put(routing.push(redirectURL))
    }
    else {
        yield call(routing.redirectToDashboard)
    }
}

function* setGRPCAuthorizationMetadata() {
    const tokenPayload: Maybe<string> = yield select(getTokenPayload)
    if (tokenPayload) {
        yield put(grpc.setMetadata({
            key   : 'Authorization',
            value : tokenPayload
        }))
    }
}

function* setCookie() {
    const token: Maybe<Token> = yield select(getToken)
    if (token) {
        Cookies.set('auth_token', token.id_token, {
            domain   : window.location.hostname,
            secure   : true,
            sameSite : 'strict'
        })
    }
}

export function* ensureAuthentication(onComplete?: () => any) {
    const isAlreadyAuthenticated: boolean = yield select(isAuthenticated)

    if (!isAlreadyAuthenticated) {
        yield take(ACCESS_GRANTED)
        if (isFunction(onComplete)) {
            yield call(onComplete)
        }
    }
}

function* clearProviderState() {
    const provider: Maybe<UserManager> = yield select(getProvider)
    if (!provider) {
        return
    }

    provider.clearStaleState()
}

function normalizeAuthenticationRoute(route: routing.Route) {
    const fragment = routing.toFragment(route.path)
    return replace(fragment, '#/', '#')
}

//
//   HELPERS and UTILITIES

function createProvider(config: Configuration): UserManager | null {
    if (!has(global, 'window')) {
        return null
    }

    if (!isValidConfiguration(config)) {
        return null
    }

    const provider = new UserManager({
        authority : config.oidcIssuer as string,
        client_id : config.oidcClientId as string,
        ...DEFAULT_OIDC_SETTINGS
    })

    bindProviderEvents(provider)

    return provider
}

function bindProviderEvents(provider: UserManager) {
    provider.events.addUserLoaded((token) => {
        token && !token.expired
            ? channel.put(loginSuccess(token))
            : channel.put(loginFailure(new Error(`Invalid token: ${token}`)))
    })
    provider.events.addUserUnloaded(() => channel.put(logout()))
    provider.events.addUserSignedOut(() => channel.put(logout()))
    provider.events.addSilentRenewError(() => channel.put(logout()))
    provider.events.addAccessTokenExpired(() => channel.put(logout()))
    provider.events.addAccessTokenExpiring(() => channel.put(refreshToken()))
}

function unbindProviderEvents(provider: UserManager) {
    provider.events.removeUserLoaded(noop)
    provider.events.removeUserUnloaded(noop)
    provider.events.removeUserSignedOut(noop)
    provider.events.removeSilentRenewError(noop)
    provider.events.removeAccessTokenExpired(noop)
    provider.events.removeAccessTokenExpiring(noop)
}

function providerHasConfiguration(provider: UserManager, configuration: Configuration): boolean {
    return provider.settings.authority === configuration.oidcIssuer
        && provider.settings.client_id === configuration.oidcClientId
}

function isAuthenticationRoute(route: routing.Route) {
    return /access_token|id_token|error/.test(route.path)
}

function isInstallationRoute(route: routing.Route) {
    return route.key === routing.Key.install && isString(get(route.params, 'token'))
}

function isTokenValid(token: Token | null) {
    if (!token || !token.expires_at) {
        return false
    }
    return (Date.now() / 1000) < token.expires_at
}

export function isValidInstallToken(token: Token | null) {
    if (!isTokenValid(token)) {
        return false
    }

    return isEqual(pick(token?.profile, ['iss', 'sub']), INSTALLER_CLAIMS)
}

function normalizeProfile(token: Token): Maybe<User> {
    if (!isTokenValid(token)) {
        return null
    }

    return {
        id              : token.profile.sub,
        isEmailVerified : token.profile.email_verified || false,
        avatarURL       : token.profile.picture,
        fullName        : token.profile.name,
        ...pick(token.profile, ['email', 'nickname'])
    }
}

function decodeInstallToken(idToken: string): Token {
    const token = jwtDecode<InstallToken>(idToken)
    return {
        id_token     : idToken,
        expires_at   : token.exp,
        token_type   : 'Bearer',
        access_token : '',
        scope        : get(token, 'scope', ''),
        scopes       : split(get(token, 'scope', ''), ' '),
        profile      : {
            ...pick(token, ['sub', 'iss', 'exp', 'aud', 'iat', 'name', 'email']),
            email_verified: true
        } as OIDCProfile
    }
}

export function isUser(entry: any): entry is User {
    return has(entry, 'email')
        && has(entry, 'isEmailVerified')
        && has(entry, 'avatarURL')
        && has(entry, 'fullName')
}

export function avatarURL(entry: User) {
    return isEmpty(entry.avatarURL) && isString(entry.email) && !isEmpty(entry.email)
        ? gravatarURL(entry.email)
        : isArray(entry.avatarURL) ? head(entry.avatarURL) : entry.avatarURL
}

export function isValidConfiguration(config: Configuration) {
    return !isEmpty(config) && isURL(config.oidcIssuer) && startsWith(config.oidcIssuer as string, 'https://')
        && isString(config.oidcClientId) && !isEmpty(config.oidcClientId)
}

export function isConfigurationError(error: Error) {
    return includes(error.message, 'oidc')
        || error.message === 'authority mismatch on settings vs. signin state'
}


//
//  SELECTORS

export const getState = (state: RootState): State => state.auth
export const getProvider = createSelector(
    getState,
    (state) => state.provider
)
export const getToken = createSelector(
    getState,
    (state) => state ? state.token : null
)
export const getRedirectURL = createSelector(
    getState,
    (state) => state ? state.redirectURL : null
)
export const getTokenPayload = createSelector(
    getToken,
    (token) => token ? join([token.token_type, token.id_token], ' ') : null
)
export const getCurrentUser = createSelector(
    getToken,
    (token) => token ? normalizeProfile(token) : null
)
export const hasValidToken = createSelector(
    getToken,
    (token) => token ? isTokenValid(token) : false
)
export const isAuthenticated = createSelector(
    [hasValidToken, getState],
    (hasToken, state) => hasToken && state.isAccessGranted
)
export const isLoggedOut = createSelector(
    getState,
    (state) => state.isLoggedOut
)
export const isAdmin = createSelector(
    [getToken, getCurrentUser, system.getAdminUsers],
    (token, user, adminUsers) => isValidInstallToken(token) || includes(map(adminUsers, 'email'), user?.email)
)
export const isEmailVerified = createSelector(
    getCurrentUser,
    (user) => user ? user.isEmailVerified : false
)
export const getConfiguration = system.getAuthConfiguration
export const isConfigured = system.isAuthConfigured
export const isConfiguring = grpc.isLoadingRequest(system.fetchAuthConfiguration().payload)
export const hasConfigurationError = createSelector(
    getState,
    (state) => state.hasConfigurationError
)
