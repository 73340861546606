import * as React from 'react'
import { Tag, Classes, Intent, Elevation } from '@blueprintjs/core'
import { Flex, Box } from 'reflexbox'
import classes from 'classnames'

import { isString, isEmpty } from 'lodash'

import { routing } from '../redux'

import Container, { Props as ContainerProps } from '../components/Container'
import Link from '../components/Link'
import Icon, { IconName } from '../components/Icon'
import colors from '../assets/colors'

import styles from './Card.module.scss'

type Props = {
    title: React.ReactNode
    subtitle?: string
    tag?: string
    intent?: Intent
    icon?: React.ReactNode | IconName
    rightItem?: React.ReactNode
    footer?: React.ReactNode
    link?: routing.Path
    elevation?: Elevation
    onClick?: () => void
} & ContainerProps

const Card: React.FC<Props> = (props) => {
    const { icon, rightItem, footer, children, intent, className, ...otherProps } = props
    const hasIcon = !isEmpty(icon)

    return (
        <Container className={ classes(styles.container, Classes.intentClass(intent), className) } { ...otherProps }>
            {
                hasIcon
                    ? [
                        <Flex justify="space-between" key="header">
                            <Box><CardIcon { ...props } /></Box>
                            { rightItem && <Box mr={ -15 }>{ rightItem }</Box> }
                        </Flex>,
                        <CardTitle key="title" { ...props } />
                    ] : (
                        <Flex justify="space-between">
                            <Box w={ 1 }>
                                <CardTitle { ...props } />
                            </Box>
                            { rightItem && <Box mr={ -15 }>{ rightItem }</Box> }
                        </Flex>
                    )
            }
            { children }
            { footer && <div className={ styles.footer }>{ footer }</div> }
        </Container>
    )
}

const CardIcon: React.FC<Props> = (props) => {
    const iconProps = { size: 35, color: colors.blue500 }
    const renderedIcon = React.isValidElement(props.icon)
        ? React.cloneElement(props.icon, { ...props.icon.props, ...iconProps })
        : isString(props.icon) && <Icon name={ props.icon as IconName } { ...iconProps } />

    return renderedIcon ? <div className={ styles.icon }>{ renderedIcon }</div> : null
}

const CardTitle: React.FC<Props> = (props) => {
    const { title, subtitle, tag, link, onClick } = props
    return (
        <>
            { title && isString(title) && (
                <h2 key="title" className={ styles.title }>
                    {
                        link || onClick
                            ? <Link onClick={ onClick } to={ link }>{ title }</Link>
                            : title
                    }
                    { tag && <Tag>{ tag }</Tag> }
                </h2>
            ) }
            { title && !isString(title) ? title : null }
            { subtitle && <h3 key="subtitle">{ subtitle }</h3> }
        </>
    )
}

Card.defaultProps = {
    elevation: Elevation.TWO
}


export default Card
