import * as React from 'react'

import { api, organizations, ui } from '../redux'
import { useTranslation } from '../hooks'

import Avatar from '../components/Avatar'
import TitleBar, { ModifierProps } from '../components/TitleBar'

type Props = {
    entry?: organizations.IOrganization | null
    rightItem?: React.ReactNode
    withActions?: boolean
    withAvatar?: boolean
} & ModifierProps

const OrganizationTitle: React.FC<Props> = (props) => {
    const { entry, withAvatar, isCompact, rightItem } = props
    const [t] = useTranslation()

    const title = !entry || api.isNewEntry(entry)
        ? t(ui.createTitle(api.Resource.organization, api.Request.create))
        : api.displayName(entry)

    return (
        <TitleBar
            title={ title }
            icon={ withAvatar && <Avatar name={ entry ? title : null } /> }
            rightItem={ rightItem }
            isCentered={ !api.isNewEntry(entry) }
            isCompact={ isCompact }
        />
    )
}

OrganizationTitle.defaultProps = {
    withActions : true,
    withAvatar  : false,
    isCompact   : false
}

export default OrganizationTitle
