import * as React from 'react'
import { Menu, MenuItem, AnchorButton, MenuDivider } from '@blueprintjs/core'
import classes from 'classnames'

import { map, flatten, values, size, isEmpty } from 'lodash'

import { search, ui, api } from '../redux'
import { useTranslation } from '../hooks'
import { Maybe } from '../utils'

import ResourceTitle from '../components/ResourceTitle'

import styles from './SearchResultsList.module.scss'

type Props = {
    query: search.Query | null
    results: search.ResultsList
    selectedItem: Maybe<search.Result>
    onSelect: (result: search.Result) => void
    isSearching: boolean
    isPerformed: boolean
}

const SearchResultsList: React.FC<Props> = (props) => {
    const { results, query, selectedItem, onSelect, isPerformed, isSearching } = props
    const [t] = useTranslation()

    const allItems = flatten(values(results))

    if (isSearching && isEmpty(allItems)) {
        return (
            <BlankSlate
                text={ t('Searching for "{{query}}"', { query }) }
            />
        )
    }

    if (isEmpty(query)) {
        return null
    }

    if (size(query) < search.MIN_QUERY_LENGTH) {
        return (
            <BlankSlate
                text={ t('Type at least {{minQueryLength}} characters to perform a search', {
                    minQueryLength: search.MIN_QUERY_LENGTH
                }) }
            />
        )
    }

    if (isPerformed && isEmpty(allItems)) {
        return <BlankSlate text={ t('Nothing found for "{{query}}"', { query }) } />
    }

    return (
        <Menu className={ styles.container }>
            { map([
                api.Resource.site,
                api.Resource.project,
                api.Resource.organization
            ], (resource: search.IndexedResource) => {
                const list: search.Result[] = results[resource]

                if (isEmpty(list)) {
                    return null
                }

                return (
                    <React.Fragment key={ resource }>
                        <MenuDivider title={ t(ui.resourceDisplayName(resource)) } />
                        { map(list, (result) => (
                            <ResultItem
                                key={ result.name }
                                entry={ result }
                                resource={ resource }
                                isSelected={ result === selectedItem }
                                onSelect={ onSelect }
                            />
                        )) }
                    </React.Fragment>
                )
            }) }
        </Menu>
    )
}

const BlankSlate: React.FC<{ text: string }> = ({ text }) => (
    <Menu>
        <MenuItem disabled text={ text } />
    </Menu>
)

const ResultItem: React.FC<{
    resource: api.Resource
    entry: search.Result
    onSelect: (entry: search.Result) => void
    isSelected: boolean
}> = ({ entry, resource, onSelect, isSelected }) => (
    <AnchorButton
        className={ classes(styles.item, { [styles.selected]: isSelected }) }
        onClick={ () => onSelect(entry) }
    >
        <ResourceTitle
            entry={ entry }
            resource={ resource }
            isCompact
            withAvatar
            isInteractive={ false }
        />
    </AnchorButton>
)

export default SearchResultsList
