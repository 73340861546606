import * as React from 'react'
import { Field } from 'formik'

import { values, startCase } from 'lodash'

import { useTranslation } from '../hooks'
import { tolerations, forms } from '../redux'
import { Maybe, keyOfEnum } from '../utils'

import SelectField from '../components/SelectField'

type Props = forms.FieldProps<tolerations.Effect>

const TolerationEffectSelect: React.FC<Props> = (props) => {
    const { field } = props
    const { value } = field

    const [t] = useTranslation()

    return (
        <Field
            { ...props }
            items={ values(tolerations.Effect) }
            component={ SelectField }
            selectedItem={ value }
            itemParser={ (item: Maybe<tolerations.Effect>) => (item ? {
                value : item,
                key   : item,
                label : t(startCase(keyOfEnum(tolerations.Effect, item) || item))
            } : null) }
            placeholder={ t('Select effect...') }
            filterable={ false }
        />
    )
}

export default TolerationEffectSelect
