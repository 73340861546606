import i18next, { TFunction, i18n } from 'i18next'
import { initReactI18next, Trans } from 'react-i18next'

import { reduce, get } from 'lodash'

import {
    enUS as enDate,
    ro as roDate,
    sv as svDate,
    ja as jaDate,
    de as deDate,
    es as esDate,
    nl as nlDate,
    fr as frDate
} from 'date-fns/locale'

import enResource from './locales/en.json'
import roResource from './locales/ro.json'
import svResource from './locales/sv.json'
import jaResource from './locales/ja.json'
import deResource from './locales/de.json'
import esResource from './locales/es.json'
import nlResource from './locales/nl.json'
import frResource from './locales/fr.json'

import { Maybe } from './utils'

export type TranslationProps = {
    t: TFunction
    i18n: i18n
}

export enum Language {
    en = 'en',
    ro = 'ro',
    sv = 'sv',
    ja = 'ja',
    de = 'de',
    es = 'es',
    nl = 'nl',
    fr = 'fr'
}

export enum LanguageName {
    en = 'English',
    ro = 'Romanian',
    sv = 'Swedish',
    ja = 'Japanese',
    de = 'German',
    es = 'Spanish',
    nl = 'Dutch',
    fr = 'French'
}

const RESOURCES: Record<Language, Record<string, unknown>> = {
    en : enResource,
    ro : roResource,
    sv : svResource,
    ja : jaResource,
    de : deResource,
    es : esResource,
    nl : nlResource,
    fr : frResource
}

const DATE_LOCALES: Record<Language, Record<string, unknown>> = {
    en : enDate,
    ro : roDate,
    sv : svDate,
    ja : jaDate,
    de : deDate,
    es : esDate,
    nl : nlDate,
    fr : frDate
}

export const DEFAULT_LANGUAGE: Language = Language.en

i18next
    .use(initReactI18next)
    .init({
        lng          : DEFAULT_LANGUAGE,
        nsSeparator  : false,
        keySeparator : false,
        fallbackLng  : DEFAULT_LANGUAGE,
        debug        : false,
        react        : {
            useSuspense   : true,
            bindI18n      : 'languageChanged',
            bindI18nStore : false,
            nsMode        : 'default'
        },
        resources: reduce(Language, (acc, language) => ({
            ...acc,
            [language]: { translation: RESOURCES[language] }
        }), {})
    })

export const getDateLocale = (language: Maybe<Language> = i18next.language as Language) => (
    get(DATE_LOCALES, language as string, DATE_LOCALES[DEFAULT_LANGUAGE])
)

export { i18next as i18n, Trans as T }
