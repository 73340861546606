import * as React from 'react'
import { connect } from 'react-redux'

import { Spinner } from '@blueprintjs/core'

import { RootState, grpc } from '../redux'

import styles from './NetworkLoadingIndicator.module.scss'

type ReduxProps = {
    isLoading: boolean
}

type Props = ReduxProps

const NetworkLoadingIndicator: React.FC<Props> = (props) => {
    const { isLoading } = props

    if (!isLoading) {
        return null
    }

    return (
        <Spinner size={ 30 } className={ styles.spinner } />
    )
}

function mapStateToProps(state: RootState): ReduxProps {
    return {
        isLoading: grpc.isLoading(state)
    }
}

export default connect(mapStateToProps)(NetworkLoadingIndicator)
