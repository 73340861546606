/* eslint-disable max-len */

import * as React from 'react'
import { Props } from '../../components/BaseIcon'

const ProjectIcon: React.FC<Props> = (props) => (
    <svg width={ 317 } height={ 253 } preserveAspectRatio="xMaxYMax meet" fill="none" viewBox="0 0 317 233" { ...props }>
        <g opacity="0.3" stroke="#E6EEF8" strokeMiterlimit="10">
            <path d="M201.852 206.895L44.0347 115.818" />
            <path d="M316.635 140.63L158.817 49.6257" />
            <path d="M302.241 148.95L144.424 57.9453" />
            <path d="M259.207 173.763L101.389 82.7581" />
            <path d="M273.6 165.516L115.783 74.4385" />
            <path d="M287.921 157.197L130.103 66.1919" />
            <path d="M244.886 182.009L87.0688 91.0047" />
            <path d="M187.458 215.142L29.6411 124.137" />
            <path d="M216.172 198.576L58.355 107.571" />
            <path d="M230.566 190.329L72.6753 99.3243" />
            <path d="M173.138 223.389L15.3203 132.384" />
            <path d="M1 140.63L158.817 231.708" />
            <path d="M101.389 198.576L259.207 107.571" />
            <path d="M1 140.63L158.817 49.6257" />
            <path d="M15.3203 148.95L173.138 57.9453" />
            <path d="M115.783 206.895L273.6 115.818" />
            <path d="M29.6411 157.197L187.458 66.1919" />
            <path d="M87.0688 190.329L244.886 99.3243" />
            <path d="M44.0347 165.516L201.852 74.4385" />
            <path d="M72.6753 182.009L230.566 91.0047" />
            <path d="M58.355 173.763L216.172 82.7581" />
            <path d="M130.103 215.142L287.921 124.137" />
            <path d="M144.424 223.389L302.241 132.384" />
            <path d="M158.817 231.708L316.635 140.63" />
        </g>
        <path d="M158.818 215.142L287.921 140.63L158.818 66.1919L29.6411 140.63L158.818 215.142Z" stroke="#B4C6DF" strokeMiterlimit="10" strokeDasharray="4 4" />
        <path opacity="0.1" d="M101.389 132.384L87.0688 140.63L158.817 182.009L230.566 140.63L216.172 132.384L158.817 165.516L101.389 132.384Z" fill="#89A5CC" />
        <g opacity="0.8">
            <path d="M87.0688 41.3791L158.817 82.7581L230.566 41.3791L158.817 0L87.0688 41.3791Z" fill="#E6EEF8" />
            <path d="M87.0688 41.379V124.137L158.817 165.516V82.7581L87.0688 41.379Z" fill="#CAD7EA" />
            <path d="M158.817 82.7581L230.566 41.379V124.137L158.817 165.516V82.7581Z" fill="#89A5CC" />
        </g>
    </svg>
)

export default ProjectIcon
