import * as React from 'react'
import { MenuItem } from '@blueprintjs/core'
import { TimePicker } from '@blueprintjs/datetime'
import { Select } from '@blueprintjs/select'
import { Flex, Box } from 'reflexbox'
import { setHours, setMinutes } from 'date-fns'

import { map, without, filter, get, tail, range, parseInt, toString, includes, startsWith } from 'lodash'

import { useTranslation } from '../hooks'
import { forms } from '../redux'
import { CronFrequency, parseCron, formatCron, weekDayName } from '../utils'

import FormGroup from '../components/FormGroup'
import Button from '../components/Button'

import styles from './CronField.module.scss'

const NumberSelect = Select.ofType<number>()
const FrequencySelect = Select.ofType<CronFrequency>()

type Props = forms.FieldProps & {
    disabled?: boolean
}

const CronField: React.FC<Props> = (props) => {
    const { field, form, label, helperText, disabled } = props
    const { name, value } = field
    const errors = forms.getFieldErrors(form.errors, name)

    const cron = parseCron(value)

    const [t] = useTranslation()
    const { frequency } = cron

    const showTime = includes([CronFrequency.daily, CronFrequency.weekly, CronFrequency.monthly], frequency)
    const showDayOfWeek = frequency === CronFrequency.weekly
    const showDayOfMonth = frequency === CronFrequency.monthly

    return (
        <FormGroup
            errors={ forms.formatFieldErrors(label, errors) }
            label={ label }
            { ...field }
        >
            <Flex column>
                <Flex align="center">
                    <Box>
                        <FrequencySelect
                            items={ without(map(CronFrequency), CronFrequency.custom) as CronFrequency[] }
                            onItemSelect={ (frequency: CronFrequency) => {
                                const newCron = formatCron({ ...cron, frequency })
                                form.setFieldValue(name, newCron)
                            } }
                            itemRenderer={ (item: CronFrequency, { handleClick }) => (
                                <MenuItem
                                    key={ item }
                                    onClick={ handleClick }
                                    active={ frequency === item }
                                    text={ t(item) }
                                />
                            ) }
                            disabled={ disabled }
                            filterable={ false }
                        >
                            <Button rightIcon="caret-down" isBordered>
                                { t(frequency) }
                            </Button>
                        </FrequencySelect>
                    </Box>
                    { showDayOfWeek && (
                        <Flex align="center">
                            <Box ml={ 2 } mr={ 2 }>
                                on
                            </Box>
                            <Box>
                                <NumberSelect
                                    items={ range(7) }
                                    onItemSelect={ (item: number) => {
                                        const dayOfWeek = toString(item)
                                        const newCron = formatCron({ ...cron, frequency, dayOfWeek })
                                        form.setFieldValue(name, newCron)
                                    } }
                                    itemRenderer={ (item: number, { handleClick }) => (
                                        <MenuItem
                                            key={ item }
                                            onClick={ handleClick }
                                            active={ item === parseInt(get(cron, 'dayOfWeek', '0')) }
                                            text={ weekDayName(item) }
                                        />
                                    ) }
                                    disabled={ disabled }
                                    filterable={ false }
                                >
                                    <Button rightIcon="caret-down" isBordered>
                                        { weekDayName(parseInt(get(cron, 'dayOfWeek', '0'))) }
                                    </Button>
                                </NumberSelect>
                            </Box>
                        </Flex>
                    ) }
                    { showDayOfMonth && (
                        <Flex align="center">
                            <Box ml={ 2 } mr={ 2 }>
                                on
                            </Box>
                            <Box>
                                <NumberSelect
                                    items={ tail(range(32)) }
                                    onItemSelect={ (item: number) => {
                                        const dayOfMonth = toString(item)
                                        const newCron = formatCron({ ...cron, frequency, dayOfMonth })
                                        form.setFieldValue(name, newCron)
                                    } }
                                    itemRenderer={ (item: number, { handleClick }) => (
                                        <MenuItem
                                            key={ item }
                                            onClick={ handleClick }
                                            active={ item === parseInt(get(cron, 'dayOfMonth', '0')) }
                                            text={ item }
                                        />
                                    ) }
                                    itemListPredicate={ (query: string, items: number[]) => (
                                        filter(items, (i) => startsWith(toString(i), query))
                                    ) }
                                    popoverProps={ { popoverClassName: styles.dayOfMonthPicker } }
                                    disabled={ disabled }
                                >
                                    <Button rightIcon="caret-down" isBordered>
                                        { get(cron, 'dayOfMonth', '1') }
                                    </Button>
                                </NumberSelect>
                            </Box>
                        </Flex>
                    ) }
                    { showTime && (
                        <Flex align="center">
                            <Box ml={ 2 } mr={ 2 }>
                                at
                            </Box>
                            <Box>
                                <TimePicker
                                    onChange={ (date: Date) => {
                                        const hour = toString(date.getHours())
                                        const minute = toString(date.getMinutes())
                                        const cronExpression = formatCron({ frequency, hour, minute })
                                        form.setFieldValue(name, cronExpression)
                                    } }
                                    value={
                                        cron ? setMinutes(
                                            setHours(
                                                new Date(),
                                                parseInt(get(cron, 'hour', '0'))
                                            ),
                                            parseInt(get(cron, 'minute', '0'))
                                        ) : undefined
                                    }
                                    disabled={ disabled }
                                />
                            </Box>
                        </Flex>
                    ) }
                </Flex>
                <span className="bp3-form-helper-text">{ helperText }</span>
            </Flex>
        </FormGroup>
    )
}

export default CronField
