import * as React from 'react'

import { api, pods } from '../redux'
import colors from '../assets/colors'
import ResourceTitle, { Props as BaseProps } from '../components/ResourceTitle'
import Icon from '../components/Icon'

type Props = Omit<BaseProps, 'resource'>

const PodTitle: React.FC<Props> = (props) => {
    const { entry } = props
    return (
        <ResourceTitle
            resource={ api.Resource.pod }
            avatar={ (
                entry ? (
                    <Icon size={ 20 } name={ pods.iconName(entry) } color={ colors.gray500 } />
                ) : undefined
            ) }
            { ...props }
        />
    )
}

export default PodTitle
