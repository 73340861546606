import { ActionType, action as createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'
import { takeEvery, fork, call, put, race, take, select } from 'redux-saga/effects'

import {
    map, find, some, parseInt, get, head, last, startCase, snakeCase, toString,
    pick, pickBy, size, min, startsWith, isEmpty, isBoolean, isNumber, isNaN
} from 'lodash'

import {
    RootState, Selector, SelectorCreator, api, auth, system, sites, routing, forms, ui, organizations
} from '../redux'

export type Wizard = {
    values: Values
    errors: forms.Errors
    currentStep: number
    isCompleted: boolean
    steps: Step[]
}
export enum Key {
    site  = 'site',
    install = 'install'
}
export type State = Record<Key, Wizard>
export type Actions = ActionType<typeof actions>
export type Values = forms.Values
export type Step = {
    key: StepKey
    number: number
    fields: string[]
    isSkippable: boolean
}
export enum StepKey {
    routing = 'routing',
    runtime = 'runtime',
    bootstrap = 'bootstrap',
    adminUser = 'adminUser',
    authentication = 'authentication',
    letsEncrypt = 'letsEncrypt',
    configConnector = 'configConnector'
}
type StepPayload = Partial<Step> & { key: StepKey }


//
//  ACTIONS

export const UPDATED   = '@ wizard / UPDATED'
export const FAILED    = '@ wizard / FAILED'
export const ADVANCED  = '@ wizard / ADVANCED'
export const DISCARDED = '@ wizard / DISCARDED'

export const update = (key: Key, values: Values) => createAction(UPDATED, { key, values })
export const fail = (key: Key, errors: forms.Errors) => createAction(FAILED, { key, errors })
export const advance = (key: Key) => createAction(ADVANCED, { key })
export const discard = (key: Key) => createAction(DISCARDED, { key })

const actions = {
    update,
    fail,
    advance,
    discard
}


//
//  REDUCER

const initialState: State = {
    [Key.site]: buildWizard(
        [{
            key    : StepKey.routing,
            fields : ['site.routes']
        }, {
            key         : StepKey.runtime,
            isSkippable : true,
            fields      : ['site.wordpressImage']
        }, {
            key    : StepKey.bootstrap,
            fields : [
                'site.bootstrap.username', 'site.bootstrap.password',
                'site.bootstrap.email', 'site.bootstrap.title'
            ]
        }]
    ),
    [Key.install]: buildWizard(
        [{
            key         : StepKey.adminUser,
            fields      : ['adminUsers'],
            isSkippable : true
        }, {
            key         : StepKey.authentication,
            fields      : ['configuration.oidcIssuer', 'configuration.oidcClientId', 'configuration.oidcClientSecret'],
            isSkippable : true
        }, {
            key         : StepKey.letsEncrypt,
            fields      : ['configuration.email', 'configuration.server'],
            isSkippable : true
        }, {
            key         : StepKey.configConnector,
            fields      : ['configConnector.isConfigured'],
            isSkippable : true
        }]
    )
}


export function reducer(
    state: State = initialState,
    action: Actions | ActionType<typeof auth.logout>
) {
    switch (action.type) {
        case UPDATED: {
            const { key } = action.payload
            const wizard = get(state, key)

            return {
                ...state,
                [key]: {
                    ...wizard,
                    values: {
                        ...wizard.values,
                        ...action.payload.values
                    },
                    errors: {}
                }
            }
        }

        case ADVANCED: {
            const { key } = action.payload
            const wizard = get(state, key)

            return {
                ...state,
                [key]: {
                    ...wizard,
                    isCompleted : wizard.currentStep === size(wizard.steps),
                    currentStep : min([wizard.currentStep + 1, size(wizard.steps)])
                }
            }
        }

        case FAILED: {
            const { key } = action.payload
            const wizard = get(state, key)

            return {
                ...state,
                [key]: {
                    ...wizard,
                    errors: {
                        ...wizard.errors,
                        ...action.payload.errors
                    }
                }
            }
        }

        case DISCARDED: {
            const { key } = action.payload

            return {
                ...state,
                [key]: initialState[key]
            }
        }

        case auth.LOGOUT_REQUESTED: {
            return initialState
        }

        default:
            return state
    }
}


//
//  SAGA

export function* saga() {
    yield fork(forms.takeEverySubmission, [
        forms.Name.installWizard,
        forms.Name.createSiteWizard
    ], handleFormSubmit)

    yield takeEvery(ADVANCED, redirectToNextStep)
}

function* handleFormSubmit(action: ActionType<typeof forms.submit>) {
    const { name, values, initialValues, helpers } = action.payload

    switch (name) {
        case forms.Name.installWizard: {
            const key = Key.install
            const activeStep: Step = yield select(getActiveStep(key))
            const currentStep: Step = yield select(getCurrentStep(key))
            const isPristine = forms.areValuesEqual(initialValues, values)

            switch (activeStep.key) {
                default: {
                    if (activeStep.isSkippable && (isPristine || !hasStepValues(activeStep, values))) {
                        if (activeStep.number === currentStep.number) {
                            yield put(advance(key))
                        }
                        else {
                            yield call(redirectToNextStep, advance(key))
                        }

                        yield call(helpers.setSubmitting, false)
                    }
                    else {
                        const { handler, success, failure } = getStepFormHandler(activeStep.key)
                        if (!handler) {
                            yield call(helpers.setSubmitting, false)
                            return
                        }

                        const updateMask = forms.createUpdateMaskFromValues(values, initialValues)
                        yield put(handler({ ...values, updateMask }))

                        const { failureResult } = yield race({
                            successResult : take(success),
                            failureResult : take(failure)
                        })

                        yield call(helpers.setSubmitting, false)

                        if (failureResult) {
                            const errors = api.getFieldErrorsFromAction(failureResult)
                            const stepErrors = pickErrorForStep(activeStep, errors)
                            yield put(fail(key, stepErrors))
                            yield call(helpers.setErrors, stepErrors)
                            yield fork(ui.notifyAboutAction, failureResult)
                        }
                        else {
                            yield put(update(key, values))
                            yield put(advance(key))
                        }
                    }
                    break
                }

                case StepKey.configConnector: {
                    yield put(advance(key))
                    yield call(helpers.setSubmitting, false)
                    yield put(organizations.ensure())
                    break
                }
            }

            break
        }

        case forms.Name.createSiteWizard: {
            const key = Key.site

            yield put(sites.create(values))

            const { successResult, failureResult } = yield race({
                successResult : take(sites.CREATE_SUCCEEDED),
                failureResult : take(sites.CREATE_FAILED)
            })

            yield call(helpers.setSubmitting, false)

            if (failureResult) {
                const activeStep: Step = yield select(getActiveStep(key))
                const errors = api.getFieldErrorsFromAction(failureResult)
                const stepErrors = pickErrorForStep(activeStep, errors)

                if (isEmpty(errors)) {
                    yield fork(ui.notifyAboutAction, failureResult)
                }
                else if (isEmpty(stepErrors)) {
                    yield put(update(key, values))
                    yield put(advance(key))
                }
                else {
                    yield put(fail(key, stepErrors))
                    yield call(helpers.setErrors, stepErrors)
                    yield fork(ui.notifyAboutAction, failureResult)
                }
            }
            else {
                yield put(discard(key))
                yield fork(ui.notifyAboutAction, successResult)
            }
            break
        }

        default:
            break
    }
}

function* redirectToNextStep(action: ActionType<typeof advance>) {
    const { key } = action.payload
    const activeStep: number = yield select(getActiveStepNumber(key))
    const totalSteps: Step[] = yield select(getSteps(key))
    const nextStep = activeStep + 1

    if (nextStep <= size(totalSteps)) {
        yield put(routing.push({ step: toString(nextStep) }))
    }
}

function buildWizard(steps: StepPayload[]): Wizard {
    return {
        values      : {},
        errors      : {},
        currentStep : 1,
        isCompleted : false,
        steps       : map(steps, buildStep)
    }
}

function buildStep(payload: StepPayload, number: number): Step {
    return {
        key         : payload.key,
        number      : number + 1,
        isSkippable : get(payload, 'isSkippable', false),
        fields      : get(payload, 'fields', [])
    }
}

export function pickErrorForStep(step: Step, errors: forms.Errors): forms.Errors {
    return pickBy(errors, (error, errorFieldName) => (
        some(map(step.fields, (field) => startsWith(errorFieldName, field)))
    )) as Record<string, string[]>
}

export function pickValuesForStep(step: Step, values: forms.Values): forms.Values {
    return pick(values, step.fields)
}

export function hasStepValues(step: Step, formValues: forms.Values): boolean {
    const values = pickValuesForStep(step, formValues)
    return some(map(step.fields, (field) => {
        const value = get(values, field)
        return isBoolean(value) || !isEmpty(value) || (isNumber(value) && !isNaN(value))
    }))
}

export function stepDisplayName(key: StepKey): string {
    switch (key) {
        case StepKey.letsEncrypt:
            return "Let's Encrypt"
        default:
            return startCase(snakeCase(key))
    }
}

function getStepFormHandler(key: StepKey) {
    switch (key) {
        case StepKey.authentication: {
            return {
                handler : system.updateAuthConfiguration,
                success : system.authConfiguration.UPDATE_SUCCEEDED,
                failure : system.authConfiguration.UPDATE_FAILED
            }
        }

        case StepKey.letsEncrypt: {
            return {
                handler : system.updateLetsEncryptConfiguration,
                success : system.letsEncryptConfiguration.UPDATE_SUCCEEDED,
                failure : system.letsEncryptConfiguration.UPDATE_FAILED
            }
        }

        case StepKey.adminUser: {
            return {
                handler : system.updateAdminUsers,
                success : system.adminUser.UPDATE_SUCCEEDED,
                failure : system.adminUser.UPDATE_FAILED
            }
        }

        default:
            return {}
    }
}


//
//  SELECTORS

export const getState = (state: RootState): State => state.wizard
export const getWizard: SelectorCreator<Key, Wizard> = (key: Key) => createSelector(
    getState,
    (state) => get(state, key)
)
export const getSteps: SelectorCreator<Key, Step[]> = (key: Key) => createSelector(
    getWizard(key),
    (wizard: Wizard) => wizard.steps
)
export const getValues: SelectorCreator<Key, forms.Values> = (key: Key) => createSelector(
    getWizard(key),
    (wizard: Wizard) => wizard.values
)
export const getErrors: SelectorCreator<Key, forms.Errors> = (key: Key) => createSelector(
    getWizard(key),
    (wizard: Wizard) => wizard.errors
)
export const isCompleted: SelectorCreator<Key, boolean> = (key: Key) => createSelector(
    getWizard(key),
    (wizard: Wizard) => wizard.isCompleted
)
export const getCurrentStepNumber: SelectorCreator<Key, number> = (key: Key) => createSelector(
    getWizard(key),
    (wizard: Wizard) => wizard.currentStep
)
export const getActiveStepNumber: SelectorCreator<Key, number> = (key: Key) => createSelector(
    [routing.getParams, getCurrentStepNumber(key)],
    (params, currentStep) => {
        const stepFromRoute = parseInt(toString(get(params, 'step', currentStep || '1')))
        return min([stepFromRoute, currentStep]) as number
    }
)
export const getStep = (key: Key, number: number): Selector<Step> => createSelector(
    getSteps(key),
    (steps) => {
        if (number < 1) {
            return head(steps) as Step
        }

        if (number > size(steps)) {
            return last(steps) as Step
        }

        return find(steps, { number }) || head(steps) as Step
    }
)
export const getCurrentStep: SelectorCreator<Key, Step> = (key: Key) => createSelector(
    [getSteps(key), getCurrentStepNumber(key)],
    (steps, number) => (find(steps, { number }) || head(steps)) as Step
)
export const getActiveStep: SelectorCreator<Key, Step> = (key: Key) => createSelector(
    [getSteps(key), getActiveStepNumber(key)],
    (steps, number) => (find(steps, { number }) || head(steps)) as Step
)
export const isStepSkippable: SelectorCreator<Key, boolean> = (key: Key) => createSelector(
    [getCurrentStep(key), getActiveStep(key)],
    (currentStep, activeStep) => (
        activeStep.isSkippable
        || activeStep.number < currentStep.number
        || isEmpty(activeStep.fields)
    )
)
export const isFirstStep: SelectorCreator<Key, boolean> = (key: Key) => createSelector(
    getActiveStepNumber(key),
    (step) => step === 1
)
export const isLastStep: SelectorCreator<Key, boolean> = (key: Key) => createSelector(
    [getSteps(key), getActiveStepNumber(key)],
    (steps, activeStep) => activeStep === size(steps)
)
