import * as React from 'react'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'

import { omit } from 'lodash'

import Button, { Props as ButtonProps } from '../components/Button'

import { RootState, api, organizations, routing, ui } from '../redux'
import { useTranslation } from '../hooks'
import { Maybe } from '../utils'

type ReduxProps = {
    organization: Maybe<organizations.OrganizationName>
}

type Props = ButtonProps & ReduxProps

const CreateProjectButton: React.FC<Props> = (props) => {
    const { organization, ...otherProps } = props
    const [t] = useTranslation()

    if (!organization) {
        return null
    }

    return (
        <Button
            text={ t(ui.createTitle(api.Resource.project, api.Request.create)) }
            icon="add"
            intent={ Intent.SUCCESS }
            href={
                routing.routeFor(routing.Key.projects, {
                    organization : organizations.parseName(organization).slug,
                    slug         : '_',
                    action       : 'new'
                })
            }
            { ...omit(otherProps, 'dispatch') }
        />
    )
}

function mapStateToProps(state: RootState) {
    return {
        organization: organizations.getCurrentName(state)
    }
}

export default connect(mapStateToProps)(CreateProjectButton)
