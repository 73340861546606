import * as React from 'react'

import { system, api, ui } from '../redux'
import { Maybe } from '../utils'

import Avatar from '../components/Avatar'
import ResourceTitle, { Props as BaseProps } from '../components/ResourceTitle'

type Props = Omit<BaseProps, 'resource'> & {
    entry: Maybe<system.IAdminUser>
}

const AccountBindingTitle: React.FC<Props> = (props) => {
    const { entry } = props

    if (!entry) {
        return null
    }

    return (
        <ResourceTitle
            resource={ api.Resource.accountBinding }
            avatar={ <Avatar name={ entry.email } imageURL={ ui.avatarURL(entry) } isRound /> }
            title={ entry.email }
            { ...props }
        />
    )
}

export default AccountBindingTitle
