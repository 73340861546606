import * as React from 'react'
import { Tooltip, Position, Intent } from '@blueprintjs/core'
import BaseCopyToClipboard from 'react-copy-to-clipboard'

import { useState, useTranslation } from '../hooks'

import Button from '../components/Button'

type Props = {
    text: string
}

const CopyToClipboardButton: React.FC<Props> = ({ text }) => {
    const [isCopied, setCopied] = useState(false)
    const [t] = useTranslation()

    const handleCopying = () => {
        setCopied(true)
        return setTimeout(() => setCopied(false), 1000)
    }

    return (
        <Tooltip
            content={ t('Copied') }
            isOpen={ isCopied }
            position={ Position.RIGHT }
        >
            <BaseCopyToClipboard
                text={ text }
                onCopy={ handleCopying }
            >
                <Button
                    icon="clipboard-add"
                    intent={ isCopied ? Intent.SUCCESS : Intent.PRIMARY }
                    minimal
                    isSquare
                />
            </BaseCopyToClipboard>
        </Tooltip>
    )
}

export default CopyToClipboardButton
