import * as React from 'react'

import { Navbar as BlueprintNavBar } from '@blueprintjs/core'
import { Flex, Box } from 'reflexbox'

import Breadcrumbs from '../components/Breadcrumbs'
import UserNav from '../components/UserNav'
import SearchBox from '../components/SearchBox'
import LanguageMenu from '../components/LanguageMenu'

import styles from './NavBar.module.scss'

const NavBar: React.FC = () => (
    <BlueprintNavBar className={ styles.container }>
        <Flex justify="space-between" align="center" w="100%">
            <Box w={ 2 / 3 }>
                <Breadcrumbs />
            </Box>
            <Flex w={ 1 / 3 } justify="flex-end">
                <Box mr={ 2 }>
                    <SearchBox />
                </Box>
                <Box mr={ 1 }>
                    <LanguageMenu />
                </Box>
                <Box>
                    <UserNav />
                </Box>
            </Flex>
        </Flex>
    </BlueprintNavBar>
)

export default NavBar
