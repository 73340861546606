import * as React from 'react'
import { connect } from 'react-redux'
import { InputGroup, Popover, Position, Keys } from '@blueprintjs/core'
import { Classes } from '@blueprintjs/select'

import { head, flatten, values, size, indexOf, isEmpty } from 'lodash'

import SearchResultsList from '../components/SearchResultsList'

import { RootState, DispatchProp, search, routing } from '../redux'
import { useTranslation, useState, useEffect } from '../hooks'
import { Maybe } from '../utils'

import styles from './SearchBox.module.scss'

type ReduxProps = {
    query: search.Query | null
    results: search.ResultsList
    isSearching: boolean
    isPerformed: boolean
}

type Props = ReduxProps & DispatchProp

const SearchBox: React.FC<Props> = (props) => {
    const { results, query, isPerformed, isSearching, dispatch } = props
    const items = flatten(values(results))

    const [t] = useTranslation()
    const [isOpen, setOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState<Maybe<search.Result>>(null)

    useEffect(() => {
        const items = flatten(values(results))
        if (isEmpty(items)) {
            setSelectedItem(null)
            return
        }

        const firstItem = head(items)
        if (firstItem) {
            setSelectedItem(firstItem)
        }
    }, [results])

    const onSelect = (item: search.Result) => {
        dispatch(routing.push(routing.routeForResource(item)))
        setOpen(false)
    }

    const updateSelection = (
        items: search.Result[],
        selectedItem: Maybe<search.Result>,
        direction: 'next' | 'prev'
    ) => {
        if (!selectedItem) {
            setSelectedItem(head(items))
            return
        }

        const currentIndex = indexOf(items, selectedItem)
        const [increment, treshold, fallback] = direction === 'next'
            ? [1, size(items) - 1, 0]
            : [-1, 0, size(items) - 1]

        const indexToSelect = currentIndex === treshold ? fallback : currentIndex + increment
        const itemToSelect = items[indexToSelect]

        setSelectedItem(itemToSelect)
    }

    const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
        switch (e.keyCode) {
            case Keys.ENTER: {
                if (selectedItem) {
                    setOpen(false)
                    onSelect(selectedItem)
                }
                break
            }

            case Keys.ARROW_UP: {
                e.preventDefault()
                setOpen(true)
                updateSelection(items, selectedItem, 'prev')
                break
            }

            case Keys.ARROW_DOWN: {
                e.preventDefault()
                setOpen(true)
                updateSelection(items, selectedItem, 'next')
                break
            }

            case Keys.ESCAPE: {
                setOpen(false)
                break
            }

            default: {
                setOpen(true)
                break
            }
        }
    }

    return (
        <Popover
            autoFocus={ false }
            enforceFocus={ false }
            position={ Position.BOTTOM_LEFT }
            popoverClassName={ Classes.SELECT_POPOVER }
            isOpen={ isOpen }
            minimal
        >
            <InputGroup
                className={ styles.inputContainer }
                leftIcon="search"
                placeholder={ t('Search...') }
                onChange={ (e: React.FormEvent<HTMLInputElement>) => (
                    dispatch(search.request({ query: e.currentTarget.value }))
                ) }
                onFocus={ () => setOpen(true) }
                onBlur={ () => setTimeout(() => setOpen(false), 200) }
                onKeyDown={ handleKeyPress }
                value={ query || '' }
            />
            <SearchResultsList
                query={ query }
                results={ results }
                selectedItem={ selectedItem }
                isPerformed={ isPerformed }
                isSearching={ isSearching }
                onSelect={ onSelect }
            />
        </Popover>
    )
}

function mapStateToProps(state: RootState): ReduxProps {
    return {
        query       : search.getQuery(state),
        results     : search.getResults(state),
        isSearching : search.isSearching(state),
        isPerformed : search.isPerformed(state)
    }
}

export default connect(mapStateToProps)(SearchBox)
