import * as React from 'react'

import { map, values, startCase } from 'lodash'

import { useTranslation } from '../hooks'
import { affinities } from '../redux'

import Button from '../components/Button'
import ButtonGroup from '../components/ButtonGroup'

type Props = {
    onChange: (item: affinities.SchedulingPreference) => void
    selectedItem?: affinities.SchedulingPreference
    isDisabled?: boolean
}

const SchedulingPreferenceButtonGroupSelect: React.FC<Props> = (props) => {
    const { selectedItem, onChange, isDisabled } = props
    const [t] = useTranslation()

    return (
        <ButtonGroup>
            { map(values(affinities.SchedulingPreference), (item: affinities.SchedulingPreference) => (
                <Button
                    key={ item }
                    onClick={ () => onChange(item) }
                    active={ selectedItem === item }
                    text={ t(startCase(item)) }
                    disabled={ isDisabled }
                    isBordered
                />
            )) }
        </ButtonGroup>
    )
}

export default SchedulingPreferenceButtonGroupSelect
