/* eslint-disable no-use-before-define */

import { ActionType, action as createAction } from 'typesafe-actions'
import { SagaIterator } from 'redux-saga'
import { takeLatest, takeEvery, fork, put, take, call, race, select as _select, delay } from 'redux-saga/effects'
import { createSelector } from 'reselect'

import { find, head, keys, values as _values, get as _get, isEmpty, isFunction } from 'lodash'

import { bitpoke } from '@bitpoke/bitpoke-proto'

import { Selector, auth, api, grpc, routing, forms, ui } from '../redux'
import { Maybe } from '../utils'

const {
    Organization,
    OrganizationsService,
    ListOrganizationsRequest,
    ListOrganizationsResponse,
    GetOrganizationRequest,
    CreateOrganizationRequest,
    UpdateOrganizationRequest,
    DeleteOrganizationRequest
} = bitpoke.organizations.v1

export {
    Organization,
    OrganizationsService,
    ListOrganizationsRequest,
    ListOrganizationsResponse,
    GetOrganizationRequest,
    CreateOrganizationRequest,
    UpdateOrganizationRequest,
    DeleteOrganizationRequest
}


//
//  TYPES

export type OrganizationName = string
export interface IOrganization extends bitpoke.organizations.v1.IOrganization {
    name: OrganizationName
}
export type Organization               = bitpoke.organizations.v1.Organization
export type IOrganizationPayload       = bitpoke.organizations.v1.IOrganization
export type IListOrganizationsRequest  = bitpoke.organizations.v1.IListOrganizationsRequest
export type IGetOrganizationRequest    = bitpoke.organizations.v1.IGetOrganizationRequest
export type ICreateOrganizationRequest = bitpoke.organizations.v1.ICreateOrganizationRequest
export type IUpdateOrganizationRequest = bitpoke.organizations.v1.IUpdateOrganizationRequest
export type IDeleteOrganizationRequest = bitpoke.organizations.v1.IDeleteOrganizationRequest
export type IListOrganizationsResponse = bitpoke.organizations.v1.IListOrganizationsResponse

export type State = {
    current: string | null
} & api.State<IOrganization>

export type Actions = ActionType<typeof actions>

export enum FieldName {
    displayName = 'Name',
    iconUrl = 'Icon'
}

const service = OrganizationsService.create(
    grpc.createTransport('bitpoke.organizations.v1.OrganizationsService')
)

export const { parseName, buildName } = api.createNameHelpers(api.Resource.organization)


//
//  ACTIONS

export const SELECTED = '@ organizations / SELECTED'
export const ENSURED  = '@ organizations / ENSURED'

export const select = (payload: IOrganization) => createAction(SELECTED, payload)
export const ensure = () => createAction(ENSURED)

export const get = (payload: IGetOrganizationRequest) => grpc.invoke({
    service,
    method       : 'getOrganization',
    data         : GetOrganizationRequest.create(payload),
    responseType : Organization
})

export const list = (payload?: IListOrganizationsRequest) => grpc.invoke({
    service,
    method       : 'listOrganizations',
    data         : ListOrganizationsRequest.create(payload),
    responseType : ListOrganizationsResponse
})

export const create = (payload: ICreateOrganizationRequest) => grpc.invoke({
    service,
    method       : 'createOrganization',
    data         : CreateOrganizationRequest.create(payload),
    responseType : Organization
})

export const update = (payload: IUpdateOrganizationRequest) => grpc.invoke({
    service,
    method       : 'updateOrganization',
    data         : UpdateOrganizationRequest.create(payload),
    responseType : Organization
})

export const destroy = (payload: IOrganizationPayload) => grpc.invoke({
    service,
    method : 'deleteOrganization',
    data   : DeleteOrganizationRequest.create(payload)
})

const actions = {
    get,
    list,
    create,
    update,
    destroy,
    select,
    ensure
}

const apiTypes = api.createActionTypes(api.Resource.organization)

export const {
    LIST_REQUESTED,    LIST_SUCCEEDED,    LIST_FAILED,
    GET_REQUESTED,     GET_SUCCEEDED,     GET_FAILED,
    CREATE_REQUESTED,  CREATE_SUCCEEDED,  CREATE_FAILED,
    UPDATE_REQUESTED,  UPDATE_SUCCEEDED,  UPDATE_FAILED,
    DESTROY_REQUESTED, DESTROY_SUCCEEDED, DESTROY_FAILED
} = apiTypes


//
//  REDUCER

const apiReducer = api.createReducer(api.Resource.organization, apiTypes)

const initialState: State = {
    ...api.initialState,
    current: null
}

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case SELECTED: {
            return {
                ...state,
                current: action.payload.name
            }
        }

        default:
            return apiReducer(state, action)
    }
}


//
//  SAGA

export function* saga() {
    yield fork(api.emitResourceActions, api.Resource.organization, apiTypes)
    yield fork(forms.takeEverySubmission, keys(FORM_HANDLERS) as forms.Name[], handleFormSubmission)

    yield takeLatest(CREATE_SUCCEEDED, switchToOrganization)

    yield takeLatest([
        auth.ACCESS_GRANTED,
        DESTROY_SUCCEEDED,
        ENSURED
    ], () => decideOrganizationContext(0))

    yield takeEvery([
        CREATE_SUCCEEDED,
        DESTROY_SUCCEEDED
    ], routing.redirectToDashboard)

    yield takeEvery([
        CREATE_SUCCEEDED, CREATE_FAILED,
        UPDATE_SUCCEEDED, UPDATE_FAILED,
        DESTROY_SUCCEEDED, DESTROY_FAILED
    ], ui.notifyAboutAction)

    yield takeEvery(routing.ROUTE_CHANGED, updateRouteIfNeeded)
}

const FORM_HANDLERS: forms.HandlerMapping = {
    [forms.Name.createOrganization]         : create,
    [forms.Name.updateOrganizationSettings] : update
}

const handleFormSubmission = forms.createHandlers(FORM_HANDLERS, apiTypes)

function* decideOrganizationContext(retryCount: number = 0): SagaIterator {
    yield delay(50)

    const isAuthenticated = yield _select(auth.isAuthenticated)

    if (!isAuthenticated) {
        return
    }

    const organizations = yield _select(getAll)
    const currentlySelected = yield _select(getCurrent)
    const fromURL = yield _select(getCurrentFromURL)

    if (isEmpty(organizations)) {
        yield put(list())
        const { success, failure } = yield race({
            success : take(LIST_SUCCEEDED),
            failure : take(LIST_FAILED),
            timeout : delay(15 * 1000)
        })

        if (success && !api.isEmptyResponse(success)) {
            yield call(decideOrganizationContext, 0)
        }
        else if (failure && grpc.isNetworkError(failure)) {
            if (retryCount < 3) {
                yield call(decideOrganizationContext, retryCount + 1)
            }
        }
    }

    if (fromURL && fromURL !== currentlySelected) {
        yield put(select(fromURL))
    }
    else if (!currentlySelected) {
        const firstOrganizationAsDefault = head(_values(organizations))
        if (firstOrganizationAsDefault) {
            yield put(select(firstOrganizationAsDefault))
            yield put(list())
        }
    }
}

function* switchToOrganization(action: ActionType<typeof create | typeof update>) {
    const entry = action.payload.data as IOrganization
    yield put(select(entry))
}

export function isOrganization(entry: any): entry is IOrganization {
    return api.isOfType(api.Resource.organization, entry)
}

export function* ensureOrganizationSelected(onComplete?: () => any): SagaIterator {
    const currentOrganization = yield _select(getCurrent)

    if (!currentOrganization) {
        yield call(decideOrganizationContext, 0)
        yield take(SELECTED)
        yield call(ensureOrganizationSelected, onComplete)
        return
    }

    if (isFunction(onComplete)) {
        yield call(onComplete)
    }
}

function* updateRouteIfNeeded(action: ActionType<typeof routing.updateRoute>) {
    const currentOrganization: Maybe<IOrganization> = yield _select(getCurrent)
    const currentRoute: routing.Route = yield _select(routing.getCurrentRoute)

    if (!currentOrganization) {
        return
    }

    const parsedRoute = parseName(currentRoute.url)
    if (currentRoute.key === routing.Key.dashboard && !parsedRoute.name) {
        yield put(routing.push(routing.routeForResource(currentOrganization)))
    }
}

//
//  SELECTORS

const selectors: api.Selectors<IOrganization> = api.createSelectors(api.Resource.organization)
export const { getState, getAll, countAll, getByName, getForURL, getForCurrentURL } = selectors

export const getCurrentName: Selector<Maybe<OrganizationName>> = createSelector(
    getState,
    (state) => _get(state, 'current', null)
)
export const getCurrent: Selector<Maybe<IOrganization>> = createSelector(
    [getCurrentName, getAll],
    (current, orgs) => current ? find(orgs, { name: current }) || null : null
)
export const getCurrentFromURL: Selector<Maybe<IOrganization>> = createSelector(
    [routing.getCurrentRoute, getAll],
    (currentRoute, orgs) => {
        const name = parseName(currentRoute.url).name
        if (!name) {
            return null
        }
        return find(orgs, { name }) || null
    }
)
