import * as React from 'react'
import { Field, FieldArray } from 'formik'

import { forms, system, nodes, routing } from '../redux'
import { useTranslation, useDispatch, useEffect } from '../hooks'
import { Maybe } from '../utils'

import { withForm } from '../components/Form'
import TitleBar from '../components/TitleBar'
import Container from '../components/Container'
import FormContainer from '../components/FormContainer'
import NodeAffinityField from '../components/NodeAffinityField'
import SchedulableComponentSelectField from '../components/SchedulableComponentSelectField'
import NodeTolerationsFieldList from '../components/NodeTolerationsFieldList'

type Props = forms.FormProps<{ componentScheduling: system.IComponentScheduling }>

const SystemComponentSchedulingForm: React.FC<Props> = (props) => {
    const [t] = useTranslation()
    const dispatch = useDispatch()
    const { values } = props

    useEffect(() => {
        dispatch(nodes.list())
    }, [dispatch])

    const isComponentSelected = !!values?.componentScheduling?.component

    return (
        <Container>
            <FormContainer
                header={ (
                    <TitleBar
                        title={ t('Scheduling Rules') }
                        subtitle={ t('Rules describing how system components should be scheduled inside the cluster') }
                    />
                ) }
                { ...props }
            >
                <Field
                    name="componentScheduling.component"
                    component={ SchedulableComponentSelectField }
                    label={ t('Component') }
                    onChange={ (value: Maybe<system.IComponent>) => {
                        if (value) {
                            dispatch(routing.push({ component: system.componentId(value) }))
                        }
                    } }
                />
                { isComponentSelected && (
                    <>
                        <Field
                            name="componentScheduling.affinity.nodeAffinity"
                            component={ NodeAffinityField }
                            disabled={ !isComponentSelected }
                        />
                        <FieldArray
                            name="componentScheduling.tolerations"
                            component={ NodeTolerationsFieldList }
                        />
                    </>
                ) }
            </FormContainer>
        </Container>
    )
}

export default withForm({ name: forms.Name.updateSystemComponentScheduling })(SystemComponentSchedulingForm)
