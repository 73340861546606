import * as React from 'react'
import { Tooltip, Intent } from '@blueprintjs/core'
import { Flex, Box } from 'reflexbox'

import { get, toUpper, isEmpty } from 'lodash'

import { pods } from '../redux'
import { useTranslation } from '../hooks'
import { keyOfEnum, formatDistance } from '../utils'

import Bullet from '../components/Bullet'
import TitleBar from '../components/TitleBar'
import StatusTag from '../components/StatusTag'

import styles from '../components/ContainerStatusTag.module.scss'

type OwnProps = {
    entry?: pods.IContainerStatus | null
    onClick?: () => void
    large?: boolean
    withIcon?: boolean
    minimal?: boolean
}

type Props = OwnProps

const STATUS_INTENT: Record<pods.ContainerState, Intent> = {
    [pods.ContainerState.UNSPECIFIED] : Intent.NONE,
    [pods.ContainerState.RUNNING]     : Intent.SUCCESS,
    [pods.ContainerState.PROBING]     : Intent.PRIMARY,
    [pods.ContainerState.STARTING]    : Intent.PRIMARY,
    [pods.ContainerState.FAILED]      : Intent.DANGER,
    [pods.ContainerState.TERMINATING] : Intent.NONE,
    [pods.ContainerState.TERMINATED]  : Intent.NONE
}

const ContainerStatusTag: React.FC<Props> = (props) => {
    const { entry, ...otherProps } = props
    const [t] = useTranslation()

    const status = get(entry, 'containerState')

    if (!entry || !status) {
        return null
    }

    const statusName = t(toUpper(keyOfEnum(pods.ContainerState, status) as string))
    const statusIntent = get(STATUS_INTENT, status || '', Intent.NONE)

    return (
        <Tooltip
            content={ (
                <TitleBar
                    icon={ <Bullet intent={ statusIntent } /> }
                    title={ entry.name }
                    subtitle={ (
                        <Flex column>
                            { entry.startedAt ? (
                                <Box>
                                    { t('Started {{startedAt}}', {
                                        startedAt: formatDistance(entry.startedAt)
                                    }) }
                                </Box>
                            ) : null }
                            { entry.restartCount ? (
                                <Box>
                                    { t('{{restartCount}} restarts', {
                                        restartCount: entry.restartCount
                                    }) }
                                </Box>
                            ) : null }
                            { !isEmpty(entry.reason) ? <Box><span>{ entry.reason }</span></Box> : null }
                            { !isEmpty(entry.message) ? <Box><span>{ entry.message }</span></Box> : null }
                        </Flex>
                    ) }
                    className={ styles.tooltip }
                    isCompact
                />
            ) }
        >
            <StatusTag
                name={ statusName }
                intent={ statusIntent }
                { ...otherProps }
            />
        </Tooltip>
    )
}

export default ContainerStatusTag
