import * as React from 'react'

import { routing } from '../redux'

type Props = {
    to?: string
    onClick?: () => void
    className?: string
}

const Link: React.FC<Props> = ({ children, to, onClick, className }) => (
    <a href={ to ? routing.toFragment(to) : undefined } onClick={ onClick } className={ className }>
        { children }
    </a>
)

export default Link
