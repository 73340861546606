import * as React from 'react'
import { connect } from 'react-redux'
import { Tab, Tabs } from '@blueprintjs/core'

import { map, kebabCase, isEmpty } from 'lodash'

import { RootState, api, routing, ui, navigation } from '../redux'
import { useDispatch, useTranslation } from '../hooks'
import { Maybe } from '../utils'

type OwnProps = {
    resource: api.Resource
}

type ReduxProps = {
    tabs: navigation.TabEntry[]
    activeTab: Maybe<navigation.TabEntry>
}

type Props = OwnProps & ReduxProps

const TabBar: React.FC<Props> = (props) => {
    const { resource, tabs, activeTab } = props
    const dispatch = useDispatch()
    const [t] = useTranslation()

    if (isEmpty(tabs)) {
        return null
    }

    return (
        <Tabs
            id="tabs"
            onChange={ (tab: string) => dispatch(routing.push({ tab: kebabCase(tab) })) }
            selectedTabId={ activeTab || undefined }
        >
            { map(tabs, (tab: navigation.TabEntry) => (
                <Tab key={ tab } id={ tab } title={ t(ui.createTitle(resource, tab)) } />
            )) }
        </Tabs>
    )
}

function mapStateToProps(state: RootState): ReduxProps {
    const tabs = navigation.getTabsForCurrentURL(state)
    const activeTab = navigation.getActiveTab(state)

    return {
        tabs,
        activeTab
    }
}

export default connect(mapStateToProps)(TabBar)
