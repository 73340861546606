import * as React from 'react'
import { connect } from 'react-redux'

import { RootState, auth, system, wizard } from '../redux'
import { useEffect, useDispatch } from '../hooks'

import MainContainer from '../components/MainContainer'
import InstallWizardForm from '../components/InstallWizardForm'

type ReduxProps = {
    isAdmin: boolean
    isAuthenticated: boolean
    isConfigured: boolean
    isConfigConectorConfigured: boolean
    isWizardCompleted: boolean
    config: system.IConfiguration
    wizardValues: wizard.Values
}

type Props = ReduxProps

const ConfigurationGate: React.FC<Props> = (props) => {
    const {
        isConfigured, isAuthenticated, isAdmin, isConfigConectorConfigured, isWizardCompleted,
        wizardValues, config, children
    } = props
    const dispatch = useDispatch()
    useEffect(() => {
        if (isAuthenticated) {
            dispatch(system.fetchAdminUsers())
            if (!isConfigured) {
                dispatch(system.fetchStatus())
                dispatch(system.fetchAuthConfiguration())
                dispatch(system.fetchLetsEncryptConfiguration())
                dispatch(system.fetchConfigConnectorConfiguration())
            }
        }
    }, [isAuthenticated, isConfigured, dispatch])

    if (!isConfigured && isAdmin && !isWizardCompleted) {
        return (
            <MainContainer>
                <InstallWizardForm
                    initialValues={ {
                        ...wizardValues,
                        configuration: {
                            ...wizardValues.configuration,
                            ...config.auth,
                            ...config.letsEncrypt
                        },
                        configConnector: {
                            isConfigured: isConfigConectorConfigured
                        },
                        adminUsers: config.adminUsers
                    } }
                />
            </MainContainer>
        )
    }

    return <>{ children }</>
}

function mapStateToProps(state: RootState): ReduxProps {
    return {
        isConfigured               : system.isConfigured(state),
        isAuthenticated            : auth.isAuthenticated(state),
        isAdmin                    : auth.isAdmin(state),
        config                     : system.getConfiguration(state),
        isConfigConectorConfigured : system.isConfigConnectorConfigured(state),
        isWizardCompleted          : wizard.isCompleted(wizard.Key.install)(state),
        wizardValues               : wizard.getValues(wizard.Key.install)(state)
    }
}

export default connect(mapStateToProps)(ConfigurationGate)
