import * as React from 'react'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'

import { find, sortBy, reverse, size, toString, isEmpty } from 'lodash'

import { RootState, api, grpc, routing, organizations, accountBindings, navigation } from '../redux'
import { useTranslation, useDispatch, useEffect } from '../hooks'
import { Maybe, arePropsEqual, formatDistance } from '../utils'

import Card from '../components/Card'
import Icon from '../components/Icon'
import BlankSlate from '../components/BlankSlate'
import AvatarsList from '../components/AvatarsList'
import Button from '../components/Button'

type ReduxProps = {
    items: api.ResourcesList<accountBindings.IAccountBinding>
    isLoading: boolean
    isFailed: boolean
}

type OwnProps = {
    entry: Maybe<organizations.IOrganization>
}

type Props = OwnProps & ReduxProps

const OrganizationMembersCard: React.FC<Props> = React.memo((props) => {
    const { entry, isLoading, isFailed } = props
    const [t] = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(accountBindings.list({ organization: entry?.name }))
    }, [dispatch, entry])

    if (!entry) {
        return null
    }

    const items = reverse(sortBy(props.items, 'createdAt.seconds'))
    const recentItem = find(items, accountBindings.isAccountBinding)
    const link = routing.routeForResource(entry, { action: navigation.MenuEntry.access })

    return (
        <Card
            title={ t('Members') }
            tag={ toString(size(items)) }
            link={ link }
            rightItem={ (
                <Button
                    icon="add"
                    intent={ Intent.SUCCESS }
                    href={ link }
                    isRound
                />
            ) }
            isBorderedBottom
        >
            { recentItem && recentItem.createdAt && (
                <p>
                    <strong>{ t('Last member joined:') }</strong><br />
                    <span>{ formatDistance(recentItem.createdAt) }</span>
                </p>
            ) }
            { !isLoading && !isFailed && isEmpty(items) && (
                <BlankSlate
                    title={ t('There are no members in your organization.') }
                />
            ) }
            { !isLoading && isFailed && isEmpty(items) && (
                <BlankSlate
                    icon={ <Icon name="delete-circle" size={ 32 } /> }
                    title={ t('Failed to load members.') }
                />
            ) }
            <AvatarsList
                items={ items }
                listRoute={ link }
                isRound
            />
        </Card>
    )
}, arePropsEqual(['entry.name', 'items', 'isFailed', 'isLoading']))

function mapStateToProps(state: RootState): ReduxProps {
    const organization = organizations.getCurrentName(state)
    const itemsRequest = accountBindings.list({ organization })
    return {
        items     : accountBindings.getForCurrentOrganization(state),
        isLoading : grpc.isLoadingRequest(itemsRequest.payload)(state),
        isFailed  : grpc.isFailedRequest(itemsRequest.payload)(state)
    }
}

export default connect(mapStateToProps)(OrganizationMembersCard)
