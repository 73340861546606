import * as React from 'react'
import { Field, FieldArray } from 'formik'

import { get } from 'lodash'

import { forms, memcacheds, nodes } from '../redux'
import { useTranslation, useDispatch, useEffect } from '../hooks'

import { withForm } from '../components/Form'
import TitleBar from '../components/TitleBar'
import FormContainer from '../components/FormContainer'
import NodeAffinityField from '../components/NodeAffinityField'
import NodeTolerationsFieldList from '../components/NodeTolerationsFieldList'

type Props = forms.FormProps<{ memcached: memcacheds.IMemcached }>

const MemcachedSchedulingForm: React.FC<Props> = (props) => {
    const { initialValues } = props
    const [t] = useTranslation()
    const dispatch = useDispatch()
    const entry = get(initialValues, 'memcached')

    useEffect(() => {
        dispatch(nodes.list())
    }, [dispatch, entry])

    return (
        <FormContainer
            header={ <TitleBar title={ t('Scheduling Rules') } /> }
            { ...props }
        >
            <Field
                name="memcached.affinity.nodeAffinity"
                component={ NodeAffinityField }
            />
            <TitleBar
                title={ t('Tolerations') }
                subtitle={ t('Tolerations') }
            />
            <FieldArray
                name="memcached.tolerations"
                component={ NodeTolerationsFieldList }
            />
        </FormContainer>
    )
}

export default withForm({ name: forms.Name.updateMemcached })(MemcachedSchedulingForm)
