import * as React from 'react'
import { connect } from 'react-redux'
import { Flex, Box } from 'reflexbox'
import { Tooltip, Intent } from '@blueprintjs/core'

import { isString } from 'lodash'

import { RootState, system } from '../redux'
import { useTranslation, useInterval, useDispatch } from '../hooks'
import { POLL_INTERVAL } from '../utils'

import Container from '../components/Container'
import Button from '../components/Button'
import TitleBar from '../components/TitleBar'
import StatusIcon from '../components/StatusIcon'
import { IconName } from '../components/Icon'


type ReduxProps = {
    isConfigured: boolean
    config: system.IConfigConnectorConfiguration
}

type Props = ReduxProps

const ConfigConnectorInstallCard: React.FC<Props> = (props) => {
    const { config, isConfigured } = props
    const dispatch = useDispatch()
    const [t] = useTranslation()

    useInterval(() => {
        dispatch(system.fetchStatus())
        dispatch(system.fetchConfigConnectorConfiguration())
    }, POLL_INTERVAL.fast)

    const [subtitle, intent, buttonIcon, buttonTitle] = isConfigured ? [
        t('Config Connector is installed successfully'),
        Intent.SUCCESS,
        'recycle-cw' as IconName,
        t('Reinstall')
    ] : [
        t('Config Connector is not installed yet'),
        Intent.WARNING,
        'drive-down' as IconName,
        t('Install')
    ]

    return (
        <Box mb={ 3 }>
            <Container isBordered>
                <Flex align="center" justify="space-between">
                    <TitleBar
                        title={ t('Config Connector') }
                        subtitle={ subtitle }
                        icon={ <StatusIcon size={ 40 } intent={ intent } /> }
                        isCompact
                        isSmall
                    />
                    <Tooltip content={ buttonTitle }>
                        <Button
                            href={ config.installLink ? config.installLink : undefined }
                            target="_blank"
                            intent={ Intent.PRIMARY }
                            icon={ buttonIcon }
                            disabled={ !isString(config.installLink) }
                            minimal
                        />
                    </Tooltip>
                </Flex>
            </Container>
        </Box>
    )
}

function mapStateToProps(state: RootState) {
    const isConfigured = system.isConfigConnectorConfigured(state)
    const config = system.getConfigConnectorConfiguration(state)
    return {
        isConfigured,
        config
    }
}

export default connect(mapStateToProps)(ConfigConnectorInstallCard)
