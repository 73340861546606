import * as React from 'react'
import { Tag, Tooltip, Intent, Position } from '@blueprintjs/core'

import { get, startCase, toLower, toUpper } from 'lodash'

import { api, mysqlClusterBackups } from '../redux'
import { useTranslation } from '../hooks'
import { arePropsEqual, keyOfEnum } from '../utils'

import Icon, { IconName } from '../components/Icon'
import Bullet from '../components/Bullet'

type OwnProps = {
    entry?: mysqlClusterBackups.IMySQLClusterBackup | null
    onClick?: () => void
    large?: boolean
    withIcon?: boolean
    minimal?: boolean
}

type Props = OwnProps

const STATUS_INTENT: Record<mysqlClusterBackups.MySQLClusterBackupStatus, Intent> = {
    [mysqlClusterBackups.MySQLClusterBackupStatus.UNSPECIFIED] : Intent.NONE,
    [mysqlClusterBackups.MySQLClusterBackupStatus.STARTED]     : Intent.PRIMARY,
    [mysqlClusterBackups.MySQLClusterBackupStatus.COMPLETED]   : Intent.SUCCESS,
    [mysqlClusterBackups.MySQLClusterBackupStatus.FAILED]      : Intent.DANGER
}

const STATUS_ICON: Record<mysqlClusterBackups.MySQLClusterBackupStatus, IconName> = {
    [mysqlClusterBackups.MySQLClusterBackupStatus.UNSPECIFIED] : 'circle',
    [mysqlClusterBackups.MySQLClusterBackupStatus.STARTED]     : 'cycle-cw',
    [mysqlClusterBackups.MySQLClusterBackupStatus.COMPLETED]   : 'ok-circle',
    [mysqlClusterBackups.MySQLClusterBackupStatus.FAILED]      : 'delete-circle'
}

const BackupStatusTag: React.FC<Props> = React.memo(({ entry, onClick, large, minimal, withIcon }) => {
    const [t] = useTranslation()

    if (!entry || api.isNewEntry(entry)) {
        return null
    }

    const { status } = entry
    const statusName = t(toUpper(keyOfEnum(mysqlClusterBackups.MySQLClusterBackupStatus, status) as string))
    const statusIntent = get(STATUS_INTENT, status || '', Intent.NONE)
    const statusIcon = get(STATUS_ICON, status || '', 'circle')

    if (minimal) {
        return (
            <Tooltip content={ startCase(toLower(statusName)) } position={ Position.RIGHT }>
                <Bullet
                    intent={ statusIntent }
                    onClick={ onClick }
                />
            </Tooltip>
        )
    }

    return (
        <Tag
            icon={ withIcon ? <Icon name={ statusIcon } /> : null }
            intent={ statusIntent }
            onClick={ onClick }
            interactive={ !!onClick }
            large={ large }
            minimal
        >
            { statusName }
        </Tag>
    )
}, arePropsEqual('entry'))

export default BackupStatusTag
