import * as React from 'react'

import { sites } from '../redux'
import { useTranslation } from '../hooks'

import Card from '../components/Card'
import SiteComponentsStatusTabs from '../components/SiteComponentsStatusTabs'

type Props = {
    entry: sites.ISite | null
}

const SiteStatusCard: React.FC<Props> = ({ entry }) => {
    const [t] = useTranslation()

    if (!entry) {
        return null
    }

    return (
        <Card
            title={ t("Components' Status") }
            subtitle={ t('Every site component has a status attached to it') }
            isBorderedBottom
        >
            <SiteComponentsStatusTabs entry={ entry } />
        </Card>
    )
}

export default SiteStatusCard
