import * as React from 'react'
import { Field } from 'formik'

import { Box } from 'reflexbox'

import { api, forms, invites, ui } from '../redux'
import { useTranslation } from '../hooks'

import { withForm } from '../components/Form'
import FormContainer from '../components/FormContainer'
import AdminUserEmailField from '../components/AdminUserEmailField'

type Props = forms.FormProps<invites.IInvitePayload>

const AdminUsersForm: React.FC<Props> = (props) => {
    const [t] = useTranslation()

    return (
        <FormContainer
            submitText={ t(ui.createTitle(api.Resource.adminUser, api.Request.create)) }
            isInline
            { ...props }
        >
            <Box w={ 0.45 }>
                <Field
                    name="adminUsers"
                    placeholder={ t(invites.FieldName.email) }
                    component={ AdminUserEmailField }
                    autoFocus
                    inline
                />
            </Box>
        </FormContainer>
    )
}

export default withForm({ name: forms.Name.createAdminUser })(AdminUsersForm)
