export default {
    green100 : '#E9F7EF',
    green200 : '#C0E7D0',
    green300 : '#97D8B2',
    green400 : '#6FC894',
    green500 : '#46B976',
    green600 : '#3C9F65',
    green700 : '#318153',
    green800 : '#276842',
    green900 : '#1C4A2F',

    gray50  : '#F6F6F7',
    gray100 : '#EEEFF1',
    gray200 : '#CFD3D8',
    gray300 : '#B0B6BF',
    gray400 : '#929AA5',
    gray500 : '#737D8C',
    gray600 : '#636C79',
    gray700 : '#505862',
    gray800 : '#40464F',
    gray900 : '#2E3238',

    blue100 : '#E2F0FD',
    blue200 : '#ADD4FB',
    blue300 : '#78B8F8',
    blue400 : '#429CF5',
    blue500 : '#0D80F2',
    blue600 : '#0B6ED0',
    blue700 : '#0959A9',
    blue800 : '#074787',
    blue900 : '#053361',

    red100 : '#FDE2E7',
    red300 : '#F8778D',
    red500 : '#F20D33',
    red800 : '#88071D',

    purple100 : '#EDE8F7',
    purple300 : '#AC94DB',
    purple500 : '#6A40BF',
    purple800 : '#3B246B',

    pink100 : '#F7E8F7',
    pink300 : '#DB94DB',
    pink500 : '#BF40BF',
    pink800 : '#6B246B',

    teal100 : '#E5F7FA',
    teal300 : '#86D9EA',
    teal500 : '#27BBD9',
    teal800 : '#156979',

    orange100 : '#FFF2E0',
    orange300 : '#FFC470',
    orange500 : '#FF9500',
    orange800 : '#B34A00',

    yellow100 : '#FFFAE0',
    yellow300 : '#FFE770',
    yellow500 : '#FFD500',
    yellow800 : '#E69900'
}
