import * as React from 'react'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import { Flex, Box } from 'reflexbox'

import { RootState, auth, organizations, projects, errors } from '../redux'
import { useDispatch, useTranslation } from '../hooks'
import { Maybe } from '../utils'

import Icon from '../components/Icon'
import Button from '../components/Button'
import BlankSlate from '../components/BlankSlate'

type ReduxProps = {
    currentOrganization: Maybe<organizations.IOrganization>
}
type OwnProps = {
    error: errors.Error
}
type Props = ReduxProps & OwnProps

const ErrorMessage: React.FC<Props> = (props) => {
    const { currentOrganization, error } = props
    const dispatch = useDispatch()
    const [t] = useTranslation()

    switch (error) {
        case errors.Error.network: {
            return (
                <BlankSlate
                    icon={ <Icon name="charging-off" size={ 64 } /> }
                    title={ t('You appear to be offline.') }
                    action={
                        <Button
                            intent={ Intent.PRIMARY }
                            text={ t('Retry') }
                            onClick={ () => {
                                if (!currentOrganization) {
                                    window.location.reload()
                                    return
                                }

                                dispatch(organizations.ensure())
                                dispatch(projects.list({ organization: currentOrganization.name }))
                            } }
                        />
                    }
                />
            )
        }

        case errors.Error.notFound: {
            return (
                <BlankSlate
                    icon={ <Icon name="badge-delete" size={ 64 } /> }
                    title={ t('The resource you requested does not exist.') }
                />
            )
        }

        case errors.Error.unauthenticated: {
            return (
                <BlankSlate
                    icon={ <Icon name="login" size={ 64 } /> }
                    title={ t('You are logged out from your account.') }
                    description={ t('To continue to the Dashboard, please log in.') }
                    action={
                        <Flex>
                            <Box mr={ 2 }>
                                <Button
                                    intent={ Intent.PRIMARY }
                                    text={ t('Login') }
                                    onClick={ () => {
                                        dispatch(auth.refreshToken())
                                    } }
                                />
                            </Box>
                        </Flex>
                    }
                />
            )
        }

        case errors.Error.unverifiedAccount: {
            return (
                <BlankSlate
                    icon={ <Icon name="mail" size={ 64 } /> }
                    title={ t('Please verify your email address.') }
                    action={
                        <Flex>
                            <Box mr={ 2 }>
                                <Button
                                    intent={ Intent.PRIMARY }
                                    text={ t('Reload') }
                                    onClick={ () => {
                                        dispatch(auth.refreshToken())
                                    } }
                                />
                            </Box>
                            <Box>
                                <Button
                                    intent={ Intent.NONE }
                                    text={ t('Logout') }
                                    onClick={ () => {
                                        dispatch(auth.logout())
                                    } }
                                />
                            </Box>
                        </Flex>
                    }
                />
            )
        }

        case errors.Error.siteProvisioning: {
            return (
                <BlankSlate
                    icon={ <Icon name="bricks" size={ 64 } /> }
                    title={ t('Your site is currently provisioning.') }
                    description={ t('It should be up and running in no time.') }
                />
            )
        }

        case errors.Error.invalidOIDCConfig: {
            return (
                <BlankSlate
                    icon={ <Icon name="settings-delete" size={ 64 } /> }
                    title={ t('Your OIDC configuration is not valid.') }
                    description={ t('Please refer to your Cloud Dashboard installation page.') }
                />
            )
        }

        default:
            return null
    }
}

function mapStateToProps(state: RootState): ReduxProps {
    return {
        currentOrganization: organizations.getCurrent(state)
    }
}

export default connect(mapStateToProps)(ErrorMessage)
