import * as React from 'react'
import { Field } from 'formik'

import { get, head, join } from 'lodash'

import { sites, forms } from '../redux'

import InputField from '../components/InputField'

type Props = forms.FieldProps<sites.IRoute[]>

const SitePrimaryDomainField: React.FC<Props> = (props) => {
    const { form, field } = props
    const { name } = field

    const value = get(head(field.value), 'domain', '')
    const error = get(form.errors, join([name, 0, 'domain'], '.'))

    return (
        <Field
            { ...props }
            component={ InputField }
            form={ {
                ...form,
                errors: {
                    ...form.errors,
                    [field.name]: error
                }
            } }
            field={ {
                ...field,
                value,
                onChange: (e: React.ChangeEvent<any>) => {
                    const domain = e.target.value
                    form.setFieldValue(name, [{ domain, pathPrefix: '/' }])
                }
            } }
        />
    )
}

export default SitePrimaryDomainField
