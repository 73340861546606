import { fork, takeEvery } from 'redux-saga/effects'
import { createSelector } from 'reselect'

import { find, pick, keys, startsWith } from 'lodash'

import { bitpoke } from '@bitpoke/bitpoke-proto'

import {
    RootState, Selector, SelectorCreator, AnyAction,
    api, grpc, forms, projects, ui, routing
} from '../redux'
import { Maybe } from '../utils'

const {
    Grafana,
    ResourceAllocation,
    GrafanaService,
    ListGrafanasRequest,
    ListGrafanasResponse,
    GetGrafanaRequest,
    UpdateGrafanaRequest,
    UpdateGrafanaResourcesRequest
} = bitpoke.grafana.v1

export {
    Grafana,
    ResourceAllocation,
    GrafanaService,
    ListGrafanasRequest,
    ListGrafanasResponse,
    GetGrafanaRequest,
    UpdateGrafanaRequest,
    UpdateGrafanaResourcesRequest
}


//
//  TYPES

export type GrafanaName = string
export interface IGrafana extends bitpoke.grafana.v1.IGrafana {
    name: GrafanaName
}
export type Grafana                        = bitpoke.grafana.v1.Grafana
export type ResourceAllocation             = bitpoke.grafana.v1.ResourceAllocation
export type IResourceAllocation            = bitpoke.grafana.v1.IResourceAllocation
export type IListGrafanasRequest           = bitpoke.grafana.v1.IListGrafanasRequest
export type IListGrafanasResponse          = bitpoke.grafana.v1.IListGrafanasResponse
export type GetGrafanaRequest              = bitpoke.grafana.v1.GetGrafanaRequest
export type IGetGrafanaRequest             = bitpoke.grafana.v1.IGetGrafanaRequest
export type UpdateGrafanaRequest           = bitpoke.grafana.v1.UpdateGrafanaRequest
export type IUpdateGrafanaRequest          = bitpoke.grafana.v1.IUpdateGrafanaRequest
export type UpdateGrafanaResourcesRequest  =
    bitpoke.grafana.v1.UpdateGrafanaResourcesRequest
export type IUpdateGrafanaResourcesRequest =
    bitpoke.grafana.v1.IUpdateGrafanaResourcesRequest

export type State = api.State<IGrafana>

const service = GrafanaService.create(
    grpc.createTransport('bitpoke.grafana.v1.GrafanaService')
)

export const { parseName, buildName } = api.createNameHelpers(api.Resource.grafana)

//
//  ACTIONS

export const get = (payload: IGetGrafanaRequest) => grpc.invoke({
    service,
    method       : 'getGrafana',
    data         : GetGrafanaRequest.create(payload),
    responseType : Grafana
})

export const list = (payload?: IListGrafanasRequest) => grpc.invoke({
    service,
    method       : 'listGrafanas',
    data         : ListGrafanasRequest.create(payload),
    responseType : ListGrafanasResponse
})

export const update = (payload: IUpdateGrafanaRequest) => grpc.invoke({
    service,
    method       : 'updateGrafana',
    data         : UpdateGrafanaRequest.create(payload),
    responseType : Grafana
})

export const updateResources = (payload: IUpdateGrafanaResourcesRequest) => grpc.invoke({
    service,
    method       : 'updateGrafanaResources',
    data         : UpdateGrafanaResourcesRequest.create(payload),
    responseType : Grafana
})

const apiTypes = api.createActionTypes(api.Resource.grafana)

export const {
    LIST_REQUESTED,    LIST_SUCCEEDED,    LIST_FAILED,
    GET_REQUESTED,     GET_SUCCEEDED,     GET_FAILED,
    CREATE_REQUESTED,  CREATE_SUCCEEDED,  CREATE_FAILED,
    UPDATE_REQUESTED,  UPDATE_SUCCEEDED,  UPDATE_FAILED,
    DESTROY_REQUESTED, DESTROY_SUCCEEDED, DESTROY_FAILED
} = apiTypes

//
//  REDUCER

const apiReducer = api.createReducer(api.Resource.grafana, apiTypes)

export function reducer(state: State = api.initialState, action: AnyAction) {
    return apiReducer(state, action)
}


//
//  SAGA

export function* saga() {
    yield fork(api.emitResourceActions, api.Resource.grafana, apiTypes)
    yield fork(forms.takeEverySubmission, keys(FORM_HANDLERS) as forms.Name[], handleFormSubmission)

    yield takeEvery([
        UPDATE_SUCCEEDED, UPDATE_FAILED
    ], ui.notifyAboutAction)
}

const FORM_HANDLERS: forms.HandlerMapping = {
    [forms.Name.updateGrafana]          : update,
    [forms.Name.updateGrafanaResources] : updateResources
}

const handleFormSubmission = forms.createHandlers(FORM_HANDLERS, apiTypes)

export function isGrafana(entry: any): entry is IGrafana {
    return api.isOfType(api.Resource.grafana, entry)
}


//
//  SELECTORS

const selectors: api.Selectors<IGrafana> = api.createSelectors(api.Resource.grafana)
export const { getState, getAll, countAll, getByName, getForURL, getForCurrentURL } = selectors

export const getForProject: SelectorCreator<projects.ProjectName, Maybe<IGrafana>> = (
    project: projects.ProjectName
) => createSelector(
    getAll,
    (entries) => find(entries, ({ name }) => startsWith(name, project))
)
export const isNotFound: Selector<boolean> = createSelector(
    [routing.getCurrentRoute, (state: RootState) => pick(state, 'grpc')],
    (currentRoute: routing.Route, state) => {
        const parsedName = parseName(currentRoute.url)
        if (!parsedName.name) {
            return false
        }

        const request: grpc.Request = get({ name: parsedName.name }).payload
        return grpc.isFailedRequest(request)(state as RootState)
    }
)
