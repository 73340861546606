import { map, flatMap, filter, sortBy, reverse, get, head, toInteger, toString, includes, isEmpty } from 'lodash'

import { bitpoke } from '@bitpoke/bitpoke-proto'

import { nodes } from '../redux'
import { isBooleanLike } from '../utils'

const {
    Affinity,
    NodeAffinity,
    NodeSelector,
    PreferredSchedulingTerm,
    NodeSelectorTerm,
    NodeSelectorRequirement
} = bitpoke.affinity.v1

export {
    Affinity,
    NodeAffinity,
    NodeSelector,
    PreferredSchedulingTerm,
    NodeSelectorTerm,
    NodeSelectorRequirement
}

export type Affinity                 = bitpoke.affinity.v1.Affinity
export type IAffinity                = bitpoke.affinity.v1.IAffinity
export type NodeAffinity             = bitpoke.affinity.v1.NodeAffinity
export type INodeAffinity            = bitpoke.affinity.v1.INodeAffinity
export type NodeSelector             = bitpoke.affinity.v1.NodeSelector
export type INodeSelector            = bitpoke.affinity.v1.INodeSelector
export type PreferredSchedulingTerm  = bitpoke.affinity.v1.PreferredSchedulingTerm
export type IPreferredSchedulingTerm = bitpoke.affinity.v1.IPreferredSchedulingTerm
export type NodeSelectorTerm         = bitpoke.affinity.v1.NodeSelectorTerm
export type INodeSelectorTerm        = bitpoke.affinity.v1.INodeSelectorTerm
export type NodeSelectorRequirement  = bitpoke.affinity.v1.NodeSelectorRequirement

export type SchedulingRule = {
    label: nodes.Label
    preference: SchedulingPreference
}

export interface INodeSelectorRequirement extends bitpoke.affinity.v1.INodeSelectorRequirement {
    key: string
}

export enum SchedulingPreference {
    always = 'always',
    prefer = 'prefer',
    never = 'never'
}

export enum NodeSelectorOperator {
    in = 'In',
    notIn = 'NotIn',
    exists = 'Exists',
    doesNotExist = 'DoesNotExist',
    gt = 'Gt',
    lt = 'Lt'
}

export enum RuleType {
    weak = 'weak',
    strong = 'strong'
}

export function buildNodeAffinity(rules: SchedulingRule[]) {
    const weakRules = filter(rules, { preference: SchedulingPreference.prefer })
    const strongRules = filter(rules, (rule) => includes(
        [SchedulingPreference.never, SchedulingPreference.always],
        rule.preference
    ))

    const requiredDuringSchedulingIgnoredDuringExecution = !isEmpty(strongRules) ? {
        nodeSelectorTerms: map(strongRules, buildTerm)
    } : null

    const preferredDuringSchedulingIgnoredDuringExecution = !isEmpty(weakRules) ? map(weakRules, (rule, index) => {
        const weight = toInteger((1 / (index + 1)) * 100)
        const preference = buildTerm(rule)
        return {
            weight,
            preference
        }
    }) : null

    return {
        requiredDuringSchedulingIgnoredDuringExecution,
        preferredDuringSchedulingIgnoredDuringExecution
    }
}

export function buildSelector(rule: SchedulingRule): INodeSelectorRequirement {
    const { label, preference } = rule
    const { key, value } = label

    switch (preference) {
        case SchedulingPreference.never: {
            if (isBooleanLike(label.value)) {
                return {
                    key,
                    operator : NodeSelectorOperator.doesNotExist,
                    values   : []
                }
            }

            return {
                key,
                operator : NodeSelectorOperator.notIn,
                values   : [toString(value)]
            }
        }

        default:
        case SchedulingPreference.prefer:
        case SchedulingPreference.always: {
            return {
                key,
                operator : NodeSelectorOperator.in,
                values   : [toString(value)]
            }
        }
    }
}

export function buildTerm(rule: SchedulingRule): INodeSelectorTerm {
    const selector = buildSelector(rule)
    return {
        matchExpressions : [selector],
        matchFields      : []
    }
}

export function buildRule(selector: INodeSelectorRequirement, type: RuleType): SchedulingRule {
    const { values, operator, key } = selector
    const value = head(values) as string
    const label = nodes.buildLabel(key, value)

    switch (operator) {
        default:
        case NodeSelectorOperator.in:
        case NodeSelectorOperator.exists: {
            const preference = type === RuleType.strong
                ? SchedulingPreference.always
                : SchedulingPreference.prefer

            return {
                label,
                preference
            }
        }

        case NodeSelectorOperator.notIn:
        case NodeSelectorOperator.doesNotExist: {
            return {
                label,
                preference: SchedulingPreference.never
            }
        }
    }
}

export function parseNodeAffinity(affinity: INodeAffinity): SchedulingRule[] {
    const strongSelectors = flatMap(
        get(affinity, 'requiredDuringSchedulingIgnoredDuringExecution.nodeSelectorTerms'),
        'matchExpressions'
    )
    const weakSelectors = flatMap(
        map(reverse(sortBy(get(affinity, 'preferredDuringSchedulingIgnoredDuringExecution'), 'weight')), 'preference'),
        'matchExpressions'
    )

    return [
        ...map(strongSelectors, (selector) => buildRule(selector, RuleType.strong)),
        ...map(weakSelectors, (selector) => buildRule(selector, RuleType.weak))
    ]
}
