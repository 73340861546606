import * as React from 'react'
import { connect } from 'react-redux'
import { Flex } from 'reflexbox'

import { RootState, api, system, navigation, ui } from '../redux'
import { useTranslation } from '../hooks'
import { arePropsEqual } from '../utils'

import Table, { TableColumn, TableCell, TableCellProps, SortDirection } from '../components/Table'
import TitleBar from '../components/TitleBar'
import Container from '../components/Container'
import ContextMenu from '../components/ContextMenu'
import AdminUserTitle from '../components/AdminUserTitle'
import AdminUsersForm from '../components/AdminUsersForm'

type ReduxProps = {
    adminUsers: system.IAdminUser[]
}
type Props = ReduxProps

const AdminUsersList: React.FC<Props> = React.memo((props) => {
    const { adminUsers } = props
    const [t] = useTranslation()

    const columns: TableColumn[] = [
        {
            label        : t('Admin User'),
            dataKey      : 'name',
            cellRenderer : (props: TableCellProps) => (
                <TableCell>
                    <AdminUserTitle
                        entry={ props.rowData }
                        isCompact
                        isStretched
                        withAvatar
                    />
                </TableCell>
            )
        },
        {
            dataKey      : 'actions',
            width        : 100,
            flexGrow     : 0,
            cellRenderer : (props: TableCellProps) => (
                <TableCell align="flex-end" onClick={ (e) => e.stopPropagation() }>
                    <ContextMenu
                        entry={ props.rowData }
                        resource={ api.Resource.adminUser }
                    />
                </TableCell>
            )
        }
    ]

    return (
        <Container>
            <TitleBar
                title={ t(ui.createTitle(navigation.TabEntry.adminUsers)) }
                subtitle={
                    t('Only an admin user can manage organizations and modify the Dashboard settings.')
                }
            />
            <Table
                items={ adminUsers }
                columns={ columns }
                sortDirection={ SortDirection.ASC }
                sortBy="email"
            />
            <Flex w={ 1 }>
                <AdminUsersForm initialValues={ { adminUsers } } />
            </Flex>
        </Container>
    )
}, arePropsEqual('adminUsers'))

function mapStateToProps(state: RootState): ReduxProps {
    return {
        adminUsers: system.getAdminUsers(state)
    }
}

export default connect(mapStateToProps)(AdminUsersList)
