import * as React from 'react'

import { prometheuses } from '../redux'
import { useTranslation } from '../hooks'

import TitleBar from '../components/TitleBar'
import ScheduledNodesList from '../components/ScheduledNodesList'

type Props = {
    entry: prometheuses.IPrometheus
}

const PrometheusSchedulingStatus: React.FC<Props> = (props) => {
    const { entry } = props
    const [t] = useTranslation()

    if (!entry) {
        return null
    }

    return (
        <>
            <TitleBar
                title={ t('Scheduling Status') }
                subtitle={ t('List of the nodes the collector is currently scheduled on') }
            />
            <ScheduledNodesList
                parents={ [entry.name] }
            />
        </>
    )
}

export default PrometheusSchedulingStatus
