import * as React from 'react'

import ActionLabel from '../components/ActionLabel'
import CopyToClipboardButton from '../components/CopyToClipboardButton'
import { Maybe } from '../utils'

type Props = {
    text: Maybe<string | React.ReactNode>
}

const CopyToClipboardLabel: React.FC<Props> = (props) => {
    const text: string = React.isValidElement(props.text) ? props.text.props.children : props.text

    return (
        <ActionLabel
            text={ props.text }
            action={ (
                text ? <CopyToClipboardButton text={ text } /> : null
            ) }
        />
    )
}

export default CopyToClipboardLabel
