import * as React from 'react'

import { isEmpty } from 'lodash'

import { auth, ui } from '../redux'
import { Maybe } from '../utils'

import TitleBar from '../components/TitleBar'
import Avatar from '../components/Avatar'

type Props = {
    entry: Maybe<auth.User>
}

const UserTitle: React.FC<Props> = ({ entry }) => {
    if (!entry) {
        return null
    }

    const [title, subtitle] = isEmpty(entry.fullName) || entry.fullName === entry.email
        ? [entry.email, null]
        : [entry.fullName, entry.email]

    return (
        <TitleBar
            icon={ <Avatar name={ entry.fullName } imageURL={ ui.avatarURL(entry) } isRound /> }
            title={ title }
            subtitle={ subtitle }
            isCompact
        />
    )
}

export default UserTitle
