import * as React from 'react'
import { Formik, FormikHelpers } from 'formik'

import { get, isFunction } from 'lodash'

import { forms } from '../redux'
import { useDispatch } from '../hooks'

type Config = {
    name: forms.Name
    valuesFormatter?: (values: forms.Values) => any
    enableReinitialize?: boolean
    validateOnBlur?: boolean
}
type Props = {
    initialValues: forms.Values
}

export function withForm(config: Config): (WrappedComponent: React.ComponentType<any>) => React.ComponentType<Props> {
    const { name, valuesFormatter, ...otherConfig } = config

    return (WrappedComponent: React.ComponentType<any>) => {
        const Form = (props: Props) => {
            const dispatch = useDispatch()

            const handleSubmit = (inputValues: forms.Values, helpers: FormikHelpers<forms.Values>) => {
                const values = isFunction(valuesFormatter) ? valuesFormatter(inputValues) : inputValues
                const initialValues = get(props, 'initialValues', {})
                dispatch(forms.submit({ name, values, initialValues, helpers }))
            }

            return (
                <Formik
                    enableReinitialize
                    validateOnBlur={ false }
                    { ...props }
                    { ...otherConfig }
                    onSubmit={ handleSubmit }
                    render={ (formProps) => {
                        const isPristine = forms.areValuesEqual(formProps.initialValues, formProps.values)
                        return (
                            <form onSubmit={ formProps.handleSubmit }>
                                <WrappedComponent
                                    isPristine={ isPristine }
                                    { ...formProps }
                                />
                            </form>
                        )
                    } }
                />
            )
        }

        return Form
    }
}
