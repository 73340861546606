import { fork } from 'redux-saga/effects'
import { createSelector } from 'reselect'

import { pickBy, startsWith } from 'lodash'

import { bitpoke } from '@bitpoke/bitpoke-proto'

import { SelectorCreator, AnyAction, api, grpc, projects } from '../redux'
import { Maybe } from '../utils'

const {
    MySQLClusterBackup,
    MySQLClusterBackupService,
    Status: MySQLClusterBackupStatus,
    ListMySQLClusterBackupsRequest,
    ListMySQLClusterBackupsResponse
} = bitpoke.mysqlclusterbackups.v1

export {
    MySQLClusterBackup,
    MySQLClusterBackupStatus
}


//
//  TYPES

export type MySQLClusterBackupName = string
export interface IMySQLClusterBackup extends bitpoke.mysqlclusterbackups.v1.IMySQLClusterBackup {
    name: MySQLClusterBackupName
}

export type MySQLClusterBackup = bitpoke.mysqlclusterbackups.v1.MySQLClusterBackup
export type MySQLClusterBackupStatus = bitpoke.mysqlclusterbackups.v1.Status
export type ListMySQLClusterBackupsRequest = bitpoke.mysqlclusterbackups.v1.ListMySQLClusterBackupsRequest
export type IListMySQLClusterBackupsRequest = bitpoke.mysqlclusterbackups.v1.IListMySQLClusterBackupsRequest

export type State = api.State<IMySQLClusterBackup>

const service = MySQLClusterBackupService.create(
    grpc.createTransport('bitpoke.mysqlclusterbackups.v1.MySQLClusterBackupService')
)

//
//  ACTIONS

export const list = (payload: IListMySQLClusterBackupsRequest) => grpc.invoke({
    service,
    method       : 'listMySQLClusterBackups',
    data         : ListMySQLClusterBackupsRequest.create(payload),
    responseType : ListMySQLClusterBackupsResponse
})


const apiTypes = api.createActionTypes(api.Resource.mysqlClusterBackup)

export const { LIST_REQUESTED, LIST_SUCCEEDED, LIST_FAILED } = apiTypes


//
//  REDUCER

const apiReducer = api.createReducer(api.Resource.mysqlClusterBackup, apiTypes)

export function reducer(state: State = api.initialState, action: AnyAction) {
    return apiReducer(state, action)
}


//
//  SAGA

export function* saga() {
    yield fork(api.emitResourceActions, api.Resource.mysqlClusterBackup, apiTypes)
}


export function isMySQLClusterBackup(entry: Maybe<api.AnyResourceInstance>): entry is IMySQLClusterBackup {
    return api.isOfType(api.Resource.mysqlClusterBackup, entry)
}


//
//  SELECTORS

const selectors: api.Selectors<IMySQLClusterBackup> = api.createSelectors(api.Resource.mysqlClusterBackup)
export const {
    getState,
    getAll,
    countAll,
    getByName,
    getForURL,
    getForCurrentURL,
    getForOrganization,
    getForCurrentOrganization
} = selectors

export const getForProject: SelectorCreator<projects.ProjectName, api.ResourcesList<IMySQLClusterBackup>> = (
    project: projects.ProjectName
) => createSelector(
    getForCurrentOrganization,
    (backups) => pickBy(backups, ({ name }) => startsWith(name, project))
)
