import * as React from 'react'
import { connect } from 'react-redux'
import { Flex, Box } from 'reflexbox'
import { Intent, Classes, Tooltip } from '@blueprintjs/core'
import classes from 'classnames'

import { get, head, isNumber } from 'lodash'

import { RootState, routing, events, sites, navigation, ui } from '../redux'
import { useTranslation } from '../hooks'
import { Maybe, arePropsEqual } from '../utils'

import Card from '../components/Card'
import Button from '../components/Button'
import TitleBar from '../components/TitleBar'
import ScalingGauge from '../components/ScalingGauge'

import styles from './SiteScalingCard.module.scss'

type OwnProps = {
    entry: sites.ISite | null
}

type ReduxProps = {
    latestEvent: Maybe<events.IEvent>
}

type Props = OwnProps & ReduxProps

const SiteScalingCard: React.FC<Props> = React.memo(({ entry, latestEvent }) => {
    const [t] = useTranslation()

    const title = t('Scaling')
    const subtitle = t('Auto-scaling for your site is enabled with pods as scaling units.')

    if (!entry) {
        return null
    }

    const status = get(entry, 'status')
    const autoscaler = get(entry, 'resources.autoscaler')

    if (!autoscaler || !status) {
        return (
            <Card
                title={ title }
                subtitle={ subtitle }
                isBorderedRight
                isBorderedBottom
            />
        )
    }

    if (sites.isPaused(entry)) {
        return (
            <Card
                title={ title }
                subtitle={ subtitle }
                rightItem={ (
                    <Tooltip content={ t(ui.createTitle(navigation.MenuEntry.settings)) }>
                        <Button
                            icon="tune"
                            href={ routing.routeForResource(entry, { action: navigation.MenuEntry.resources }) }
                            minimal
                            isSquare
                        />
                    </Tooltip>
                ) }
                isBorderedRight
                isBorderedBottom
            >
                <Flex>
                    <Box w={ 1 / 2 }>
                        <ScalingGauge
                            currentValue={ 0 }
                            maxValue={ 0 }
                            title={ t('Available') }
                            subtitle={ t('WordPress replicas available to users') }
                            intent={ Intent.WARNING }
                        />
                    </Box>
                    <Box w={ 1 / 2 }>
                        <ScalingGauge
                            currentValue={ 0 }
                            maxValue={ 0 }
                            title={ t('Up-to-date') }
                            subtitle={ t('Replicas with the latest deployment version') }
                            intent={ Intent.WARNING }
                        />
                    </Box>
                </Flex>
                <div className={ styles.replicas }>
                    <Flex mt={ 3 } justify="space-between">
                        <Box w={ 1 / 3.3 }>
                            <ReplicaInfo
                                title={ t('Minimum pods') }
                                subtitle={ t('Lower threshold for WordPress replicas') }
                                number={ 0 }
                                intent={ Intent.WARNING }
                            />
                        </Box>
                        <Box w={ 1 / 3.3 }>
                            <ReplicaInfo
                                title={ t('Desired pods') }
                                subtitle={
                                    t('Desired replicas, as computed by the resource autoscaler')
                                }
                                number={ 0 }
                                intent={ Intent.WARNING }
                            />
                        </Box>
                        <Box w={ 1 / 3.3 }>
                            <ReplicaInfo
                                title={ t('Maximum pods') }
                                subtitle={ t('Upper threshold for WordPress replicas') }
                                number={ 0 }
                                intent={ Intent.WARNING }
                            />
                        </Box>
                    </Flex>
                </div>
            </Card>
        )
    }

    const minReplicas = isNumber(autoscaler.minReplicas)
        ? autoscaler.minReplicas
        : sites.DEFAULT_AUTOSCALER.minReplicas
    const maxReplicas = isNumber(autoscaler.maxReplicas)
        ? autoscaler.maxReplicas
        : sites.DEFAULT_AUTOSCALER.maxReplicas

    const desiredReplicas = isNumber(status.desiredReplicas) ? status.desiredReplicas : minReplicas
    const availableReplicas = isNumber(status.availableReplicas) ? status.availableReplicas : 0
    const updatedReplicas = isNumber(status.updatedReplicas) ? status.updatedReplicas : 0

    return (
        <Card
            title={ title }
            subtitle={ subtitle }
            rightItem={ (
                <Tooltip content={ t(ui.createTitle(navigation.MenuEntry.settings)) }>
                    <Button
                        icon="tune"
                        href={ routing.routeForResource(entry, { action: navigation.MenuEntry.resources }) }
                        minimal
                        isSquare
                    />
                </Tooltip>
            ) }
            isBorderedRight
            isBorderedBottom
        >
            <Flex>
                <Box w={ 1 / 2 }>
                    <ScalingGauge
                        currentValue={ availableReplicas }
                        maxValue={ desiredReplicas }
                        title={ t('Available') }
                        subtitle={ t('WordPress replicas available to users') }
                        intent={ Intent.SUCCESS }
                    />
                </Box>
                <Box w={ 1 / 2 }>
                    <ScalingGauge
                        currentValue={ updatedReplicas }
                        maxValue={ desiredReplicas }
                        title={ t('Up-to-date') }
                        subtitle={ t('Replicas with the latest deployment version') }
                        intent={ Intent.PRIMARY }
                    />
                </Box>
            </Flex>
            <div className={ styles.replicas }>
                <Flex mt={ 3 } justify="space-between">
                    <Box w={ 1 / 3.3 }>
                        <ReplicaInfo
                            title={ t('Minimum pods') }
                            subtitle={ t('Lower threshold for WordPress replicas') }
                            number={ minReplicas }
                        />
                    </Box>
                    <Box w={ 1 / 3.3 }>
                        <ReplicaInfo
                            title={ t('Desired pods') }
                            subtitle={
                                t('Desired replicas, as computed by the resource autoscaler')
                            }
                            number={ desiredReplicas }
                            intent={
                                desiredReplicas < minReplicas || desiredReplicas > maxReplicas
                                    ? Intent.DANGER
                                    : Intent.PRIMARY
                            }
                        />
                    </Box>
                    <Box w={ 1 / 3.3 }>
                        <ReplicaInfo
                            title={ t('Maximum pods') }
                            subtitle={ t('Upper threshold for WordPress replicas') }
                            number={ maxReplicas }
                        />
                    </Box>
                </Flex>
            </div>
        </Card>
    )
}, arePropsEqual(['entry', 'latestEvent']))

type ReplicaInfoProps = {
    title: string
    subtitle: string
    number: number
    intent?: Intent
}

const ReplicaInfo: React.FC<ReplicaInfoProps> = (props) => {
    const { title, subtitle, number } = props
    const intent = get(props, 'intent', Intent.PRIMARY)

    return (
        <div className={ classes(styles.replicaInfo, Classes.intentClass(intent)) }>
            <h3>{ number }</h3>
            <TitleBar
                title={ title }
                subtitle={ subtitle }
                isSmall
            />
        </div>
    )
}

function mapStateToProps(state: RootState, ownProps: OwnProps): ReduxProps {
    const { entry } = ownProps
    const recentEvents: events.IEvent[] = entry
        ? events.getScalingEventsForSource(entry.name)(state)
        : []

    return {
        latestEvent: head(recentEvents)
    }
}

export default connect(mapStateToProps)(SiteScalingCard)
