import { ActionType } from 'typesafe-actions'
import { fork } from 'redux-saga/effects'
import { createSelector } from 'reselect'

import {
    map, reduce, find, filter, values, pickBy, uniqBy, sortBy, get as _get,
    compact, split, join, last, toUpper, toArray, replace, includes
} from 'lodash'
import { bitpoke } from '@bitpoke/bitpoke-proto'

import { AnyAction, Selector, SelectorCreator, api, grpc, pods } from '../redux'
import { Maybe, parseQuantity, formatQuantity, toBoolean, isBooleanLike } from '../utils'
import { i18n } from '../i18n'

const {
    Node,
    Taint,
    Status,
    NodesService,
    GetNodeRequest,
    ListNodesRequest,
    ListNodesResponse
} = bitpoke.nodes.v1

export {
    Node,
    Taint,
    Status,
    NodesService,
    GetNodeRequest,
    ListNodesRequest,
    ListNodesResponse
}

export type NodeName = string
export interface RawNode extends bitpoke.nodes.v1.INode {
    name: NodeName
}
export type INode = RawNode & {
    labels?: LabelsList
}
export type Node                      = bitpoke.nodes.v1.Node
export type Taint                     = bitpoke.nodes.v1.Taint
export type ITaint                    = bitpoke.nodes.v1.ITaint
export type Status                    = bitpoke.nodes.v1.Status
export type IStatus                   = bitpoke.nodes.v1.IStatus
export type Resource                  = bitpoke.nodes.v1.Resource
export type IResource                 = bitpoke.nodes.v1.IResource
export type GetNodeRequest            = bitpoke.nodes.v1.GetNodeRequest
export type IGetNodeRequest           = bitpoke.nodes.v1.IGetNodeRequest
export type ListNodesRequest          = bitpoke.nodes.v1.ListNodesRequest
export type IListNodesRequest         = bitpoke.nodes.v1.IListNodesRequest
export type ListNodesResponse         = bitpoke.nodes.v1.ListNodesResponse
export type IListNodesResponse        = bitpoke.nodes.v1.IListNodesResponse

export type State = api.State<INode>
export type Actions = ActionType<typeof actions>
export enum LabelKey {
    region = 'topology.kubernetes.io/region',
    zone = 'topology.kubernetes.io/zone',
    preemptible = 'cloud.google.com/gke-preemptible'
}
export type AnyLabelKey = LabelKey | string
export type Label = {
    key: AnyLabelKey
    value: LabelValue
    name: string
    displayName?: string
}
export type LabelValue = string | boolean
export type LabelsList = Record<AnyLabelKey, Label>
export enum NodeResourceType {
    cpu = 'cpu',
    memory = 'memory',
    pods = 'pods'
}

export type ScheduledNode = {
    node: INode
    pods: pods.IPod[]
}

const service = NodesService.create(
    grpc.createTransport('bitpoke.nodes.v1.NodesService')
)

export const { parseName, buildName } = api.createNameHelpers(api.Resource.node)


//
//  ACTIONS

export const get = (payload: IGetNodeRequest) => grpc.invoke({
    service,
    method       : 'getNode',
    data         : GetNodeRequest.create(payload),
    responseType : Node
})

export const list = (payload?: IListNodesRequest) => grpc.invoke({
    service,
    method       : 'listNodes',
    data         : ListNodesRequest.create(payload),
    responseType : ListNodesResponse
})

const actions = {
    get,
    list
}

const apiTypes = api.createActionTypes(api.Resource.node)

export const {
    LIST_REQUESTED,    LIST_SUCCEEDED,    LIST_FAILED,
    GET_REQUESTED,     GET_SUCCEEDED,     GET_FAILED
} = apiTypes

//
//  REDUCER

const apiReducer = api.createReducer(api.Resource.node, apiTypes, parseEntry)

export function reducer(state: State = api.initialState, action: AnyAction) {
    return apiReducer(state, action)
}

function parseEntry(entry: RawNode): INode {
    return {
        ...entry,
        labels: normalizeLabels(entry?.labels)
    } as INode
}


//
//  SAGA

export function* saga() {
    yield fork(api.emitResourceActions, api.Resource.node, apiTypes)
}

//
//  HELPERS

const HIDDEN_LABEL_KEYS = [
    'beta.kubernetes.io/arch',
    'beta.kubernetes.io/os',
    'beta.kubernetes.io/fluentd-ds-ready',
    'failure-domain.beta.kubernetes.io/region',
    'failure-domain.beta.kubernetes.io/zone',
    'kubernetes.io/hostname',
    'kubernetes.io/arch',
    'kubernetes.io/os',
    'iam.gke.io/gke-metadata-server-enabled',
    'cloud.google.com/gke-netd-ready',
    'cloud.google.com/gke-os-distribution'
]
const BOOLEAN_LABEL_KEYS = [
    'cloud.google.com/gke-preemptible',
    'beta.kubernetes.io/fluentd-ds-ready',
    'iam.gke.io/gke-metadata-server-enabled'
]
const ROLE_DOMAIN = 'node-role.kubernetes.io'

export function buildLabel(key: AnyLabelKey, value: string): Label {
    return {
        key,
        value : labelValue(key, value),
        name  : labelName(key)
    }
}

export function labelName(key: AnyLabelKey): string {
    const { domain, name } = parseLabelKey(key)
    if (domain === ROLE_DOMAIN) {
        return 'role'
    }

    return replace(name, /gke-/, '')
}

export function labelValue(key: AnyLabelKey, value: string): LabelValue {
    const { domain, name } = parseLabelKey(key)
    if (domain === ROLE_DOMAIN) {
        return name
    }

    if (isBooleanLike(value)) {
        return toBoolean(value)
    }

    if (includes(BOOLEAN_LABEL_KEYS, key)) {
        if (value === undefined) {
            return true
        }
        return toBoolean(value)
    }

    return value
}

export function labelDisplayName(label: Label): string {
    const { name, value } = label
    if (isBooleanLike(value)) {
        return name
    }

    return join([name, value], ': ')
}

function parseLabelKey(key: string) {
    if (!includes(key, '/')) {
        return {
            domain : null,
            name   : key
        }
    }

    const [domain, name] = split(key, '/') as [string, string]
    return { domain, name }
}

export function getLabel(key: LabelKey, entry: INode): Maybe<Label> {
    const labels = _get(entry, 'labels', []) as Label[]
    return find(labels, { key })
}

export function capacityDescription(entry: INode): string {
    const memory = entry.status?.allocatable?.memory
    const cpu = entry.status?.allocatable?.cpu
    return join(compact([
        cpu ? i18n.t('CPU: {{value}}', { value: formatQuantity(parseQuantity(cpu), { omitUnit: true }) }) : null,
        memory ? i18n.t('Memory: {{value}}', { value: formatQuantity(parseQuantity(memory)) }) : null
    ]), ' / ')
}

export function shortName(entry: INode): string {
    return toUpper(last(split(entry.name, '-')))
}

export function zone(entry: INode): Maybe<LabelValue> {
    return getLabel(LabelKey.zone, entry)?.value
}

export function normalizeLabels(rawLabels: Maybe<Record<string, string>>): LabelsList {
    const labels = map(rawLabels, (value, key) => buildLabel(key, value))
    const filteredLables = filter(labels, (label) => !includes(HIDDEN_LABEL_KEYS, label.key))
    const uniqueLabels = uniqBy(filteredLables, (label: Label) => join([label.name, label.value]))
    const sortedLabels = sortBy(uniqueLabels, 'name')
    return reduce(sortedLabels, (acc, label) => ({
        ...acc,
        [label.key]: label
    }), {})
}

export function isNode(entry: any): entry is INode {
    return api.isOfType(api.Resource.node, entry)
}

//
//  SELECTORS

type NodesList = Record<NodeName, INode>

const selectors: api.Selectors<INode> = api.createSelectors(api.Resource.node)
export const { getState, getAll, countAll, getByName, getForURL, getForCurrentURL } = selectors
export const getSchedulable: Selector<NodesList> = createSelector(
    getAll,
    (nodes) => pickBy(nodes, { unschedulable: false })
)
export const getUnschedulable: Selector<NodesList> = createSelector(
    getAll,
    (nodes) => pickBy(nodes, { unschedulable: true })
)
export const getAllLabels: Selector<Label[]> = createSelector(
    getAll,
    (nodes) => (
        values(reduce(nodes, (acc, node) => ({
            ...acc,
            ...node.labels
        }), {})) as Label[]
    )
)
const getGroupedByLabel: SelectorCreator<Selector<NodesList>, Record<AnyLabelKey, INode>> = (nodeSelector) => (
    createSelector(
        [getAllLabels, nodeSelector],
        (labels, nodes) => reduce(labels, (acc, label) => ({
            ...acc,
            [label.key]: filter(nodes, (node) => !!find(node.labels as any as Label[], label))
        }), {})
    )
)
export const getSchedulableByLabel = getGroupedByLabel(getSchedulable)
export const getUnschedulableByLabel = getGroupedByLabel(getUnschedulable)

export const getScheduledForResource: SelectorCreator<api.ResourceName, Record<NodeName, ScheduledNode>> = (
    resourceName: api.ResourceName
) => createSelector(
    [getAll, pods.getForParent(resourceName)],
    (nodes, resourcePods) => {
        const resourceNodeNames = map(resourcePods, 'node')
        return reduce(nodes, (acc, node) => {
            if (includes(resourceNodeNames, node.name)) {
                const pods = filter(resourcePods, { node: node.name })
                return {
                    ...acc,
                    [node.name]: {
                        node,
                        pods: toArray(pods)
                    }
                }
            }

            return acc
        }, {})
    }
)
