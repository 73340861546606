/* eslint-disable no-use-before-define */
import { ActionType } from 'typesafe-actions'
import { takeEvery, fork, call } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import URI from 'urijs'

import {
    reduce, filter, find, findKey, pick, pickBy, get as _get, head,
    keys, join, compact, includes, startsWith, isEmpty, isNumber
} from 'lodash'

import { bitpoke } from '@bitpoke/bitpoke-proto'

import {
    RootState, Selector, SelectorCreator, AnyAction, ActionWithPayload,
    api, grpc, forms, projects, memcacheds, statuses, conditions, resources, routing, ui
} from '../redux'
import { Maybe, parseQuantity, cloudConsoleURL } from '../utils'

const {
    Site,
    Status,
    Route,
    Endpoint,
    EnvVar,
    PageCache,
    ResourceAllocation,
    StorageBucket,
    SitesService,
    ListSitesRequest,
    ListSitesResponse,
    GetSiteRequest,
    CreateSiteRequest,
    UpdateSiteRequest,
    UpdateSiteRoutesRequest,
    UpdateSiteEnvVarsRequest,
    UpdateSiteResourcesRequest,
    UpdateSitePageCacheRequest,
    UpdateSiteSMTPRequest,
    DeleteSiteRequest,
    ListSiteEventsRequest,
    ListSiteEventsResponse
} = bitpoke.sites.v1

const {
    Backend: PageCacheBackend
} = PageCache

export {
    Site,
    Status,
    Route,
    Endpoint,
    EnvVar,
    PageCache,
    PageCacheBackend,
    ResourceAllocation,
    StorageBucket,
    SitesService,
    ListSitesRequest,
    ListSitesResponse,
    GetSiteRequest,
    CreateSiteRequest,
    UpdateSiteRequest,
    UpdateSiteRoutesRequest,
    UpdateSiteEnvVarsRequest,
    UpdateSiteResourcesRequest,
    UpdateSitePageCacheRequest,
    UpdateSiteSMTPRequest,
    DeleteSiteRequest,
    ListSiteEventsRequest,
    ListSiteEventsResponse
}


//
//  TYPES

export type SiteName = string
export interface ISite extends bitpoke.sites.v1.ISite {
    name: SiteName
}
export interface IEnvVar extends bitpoke.sites.v1.IEnvVar {
    name: string
    value: string
}
export interface IRoute extends bitpoke.sites.v1.IRoute {
    domain: string
    pathPrefix: string
}
export type Site                                 = bitpoke.sites.v1.Site
export type Status                               = bitpoke.sites.v1.Status
export type Route                                = bitpoke.sites.v1.Route
export type Endpoint                             = bitpoke.sites.v1.Endpoint
export type PageCache                            = bitpoke.sites.v1.PageCache
export type PageCacheBackend                     = bitpoke.sites.v1.PageCache.Backend

export type IStorageBucket                       = bitpoke.sites.v1.IStorageBucket
export type ResourceAllocation                   = bitpoke.sites.v1.ResourceAllocation
export type IResourceAllocation                  = bitpoke.sites.v1.IResourceAllocation
export type IEnvVarList                          = bitpoke.sites.v1.IEnvVarList
export type Autoscaler                           = bitpoke.sites.v1.Autoscaler
export type IAutoscaler                          = bitpoke.sites.v1.IAutoscaler
export type ISitePayload                         = bitpoke.sites.v1.ISite
export type IListSitesRequest                    = bitpoke.sites.v1.IListSitesRequest
export type IListSitesResponse                   = bitpoke.sites.v1.IListSitesResponse
export type IGetSiteRequest                      = bitpoke.sites.v1.IGetSiteRequest
export type ICreateSiteRequest                   = bitpoke.sites.v1.ICreateSiteRequest
export type IUpdateSiteRequest                   = bitpoke.sites.v1.IUpdateSiteRequest
export type IUpdateSiteRoutesRequest             = bitpoke.sites.v1.IUpdateSiteRoutesRequest
export type IUpdateSiteEnvVarsRequest            = bitpoke.sites.v1.IUpdateSiteEnvVarsRequest
export type IUpdateSiteResourcesRequest          = bitpoke.sites.v1.IUpdateSiteResourcesRequest
export type IUpdateSitePageCacheRequest          = bitpoke.sites.v1.IUpdateSitePageCacheRequest
export type IUpdateSiteSMTPRequest               = bitpoke.sites.v1.IUpdateSiteSMTPRequest
export type IDeleteSiteRequest                   = bitpoke.sites.v1.IDeleteSiteRequest
export type IListSiteEventsRequest               = bitpoke.sites.v1.IListSiteEventsRequest
export type IListSiteEventsResponse              = bitpoke.sites.v1.IListSiteEventsResponse

export type State = api.State<ISite>

export type Actions = ActionType<typeof actions>

export enum ComponentName {
    mysql               = 'MySQL',
    wordpressSite       = 'WordPress Site',
    wordpressDeployment = 'WordPress Deployment',
    certificate         = 'Certificate',
    ingress             = 'Ingress',
    memcached           = 'Memcached',
    storage             = 'Storage',
    monitoring          = 'Monitoring'
}

export enum ComponentGroupName {
    wordpress   = 'WordPress',
    routing     = 'Routing',
    database    = 'Database',
    cache       = 'Cache',
    storage     = 'Storage',
    monitoring  = 'Monitoring'
}

export enum ConditionName {
    cluster     = 'ClusterReady',
    database    = 'DatabaseReady',
    user        = 'UserReady',
    provisioned = 'Provisioned'
}

export enum FieldName {
    routes              = 'Routes',
    primaryDomainName   = 'Primary Domain Name',
    envVars             = 'Environment Variables',
    gitRepositoryOrigin = 'Git Repository',
    gitRepositoryRef    = 'Git Reference',
    wordpressImage      = 'Docker Image',
    autoscaler          = 'Pod range allocation',
    storageBucket       = 'Storage Bucket URL',
    googleProjectId     = 'Google Project ID',
    serviceAccount      = 'Google Service Account',
    kubernetesNamespace = 'Kubernetes Project Namespace',
    kubernetesName      = 'Kubernetes Site Name'
}

export enum FieldDescription {
    primaryDomainName        = 'A valid, fully qualified domain name',
    gitRepositoryRef         = 'A valid git reference: tag, branch or commit',
    gitRepositoryOrigin      = 'A valid, public repository that can be cloned via git over ssh or https',
    gitRepositoryDeployedRef = 'The SHA reference of the git commit currently deployed',
    wpAdminUrl               = 'A valid, fully-qualified domain name',
    wordpressImage           = 'The name of your custom Docker image (optional)',
    endpoints                = 'In order for your site to be accessible, '
                               + 'you must add an A record in your DNS zone pointing to this IP address',
    autoscaler               = 'Set the range of pod allocation  between requested '
                               + 'number(lower end) and limit number (upper end).'
}

export enum BootstrapDetailsFieldName {
    username = 'Username',
    password = 'Password',
    email = 'Email',
    title = 'Title',
    description = 'Description',
}

export enum BootstrapDetailsFieldDescription {
    username = 'Choose a WordPress administrator user name',
    password = 'Make sure to pick a strong password: letters, numbers and special characters',
    email = 'This is the most important email to manage your site.',
    title = 'How is your site going to be called?',
}

export enum SMTPFieldName {
    host = 'Host',
    port = 'Port',
    useTLS = 'Use TLS',
    startTLS = 'Start TLS',
    user = 'Login',
    password = 'Password',
}

export enum PageCacheFieldName {
    enabled = 'Enable Page Caching',
    backend = 'Backend',
    redisHost = 'Redis Hostname',
    redisPort = 'Redis Port',
    memcachedHost = 'Memcached Hostname',
    memcachedPort = 'Memcached Port',
    storeStatuses = 'Cacheable Response Status Codes',
    responseCacheControl = 'Honor bypass from response headers',
    keyIncludedQueryParams = 'Included Query Parameters',
    keyDiscardedQueryParams = 'Ignored Query Parameters',
    expireSeconds = 'Default TTL'
}

export enum PageCacheFieldDescription {
    storeStatuses = 'Pages are cached only if their response status code is specified in this list.',
    expireSeconds = 'The default duration in seconds for which each page is kept in cache before expiring. '
        + 'Cache-Control and Expires response headers have priority over this setting.',
    responseCacheControl = 'When this toggle is active, it allows <0>bypassing</0> the cache when '
        + 'the Cache-Control or the Expires response headers specify so.',
    keyIncludedQueryParams = 'Add the query parameters that influence the content '
        + 'that is rendered on the page. \n e.g. page_id, category_name, page (if permalinks are not enabled)',
    keyDiscardedQueryParams = 'Add query parameters that should be ignored when caching, '
        + 'e.g. utm_content, utm_source, fbclid'
}

export enum SMTPFieldDescription {
    host = 'SMTP server hostname as specified by your provider ',
    port = 'Port number for outgoing emails',
    useTLS = 'Transport layer security protocol',
    startTLS = 'Force the server to use a secure protocol',
    user = 'Your user name, usually an email address',
    password = 'The password associated with the SMTP login',
}

export enum ResourceType {
    cpuPerPod        = 'cpuPerPod',
    memoryPerPod     = 'memoryPerPod',
    phpWorkers       = 'phpWorkers',
    phpWorkersMemory = 'phpWorkersMemory'
}

export enum ResourceName {
    cpuPerPod        = 'CPU / Pod',
    memoryPerPod     = 'Memory / Pod',
    phpWorkers       = 'No. of PHP Workers / Pod',
    phpWorkersMemory = 'Memory / PHP Worker'
}

export enum CommandsArticle {
    database   = 'database',
    mediaFiles = 'mediaFiles',
    wpCli      = 'wpCli',
    code       = 'code'
}

export enum CommandsArticleTitle {
    database   = 'Connect to the database',
    mediaFiles = 'Access the media files',
    wpCli      = 'Run WP-CLI',
    code       = 'Deploy code updates'
}

export enum CommandsArticleDescription {
    database   = 'Follow these steps in order to forward the MySQL port to localhost and connect to the database.',
    mediaFiles = 'Use the Google Cloud Console or the terminal to manage media assets.',
    wpCli      = 'Run wp-cli commands by connecting to the containers running your WordPress site.',
    code       = 'Run a new rolling deployment using kubectl.'
}

export enum CLICommand {
    exportPodName     = 'exportPodName',
    execWpCli         = 'execWpCli',
    exportSecretValue = 'exportSecretValue',
    mysqlPortForward  = 'mysqlPortForward',
    updateImage       = 'updateImage',
    gsutil            = 'gsutil'
}

const service = SitesService.create(
    grpc.createTransport('bitpoke.sites.v1.SitesService')
)

export const { parseName, buildName } = api.createNameHelpers(api.Resource.site)

export const DEFAULT_AUTOSCALER = {
    minReplicas : 1,
    maxReplicas : 5,
    replicas    : 0
}

export const EMPTY_ROUTE: IRoute = {
    domain     : '',
    pathPrefix : ''
}

export const EMPTY_ENV_VAR: IEnvVar = {
    name  : '',
    value : ''
}

export const COMPONENT_GROUPS: Record<ComponentGroupName, ComponentName[]> = {
    [ComponentGroupName.database]   : [ComponentName.mysql],
    [ComponentGroupName.wordpress]  : [ComponentName.wordpressSite, ComponentName.wordpressDeployment],
    [ComponentGroupName.routing]    : [ComponentName.ingress, ComponentName.certificate],
    [ComponentGroupName.cache]      : [ComponentName.memcached],
    [ComponentGroupName.storage]    : [ComponentName.storage],
    [ComponentGroupName.monitoring] : [ComponentName.monitoring]
}

//
//  ACTIONS

export const get = (payload: IGetSiteRequest) => grpc.invoke({
    service,
    method       : 'getSite',
    data         : GetSiteRequest.create(payload),
    responseType : Site
})

export const list = (payload?: IListSitesRequest) => grpc.invoke({
    service,
    method       : 'listSites',
    data         : ListSitesRequest.create(payload),
    responseType : ListSitesResponse
})

export const create = (payload: ICreateSiteRequest) => grpc.invoke({
    service,
    method       : 'createSite',
    data         : CreateSiteRequest.create(payload),
    responseType : Site
})

export const update = (payload: IUpdateSiteRequest) => grpc.invoke({
    service,
    method       : 'updateSite',
    data         : UpdateSiteRequest.create(payload),
    responseType : Site
})

export const updateRoutes = (payload: IUpdateSiteRoutesRequest) => grpc.invoke({
    service,
    method       : 'updateSiteRoutes',
    data         : UpdateSiteRoutesRequest.create(payload),
    responseType : Site
})

export const updateEnvVars = (payload: IUpdateSiteEnvVarsRequest) => grpc.invoke({
    service,
    method       : 'updateSiteEnvVars',
    data         : UpdateSiteEnvVarsRequest.create(payload),
    responseType : Site
})

export const updateResources = (payload: IUpdateSiteResourcesRequest) => grpc.invoke({
    service,
    method       : 'updateSiteResources',
    data         : UpdateSiteResourcesRequest.create(payload),
    responseType : Site
})

export const updatePageCache = (payload: IUpdateSitePageCacheRequest) => grpc.invoke({
    service,
    method       : 'updateSitePageCache',
    data         : UpdateSitePageCacheRequest.create(payload),
    responseType : Site
})

export const updateSMTP = (payload: IUpdateSiteSMTPRequest) => grpc.invoke({
    service,
    method       : 'updateSiteSMTP',
    data         : UpdateSiteSMTPRequest.create(payload),
    responseType : Site
})

export const destroy = (payload: ISitePayload) => grpc.invoke({
    service,
    method       : 'deleteSite',
    data         : DeleteSiteRequest.create(payload),
    responseType : Site
})

export const listEvents = (payload: IListSiteEventsRequest) => grpc.invoke({
    service,
    method       : 'listSiteEvents',
    data         : ListSiteEventsRequest.create(payload),
    responseType : ListSiteEventsResponse
})

const actions = {
    get,
    list,
    create,
    update,
    destroy,
    listEvents
}

const apiTypes = api.createActionTypes(api.Resource.site)

export const {
    LIST_REQUESTED,    LIST_SUCCEEDED,    LIST_FAILED,
    GET_REQUESTED,     GET_SUCCEEDED,     GET_FAILED,
    CREATE_REQUESTED,  CREATE_SUCCEEDED,  CREATE_FAILED,
    UPDATE_REQUESTED,  UPDATE_SUCCEEDED,  UPDATE_FAILED,
    DESTROY_REQUESTED, DESTROY_SUCCEEDED, DESTROY_FAILED
} = apiTypes

//
//  REDUCER

const apiReducer = api.createReducer(api.Resource.site, apiTypes)

export function reducer(state: State = api.initialState, action: AnyAction) {
    return apiReducer(state, action)
}


//
//  SAGA

export function* saga() {
    yield fork(api.emitResourceActions, api.Resource.site, apiTypes)
    yield fork(forms.takeEverySubmission, keys(FORM_HANDLERS) as forms.Name[], handleFormSubmission)

    yield takeEvery([
        UPDATE_SUCCEEDED, UPDATE_FAILED,
        DESTROY_SUCCEEDED, DESTROY_FAILED
    ], ui.notifyAboutAction)

    yield takeEvery([
        CREATE_SUCCEEDED,
        UPDATE_SUCCEEDED,
        DESTROY_SUCCEEDED
    ], performRedirect)
}

const FORM_HANDLERS: forms.HandlerMapping = {
    [forms.Name.updateSiteRuntime]   : update,
    [forms.Name.updateSiteRoutes]    : updateRoutes,
    [forms.Name.updateSiteEnvVars]   : updateEnvVars,
    [forms.Name.updateSiteSMTP]      : updateSMTP,
    [forms.Name.updateSitePageCache] : updatePageCache,
    [forms.Name.updateSiteResources] : updateResources
}

const handleFormSubmission = forms.createHandlers(FORM_HANDLERS, apiTypes)

function* performRedirect(action: ActionWithPayload) {
    switch (action.type) {
        case CREATE_SUCCEEDED:
            yield call(routing.redirectToResource, action)
            break

        default:
            break
    }
}

export function getCondition(entry: ISite, component: ComponentName, name: ConditionName): Maybe<conditions.Condition> {
    const conditions: conditions.Condition[] = _get(entry, 'status.componentStatus.conditions', [])
    return find(conditions, { component, name })
}

export function getGroupedConditions(entry: ISite): Record<ComponentGroupName, conditions.Condition[]> {
    const conditions: conditions.Condition[] = _get(entry, 'status.componentStatus.conditions', [])
    return reduce(ComponentGroupName, (acc, group) => ({
        ...acc,
        [group]: filter(conditions, (condition) => (
            includes(COMPONENT_GROUPS[group], condition.component)
        ))
    }), {} as Record<ComponentGroupName, conditions.Condition[]>)
}

export function getGroupForCondition(condition: conditions.ICondition): Maybe<ComponentGroupName> {
    return findKey(COMPONENT_GROUPS, (group) => includes(group, condition.component)) as ComponentGroupName
}

export function computeTotalResourceUsage(entry: ISite, resource: resources.ResourceType): resources.ResourceUsage {
    const minReplicas = _get(entry, 'resources.autoscaler.minReplicas', 0)
    const maxReplicas = _get(entry, 'resources.autoscaler.maxReplicas', 0)
    const allocationPerPod = _get(entry, ['resources', resource])

    if (!allocationPerPod) {
        return resources.EMPTY_RESOURCE_USAGE
    }

    const minAllocationPerPod = parseQuantity(allocationPerPod.requested)
    const maxAllocationPerPod = resource === ResourceType.cpuPerPod
        ? parseQuantity(allocationPerPod.requested)
        : parseQuantity(allocationPerPod.limit)

    return {
        minimum : minReplicas * minAllocationPerPod,
        maximum : maxReplicas * maxAllocationPerPod
    }
}

export function isUpdating(entry: ISite): boolean {
    const status = _get(entry, 'status')
    const autoscaler = _get(entry, 'resources.autoscaler')

    if (!autoscaler || !status || isPaused(entry)) {
        return false
    }

    const minReplicas = isNumber(autoscaler.minReplicas)
        ? autoscaler.minReplicas
        : DEFAULT_AUTOSCALER.minReplicas
    const maxReplicas = isNumber(autoscaler.maxReplicas)
        ? autoscaler.maxReplicas
        : DEFAULT_AUTOSCALER.maxReplicas

    const desiredReplicas = isNumber(status.desiredReplicas) ? status.desiredReplicas : minReplicas
    const availableReplicas = isNumber(status.availableReplicas) ? status.availableReplicas : 0
    const updatedReplicas = isNumber(status.updatedReplicas) ? status.updatedReplicas : 0

    const isUpdating = desiredReplicas < minReplicas
        || desiredReplicas > maxReplicas
        || availableReplicas < desiredReplicas
        || updatedReplicas < desiredReplicas

    return isUpdating
}

export function isPaused(entry: ISite): boolean {
    return entry?.status?.componentStatus === statuses.GeneralStatus.PAUSED
}

export function isSite(entry: any): entry is ISite {
    return api.isOfType(api.Resource.site, entry)
}

export function URL(entry: ISite) {
    if (isEmpty(entry.routes)) {
        return ''
    }

    const route = head(entry.routes)

    return new URI({
        protocol : 'https',
        hostname : _get(route, 'domain') as string,
        path     : _get(route, 'pathPrefix', '/') as string
    }).toString()
}

export function storageBucketID(bucket: Maybe<IStorageBucket>) {
    return join(compact([
        _get(bucket, 'name'),
        _get(bucket, 'prefix')
    ]), '/')
}

export function kubernetesConsoleURL(entry: ISite) {
    return `https://console.cloud.google.com/kubernetes/list/overview?project=${entry.googleProjectId}`
}

export function storageURL(entry: ISite, opts: { cannonical: boolean } = { cannonical: true }) {
    const bucketPath = storageBucketID(entry.storageBucket)

    return _get(opts, 'cannonical')
        ? new URI({ protocol: 'gs', hostname: bucketPath }).toString()
        : cloudConsoleURL(
            URI.joinPaths('storage/browser', bucketPath).toString(),
            entry.googleProjectId
        )
}

export function cli(command: CLICommand, entry: ISite, args: Record<string, unknown> = {}) {
    const { kubernetesNamespace, kubernetesName } = entry
    if (!kubernetesNamespace || !kubernetesName) {
        return null
    }

    switch (command) {
        case CLICommand.exportPodName:
            return `export POD_NAME=$(kubectl -n ${kubernetesNamespace} get pod`
                + ` --selector='app.kubernetes.io/component=web, app.kubernetes.io/instance=${kubernetesName}'`
                + " -o jsonpath='{.items..metadata.name}'"
                + " | cut -d ' ' -f 1)"

        case CLICommand.execWpCli:
            return `kubectl -n ${kubernetesNamespace} exec -it deploy/${kubernetesName} -c wordpress -- wp`

        case CLICommand.gsutil:
            return `gsutil -m cp -r ./wp-content/uploads ${storageURL(entry)}wp-content/`

        case CLICommand.exportSecretValue: {
            const kinds = {
                mysql: 'mysql'
            }

            const paths = {
                username : '{.data.USER}',
                password : '{.data.PASSWORD}',
                database : '{.data.DATABASE}'
            }

            const varNames = {
                username : 'MYSQL_USER',
                password : 'MYSQL_PWD',
                database : 'MYSQL_DATABASE'
            }

            const kind    = kinds[_get(args, 'kind', head(keys(kinds))) as string]
            const path    = paths[_get(args, 'data', head(keys(paths))) as string]
            const varName = varNames[_get(args, 'data', head(keys(paths))) as string]

            return `export ${varName}="$(kubectl -n ${kubernetesNamespace} get secret ${kubernetesName}-${kind}`
                + ` -o jsonpath='${path}' | base64 --decode)"`
        }

        case CLICommand.mysqlPortForward: {
            return `kubectl -n ${kubernetesNamespace} port-forward default-mysql-0 3307:3306`
        }

        case CLICommand.updateImage: {
            return `kubectl -n ${kubernetesNamespace} patch wordpress ${kubernetesName}`
                + ' --type=json -p \'[{"op": "replace", "path": "/spec/image", "value": "DOCKER_IMAGE"}]\''
        }

        default:
            return null
    }
}

//
//  SELECTORS

const selectors: api.Selectors<ISite> = api.createSelectors(api.Resource.site)
export const {
    getState,
    getAll,
    countAll,
    getByName,
    getForURL,
    getForCurrentURL,
    getForOrganization,
    getForCurrentOrganization
} = selectors

export const getForProject: SelectorCreator<projects.ProjectName, api.ResourcesList<ISite>> = (
    project: projects.ProjectName
) => createSelector(
    getForCurrentOrganization,
    (sites) => pickBy(sites, ({ name }) => startsWith(name, project))
)
export const getForMemcached: SelectorCreator<memcacheds.MemcachedName, Maybe<ISite>> = (
    memcached: memcacheds.MemcachedName
) => createSelector(
    getForCurrentOrganization,
    (sites) => find(sites, { memcached })
)
export const getResources: SelectorCreator<SiteName, Maybe<IResourceAllocation>> = (name: SiteName) => createSelector(
    getByName(name),
    (site) => site ? site.resources : {}
)
export const getEnvironmentVariables: SelectorCreator<SiteName, Maybe<IEnvVarList>> = (
    name: SiteName
) => createSelector(
    getByName(name),
    (site) => site ? site.envVars : {}
)
export const isNotFound: Selector<boolean> = createSelector(
    [routing.getCurrentRoute, (state: RootState) => pick(state, 'grpc')],
    (currentRoute: routing.Route, state) => {
        const parsedName = parseName(currentRoute.url)
        if (!parsedName.name) {
            return false
        }

        const request: grpc.Request = get({ name: parsedName.name }).payload
        return grpc.isFailedRequest(request)(state as RootState)
    }
)
