import * as React from 'react'
import { Field } from 'formik'

import { get, last, join, difference, size } from 'lodash'

import { system, forms } from '../redux'

import InputField from '../components/InputField'

type Props = forms.FieldProps<system.IAdminUser[]>

const AdminUserEmailField: React.FC<Props> = (props) => {
    const { form, field } = props

    const initialValue = get(form.initialValues, field.name)
    const changes = difference(field.value, initialValue)
    const currentValue = last(changes)
    const value = get(currentValue, 'email', '')
    const error = get(form.errors, join([field.name, size(field.value) - 1, 'email'], '.'))

    return (
        <Field
            { ...props }
            component={ InputField }
            form={ {
                ...form,
                errors: {
                    ...form.errors,
                    [field.name]: error
                }
            } }
            field={ {
                ...field,
                value,
                onChange: (e: React.ChangeEvent<any>) => {
                    const email = e.target.value
                    form.setFieldValue(field.name, [...initialValue, { email }])
                }
            } }
        />
    )
}

export default AdminUserEmailField
