import * as React from 'react'
import { Field } from 'formik'

import { get, join, identity, size, toString, isEmpty } from 'lodash'

import { sites, resources, forms } from '../redux'
import { useTranslation } from '../hooks'
import { parseQuantity, formatQuantity } from '../utils'

import SliderField, { Scale, Value } from '../components/SliderField'
import RangeSliderField from '../components/RangeSliderField'

type Props = forms.FormProps<any> & {
    resource: sites.ResourceType
}

const STEPS: Record<sites.ResourceType, number> = {
    [sites.ResourceType.phpWorkers]       : 256,
    [sites.ResourceType.phpWorkersMemory] : size(resources.RESOURCE_SCALES.memory.small),
    [sites.ResourceType.memoryPerPod]     : size(resources.RESOURCE_SCALES.memory.large),
    [sites.ResourceType.cpuPerPod]        : size(resources.RESOURCE_SCALES.cpu.default)
}

const SCALES: Record<sites.ResourceType, Scale> = {
    [sites.ResourceType.phpWorkers]       : (step: number) => step,
    [sites.ResourceType.phpWorkersMemory] : (step: number) => resources.RESOURCE_SCALES.memory.small[step - 1],
    [sites.ResourceType.memoryPerPod]     : (step: number) => resources.RESOURCE_SCALES.memory.large[step - 1],
    [sites.ResourceType.cpuPerPod]        : (step: number) => resources.RESOURCE_SCALES.cpu.default[step - 1]
}

const isIntervalValue = (resource: sites.ResourceType) => (
    resource === sites.ResourceType.phpWorkers
)

const shouldSetLimit = (resource: sites.ResourceType) => (
    resource !== sites.ResourceType.cpuPerPod
)

const SiteResourceAllocationSliderField: React.FC<Props> = (props) => {
    const { initialValues, resource } = props
    const [t] = useTranslation()

    const name = join(['resources', resource], '.')
    const isDisabled = isEmpty(get(initialValues, name))

    const parseValue = resource === sites.ResourceType.phpWorkers
        ? parseInt
        : parseQuantity

    const formatValue = resource === sites.ResourceType.phpWorkers
        ? (value: Value) => toString(value)
        : (value: Value) => formatQuantity(value)

    const omitUnit = resource !== sites.ResourceType.phpWorkersMemory
        && resource !== sites.ResourceType.memoryPerPod

    const fieldProps = isIntervalValue(resource) ? {
        component : RangeSliderField,
        format    : (value: [any, any], rawValue: resources.Resource) => ({
            ...rawValue,
            requested : formatValue(value[0]),
            limit     : formatValue(value[1])
        }),
        parse: (rawValue: resources.Resource) => {
            if (!rawValue) {
                return [0, 0]
            }

            return [
                parseValue(rawValue.requested),
                parseValue(rawValue.limit)
            ]
        }
    } : {
        component : SliderField,
        format    : (value: any, rawValue: resources.Resource) => {
            const quantity = formatValue(value)
            if (shouldSetLimit(resource)) {
                return {
                    ...rawValue,
                    requested : quantity,
                    limit     : quantity
                }
            }
            return {
                ...rawValue,
                requested: quantity
            }
        },
        parse: (rawValue: resources.Resource) => {
            if (!rawValue) {
                return 0
            }

            return parseValue(rawValue.requested)
        }
    }

    return (
        <Field
            name={ name }
            label={ t(sites.ResourceName[resource]) }
            scale={ SCALES[resource] || identity }
            steps={ STEPS[resource] }
            labelRenderer={ (value: Value) => formatQuantity(value, { omitUnit }) }
            disabled={ isDisabled }
            { ...fieldProps }
        />
    )
}

export default SiteResourceAllocationSliderField
