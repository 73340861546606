import * as React from 'react'
import { Slider, Intent } from '@blueprintjs/core'

import { isString } from 'lodash'

import styles from './ProgressIndicator.module.scss'

export type Props = {
    percent: number
    label?: string
}

const ProgressIndicator: React.FC<Props> = (props) => {
    const { percent, label } = props

    const intent = percent < 60
        ? Intent.SUCCESS
        : percent < 80 ? Intent.WARNING : Intent.DANGER

    return (
        <Slider
            className={ styles.slider }
            min={ 0 }
            max={ 100 }
            intent={ intent }
            labelRenderer={ (step: number) => {
                if (isString(label) && step === 0) {
                    return label
                }

                return ''
            } }
            value={ percent }
        />
    )
}

export default ProgressIndicator
