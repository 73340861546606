import * as React from 'react'

import { api, routing, projects, organizations } from '../redux'
import { useDispatch, useTranslation } from '../hooks'
import { formatDistance } from '../utils'

import Table, { TableColumn, TableCell, TableCellProps, CellGetterProps, getCellData } from '../components/Table'
import BlankSlate from '../components/BlankSlate'
import ContextMenu from '../components/ContextMenu'
import ProjectTitle from '../components/ProjectTitle'
import ProjectIcon from '../assets/icons/ProjectIcon'

type Props = {
    organization: organizations.OrganizationName
}

const ProjectsList: React.FC<Props> = (props) => {
    const { organization } = props
    const dispatch = useDispatch()
    const [t] = useTranslation()

    const columns: TableColumn[] = [
        {
            label        : t('Name'),
            dataKey      : 'name',
            cellRenderer : (props: TableCellProps) => (
                <TableCell>
                    <ProjectTitle
                        entry={ props.rowData }
                        isCompact
                        isStretched
                        withAvatar
                    />
                </TableCell>
            )
        },
        {
            label   : t('Sites'),
            dataKey : 'sitesCount'
        },
        {
            label          : t('Created'),
            dataKey        : 'createdAt',
            cellDataGetter : (props: CellGetterProps) => (
                formatDistance(getCellData(props))
            )
        },
        {
            dataKey      : 'actions',
            width        : 100,
            flexGrow     : 0,
            cellRenderer : (props: TableCellProps) => (
                <TableCell align="flex-end" onClick={ (e) => e.stopPropagation() }>
                    <ContextMenu
                        entry={ props.rowData }
                        resource={ api.Resource.project }
                    />
                </TableCell>
            )
        }
    ]

    return (
        <div>
            <Table
                columns={ columns }
                itemsRequest={ projects.list({ organization }) }
                itemsSelector={ projects.getForOrganization(organization) }
                onRowClick={ ({ rowData }) => {
                    dispatch(routing.push(routing.routeForResource(rowData)))
                } }
                emptyRenderer={ () => (
                    <BlankSlate
                        icon={ <ProjectIcon /> }
                        title={ t('You don\'t have any projects yet.') }
                        description={
                            t('Create your first project by clicking the + button at'
                            + ' the upper right of the organization pane')
                        }
                    />
                ) }
            />
        </div>
    )
}

export default ProjectsList
