import * as React from 'react'
import classes from 'classnames'
import { Classes, Intent } from '@blueprintjs/core'

import { map } from 'lodash'

import { IconName as BaseIconName, IconSvgPaths20 } from '@presslabs/icons'

import { Maybe } from '../utils'

export type IconName = BaseIconName

export type Props = {
    name?: Maybe<IconName>
    size?: number
    color?: string
    title?: Maybe<string>
    className?: string
    intent?: Intent
}

const STANDARD_SIZE = 20

const Icon: React.FC<Props> = (props) => {
    const { name, size, title, intent, className, color } = props

    if (!name) {
        return null
    }

    const paths = IconSvgPaths20[name]

    if (!paths) {
        return null
    }

    return (
        <span className={ classes(Classes.ICON, Classes.iconClass(name), Classes.intentClass(intent), className) }>
            <svg
                fill={ color }
                data-icon={ name }
                width={ size }
                height={ size }
                viewBox={ `0 0 ${STANDARD_SIZE} ${STANDARD_SIZE}` }
            >
                { title && <desc>{ title }</desc> }
                { map(paths, (d, i) => <path key={ i } d={ d } fillRule="evenodd" />) }
            </svg>
        </span>
    )
}

Icon.defaultProps = {
    size: 16
}

export default Icon
