import * as React from 'react'
import { Maybe, Modify } from '../utils'

import Input, { Props as InputProps } from '../components/Input'
import CopyToClipboardButton from '../components/CopyToClipboardButton'

type Props = Modify<Omit<InputProps, 'disabled' | 'readOnly'>, {
    value: Maybe<string>
    emptyValueText?: Maybe<string>
}>

const CopyToClipboardInput: React.FC<Props> = (props) => {
    const { value, type, emptyValueText, rightItem, ...otherProps } = props

    return (
        <Input
            { ...otherProps }
            type={ value ? type : 'text' }
            value={ value || emptyValueText }
            rightItem={ (
                <>
                    { rightItem }
                    { value && <CopyToClipboardButton text={ value } /> }
                </>
            ) }
            disabled={ !value }
            readOnly
        />
    )
}

CopyToClipboardInput.defaultProps = {
    emptyValueText: 'N/A'
}

export default CopyToClipboardInput
