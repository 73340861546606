import * as React from 'react'

import { api } from '../redux'
import ResourceTitle, { Props as BaseProps } from '../components/ResourceTitle'

type Props = Omit<BaseProps, 'resource'>

const SiteTitle: React.FC<Props> = (props) => (
    <ResourceTitle resource={ api.Resource.site } { ...props } />
)

export default SiteTitle
