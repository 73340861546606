import * as React from 'react'
import { InputGroup, Intent, IInputGroupProps } from '@blueprintjs/core'

import { get, pick, isEmpty } from 'lodash'

import { forms } from '../redux'

import FormGroup from '../components/FormGroup'

const InputField: React.FC<forms.FieldProps> = (props) => {
    const { field, form, label } = props
    const { name } = field

    const errors = forms.getFieldErrors(form.errors, name)
    const intent = props.intent || !isEmpty(errors) ? Intent.DANGER : Intent.NONE

    const groupProps: Partial<IInputGroupProps> = pick(props, [
        'autoFocus', 'disabled', 'style', 'className', 'placeholder', 'rightElement', 'type'
    ])

    return (
        <FormGroup
            errors={ forms.formatFieldErrors(label, errors) }
            label={ label }
            { ...field }
            { ...pick(props, ['helperText', 'inline', 'isMonospaced']) }
            intent={ intent }
        >
            <InputGroup
                id={ name }
                large
                { ...field }
                { ...groupProps }
                value={ get(field, 'value', '') }
                intent={ intent }
            />
        </FormGroup>
    )
}

export default InputField
