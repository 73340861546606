import * as React from 'react'
import { NonIdealState, INonIdealStateProps } from '@blueprintjs/core'

import styles from './BlankSlate.module.scss'

import { Maybe } from '../utils'

type Props = INonIdealStateProps & {
    description?: Maybe<string | JSX.Element>
}

const BlankSlate: React.FC<Props> = (props) => (
    <div className={ styles.container }>
        <NonIdealState
            { ...props }
            description={ <span>{ props.description }</span> }
        />
    </div>
)

export default BlankSlate
