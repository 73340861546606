import * as React from 'react'
import { Classes, Intent } from '@blueprintjs/core'
import classes from 'classnames'

import { get } from 'lodash'

import Icon, { IconName } from '../components/Icon'

import styles from './StatusIcon.module.scss'

const ICON: Record<Intent, IconName> = {
    [Intent.NONE]    : 'circle',
    [Intent.WARNING] : 'status-warning',
    [Intent.PRIMARY] : 'status-pending',
    [Intent.SUCCESS] : 'status-ok',
    [Intent.DANGER]  : 'status-error'
}

type Props = {
    intent: Intent
    size?: number
    minimal?: boolean
}

export const StatusIcon: React.FC<Props> = (props) => {
    const { intent, minimal } = props
    const icon = get(ICON, intent, 'circle')
    const size = get(props, 'size', 18)

    if (!icon) {
        return null
    }

    return (
        <span
            className={ classes(Classes.intentClass(intent), styles.container, minimal ? styles.minimal : null) }
            style={ {
                width        : size,
                height       : size,
                borderRadius : size / 2
            } }
        >
            <Icon size={ size } name={ icon } />
        </span>
    )
}

export default StatusIcon
