import * as React from 'react'

import { organizations } from '../redux'
import { useTranslation } from '../hooks'

import TitleBar from '../components/TitleBar'
import Container from '../components/Container'
import AccessManagementList from '../components/AccessManagementList'

type OwnProps = {
    entry: organizations.IOrganization | null
}
type Props = OwnProps

const AccessManagement: React.FC<Props> = ({ entry }) => {
    const [t] = useTranslation()

    if (!entry) {
        return null
    }

    return (
        <Container>
            <TitleBar title={ t('Access Management') } />
            <AccessManagementList />
        </Container>
    )
}

export default AccessManagement
