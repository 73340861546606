import * as React from 'react'
import { connect } from 'react-redux'
import { Intent, Classes } from '@blueprintjs/core'
import { Flex, Box } from 'reflexbox'
import classes from 'classnames'

import { map, times, size, toString, isEmpty } from 'lodash'

import { useDispatch, useTranslation } from '../hooks'
import { RootState, routing, wizard, forms } from '../redux'

import Link from '../components/Link'

import styles from './StepNavBar.module.scss'

type ReduxProps = {
    steps: wizard.Step[]
    activeStep: number
    currentStep: number
    errors: forms.Errors
}

type OwnProps = {
    wizard: wizard.Key
}

type Props = OwnProps & ReduxProps

const STEP_WIDTH = 140

const StepNavBar: React.FC<Props> = (props) => {
    const { steps } = props
    return (
        <div className={ styles.container }>
            <Flex mb={ 30 } align="center" justify="center">
                { map(steps, (step) => (
                    <Box key={ step.number } w={ STEP_WIDTH } align="center">
                        <StepTitle { ...props } step={ step } />
                    </Box>
                )) }
            </Flex>
            <div className={ styles.bars }>
                <Flex justify="space-between" align="center">
                    { times(size(steps) - 1, (i) => (
                        <Box key={ i } w={ STEP_WIDTH - 40 }>
                            <HorizontalBar number={ i } { ...props } />
                        </Box>
                    )) }
                </Flex>
            </div>
        </div>
    )
}

const HorizontalBar: React.FC<Props & { number: number }> = (props) => {
    const { currentStep, number } = props
    const intent = number < currentStep - 1 ? Intent.SUCCESS : Intent.NONE
    return (
        <div className={ classes(styles.horizontalBar, intent && styles[intent]) } />
    )
}

const StepTitle: React.FC<Props & { step: wizard.Step }> = (props) => {
    const { step, activeStep, currentStep } = props

    const dispatch = useDispatch()
    const [t] = useTranslation()

    const errors = wizard.pickErrorForStep(step, props.errors)

    const hasErrors = !isEmpty(errors)
    const isActive = step.number === activeStep
    const isCompleted = (step.number < currentStep)
    const isAccessible = (step.number <= currentStep)
    const isInvalid = hasErrors && (isActive || isAccessible)

    const intent = isActive
        ? Intent.PRIMARY
        : isInvalid
            ? Intent.DANGER
            : isCompleted
                ? Intent.SUCCESS : Intent.NONE

    const onClick = isAccessible
        ? () => dispatch(routing.push({ step: toString(step.number) }))
        : undefined

    return (
        <Link
            onClick={ onClick }
            className={ classes(styles.bullet, isActive && styles.active, Classes.intentClass(intent)) }
        >
            <Flex column align="center">
                <Box><h3>{ toString(step.number) }</h3></Box>
                <Box w={ 1 }><strong>{ t(wizard.stepDisplayName(step.key)) }</strong></Box>
            </Flex>
        </Link>
    )
}


function mapStateToProps(state: RootState, ownProps: OwnProps) {
    const key = ownProps.wizard
    const steps = wizard.getSteps(key)(state)
    const activeStep = wizard.getActiveStepNumber(key)(state)
    const currentStep = wizard.getCurrentStepNumber(key)(state)
    const errors = wizard.getErrors(key)(state)

    return {
        steps,
        activeStep,
        currentStep,
        errors
    }
}

export default connect(mapStateToProps)(StepNavBar)
