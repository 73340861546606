import * as React from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import TabBar from '../components/TabBar'
import PageMeta from '../components/PageMeta'
import MainContainer from '../components/MainContainer'
import ProjectCreateForm from '../components/ProjectCreateForm'
import ProjectGeneralForm from '../components/ProjectGeneralForm'
import ProjectQuotas from '../components/ProjectQuotas'
import ProjectDetails from '../components/ProjectDetails'
import ErrorMessage from '../components/ErrorMessage'
import MainMenu from '../components/MainMenu'
import PodsList from '../components/PodsList'

import { RootState, routing, organizations, projects, mysqlClusters, pods, api, navigation, errors } from '../redux'
import { Maybe, StorageClass } from '../utils'

type ReduxProps = {
    project: Maybe<projects.IProject>
    organization: Maybe<organizations.IOrganization>
    payload: projects.IProjectPayload
    activeMenuEntry: Maybe<navigation.MenuEntry>
    isNotFound: boolean
    isCreateEntryRoute: boolean
}

type Props = ReduxProps

const ProjectsContainer: React.FC<Props> = (props) => {
    const { project, organization, payload, activeMenuEntry, isNotFound, isCreateEntryRoute } = props

    if (!project && !organization) {
        return null
    }

    if (!project && organization) {
        const projectPayload = {
            ...payload,
            organization                   : organization.name,
            bootstrapMysqlClusterResources : {
                storageClass             : StorageClass.standard,
                desiredStorageSizePerPod : {
                    requested: mysqlClusters.DEFAULT_STORAGE_SIZE
                }
            }
        }

        return (
            <>
                <MainMenu
                    resource={ api.Resource.organization }
                    entry={ organization }
                />
                <MainContainer>
                    { isNotFound && (
                        <ErrorMessage error={ errors.Error.notFound } />
                    ) }
                    { isCreateEntryRoute && (
                        <Route
                            path={ routing.routeFor(routing.Key.projects, {
                                slug         : '_',
                                action       : 'new',
                                organization : organizations.parseName(organization.name).slug
                            }) }
                            render={ () => (
                                <ProjectCreateForm
                                    initialValues={ {
                                        project: projectPayload
                                    } }
                                />
                            ) }
                        />
                    ) }
                </MainContainer>
            </>
        )
    }

    if (project && organization) {
        return (
            <>
                <PageMeta
                    entry={ project }
                    section={ activeMenuEntry }
                />
                <MainMenu
                    resource={ api.Resource.project }
                    entry={ project }
                />
                <MainContainer>
                    <TabBar resource={ api.Resource.project } />
                    <Switch>
                        <Route
                            path={ routing.routeForResource(project, { action: navigation.MenuEntry.general }) }
                            render={ () => <ProjectGeneralForm initialValues={ { project } } /> }
                        />
                        <Route
                            path={ routing.routeForResource(project, { action: navigation.MenuEntry.quotas }) }
                            render={ () => <ProjectQuotas entry={ project } /> }
                        />
                        <Route
                            path={ routing.routeForResource(project, { action: navigation.MenuEntry.pods }) }
                            render={ () => (
                                <PodsList
                                    project={ project.name }
                                    itemsRequest={ pods.list({ projects: [project.name] }) }
                                />
                            ) }
                        />
                        <Route
                            path={ routing.routeForResource(project) }
                            render={ () => <ProjectDetails entry={ project } /> }
                        />
                        <Route
                            render={ () => <ErrorMessage error={ errors.Error.notFound } /> }
                        />
                    </Switch>
                </MainContainer>
            </>
        )
    }

    return null
}

function mapStateToProps(state: RootState): ReduxProps {
    const project = projects.getForCurrentURL(state)
    const organization = organizations.getCurrent(state)
    const isNotFound = projects.isNotFound(state)
    const payload = routing.getParams(state)
    const activeMenuEntry = navigation.getActiveMenuEntry(state)
    const isCreateEntryRoute = navigation.isCreateEntryRoute(state)

    return {
        project,
        organization,
        payload,
        activeMenuEntry,
        isNotFound,
        isCreateEntryRoute
    }
}

export default connect(mapStateToProps)(ProjectsContainer)
