/* eslint-disable no-use-before-define */

import { ActionType, action as createAction } from 'typesafe-actions'
import { SagaIterator } from 'redux-saga'
import { take, takeEvery, call, put, race } from 'redux-saga/effects'
import {
    FieldProps as FormikFieldProps, FormikProps as BaseFormikProps, FormikErrors,
    FormikState, FormikHandlers, FormikHelpers, FormikComputedProps
} from 'formik'
import stringify from 'json-stable-stringify'
import { Intent } from '@blueprintjs/core'

import { google } from '@bitpoke/bitpoke-proto'

import {
    map, flatten, get, replace, startCase, snakeCase, upperFirst, toUpper, toLower, has, isFunction, isEqual
} from 'lodash'

import { ActionDescriptor, api, grpc } from '../redux'
import { Maybe, createUpdateMask, compactObject, objectDifference, objectKeysDeep } from '../utils'

export enum Name {
    createInvite                    = 'createInvite',

    createProject                   = 'createProject',
    updateProjectSettings           = 'updateProjectSettings',
    updateProjectResourceQuotas     = 'updateProjectResourceQuotas',

    updateMySQLCluster              = 'updateMySQLCluster',
    updateMySQLClusterResources     = 'updateMySQLClusterResources',

    createOrganization              = 'createOrganization',
    updateOrganizationSettings      = 'updateOrganizationSettings',

    createSiteWizard                = 'createSiteWizard',
    updateSiteRoutes                = 'updateSiteRoutes',
    updateSiteSMTP                  = 'updateSiteSMTP',
    updateSitePageCache             = 'updateSitePageCache',
    updateSiteEnvVars               = 'updateSiteEnvVars',
    updateSiteRuntime               = 'updateSiteRuntime',
    updateSiteAutoscaler            = 'updateSiteAutoscaler',
    updateSiteResources             = 'updateSiteResourcesRequest',

    updateMemcached                 = 'updateMemcached',
    updateMemcachedResources        = 'updateMemcachedResources',

    updatePrometheus                = 'updatePrometheus',
    updatePrometheusResources       = 'updatePrometheusResources',

    updateGrafana                   = 'updateGrafana',
    updateGrafanaResources          = 'updateGrafanaResources',

    updateAuthConfiguration         = 'updateAuthConfiguration',
    updateLetsEncryptConfiguration  = 'updateLetsEncryptConfiguration',
    updateSystemComponentScheduling = 'updateSystemComponentScheduling',

    createAdminUser                 = 'createAdminUser',
    installWizard                   = 'installWizard'
}

export type Handler = (payload: Record<any, any>) => grpc.RequestAction
export type HandlerMapping = Partial<Record<Name, Handler>>
export type Resolvers = {
    success: ActionDescriptor
    failure: ActionDescriptor
}

export type Values = Record<any, any>
export type Errors = Record<string, string | string[]>
export type Payload = {
    name: Name
    values: Values
    initialValues: Values
    helpers: FormikHelpers<Values>
}

export type Actions = ActionType<typeof actions>

export type FormikProps<Values> = BaseFormikProps<Values>
export type FormProps<Values> =
    FormikComputedProps<Values>
    & FormikProps<Values>
    & FormikState<Values>
    & FormikHandlers
    & { isPristine: boolean }

export type FieldProps<Values = any> = FormikFieldProps<Values> & {
    label: string
    helperText?: string
    intent?: Intent
    placeholder?: string
}

//
//  ACTIONS

export const SUBMITTED = '@ forms / SUBMITTED'

export const submit = (payload: Payload) => createAction(SUBMITTED, payload)

const actions = {
    submit
}


//
//  SAGA

export function* saga() {
    yield takeEvery(SUBMITTED, emitFormActions)
}

function* emitFormActions(action: ActionType<typeof submit>) {
    const { payload } = action
    const { name } = payload

    const type = createDescriptor(name)

    yield put({ type, payload })
}

export function* takeEverySubmission(name: Name | Name[], handler: (action: any) => SagaIterator) {
    const descriptors = map(flatten([name]), createDescriptor)
    yield takeEvery(descriptors, handler)
}

export function createUpdateMaskFromValues(values: Values, initialValues: Values): google.protobuf.IFieldMask {
    const changes = objectDifference(values, initialValues)
    return createUpdateMask(objectKeysDeep(changes))
}

export function getResolvers(handler: Handler, actionTypes: api.ActionTypes): Maybe<Resolvers> {
    const handlerAction = handler({})
    const requestType = api.getRequestTypeFromMethodName(handlerAction.payload.method)
    const resourceType = api.getResourceFromMethodName(handlerAction.payload.method)

    if (!requestType || !resourceType) {
        return null
    }

    const actions = has(actionTypes, resourceType) ? actionTypes[resourceType] : actionTypes

    return {
        success : get(actions, api.createActionDescriptor(requestType, api.Status.success)),
        failure : get(actions, api.createActionDescriptor(requestType, api.Status.failure))
    }
}

export function createHandlers(
    mapping: HandlerMapping,
    actionTypes: api.ActionTypes
) {
    return function* handleSubmit(action: ActionType<typeof submit>) {
        const { name, values, initialValues, helpers } = action.payload
        const handlerAction = mapping[name]

        if (!isFunction(handlerAction)) {
            return
        }

        const resolvers = getResolvers(handlerAction, actionTypes)

        const updateMask = createUpdateMaskFromValues(values, initialValues)
        const handler = handlerAction({ ...values, updateMask })

        yield put(handler)

        if (resolvers) {
            const { failureResult } = yield race({
                successResult : take(resolvers.success),
                failureResult : take(resolvers.failure)
            })

            yield call(helpers.setSubmitting, false)

            if (failureResult) {
                const errors = api.getFieldErrorsFromAction(failureResult)
                yield call(helpers.setErrors, errors)
            }
            else {
                yield call(helpers.resetForm)
            }
        }
        else {
            yield call(helpers.setSubmitting, false)
        }
    }
}


//
//  HELPERS and UTILITIES

function createDescriptor(name: Name) {
    return `@ forms / ${toUpper(snakeCase(name))}_FORM_SUBMITTED`
}

export function formatFieldErrors(field: string, errors: string[]): string[] {
    return map(errors, (message) => upperFirst(replace(message, /''/, upperFirst(toLower(startCase(field))))))
}

export function getFieldErrors(errors: FormikErrors<any>, fieldName: string): string[] {
    return get(errors, fieldName, []) as string[]
}

export function areValuesEqual(initialValues: Values, currentValues: Values): boolean {
    const initial = stringify(initialValues)
    const current = stringify(currentValues)
    const initialCompacted = stringify(compactObject(initialValues))
    const currentCompacted = stringify(compactObject(currentValues))
    return isEqual(initial, current)
        || isEqual(initial, currentCompacted)
        || isEqual(initialCompacted, current)
        || isEqual(initialCompacted, currentCompacted)
}
