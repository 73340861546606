import * as React from 'react'

import ProjectsList from '../components/ProjectsList'

import { organizations, ui, navigation, api } from '../redux'
import { useTranslation } from '../hooks'

import TitleBar from '../components/TitleBar'
import Container from '../components/Container'
import CreateProjectButton from '../components/CreateProjectButton'

type OwnProps = {
    entry: organizations.IOrganization | null
}

type Props = OwnProps

const OrganizationDetails: React.FC<Props> = (props) => {
    const { entry } = props
    const [t] = useTranslation()

    if (!entry) {
        return null
    }

    return (
        <Container>
            <TitleBar
                title={ t(ui.createTitle(api.Resource.organization, navigation.MenuEntry.projects)) }
                rightItem={ <CreateProjectButton /> }
            />
            <ProjectsList organization={ entry.name } />
        </Container>
    )
}


export default OrganizationDetails
