import * as React from 'react'
import { connect } from 'react-redux'

import { RootState, auth, errors } from '../redux'

import MainContainer from '../components/MainContainer'
import ErrorMessage from '../components/ErrorMessage'

type ReduxProps = {
    isAuthenticated: boolean
    isEmailVerified: boolean
    isConfigured: boolean
    isConfiguring: boolean
    isLoggedOut: boolean
    hasConfigurationError: boolean
}

type Props = ReduxProps

const AuthenticationGate: React.FC<Props> = (props) => {
    const {
        isAuthenticated, isEmailVerified, isConfigured, isConfiguring, isLoggedOut,
        hasConfigurationError, children
    } = props

    if (hasConfigurationError) {
        return (
            <MainContainer>
                <ErrorMessage error={ errors.Error.invalidOIDCConfig } />
            </MainContainer>
        )
    }

    if (!isAuthenticated) {
        if (!isConfigured && !isConfiguring) {
            return (
                <MainContainer>
                    <ErrorMessage error={ errors.Error.invalidOIDCConfig } />
                </MainContainer>
            )
        }

        if (isLoggedOut) {
            return (
                <MainContainer>
                    <ErrorMessage error={ errors.Error.unauthenticated } />
                </MainContainer>
            )
        }

        return null
    }

    if (!isEmailVerified) {
        return (
            <MainContainer>
                <ErrorMessage error={ errors.Error.unverifiedAccount } />
            </MainContainer>
        )
    }

    return <>{ children }</>
}

function mapStateToProps(state: RootState): ReduxProps {
    return {
        isAuthenticated       : auth.isAuthenticated(state),
        isEmailVerified       : auth.isEmailVerified(state),
        isConfigured          : auth.isConfigured(state),
        isConfiguring         : auth.isConfiguring(state),
        isLoggedOut           : auth.isLoggedOut(state),
        hasConfigurationError : auth.hasConfigurationError(state)
    }
}

export default connect(mapStateToProps)(AuthenticationGate)
