import * as React from 'react'
import { connect } from 'react-redux'
import { Breadcrumbs as BaseBreadcrumbs, IconName as BlueprintIconName, IBreadcrumbProps } from '@blueprintjs/core'

import { map } from 'lodash'

import { RootState, DispatchProp, navigation, api } from '../redux'
import { Maybe, arePropsEqual } from '../utils'

import Breadcrumb from '../components/Breadcrumb'

type ReduxProps = {
    entries: navigation.Breadcrumb[]
}
type BreadcrumbPayload = IBreadcrumbProps & {
    resource?: Maybe<api.Resource>
    entry?: Maybe<api.AnyResourceInstance>
}


type Props = ReduxProps & DispatchProp

const Breadcrumbs: React.FC<Props> = React.memo((props) => {
    const { entries } = props
    return (
        <BaseBreadcrumbs
            items={ map(entries, (entry) => ({
                ...entry,
                icon: entry.icon as BlueprintIconName
            })) }
            breadcrumbRenderer={ (crumb: BreadcrumbPayload) => (
                <Breadcrumb { ...crumb as navigation.Breadcrumb } />
            ) }
        />
    )
}, arePropsEqual('entries'))

function mapStateToProps(state: RootState): ReduxProps {
    const entries = navigation.getBreadcrumbsForCurrentURL(state)

    return {
        entries
    }
}

export default connect(mapStateToProps)(Breadcrumbs)
