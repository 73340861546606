import { bitpoke } from '@bitpoke/bitpoke-proto'

const {
    OperationResponse
} = bitpoke.operation.v1

export {
    OperationResponse
}

export type OperationResponse  = bitpoke.operation.v1.OperationResponse
export type IOperationResponse = bitpoke.operation.v1.IOperationResponse
