import * as React from 'react'
import { connect } from 'react-redux'
import classes from 'classnames'
import { Intent, HTMLTable } from '@blueprintjs/core'
import {
    Column, AutoSizer, Table as VirtualTable, SortIndicator,
    SortDirectionType, SortDirection, CellMeasurer, CellMeasurerCache,
    TableCellProps as BaseTableCellProps, TableHeaderProps as BaseTableHeaderProps, RowMouseEventHandlerParams
} from 'react-virtualized'
import { Align, Flex, Box } from 'reflexbox'

import {
    map, reduce, get, head, reverse, size, join, some, sortBy as _sortBy, noop, toArray,
    isFunction, isNumber, isArray
} from 'lodash'

import Icon from '../components/Icon'
import Button from '../components/Button'
import TextBox from '../components/TextBox'
import BlankSlate from '../components/BlankSlate'

import { DispatchProp, grpc, RootState, Selector } from '../redux'
import { useState, useEffect, useInterval, useTranslation } from '../hooks'
import { isTimestamp, arePropsEqual } from '../utils'

import styles from './Table.module.scss'

export type TableHeaderProps = BaseTableHeaderProps
export type TableCellProps = BaseTableCellProps
export type CellGetterProps = { columnData: any, dataKey: string, rowData: any }

export { CellMeasurerCache, SortDirection }

export const DEFAULT_ROW_HEIGHT = 65
export const DEFAULT_HEADER_HEIGHT = 40

const cellCache = new CellMeasurerCache({
    fixedWidth    : true,
    minHeight     : DEFAULT_ROW_HEIGHT,
    defaultHeight : DEFAULT_ROW_HEIGHT
})

const defaultCellRenderer = (props: TableCellProps) => (
    <TableCell>
        <TextBox isTruncated>{ props.cellData }</TextBox>
    </TableCell>
)

const defaultCellDataGetter = (props: CellGetterProps) => {
    const { rowData, dataKey } = props
    return get(rowData, dataKey)
}

export const getCellData = defaultCellDataGetter
export const renderCell = defaultCellRenderer

export type TableColumn = {
    label?: string
    dataKey: string
    width?: number
    minWidth?: number
    maxWidth?: number
    flexGrow?: number
    className?: string
    cellRenderer?: (props: TableCellProps) => JSX.Element
    headerRenderer?: (props: TableHeaderProps) => JSX.Element
    cellDataGetter?: (props: CellGetterProps) => unknown
}

type OwnProps = {
    items?: any[]
    columns: TableColumn[]
    itemsSelector?: Selector<any> | Array<Selector<any>>
    itemsRequest?: grpc.RequestAction | grpc.RequestAction[]
    pollInterval?: number
    title?: React.ReactNode
    sortBy?: string
    sortDirection?: SortDirectionType
    onRowClick?: (params: RowMouseEventHandlerParams) => void
    loadingRenderer?: (props?: Props) => JSX.Element
    emptyRenderer?: (props?: Props) => JSX.Element
    errorRenderer?: (props?: Props) => JSX.Element
    footerRenderer?: (props?: Props) => JSX.Element
    rowHeight?: number
    hasDynamicRowHeight?: boolean
    className?: string
    isVirtual?: boolean
    isSortable?: boolean
}

type ReduxProps = {
    items: any[]
    itemsCount: number
    isLoading: boolean
    isFailed: boolean
    isEmpty: boolean
}

export type Props = OwnProps & ReduxProps & DispatchProp

const Table: React.FunctionComponent<Props> = React.memo((props) => {
    const {
        columns, items, itemsCount, hasDynamicRowHeight,
        className, loadingRenderer, emptyRenderer, errorRenderer, footerRenderer,
        itemsRequest, pollInterval, onRowClick,
        isVirtual, isEmpty, isLoading, isFailed, dispatch
    } = props

    const [t] = useTranslation()

    const loadItems = () => {
        if (!itemsRequest) {
            return
        }

        isArray(itemsRequest)
            ? map(itemsRequest, dispatch)
            : dispatch(itemsRequest)
    }

    useEffect(loadItems, [dispatch, itemsRequest])
    useInterval(loadItems, pollInterval || null)

    useEffect(() => {
        if (isVirtual) {
            cellCache.clearAll()
        }
    }, [isVirtual, itemsCount])

    const isSortable = get(props, 'isSortable', true)
    const [sortBy, setSortBy] = useState(props.sortBy || 'createdAt')
    const [sortDirection, setSortDirection] = useState(props.sortDirection || SortDirection.DESC)

    if (isEmpty) {
        if (isLoading) {
            if (isFunction(loadingRenderer)) {
                return loadingRenderer(props)
            }

            return null
        }

        if (isFailed) {
            return isFunction(errorRenderer)
                ? errorRenderer(props)
                : (
                    <BlankSlate
                        icon={ <Icon name="delete-circle" size={ 64 } /> }
                        title={ t('Failed to load data.') }
                        action={
                            <Button
                                intent={ Intent.PRIMARY }
                                text={ t('Retry') }
                                onClick={ loadItems }
                            />
                        }
                    />
                )
        }

        if (isFunction(emptyRenderer)) {
            return emptyRenderer(props)
        }

        if (!isFunction(footerRenderer)) {
            return <div />
        }
    }

    const sortKey = isTimestamp(get(head(items), sortBy)) ? join([sortBy, 'seconds'], '.') : sortBy
    const sortedItems = isSortable
        ? sortDirection === SortDirection.ASC
            ? _sortBy(items, sortKey)
            : reverse(_sortBy(items, sortKey))
        : items

    const rowHeight = get(props, 'rowHeight', DEFAULT_ROW_HEIGHT)
    const headerHeight = DEFAULT_HEADER_HEIGHT
    const footerHeight = isFunction(footerRenderer) ? rowHeight : 0
    const minBodyHeight = isEmpty ? 0 : rowHeight
    const maxHeight = itemsCount * rowHeight + itemsCount + headerHeight

    if (isVirtual) {
        const columnsComponents = map(columns, (column: TableColumn, index: number) => {
            const cellRenderer = isFunction(column.cellRenderer) ? column.cellRenderer : defaultCellRenderer
            return (
                <Column
                    key={ index }
                    dataKey={ column.dataKey || '' }
                    label={ column.label || '' }
                    width={ column.width || 500 }
                    minWidth={ column.minWidth }
                    maxWidth={ column.maxWidth }
                    className={ column.className }
                    flexGrow={ isNumber(column.flexGrow) ? column.flexGrow : 1 }
                    cellRenderer={ cellRenderer }
                    headerRenderer={ column.headerRenderer }
                />
            )
        })

        return (
            <div className={ styles.container } style={ { maxHeight } }>
                <AutoSizer>
                    {
                        ({ height, width }) => (
                            <div style={ { width, height } }>
                                <VirtualTable
                                    deferredMeasurementCache={ hasDynamicRowHeight ? cellCache : undefined }
                                    sortDirection={ sortDirection }
                                    sortBy={ sortBy }
                                    sort={ (info: { sortBy: string, sortDirection: SortDirectionType }): void => {
                                        setSortBy(info.sortBy)
                                        setSortDirection(info.sortDirection)
                                    } }
                                    width={ width }
                                    height={ height }
                                    headerHeight={ DEFAULT_HEADER_HEIGHT }
                                    rowHeight={ hasDynamicRowHeight ? cellCache.rowHeight : rowHeight }
                                    rowCount={ itemsCount }
                                    rowGetter={ ({ index }) => sortedItems[index] }
                                    onRowClick={ onRowClick }
                                    className={ className }
                                    headerClassName={ styles.header }
                                    rowClassName={ classes(styles.row, { [styles.interactiveRow]: onRowClick }) }
                                >
                                    { columnsComponents }
                                </VirtualTable>
                            </div>
                        )
                    }
                </AutoSizer>
            </div>
        )
    }

    return (
        <div
            className={ styles.container }
            style={ { minHeight: headerHeight + minBodyHeight + footerHeight } }
        >
            <HTMLTable condensed style={ { minHeight: headerHeight + minBodyHeight } } className={ className }>
                <thead className={ styles.header }>
                    <tr className={ classes(styles.row, { [styles.interactiveHeader]: isSortable }) }>
                        { map(columns, (column: TableColumn, columnIndex: number) => {
                            const { width, minWidth, dataKey } = column
                            return (
                                <th
                                    key={ columnIndex }
                                    onClick={ () => {
                                        if (!isSortable) {
                                            return
                                        }
                                        if (sortBy === dataKey) {
                                            const newDirection = sortDirection === SortDirection.DESC
                                                ? SortDirection.ASC
                                                : SortDirection.DESC
                                            setSortDirection(newDirection)
                                        }
                                        else {
                                            setSortBy(dataKey)
                                        }
                                    } }
                                    style={ { width, minWidth } }
                                >
                                    <Flex>
                                        { column.label }
                                        { isSortable && sortBy === dataKey && (
                                            <SortIndicator sortDirection={ sortDirection } />
                                        ) }
                                    </Flex>
                                </th>
                            )
                        }) }
                    </tr>
                </thead>
                <tbody
                    style={ {
                        minHeight : minBodyHeight,
                        maxHeight : `calc(100vh - 375px - ${headerHeight}px - ${footerHeight}px)`
                    } }
                >
                    { map(sortedItems, (rowData: any, rowIndex: number) => (
                        <tr
                            className={ classes(styles.row, { [styles.interactiveRow]: onRowClick }) }
                            key={ rowIndex }
                            onClick={ (event: React.MouseEvent<any>) => (
                                isFunction(onRowClick)
                                    ? onRowClick({ rowData, event, index: rowIndex })
                                    : noop
                            ) }
                        >
                            { map(columns, (column: TableColumn, columnIndex: number) => {
                                const { dataKey, width, minWidth } = column

                                const cellDataGetter = isFunction(column.cellDataGetter)
                                    ? column.cellDataGetter
                                    : defaultCellDataGetter

                                const cellRenderer = isFunction(column.cellRenderer)
                                    ? column.cellRenderer
                                    : defaultCellRenderer

                                const columnData = dataKey ? map(sortedItems, dataKey) : null
                                const cellData = dataKey ? cellDataGetter({ columnData, rowData, dataKey }) : null

                                const props = {
                                    rowIndex,
                                    columnIndex,
                                    dataKey,
                                    rowData,
                                    cellData,
                                    columnData,
                                    isScrolling: false
                                }


                                return (
                                    <td
                                        key={ columnIndex }
                                        style={ { width, minWidth } }
                                        className={ column.className }
                                    >
                                        { cellRenderer(props) }
                                    </td>
                                )
                            }) }
                        </tr>
                    )) }
                </tbody>
            </HTMLTable>
            { isFunction(footerRenderer) && (
                <div className={ styles.footer }>
                    <Box column p={ 12 }>
                        { footerRenderer(props) }
                    </Box>
                </div>
            ) }
        </div>
    )
}, arePropsEqual(['pollInterval', 'itemsCount', 'items', 'isLoading', 'isFailed']))

type CellRendererProps = {
    onClick?: (args: any) => void
    align?: Align
}

export const TableCell: React.FC<CellRendererProps & { className?: string }> = (props) => {
    const { children, align, ...otherProps } = props
    return (
        <Flex column p={ 12 } align={ align } { ...otherProps }>{ children }</Flex>
    )
}

export const DynamicHeightTableCell: React.FC<TableCellProps> = (props) => (
    <CellMeasurer
        cache={ cellCache }
        parent={ props.parent }
        rowIndex={ props.rowIndex }
        columnIndex={ 0 }
    >
        <TableCell>
            { props.children }
        </TableCell>
    </CellMeasurer>
)

const mapStateToProps = (state: RootState, ownProps: OwnProps): ReduxProps => {
    const { itemsSelector, itemsRequest } = ownProps

    if (!itemsRequest || !itemsSelector) {
        const items = get(ownProps, 'items', [])
        const itemsCount = size(items)

        return {
            items,
            itemsCount,
            isLoading : false,
            isFailed  : false,
            isEmpty   : itemsCount === 0
        }
    }

    const items = isArray(itemsSelector)
        ? toArray(reduce(itemsSelector, (acc, selector) => ({
            ...acc,
            ...selector(state)
        }), {}))
        : toArray(itemsSelector(state))

    const itemsCount = size(items)
    const [isLoading, isFailed] = isArray(itemsRequest)
        ? [
            some(itemsRequest, (request) => grpc.isLoadingRequest(request.payload)(state)),
            some(itemsRequest, (request) => grpc.isFailedRequest(request.payload)(state))
        ] : [
            grpc.isLoadingRequest(itemsRequest.payload)(state),
            grpc.isFailedRequest(itemsRequest.payload)(state)
        ]
    const isEmpty = itemsCount === 0

    return {
        items, itemsCount, isLoading, isFailed, isEmpty
    }
}

export default connect(mapStateToProps)(Table)
