/* eslint-disable no-use-before-define */

import * as React from 'react'
import { connect } from 'react-redux'
import { createHashHistory, createMemoryHistory, createLocation, Location, Path as BasePath } from 'history'
import { pathToRegexp, Key as PathKey, compile } from 'path-to-regexp'
import { matchPath } from 'react-router'
import { SagaIterator, channel as createChannel } from 'redux-saga'
import { takeEvery, takeLatest, fork, put, select } from 'redux-saga/effects'
import { ActionType, action as createAction, isOfType } from 'typesafe-actions'
import { createSelector } from 'reselect'

import URI, { QueryDataMap } from 'urijs'

import {
    map, reduce, filter, reject, findKey, compact, omit, omitBy, get, head, join, toString,
    has, isEmpty, isEqual, isString, isObject, isArray
} from 'lodash'

import {
    RootState, Selector, SelectorCreator, ActionWithPayload,
    app, api, auth, navigation, organizations
} from '../redux'
import { Maybe, watchChannel } from '../utils'


//
//  TYPES

export type State = {
    currentRoute: Route
    previousRoute: Route
}

export type Actions = ActionType<typeof actions>
export type Params = QueryDataMap
export type Param = string | string[]
export type Path = BasePath

export type Route = {
    path: string
    url: string
    params: Params
    key?: string
}

export enum Key {
    dashboard     = 'dashboard',
    organizations = 'organizations',
    projects      = 'projects',
    sites         = 'sites',
    mysqlClusters = 'mysqlClusters',
    memcacheds    = 'memcacheds',
    prometheuses  = 'prometheuses',
    grafanas      = 'grafanas',
    pods          = 'pods',
    errors        = 'errors',
    system        = 'system',
    install       = 'install'
}

//
//  ROUTES

export const ROUTE_MAP = {
    [Key.dashboard]: {
        path      : '/',
        component : 'DashboardContainer'
    },
    [Key.organizations]: {
        path      : '/organizations/:slug/:action?',
        component : 'OrganizationsContainer'
    },
    [Key.memcacheds]: {
        path      : '/organizations/:organization/projects/:project/memcacheds/:slug/:action?',
        component : 'MemcachesContainer'
    },
    [Key.mysqlClusters]: {
        path      : '/organizations/:organization/projects/:project/mysql-clusters/:slug/:action?',
        component : 'MysqlClustersContainer'
    },
    [Key.prometheuses]: {
        path      : '/organizations/:organization/projects/:project/prometheuses/:slug/:action?',
        component : 'PrometheusesContainer'
    },
    [Key.grafanas]: {
        path      : '/organizations/:organization/projects/:project/grafanas/:slug/:action?',
        component : 'GrafanasContainer'
    },
    [Key.sites]: {
        path      : '/organizations/:organization/projects/:project/sites/:slug/:action?/:tab?',
        component : 'SitesContainer'
    },
    [Key.projects]: {
        path      : '/organizations/:organization/projects/:slug?/:action?/:tab?',
        component : 'ProjectsContainer'
    },
    [Key.errors]: {
        path      : '/errors/:type',
        component : 'ErrorsContainer'
    },
    [Key.system]: {
        path      : '/system/:action?/:tab?',
        component : 'SystemContainer'
    },
    [Key.install]: {
        path      : '/install/:token?',
        component : 'SystemContainer'
    }
}


//
//  ACTIONS

export const PUSH_REQUESTED    = '@ routing / PUSH_REQUESTED'
export const REPLACE_REQUESTED = '@ routing / REPLACE_REQUESTED'
export const BACK_REQUESTED    = '@ routing / BACK_REQUESTED'
export const PUSH_SKIPPED      = '@ routing / PUSH_SKIPPED'
export const ROUTE_CHANGED     = '@ routing / ROUTE_CHANGED'

export const push = (route: Path | Params) => createAction(PUSH_REQUESTED, route)
export const replace = (path: Path) => createAction(REPLACE_REQUESTED, path)
export const skipPush = (path: Path) => createAction(PUSH_SKIPPED, path)
export const updateRoute = (location: Location<any>) => createAction(ROUTE_CHANGED, location)
export const goBack = () => createAction(BACK_REQUESTED)

const actions = {
    push, replace, updateRoute, goBack
}

//
//  REDUCER

const initialRoute = {
    path   : '/',
    url    : '/',
    key    : findKey(ROUTE_MAP, { path: '/' }),
    params : {}
}

const initialState = {
    currentRoute  : initialRoute,
    previousRoute : initialRoute
}

export function reducer(
    state: State = initialState,
    action: Actions | ActionType<typeof auth.logout>
) {
    switch (action.type) {
        case ROUTE_CHANGED: {
            const currentRoute = matchRoute(action.payload)
            const previousRoute = isEqual(currentRoute, state.currentRoute)
                ? state.previousRoute
                : state.currentRoute

            return {
                currentRoute,
                previousRoute
            }
        }

        case auth.LOGOUT_REQUESTED: {
            return initialState
        }

        default:
            return state
    }
}


//
//   SAGA

const channel = createChannel()

export function* saga() {
    yield takeLatest(app.INITIALIZED, bootstrap)
    yield takeEvery([PUSH_REQUESTED, REPLACE_REQUESTED, BACK_REQUESTED], dispatchToHistory)
    yield fork(watchChannel, channel)
}

function* bootstrap() {
    yield put(updateRoute(history.location))
    history.listen((route) => channel.put(updateRoute(route)))
}

function* dispatchToHistory(
    action: ActionType<typeof push> | ActionType<typeof replace> | ActionType<typeof goBack>
): SagaIterator {
    if (isOfType(BACK_REQUESTED, action)) {
        history.goBack()
        return
    }

    const { payload } = action

    if (isOfType(PUSH_REQUESTED, action)) {
        const currentRoute = yield select(getCurrentRoute)
        if (isString(payload)) {
            if (isEqual(currentRoute.url, payload)) {
                yield put(skipPush(payload))
                return
            }

            history.push(payload)
        }
        else if (isObject(payload)) {
            history.push(routeFor(currentRoute.key, {
                ...currentRoute.params,
                ...payload
            }))
        }
    }

    if (isOfType(REPLACE_REQUESTED, action)) {
        history.replace(payload as string) // eslint-disable-line
    }
}

//
//   HELPERS and UTILITIES

export const history = process.env.NODE_ENV === 'test' || !has(global, 'window')
    ? createMemoryHistory()
    : createHashHistory()

export function routeFor(key: Key, routeData = {}) {
    if (!has(ROUTE_MAP, key)) {
        throw new Error(`Invalid route key: ${key}`)
    }

    const cleanedParams = omitBy(reduce(routeData, (acc, param, key) => ({
        ...acc,
        [key]: toString(param)
    }), {}), isEmpty)

    const route = ROUTE_MAP[key].path

    const params = reduce(getMandatoryPathParams(route), (acc, mandatoryParam) => {
        if (!has(acc, mandatoryParam)) {
            return {
                ...acc,
                [mandatoryParam]: `unknown-${mandatoryParam}`
            }
        }
        return acc
    }, cleanedParams)

    const url = new URI({ path: compile(route)(params) })
        .query(omit(params, getPathParams(route)))

    return url.toString()
}

export function URLFor(key: Key, routeData = {}) {
    const route = routeFor(key, routeData)
    const url = new URI(window.location.href).fragment(route)

    return url.toString()
}

export function routeForResource(resource: api.AnyResourceInstance, params = {}) {
    const pathname = `/${get(resource, 'name', '')}`
    const search = URI.buildQuery(params)
    const matchedRoute = matchRoute(createLocation({ pathname, search }))

    if (matchedRoute.key) {
        const action = matchedRoute?.params?.action || navigation.getDefaultMenuEntry(matchedRoute.key as api.Resource)
        return routeFor(matchedRoute.key as Key, { ...matchedRoute.params, action })
    }

    return new URI({ path: pathname, query: search }).toString()
}

function getPathParams(path: Path) {
    const keys: PathKey[] = []
    pathToRegexp(path, keys)
    return map(keys, 'name')
}

function getMandatoryPathParams(path: Path) {
    const keys: PathKey[] = []
    pathToRegexp(path, keys)
    const mandatoryKeys = reject(keys, { modifier: '?' })
    return map(mandatoryKeys, 'name')
}

export function parseRoute(url: string) {
    const parsedURL = new URI(url)
    return parsedURL.fragment()
        ? parsedURL.fragment()
        : join(compact([parsedURL.path(), parsedURL.search()]), '')
}

export function matchRoute(location: Location<any>): Route {
    const { pathname, search } = location
    const matched = filter(
        map(ROUTE_MAP, ({ path }, key) => ({
            key,
            ...matchPath(pathname, { path, exact: true })
        })),
        'isExact'
    )

    const routeParams = URI.parseQuery(search) || {}
    const matchedRoute = head(matched) as Route

    const params = omitBy({
        ...get(matchedRoute, 'params', {}),
        ...routeParams
    }, isEmpty)

    if (matchedRoute) {
        const queryParams: Params = omit(params, getPathParams(matchedRoute.path))
        const url = new URI(matchedRoute.url)
            .query(queryParams)
            .toString()

        const route: Route = {
            ...omit(matchedRoute, 'isExact'),
            url,
            params
        }

        return route
    }
    else {
        const url = new URI(pathname)
            .query(params)
            .toString()

        const route: Route = {
            url,
            params,
            path: pathname
        }

        return route
    }
}

export function matchURL(url: string): Route {
    const route = parseRoute(url)
    const parsedRoute = new URI(route)
    const location = createLocation({
        pathname : parsedRoute.path(),
        search   : parsedRoute.search()
    })
    return matchRoute(location)
}

export function* redirectToDashboard() {
    const currentOrganization: Maybe<organizations.IOrganization> = yield select(organizations.getCurrent)
    const dashboardRoute = currentOrganization
        ? routeForResource(currentOrganization)
        : routeFor(Key.dashboard)

    yield put(push(dashboardRoute))
}

export function* redirectToResource(action: ActionWithPayload) {
    const entry = get(action.payload, 'data')

    if (!api.isEntry(entry)) {
        return
    }

    yield put(push(routeForResource(entry)))
}

export function toFragment(url: string): string {
    const parsedURL = new URI(url)

    if (parsedURL.is('relative')) {
        const route = parseRoute(url)
        return `#${route}`
    }

    return url
}

export const withRouter = (component: React.ComponentType) => connect(getState)(component)


//
//  SELECTORS

export const getState = (state: RootState) => state.routing
export const getCurrentRoute: Selector<Route> = createSelector(
    getState,
    (state) => state?.currentRoute || initialRoute
)
export const getPreviousRoute: Selector<Route> = createSelector(
    getState,
    (state) => state?.currentRoute || initialRoute
)
export const getParams: Selector<Params> = createSelector(
    getCurrentRoute,
    (route: Route) => get(route, 'params', {}) as Params
)
export const getParam: SelectorCreator<string, Maybe<Param>> = (name: string) => createSelector(
    getParams,
    (params) => {
        const param = get(params, name)
        return isArray(param) ? compact(param) : param
    }
)
