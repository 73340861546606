import * as React from 'react'
import { Field } from 'formik'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import { Flex } from 'reflexbox'

import { get, toString } from 'lodash'

import { RootState, api, forms, sites, wizard, routing, ui } from '../redux'
import { useDispatch, useTranslation, useEffect } from '../hooks'

import { withForm } from '../components/Form'
import FormContainer from '../components/FormContainer'
import InputField from '../components/InputField'
import Button from '../components/Button'
import StepNavBar from '../components/StepNavBar'
import SiteIcon from '../assets/icons/SiteIcon'
import ConfirmButton from '../components/ConfirmButton'
import SitePrimaryDomainField from '../components/SitePrimaryDomainField'

type ReduxProps = {
    activeStep: wizard.Step
    isFirstStep: boolean
    isLastStep: boolean
    initialErrors: forms.Errors
}

type Props = forms.FormProps<sites.ISitePayload> & ReduxProps

const SiteWizardForm: React.FC<Props> = (props) => {
    const {
        initialValues, initialErrors, activeStep, setErrors,
        isSubmitting, isFirstStep, isLastStep
    } = props
    const dispatch = useDispatch()
    const [t] = useTranslation()

    const entry = get(initialValues, 'site')
    const hasValues = wizard.hasStepValues(activeStep, props.values)

    useEffect(() => {
        setErrors(initialErrors)
    }, [setErrors, initialErrors, activeStep])

    return (
        <FormContainer
            header={
                <>
                    <SiteIcon layers={ activeStep.number } width={ 190 } height={ 190 } />
                    <StepNavBar wizard={ wizard.Key.site } />
                </>
            }
            buttons={
                <Flex justify="space-between">
                    {
                        isFirstStep
                            ? (
                                <ConfirmButton
                                    button={ <Button minimal text={ t('Discard') } /> }
                                    onConfirm={ () => {
                                        dispatch(wizard.discard(wizard.Key.site))
                                        dispatch(routing.push(routing.routeForResource({
                                            name: get(initialValues, 'parent')
                                        })))
                                    } }
                                    confirmationText={ t(ui.createTitle(ui.Resource.draft, ui.Action.confirm)) }
                                />
                            ) : (
                                <Button
                                    text={ t('Back') }
                                    onClick={ () => dispatch(routing.push({ step: toString(activeStep.number - 1) })) }
                                    minimal
                                />
                            )
                    }
                    <Button
                        text={
                            isLastStep
                                ? t(ui.getTitleForPayload(entry, api.Resource.site))
                                : !hasValues && activeStep.isSkippable ? t('Skip') : t('Next')
                        }
                        intent={ Intent.PRIMARY }
                        loading={ isSubmitting }
                        disabled={ !activeStep.isSkippable && (!hasValues || isSubmitting) }
                        type="submit"
                    />
                </Flex>
            }
            isCentered
            { ...props }
        >
            { activeStep.key === wizard.StepKey.routing && (
                <Field
                    name="site.routes"
                    label={ t(sites.FieldName.primaryDomainName) }
                    helperText={ t(sites.FieldDescription.primaryDomainName) }
                    component={ SitePrimaryDomainField }
                    autoFocus
                />
            ) }
            { activeStep.key === wizard.StepKey.runtime && (
                <Field
                    name="site.wordpressImage"
                    label={ t(sites.FieldName.wordpressImage) }
                    helperText={ t(sites.FieldDescription.wordpressImage) }
                    placeholder={ t('Managed') }
                    component={ InputField }
                    autoFocus
                />
            ) }
            { activeStep.key === wizard.StepKey.bootstrap && (
                <>
                    <Field
                        name="site.bootstrap.username"
                        label={ t(sites.BootstrapDetailsFieldName.username) }
                        helperText={ t(sites.BootstrapDetailsFieldDescription.username) }
                        component={ InputField }
                        autoFocus
                    />
                    <Field
                        name="site.bootstrap.password"
                        label={ t(sites.BootstrapDetailsFieldName.password) }
                        helperText={ t(sites.BootstrapDetailsFieldDescription.password) }
                        component={ InputField }
                        type="password"
                    />
                    <Field
                        name="site.bootstrap.email"
                        label={ t(sites.BootstrapDetailsFieldName.email) }
                        helperText={ t(sites.BootstrapDetailsFieldDescription.email) }
                        component={ InputField }
                    />
                    <Field
                        name="site.bootstrap.title"
                        label={ t(sites.BootstrapDetailsFieldName.title) }
                        helperText={ t(sites.BootstrapDetailsFieldDescription.title) }
                        component={ InputField }
                    />
                </>
            ) }
        </FormContainer>
    )
}

function mapStateToProps(state: RootState) {
    const activeStep = wizard.getActiveStep(wizard.Key.site)(state)
    const initialErrors = wizard.getErrors(wizard.Key.site)(state)
    const isFirstStep = wizard.isFirstStep(wizard.Key.site)(state)
    const isLastStep = wizard.isLastStep(wizard.Key.site)(state)

    return {
        activeStep,
        initialErrors,
        isFirstStep,
        isLastStep
    }
}

export default withForm({ name: forms.Name.createSiteWizard })(connect(mapStateToProps)(SiteWizardForm))
