/* eslint-disable no-use-before-define */

import { ActionType, action as createAction } from 'typesafe-actions'
import { takeEvery, takeLatest, fork, race, put, call, take, select } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import * as clipboard from 'clipboard-polyfill'
import URI from 'urijs'
import { Intent } from '@blueprintjs/core'

import { omit, get as _get, keys, isString, isEmpty } from 'lodash'

import { bitpoke } from '@bitpoke/bitpoke-proto'

import {
    AnyAction, api, app, auth, routing, navigation, grpc, forms, organizations, ui
} from '../redux'
import { Maybe, gravatarURL, isTimestamp } from '../utils'
import { i18n } from '../i18n'

const {
    Invite,
    InvitesService,
    ListInvitesRequest,
    ListInvitesResponse,
    GetInviteRequest,
    CreateInviteRequest,
    RedeemInviteRequest,
    DeleteInviteRequest
} = bitpoke.invites.v1

export {
    Invite,
    InvitesService,
    ListInvitesRequest,
    ListInvitesResponse,
    GetInviteRequest,
    CreateInviteRequest,
    RedeemInviteRequest,
    DeleteInviteRequest
}


//
//  TYPES

export type InviteName = string
export interface IInvite extends bitpoke.invites.v1.IInvite {
    name: InviteName
}
export type Invite               = bitpoke.invites.v1.Invite
export type IInvitePayload       = bitpoke.invites.v1.IInvite
export type ListInvitesResponse  = bitpoke.invites.v1.ListInvitesRequest
export type IListInvitesRequest  = bitpoke.invites.v1.IListInvitesRequest
export type IGetInviteRequest    = bitpoke.invites.v1.IGetInviteRequest
export type ICreateInviteRequest = bitpoke.invites.v1.ICreateInviteRequest
export type IRedeemInviteRequest = bitpoke.invites.v1.IRedeemInviteRequest
export type IDeleteInviteRequest = bitpoke.invites.v1.IDeleteInviteRequest
export type IListInvitesResponse = bitpoke.invites.v1.IListInvitesResponse

export type State = api.State<IInvite>
export type Actions = ActionType<typeof actions>

export enum FieldName {
    email = 'Email Address'
}

const service = InvitesService.create(
    grpc.createTransport('bitpoke.invites.v1.InvitesService')
)

export const { parseName, buildName } = api.createNameHelpers(api.Resource.invite)


//
//  ACTIONS

export const LINK_COPIED        = '@ invites / LINK_COPIED'
export const RECREATE_REQUESTED = '@ invites / RECREATE_REQUESTED'
export const REDEEM_REQUESTED   = '@ invites / REDEEM_REQUESTED'
export const REDEEM_SUCCEEDED   = '@ invites / REDEEM_SUCCEEDED'
export const REDEEM_FAILED      = '@ invites / REDEEM_FAILED'

export const copyLink = (payload: IInvite) => createAction(LINK_COPIED, payload)
export const recreate = (payload: IInvite) => createAction(RECREATE_REQUESTED, payload)
export const redeemSuccess = (payload: grpc.Response) => createAction(REDEEM_SUCCEEDED, payload)

export const get = (payload: IGetInviteRequest) => grpc.invoke({
    service,
    method       : 'getInvite',
    data         : GetInviteRequest.create(payload),
    responseType : Invite
})

export const list = (payload?: IListInvitesRequest) => grpc.invoke({
    service,
    method       : 'listInvites',
    data         : ListInvitesRequest.create(payload),
    responseType : ListInvitesResponse
})

export const create = (payload: ICreateInviteRequest) => grpc.invoke({
    service,
    method       : 'createInvite',
    data         : CreateInviteRequest.create(payload),
    responseType : Invite
})

export const destroy = (payload: IInvitePayload) => grpc.invoke({
    service,
    method : 'deleteInvite',
    data   : DeleteInviteRequest.create(payload)
})

export const redeem = (payload: IInvitePayload) => grpc.invoke({
    service,
    method : 'redeemInvite',
    data   : RedeemInviteRequest.create(payload)
})

const actions = {
    get,
    list,
    create,
    destroy,
    copyLink,
    redeemSuccess
}

const apiTypes = {
    ...api.createActionTypes(api.Resource.invite),
    REDEEM_REQUESTED,
    REDEEM_SUCCEEDED,
    REDEEM_FAILED
}

export const {
    LIST_REQUESTED,    LIST_SUCCEEDED,    LIST_FAILED,
    GET_REQUESTED,     GET_SUCCEEDED,     GET_FAILED,
    CREATE_REQUESTED,  CREATE_SUCCEEDED,  CREATE_FAILED,
    DESTROY_REQUESTED, DESTROY_SUCCEEDED, DESTROY_FAILED
} = apiTypes


//
//  REDUCER

const apiReducer = api.createReducer(api.Resource.invite, apiTypes)

export function reducer(state: State = api.initialState, action: AnyAction) {
    switch (action.type) {
        case REDEEM_SUCCEEDED: {
            const response = action.payload
            const entry = response.request.data as IInvite

            if (!entry.name) {
                return state
            }

            return {
                ...state,
                entries: omit(state.entries, entry.name)
            }
        }

        default:
            return apiReducer(state, action)
    }
}


//
//  SAGA

export function* saga() {
    yield fork(api.emitResourceActions, api.Resource.invite, apiTypes)
    yield fork(forms.takeEverySubmission as any, keys(FORM_HANDLERS) as forms.Name[], handleFormSubmission)
    yield takeEvery([
        CREATE_SUCCEEDED, CREATE_FAILED,
        DESTROY_SUCCEEDED, DESTROY_FAILED
    ], ui.notifyAboutAction)

    yield takeEvery(LINK_COPIED, copyLinkToClipboard)
    yield takeEvery(CREATE_SUCCEEDED, triggerLinkCopy)
    yield takeEvery(CREATE_SUCCEEDED, performRedirect)
    yield takeEvery(RECREATE_REQUESTED, performResend)
    yield takeLatest(routing.ROUTE_CHANGED, performRedeemIfRequired)
}

const FORM_HANDLERS: forms.HandlerMapping = {
    [forms.Name.createInvite]: create
}

const handleFormSubmission = forms.createHandlers(FORM_HANDLERS, apiTypes)

function* performResend(action: ActionType<typeof recreate>) {
    const invite = action.payload

    yield put(destroy(invite))
    const { failure } = yield race({
        success : take(DESTROY_SUCCEEDED),
        failure : take(DESTROY_FAILED)
    })

    if (!failure) {
        yield put(create({ invite }))
    }
}

function* triggerLinkCopy(action: grpc.ResponseAction) {
    const invite = action.payload.data as IInvite
    yield put(copyLink(invite))
}

function* performRedirect() {
    const organization: Maybe<organizations.IOrganization> = yield select(organizations.getCurrent)
    if (organization) {
        const route = routing.routeForResource(organization, { action: navigation.MenuEntry.access })
        yield put(routing.push(route))
    }
}

function* copyLinkToClipboard(action: ActionType<typeof copyLink>) {
    const invite = action.payload
    const organization: Maybe<organizations.IOrganization> = yield select(organizations.getCurrent)
    if (!organization) {
        return
    }
    const link = createLink(invite, organization)
    try {
        yield call(clipboard.writeText, link)
        yield put(ui.showToast({
            message : i18n.t('Invitation link copied to clipboard'),
            intent  : Intent.SUCCESS,
            icon    : 'ok-circle'
        }))
    }
    catch (e) {
        if (e) {
            console.log(e) // eslint-disable-line no-console
        }
    }
}

function* performRedeemIfRequired(): SagaIterator {
    const route = routing.getCurrentRoute(yield select())
    const match = parseName(route.url)

    if (match.type === api.Resource.invite && match.name) {
        yield call(auth.ensureAuthentication)
        const { name } = match
        const { token, org } = yield select(routing.getParams)

        if (!name || !token || !org) {
            yield put(ui.showToast({
                message : i18n.t('Failed to redeem invite. The invite URL is invalid or incomplete.'),
                intent  : Intent.DANGER,
                icon    : 'delete-circle'
            }))
        }
        else {
            yield put(grpc.setMetadata({ key: 'organization', value: org }))
            yield put(redeem({ name, redeemToken: token }))

            const { success, failure } = yield race({
                success : take(REDEEM_SUCCEEDED),
                failure : take(REDEEM_FAILED)
            })

            if (success) {
                yield put(organizations.list())
                yield take(organizations.LIST_SUCCEEDED)
                const organization = yield select(organizations.getByName(org))
                if (organization) {
                    yield put(organizations.select(organization))
                    yield put(ui.showToast({
                        message: i18n.t(
                            'Invitation redeemed successfully. Welcome to {{organization}}', {
                                organization: api.displayName(organization)
                            }
                        ),
                        intent : Intent.SUCCESS,
                        icon   : 'ok-circle'
                    }))
                }
                else {
                    yield put(ui.showToast({
                        message : i18n.t('Invitation redeemed successfully'),
                        intent  : Intent.SUCCESS,
                        icon    : 'ok-circle'
                    }))
                    yield put(organizations.ensure())
                }
            }
            else {
                const error = _get(failure.payload, 'error.message')
                const message = error
                    ? i18n.t('Failed to redeem invite. ({{error}})', { error })
                    : i18n.t('Failed to redeem invite.')

                yield put(ui.showToast({
                    message,
                    intent  : Intent.DANGER,
                    icon    : 'delete-circle',
                    timeout : 10 * 1000
                }))
            }
        }

        yield call(routing.redirectToDashboard)
    }
}

export function isInvite(entry: Maybe<api.AnyResourceInstance>): entry is IInvite {
    return api.isOfType(api.Resource.invite, entry)
}

export function createLink(entry: IInvite, organization: organizations.IOrganization, baseURL: string = app.BASE_URL) {
    const route = routing.routeForResource(entry, { token: entry.redeemToken, org: organization.name })
    const uri = new URI(baseURL).fragment(route)
    return uri.toString()
}

export function isRedeemURL(url: routing.Path) {
    const parsedURL = parseName(url)
    return parsedURL.type === api.Resource.invite && parsedURL.name
}

export function isExpired(entry: IInvite) {
    if (!isTimestamp(entry.validBefore)) {
        return true
    }

    return entry.validBefore.seconds < (Date.now() / 1000)
}

export function isValid(entry: IInvite) {
    return !isExpired(entry)
}

export function avatarURL(entry: IInvite) {
    return isString(entry.email) && !isEmpty(entry.email)
        ? gravatarURL(entry.email)
        : null
}


//
//  SELECTORS

const selectors: api.Selectors<IInvite> = api.createSelectors(api.Resource.invite)
export const {
    getState,
    getAll,
    countAll,
    getByName,
    getForURL,
    getForCurrentURL,
    getForOrganization,
    getForCurrentOrganization
} = selectors

