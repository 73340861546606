/* eslint-disable max-len */

import * as React from 'react'
import { Props as BaseIconProps } from '../../components/BaseIcon'

type Props = BaseIconProps & {
    step: number
}

const SiteIcon: React.FC<Props> = ({ step, ...otherProps } = { step: 1 }) => (
    <svg width="202" height="131" viewBox="0 0 202 131" fill="none" xmlns="http://www.w3.org/2000/svg" { ...otherProps }>
        <path d="M11 80L110 25M20 85L119 30M29 90L128 35M38 95L137 40M47 100L146 45M56 105L155 50M65 110L164 55M74 115L173 60M83 120L182 65M92 125L191 70M11 70L110 125M20 65L119 120M29 60L128 115M38 55L137 110M47 50L146 105M56 45L155 100M65 40L164 95M74 35L173 90M83 30L182 85M92 25L191 80M2 75L101 20L200 75L101 130L2 75Z" stroke="#F4F5F7" />
        <path d="M101 30L20 75L101 120L182 75L101 30Z" stroke="#BCC2C8" strokeDasharray="4 4" />
        { step === 1 && (
            <path d="M101 100L56 75L101 50L146 75L101 100Z" stroke="#2E3238" strokeWidth="2" />
        ) }
        { step === 2 && (
            <>
                <path d="M56 27L101 52V102" stroke="#737D8C" />
                <path d="M101 52L146 27" stroke="#737D8C" />
                <path d="M101 102L56 77V27L101 2L146 27V77L101 102Z" stroke="#2E3238" strokeWidth="2" />
            </>
        ) }
        { step === 3 && (
            <>
                <g opacity="0.5">
                    <path d="M146 77L101 102V52L146 27V77Z" fill="#737D8C" />
                    <path d="M101 2L146 27L101 52L56 27L101 2Z" fill="#B0B6BF" />
                    <path d="M56 27L101 52V102L56 77V27Z" fill="#EEEFF1" />
                </g>
                <path d="M56 27L101 52V102" stroke="#737D8C" />
                <path d="M101 52L146 27" stroke="#737D8C" />
                <path d="M101 102L56 77V27L101 2L146 27V77L101 102Z" stroke="#2E3238" strokeWidth="2" />
            </>
        ) }
        { step === 4 && (
            <>
                <path d="M101 30L20 75L101 120L182 75L101 30Z" stroke="#BCC2C8" strokeDasharray="4 4" />
                <path d="M146 75L101 100V50L146 25V75Z" fill="#737D8C" />
                <path d="M101 0L146 25L101 50L56 25L101 0Z" fill="#B0B6BF" />
                <path d="M56 25L101 50V100L56 75V25Z" fill="#EEEFF1" />
            </>
        ) }
        { step === 5 && (
            <>
                <path d="M101 30L20 75L101 120L182 75L101 30Z" stroke="#BCC2C8" strokeDasharray="4 4" />
                <path d="M146 75L101 100V50L146 25V75Z" fill="#737D8C" />
                <path d="M101 0L146 25L101 50L56 25L101 0Z" fill="#B0B6BF" />
                <path d="M56 25L101 50V100L56 75V25Z" fill="#EEEFF1" />
            </>
        ) }
    </svg>
)

export default SiteIcon
