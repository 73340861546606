import * as React from 'react'

import { isEmpty } from 'lodash'

import { accountBindings, api, ui } from '../redux'

import Avatar from '../components/Avatar'
import ResourceTitle, { Props as BaseProps } from '../components/ResourceTitle'

type Props = Omit<BaseProps, 'resource'>

const AccountBindingTitle: React.FC<Props> = (props) => {
    const { entry } = props

    if (!accountBindings.isAccountBinding(entry)) {
        return null
    }

    const [title, subtitle] = isEmpty(entry.fullName)
        ? [api.displayName(entry), null]
        : [api.displayName(entry), entry.email]

    return (
        <ResourceTitle
            resource={ api.Resource.accountBinding }
            avatar={ <Avatar name={ title } imageURL={ ui.avatarURL(entry) } isRound /> }
            title={ title }
            subtitle={ subtitle }
            { ...props }
        />
    )
}

export default AccountBindingTitle
