import * as React from 'react'
import { Intent, Tooltip } from '@blueprintjs/core'

import { useTranslation } from '../hooks'

import Button from '../components/Button'

import styles from './DiscardButton.module.scss'

type Props = {
    onClick?: () => void
}

const DiscardButton: React.FC<Props> = (props) => {
    const { onClick } = props
    const [t] = useTranslation()

    return (
        <Tooltip content={ t('Discard unsaved changes') }>
            <Button
                onClick={ onClick }
                className={ styles.button }
                icon="status-pending"
                intent={ Intent.PRIMARY }
                minimal
                isSquare
            />
        </Tooltip>
    )
}

export default DiscardButton
