import * as React from 'react'
import { Flex, Box } from 'reflexbox'

import { sites } from '../redux'
import { POLL_INTERVAL, arePropsEqual } from '../utils'
import { useDispatch, useInterval } from '../hooks'

import SiteStatusCard from '../components/SiteStatusCard'
import SiteScalingCard from '../components/SiteScalingCard'
import SiteRuntimeCard from '../components/SiteRuntimeCard'
import GoogleCloudCard from '../components/GoogleCloudCard'
import SiteLatestEvent from '../components/SiteLatestEvent'

type Props = {
    entry: sites.ISite | null
}

const SiteOverview: React.FC<Props> = React.memo(({ entry }) => {
    const dispatch = useDispatch()

    useInterval(() => {
        if (entry) {
            dispatch(sites.get({ name: entry.name }))
        }
    }, POLL_INTERVAL.fast)

    if (!entry) {
        return null
    }

    return (
        <div>
            <Flex>
                <Box w={ 1 / 2 }>
                    <SiteScalingCard entry={ entry } />
                </Box>
                <Box w={ 1 / 2 }>
                    <SiteStatusCard entry={ entry } />
                </Box>
            </Flex>
            <Flex>
                <SiteLatestEvent entry={ entry } />
            </Flex>
            <Flex>
                <Box w={ 1 / 2 }>
                    <SiteRuntimeCard entry={ entry } />
                </Box>
                <Box w={ 1 / 2 }>
                    <GoogleCloudCard entry={ entry } />
                </Box>
            </Flex>
        </div>
    )
}, arePropsEqual('entry'))

export default SiteOverview
