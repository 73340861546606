import * as React from 'react'
import { Field } from 'formik'

import { size } from 'lodash'

import { mysqlClusters, resources } from '../redux'
import { useTranslation } from '../hooks'
import { parseQuantity, formatQuantity } from '../utils'

import SliderField, { Value, Props } from '../components/SliderField'

const scale = (step: number) => resources.RESOURCE_SCALES.storage.default[step - 1]
const steps = size(resources.RESOURCE_SCALES.storage.default)

const MySQLClusterStorageSizeField: React.FC<Props> = (props) => {
    const { field } = props
    const [t] = useTranslation()

    return (
        <Field
            name={ field.name }
            label={ t(mysqlClusters.FieldName.desiredStorageSizePerPod) }
            labelRenderer={ (value: Value) => formatQuantity(value, { omitUnit: false }) }
            component={ SliderField }
            steps={ steps }
            scale={ scale }
            parse={ parseQuantity }
            format={ formatQuantity }
            autoFocus
        />
    )
}

export default MySQLClusterStorageSizeField
