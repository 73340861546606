import * as React from 'react'

import { map, size, isEmpty } from 'lodash'

import { api, nodes, pods } from '../redux'
import { useTranslation } from '../hooks'
import { POLL_INTERVAL } from '../utils'

import Table, { TableColumn, TableCell, TableCellProps } from '../components/Table'
import NodeTitle from '../components/NodeTitle'
import LabelsList from '../components/LabelsList'
import NodeResourceUsageIndicator from '../components/NodeResourceUsageIndicator'

type Props = {
    parents: api.ResourceName[]
}

const ScheduledNodesList: React.FC<Props> = (props) => {
    const { parents } = props
    const [t] = useTranslation()

    if (isEmpty(parents)) {
        return null
    }

    const columns: TableColumn[] = [
        {
            label        : t('Node Name'),
            dataKey      : 'node.name',
            cellRenderer : (props: TableCellProps) => (
                <TableCell>
                    <NodeTitle
                        entry={ props.rowData.node }
                        isCompact
                    />
                </TableCell>
            )
        },
        {
            label        : t('Pods'),
            dataKey      : 'pods',
            width        : 100,
            cellRenderer : (props: TableCellProps) => (
                <TableCell>
                    { size(props.cellData) }
                </TableCell>
            )
        },
        {
            label        : t('CPU'),
            dataKey      : 'node.status.allocatable.cpu',
            cellRenderer : (props: TableCellProps) => (
                <TableCell>
                    <NodeResourceUsageIndicator
                        entry={ props.rowData.node }
                        resource={ nodes.NodeResourceType.cpu }
                    />
                </TableCell>
            )
        },
        {
            label        : t('Memory'),
            dataKey      : 'node.status.allocatable.memory',
            cellRenderer : (props: TableCellProps) => (
                <TableCell>
                    <NodeResourceUsageIndicator
                        entry={ props.rowData.node }
                        resource={ nodes.NodeResourceType.memory }
                    />
                </TableCell>
            )
        },
        {
            label        : t('Node Labels'),
            dataKey      : 'node.labels',
            cellRenderer : (props: TableCellProps) => (
                <TableCell>
                    <LabelsList items={ props.cellData } />
                </TableCell>
            )
        }
    ]

    return (
        <Table
            itemsRequest={ [nodes.list(), pods.list({ parents })] }
            itemsSelector={ map(parents, nodes.getScheduledForResource) }
            pollInterval={ POLL_INTERVAL.slow }
            columns={ columns }
        />
    )
}

export default ScheduledNodesList
