import * as React from 'react'
import { Flex, Box } from 'reflexbox'

import { organizations } from '../redux'
import { Maybe } from '../utils'

import OrganizationProjectsCard from '../components/OrganizationProjectsCard'
import OrganizationMembersCard from '../components/OrganizationMembersCard'

type Props = {
    entry: Maybe<organizations.IOrganization>
}

const OrganizationOverview: React.FC<Props> = ({ entry }) => {
    if (!entry) {
        return null
    }

    return (
        <Flex>
            <Box w={ 1 / 2 }>
                <OrganizationProjectsCard entry={ entry } />
            </Box>
            <Box w={ 1 / 2 }>
                <OrganizationMembersCard entry={ entry } />
            </Box>
        </Flex>
    )
}

export default OrganizationOverview
