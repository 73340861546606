import * as React from 'react'
import { Field } from 'formik'

import { get } from 'lodash'

import { api, forms, projects, routing, ui } from '../redux'
import { useDispatch, useTranslation } from '../hooks'

import { withForm } from '../components/Form'
import FormContainer from '../components/FormContainer'
import InputField from '../components/InputField'
import ProjectTitle from '../components/ProjectTitle'
import ProjectIcon from '../assets/icons/ProjectIcon'
import MySQLClusterStorageSizeField from '../components/MySQLClusterStorageSizeField'
import MySQLClusterStorageClassField from '../components/MySQLClusterStorageClassField'

type Props = forms.FormProps<projects.IProjectPayload>

const ProjectCreateForm: React.FC<Props> = (props) => {
    const { initialValues } = props
    const dispatch = useDispatch()
    const [t] = useTranslation()
    const entry = get(initialValues, 'project')

    return (
        <FormContainer
            header={
                <>
                    <ProjectIcon />
                    <ProjectTitle entry={ entry } />
                </>
            }
            submitText={ t(ui.getTitleForPayload(entry, api.Resource.project)) }
            onCancel={ () => dispatch(routing.goBack()) }
            isCentered
            { ...props }
        >
            <Field
                name="project.displayName"
                label={ t(projects.FieldName.displayName) }
                component={ InputField }
                autoFocus
            />
            <Field
                name="project.bootstrapMysqlClusterResources.desiredStorageSizePerPod.requested"
                component={ MySQLClusterStorageSizeField }
            />
            <Field
                name="project.bootstrapMysqlClusterResources.storageClass"
                component={ MySQLClusterStorageClassField }
            />
        </FormContainer>
    )
}

export default withForm({ name: forms.Name.createProject })(ProjectCreateForm)
