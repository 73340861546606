import * as React from 'react'
import { Intent } from '@blueprintjs/core'

import { map, get, pick, compact, isEmpty } from 'lodash'

import { forms } from '../redux'
import { SelectableItem, SelectableItemParser, parseSelectableItem } from '../utils'

import FormGroup from '../components/FormGroup'
import ButtonGroup from '../components/ButtonGroup'
import Button from '../components/Button'

import styles from './ButtonGroupSelectField.module.scss'

export type Props = forms.FieldProps & {
    items: any[]
    itemParser?: SelectableItemParser
}

const ButtonGroupSelectField: React.FC<Props> = (props) => {
    const { field, form, label } = props
    const { name } = field

    const errors = forms.getFieldErrors(form.errors, name)
    const intent = props.intent || !isEmpty(errors) ? Intent.DANGER : Intent.NONE
    const itemParser = props.itemParser || parseSelectableItem
    const selectedItem = itemParser(field.value)
    const items = compact(map(get(props, 'items', []), itemParser))

    return (
        <FormGroup
            errors={ forms.formatFieldErrors(label, errors) }
            label={ label }
            { ...field }
            { ...pick(props, ['helperText', 'inline', 'isMonospaced']) }
            intent={ intent }
        >
            <ButtonGroup className={ styles.group }>
                { map(items, (item: SelectableItem) => (
                    <Button
                        key={ item.value }
                        onClick={ () => form.setFieldValue(name, item.value) }
                        active={ selectedItem && selectedItem.value === item.value }
                        text={ item.label || item.value }
                        isBordered
                    />
                )) }
            </ButtonGroup>
        </FormGroup>
    )
}

export default ButtonGroupSelectField
