import * as React from 'react'
import { Tag, Intent } from '@blueprintjs/core'

import styles from './Bullet.module.scss'

type Props = {
    intent?: Intent
    onClick?: () => void
}

const Bullet: React.FC<Props> = ({ onClick, intent }) => (
    <Tag
        className={ styles.bullet }
        intent={ intent }
        onClick={ onClick }
        interactive={ !!onClick }
        round
    />
)

export default Bullet
