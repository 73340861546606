import * as React from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import PageMeta from '../components/PageMeta'
import MainMenu from '../components/MainMenu'
import MainContainer from '../components/MainContainer'
import OrganizationCreateForm from '../components/OrganizationCreateForm'
import OrganizationGeneralForm from '../components/OrganizationGeneralForm'
import OrganizationOverview from '../components/OrganizationOverview'
import OrganizationDetails from '../components/OrganizationDetails'
import AccessManagement from '../components/AccessManagement'
import ErrorMessage from '../components/ErrorMessage'

import { RootState, api, routing, organizations, navigation, errors } from '../redux'
import { Maybe } from '../utils'

type ReduxProps = {
    organization: Maybe<organizations.IOrganization>
    activeMenuEntry: Maybe<navigation.MenuEntry>
}

type Props = ReduxProps

const OrganizationsContainer: React.FC<Props> = (props) => {
    const { organization, activeMenuEntry } = props

    if (!organization) {
        return (
            <MainContainer>
                <Switch>
                    <Route
                        path={ routing.routeFor(routing.Key.organizations, { slug: '_', action: 'new' }) }
                        render={ () => <OrganizationCreateForm initialValues={ { organization: {} } } /> }
                    />
                    <Route
                        render={ () => <ErrorMessage error={ errors.Error.notFound } /> }
                    />
                </Switch>
            </MainContainer>
        )
    }

    return (
        <>
            <PageMeta
                entry={ organization }
                section={ activeMenuEntry }
            />
            <MainMenu
                resource={ api.Resource.organization }
                entry={ organization }
            />
            <MainContainer>
                <Switch>
                    <Route
                        path={ routing.routeForResource(organization, { action: navigation.MenuEntry.general }) }
                        render={ () => <OrganizationGeneralForm initialValues={ { organization } } /> }
                    />
                    <Route
                        path={ routing.routeForResource(organization, { action: navigation.MenuEntry.access }) }
                        render={ () => <AccessManagement entry={ organization } /> }
                    />
                    <Route
                        path={ routing.routeForResource(organization, { action: navigation.MenuEntry.projects }) }
                        render={ () => <OrganizationDetails entry={ organization } /> }
                    />
                    <Route
                        path={ routing.routeForResource(organization) }
                        render={ () => <OrganizationOverview entry={ organization } /> }
                    />
                    <Route
                        render={ () => <ErrorMessage error={ errors.Error.notFound } /> }
                    />
                </Switch>
            </MainContainer>
        </>
    )
}

function mapStateToProps(state: RootState): ReduxProps {
    const organization = organizations.getForCurrentURL(state)
    const activeMenuEntry = navigation.getActiveMenuEntry(state)

    return {
        organization,
        activeMenuEntry
    }
}

export default connect(mapStateToProps)(OrganizationsContainer)
