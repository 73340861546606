import * as React from 'react'
import { connect } from 'react-redux'

import { Menu } from '@blueprintjs/core'

import { RootState, routing, navigation, ui } from '../redux'
import { useTranslation } from '../hooks'
import { Maybe } from '../utils'

import Icon from '../components/Icon'
import MenuItem from '../components/MenuItem'
import SideBar from '../components/SideBar'

type Props = {
    activeTab: Maybe<navigation.TabEntry>
}

const SystemSettingsMenu: React.FC<Props> = (props) => (
    <SideBar>
        <Menu>
            <MenuEntry { ...props } entry={ navigation.TabEntry.authentication } />
            <MenuEntry { ...props } entry={ navigation.TabEntry.certificates } />
            <MenuEntry { ...props } entry={ navigation.TabEntry.configConnector } />
            <MenuEntry { ...props } entry={ navigation.TabEntry.adminUsers } />
        </Menu>
    </SideBar>
)

type MenuEntryProps = {
    entry: navigation.TabEntry
    activeTab: Maybe<navigation.TabEntry>
}

const MenuEntry: React.FC<MenuEntryProps> = (props) => {
    const { activeTab, entry } = props
    const [t] = useTranslation()

    return (
        <MenuItem
            text={ t(ui.createTitle(entry)) }
            active={ activeTab === entry }
            href={
                routing.routeFor(routing.Key.system, {
                    action : navigation.MenuEntry.settings,
                    tab    : entry
                })
            }
            icon={ <Icon name={ ui.getIconNameForAction(entry) } /> }
        />
    )
}

function mapStateToProps(state: RootState) {
    const activeTab = routing.getParam('tab')(state) as Maybe<navigation.TabEntry>
    return {
        activeTab
    }
}

export default connect(mapStateToProps)(SystemSettingsMenu)
