import * as React from 'react'
import { Tooltip } from '@blueprintjs/core'

import { sites } from '../redux'
import { useTranslation } from '../hooks'

import Card from '../components/Card'
import Button from '../components/Button'
import TitleBar from '../components/TitleBar'
import CopyToClipboardLabel from '../components/CopyToClipboardLabel'

type Props = {
    entry: sites.ISite | null
}

const GoogleCloudCard: React.FC<Props> = ({ entry }) => {
    const [t] = useTranslation()

    if (!entry) {
        return null
    }

    return (
        <Card
            title={ t('Google Cloud') }
            subtitle={ t('This section contains a short overview of your Google Cloud account information') }
            rightItem={ (
                <Tooltip content={ t('Open Google Cloud Console') }>
                    <Button
                        icon="share-alt"
                        onClick={ () => window.open(sites.storageURL(entry, { cannonical: false }), '_blank') }
                        minimal
                        isSquare
                    />
                </Tooltip>
            ) }
            isBorderedTop
            isBorderedLeft
            isBorderedBottom
        >
            <TitleBar
                title={ t(sites.FieldName.googleProjectId) }
                subtitle={ <CopyToClipboardLabel text={ entry.googleProjectId } /> }
                isSmall
            />
            <TitleBar
                title={ t(sites.FieldName.serviceAccount) }
                subtitle={ <CopyToClipboardLabel text={ entry.serviceAccount } /> }
                isSmall
            />
            <TitleBar
                title={ t(sites.FieldName.storageBucket) }
                subtitle={ <CopyToClipboardLabel text={ sites.storageURL(entry) } /> }
                isSmall
            />
        </Card>
    )
}

export default GoogleCloudCard
