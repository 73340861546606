import * as React from 'react'
import { Button as DefaultButton, AnchorButton, IButtonProps, ILinkProps } from '@blueprintjs/core'
import classes from 'classnames'

import { omit, isString } from 'lodash'

import { routing } from '../redux'
import { Maybe, Modify } from '../utils'
import Icon, { IconName } from '../components/Icon'

import styles from './Button.module.scss'

export type Props = Modify<IButtonProps & IButtonProps, {
    icon?: Maybe<IconName | JSX.Element>
    href?: string
    target?: ILinkProps['target']
    isSquare?: boolean
    isRound?: boolean
    isBordered?: boolean
    className?: string
}>

const Button: React.FC<Props> = (props) => {
    const { isSquare, isRound, isBordered, className, children, href, ...otherProps } = props

    const text = (!isSquare && !isRound) || !props.icon ? props.text : null
    const icon = <ButtonIcon { ...props } />
    const classNames = classes(
        styles.button,
        isSquare && styles.square,
        isRound && styles.round,
        isBordered && styles.bordered,
        props.active && styles.active,
        className
    )

    if (href) {
        return (
            <AnchorButton
                { ...omit(otherProps, 'elementRef') }
                href={ routing.toFragment(href) }
                text={ text }
                icon={ icon }
                className={ classNames }
            >
                { children }
            </AnchorButton>
        )
    }

    return (
        <DefaultButton
            { ...otherProps }
            text={ text }
            icon={ icon }
            className={ classNames }
        >
            { children }
        </DefaultButton>
    )
}

const ButtonIcon: React.FC<Props> = (props) => {
    const { icon } = props
    if (!icon) {
        return null
    }

    if (isString(icon)) {
        return <Icon name={ icon } />
    }

    if (React.isValidElement(icon)) {
        return icon
    }

    return null
}

export default Button
