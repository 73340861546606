/* eslint-disable no-template-curly-in-string */
import * as React from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { Flex, Box } from 'reflexbox'

import { get } from 'lodash'

import { RootState, routing, sites, navigation } from '../redux'
import { useTranslation } from '../hooks'

import Card from '../components/Card'
import Button from '../components/Button'
import CopyToClipboardInput from '../components/CopyToClipboardInput'
import SiteCommandsMenu from '../components/SiteCommandsMenu'

type OwnProps = {
    entry: sites.ISite | null
}

type ReduxProps = {
    article: sites.CommandsArticle
}

type Props = OwnProps & ReduxProps

const SiteCommands: React.FC<Props> = (props) => {
    const { entry, article } = props
    const [t] = useTranslation()

    if (!entry) {
        return null
    }

    return (
        <Flex>
            <Box w={ 2 / 3 }>
                <Card
                    title={ t(sites.CommandsArticleTitle[article]) }
                    subtitle={ t(sites.CommandsArticleDescription[article]) }
                    isBorderedBottom
                >
                    <Switch>
                        <Route
                            path={ routing.routeForResource(entry, {
                                action : navigation.MenuEntry.commands,
                                tab    : sites.CommandsArticle.wpCli
                            }) }
                            render={ () => (
                                <>
                                    <Box>
                                        <h4>{ t('Execute WP-CLI') }</h4>
                                        <p>
                                            { t('Use kubectl to run WP-CLI') }
                                        </p>
                                        <CopyToClipboardInput
                                            value={ sites.cli(sites.CLICommand.execWpCli, entry) }
                                            isMonospaced
                                        />
                                    </Box>
                                </>
                            ) }
                        />
                        <Route
                            path={ routing.routeForResource(entry, {
                                action : navigation.MenuEntry.commands,
                                tab    : sites.CommandsArticle.mediaFiles
                            }) }
                            render={ () => (
                                <>
                                    <Box mb={ 3 }>
                                        <h4>{ t('Using Google Cloud Console') }</h4>
                                        <p>
                                            { t('Launch in a new tab the built-in Google Storage Browser') }
                                        </p>
                                        <Button
                                            text={ t('Open the storage browser') }
                                            onClick={ () => (
                                                window.open(
                                                    sites.storageURL(entry, { cannonical: false }),
                                                    '_blank'
                                                )
                                            ) }
                                        />
                                    </Box>
                                    <Box>
                                        <h4>{ t('Using the CLI') }</h4>
                                        <p>
                                            { t('This requires installing the') }
                                            &nbsp;
                                            <a
                                                href="https://cloud.google.com/sdk/docs/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Google Cloud SDK
                                            </a>.
                                        </p>
                                        <CopyToClipboardInput
                                            value={ sites.cli(sites.CLICommand.gsutil, entry) }
                                            isMonospaced
                                        />
                                    </Box>
                                </>
                            ) }
                        />
                        <Route
                            path={ routing.routeForResource(entry, {
                                action : navigation.MenuEntry.commands,
                                tab    : sites.CommandsArticle.code
                            }) }
                            render={ () => (
                                <>
                                    <Box>
                                        <h4>{ t('Development guidelines') }</h4>
                                        <p>
                                            { t('Sites running on Bitpoke App should follow the guidelines '
                                                + 'described in the ') }
                                            <a
                                                href="https://www.bitpoke.io/docs/stack/how-to/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Bitpoke Stack documentation
                                            </a>.
                                        </p>
                                    </Box>
                                    <Box>
                                        <h4>{ t('Update the WordPress image') }</h4>
                                        <p>
                                            { t('In the following command replace DOCKER_IMAGE with your '
                                                + 'desired docker image') }
                                        </p>
                                        <CopyToClipboardInput
                                            value={ sites.cli(sites.CLICommand.updateImage, entry) }
                                            isMonospaced
                                        />
                                    </Box>
                                </>
                            ) }
                        />
                        <Route
                            path={ routing.routeForResource(entry, { action: navigation.MenuEntry.commands }) }
                            render={ () => (
                                <>
                                    <Box>
                                        <h4>1. { t('Forward the MySQL port to your localhost') }</h4>
                                        <p>
                                            { t('First, you set up port forwarding in order to be able '
                                                + 'to connect from your machine or ') }
                                            <a
                                                href={ sites.kubernetesConsoleURL(entry) }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Google Cloud Shell
                                            </a>.
                                        </p>
                                        <CopyToClipboardInput
                                            value={ sites.cli(sites.CLICommand.mysqlPortForward, entry) }
                                            isMonospaced
                                        />
                                    </Box>
                                    <Box>
                                        <h4>2. { t('Get the MySQL credentials') }</h4>
                                        <p>
                                            { t('Open a new tab in your terminal, then get the username, password '
                                                + 'and database name and export them as environment variables.') }
                                        </p>
                                        <CopyToClipboardInput
                                            value={ sites.cli(sites.CLICommand.exportSecretValue, entry, {
                                                kind : 'mysql',
                                                data : 'username'
                                            }) }
                                            isMonospaced
                                        />
                                        <CopyToClipboardInput
                                            value={ sites.cli(sites.CLICommand.exportSecretValue, entry, {
                                                kind : 'mysql',
                                                data : 'password'
                                            }) }
                                            isMonospaced
                                        />
                                        <CopyToClipboardInput
                                            value={ sites.cli(sites.CLICommand.exportSecretValue, entry, {
                                                kind : 'mysql',
                                                data : 'database'
                                            }) }
                                            isMonospaced
                                        />
                                    </Box>
                                    <Box>
                                        <h4>3. { t('Connect to the MySQL instance') }</h4>
                                        <p>
                                            { t('Finally, connect using the mysql command-line, without '
                                                + 'the need to type the password, used by default from '
                                                + 'the environment variable.') }
                                        </p>
                                        <CopyToClipboardInput
                                            value="mysql -u ${MYSQL_USER} -h 127.0.0.1 -P 3307 ${MYSQL_DATABASE}"
                                            isMonospaced
                                        />
                                    </Box>
                                </>
                            ) }
                        />
                    </Switch>
                </Card>
            </Box>
            <Box w={ 1 / 3 }>
                <SiteCommandsMenu entry={ entry } />
            </Box>
        </Flex>
    )
}

function mapStateToProps(state: RootState) {
    const params = routing.getParams(state)
    const article = get(
        sites.CommandsArticle,
        get(params, 'tab') as sites.CommandsArticle,
        sites.CommandsArticle.database
    )

    return {
        article
    }
}

export default connect(mapStateToProps)(SiteCommands)
