import * as React from 'react'
import { connect } from 'react-redux'

import { RootState, grpc, api, auth, organizations } from '../redux'
import { useTranslation } from '../hooks'
import { Maybe } from '../utils'

import MainMenu from '../components/MainMenu'
import MainContainer from '../components/MainContainer'
import OrganizationOverview from '../components/OrganizationOverview'
import OrganizationCreateForm from '../components/OrganizationCreateForm'
import BlankSlate from '../components/BlankSlate'
import Icon from '../components/Icon'

type ReduxProps = {
    currentOrganization: Maybe<organizations.IOrganization>
    isLoading: boolean
    isFailed: boolean
    isAdmin: boolean
}

type Props = ReduxProps

const DashboardContainer: React.FC<Props> = (props) => {
    const { currentOrganization, isLoading, isFailed, isAdmin } = props
    const [t] = useTranslation()

    if (!currentOrganization) {
        if (isLoading || isFailed) {
            return <MainContainer />
        }

        if (isAdmin) {
            return (
                <MainContainer>
                    <OrganizationCreateForm initialValues={ { organization: {} } } />
                </MainContainer>
            )
        }

        return (
            <MainContainer>
                <BlankSlate
                    icon={ <Icon name="office" size={ 64 } /> }
                    title={ t('You are not part of any organizations') }
                    description={ t('Please contact one of your cluster administrators '
                        + 'in order to receive access to one of the existing organizations') }
                />
            </MainContainer>
        )
    }

    return (
        <>
            <MainMenu
                resource={ api.Resource.organization }
                entry={ currentOrganization }
            />
            <MainContainer>
                <OrganizationOverview entry={ currentOrganization } />
            </MainContainer>
        </>
    )
}

function mapStateToProps(state: RootState): ReduxProps {
    const currentOrganization = organizations.getCurrent(state)
    const organizationsRequest = organizations.list()
    const isLoading = grpc.isLoadingRequest(organizationsRequest.payload)(state)
    const isFailed = grpc.isFailedRequest(organizationsRequest.payload)(state)
    const isAdmin = auth.isAdmin(state)

    return {
        currentOrganization,
        isLoading,
        isFailed,
        isAdmin
    }
}

export default connect(mapStateToProps)(DashboardContainer)
