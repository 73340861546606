import * as React from 'react'
import { Flex, Box } from 'reflexbox'

import { Maybe } from '../utils'

import styles from './ActionLabel.module.scss'

type Props = {
    text: Maybe<string | React.ReactNode>
    action?: React.ReactNode
}

const ActionLabel: React.FC<Props> = (props) => {
    const { text, action } = props

    return (
        <div className={ styles.container }>
            <Flex align="center">
                <span className={ styles.text }>{ text }</span>
                { action && <Box align="flex-end"><div className={ styles.action }>{ action }</div></Box> }
            </Flex>
        </div>
    )
}

export default ActionLabel
