import * as React from 'react'
import { Tag, Intent } from '@blueprintjs/core'

import { startCase } from 'lodash'

import { grpc, events } from '../redux'
import { POLL_INTERVAL, keyOfEnum, formatDistance } from '../utils'
import { useTranslation, useState } from '../hooks'

import TitleBar from '../components/TitleBar'
import Button from '../components/Button'
import Container from '../components/Container'
import Table, {
    TableColumn, TableCellProps, CellGetterProps, TableCell, getCellData
} from '../components/Table'

import styles from './EventsList.module.scss'

type OwnProps = {
    source: events.SourceName
    itemsRequest: grpc.RequestAction
}

type Props = OwnProps

const KIND_INTENT = {
    [events.Kind.NORMAL]      : Intent.NONE,
    [events.Kind.UNSPECIFIED] : Intent.NONE,
    [events.Kind.WARNING]     : Intent.WARNING,
    [events.Kind.ERROR]       : Intent.DANGER
}

const EventsList: React.FC<Props> = (props) => {
    const { source, itemsRequest } = props
    const [t] = useTranslation()
    const [isPolling, setPolling] = useState(true)

    if (!source) {
        return null
    }

    const columns: TableColumn[] = [
        {
            label        : t('Status'),
            dataKey      : 'kind',
            width        : 120,
            cellRenderer : (props: TableCellProps) => (
                <TableCell align="flex-start">
                    <Tag
                        intent={ KIND_INTENT[props.cellData as events.Kind] }
                        className={ styles.kindTag }
                        minimal
                    >
                        { startCase(keyOfEnum(events.Kind, props.cellData) as string) }
                    </Tag>
                </TableCell>
            )
        },
        {
            label   : t('Event'),
            dataKey : 'reason'
        },
        {
            label          : t('Age'),
            dataKey        : 'lastTimestamp',
            cellDataGetter : (props: CellGetterProps) => (
                getCellData(props) ? formatDistance(getCellData(props)) : null
            )
        },
        {
            label   : t('Message'),
            dataKey : 'message'
        },
        {
            label   : t('Source'),
            dataKey : 'source'
        }
    ]

    return (
        <Container>
            <TitleBar
                title={ t('Events') }
                rightItem={ (
                    <Button
                        text={ isPolling ? t('Auto-refresh enabled') : t('Auto-refresh disabled') }
                        icon={ isPolling ? 'recycle-cw' : 'circle' }
                        onClick={ () => setPolling(!isPolling) }
                        minimal
                    />
                ) }
            />
            <Table
                className={ styles.list }
                itemsRequest={ itemsRequest }
                itemsSelector={ events.getForSource(source) }
                pollInterval={ isPolling ? POLL_INTERVAL.slow : undefined }
                emptyRenderer={ () => t('No recent events.') }
                columns={ columns }
                sortBy="lastTimestamp"
            />
        </Container>
    )
}

export default EventsList
