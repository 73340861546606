/* eslint-disable no-use-before-define */
import { ActionType, action as createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'
import { takeEvery, select, put } from 'redux-saga/effects'
import URI from 'urijs'
import { has } from 'lodash'
import { RootState, grpc, routing, api, auth, organizations } from '../redux'
import { Maybe } from '../utils'
import * as i18n from '../i18n'

export const BASE_URL = has(global, 'window') ? new URI()
    .protocol(window.location.protocol)
    .hostname(window.location.host)
    .pathname('/')
    .fragment('')
    .toString() : 'http://localhost:3000'

//
//  TYPES

export type State = {
    isInitialized: boolean
    language: i18n.Language
    context: Maybe<api.ResourceName>
}
export type Actions = ActionType<typeof actions>


//
//  ACTIONS

export const INITIALIZED      = '@ app / INITIALIZED'
export const LANGUAGE_UPDATED = '@ app / LANGUAGE_UPDATED'
export const CONTEXT_SAVED    = '@ app / CONTEXT_SAVED'

export const initialize = () => createAction(INITIALIZED, Date.now())
export const setLanguage = (language: i18n.Language) => createAction(LANGUAGE_UPDATED, language)
export const saveContext = (context: api.ResourceName) => createAction(CONTEXT_SAVED, context)

const actions = {
    initialize, setLanguage, saveContext
}


//
//  REDUCER

const initialState: State = {
    isInitialized : false,
    language      : i18n.DEFAULT_LANGUAGE,
    context       : null
}

export function reducer(
    state: State = initialState,
    action: Actions | ActionType<typeof organizations.select>
) {
    switch (action.type) {
        case INITIALIZED:
            return {
                ...state,
                isInitialized: true
            }

        case LANGUAGE_UPDATED:
            return {
                ...state,
                language: action.payload
            }

        case CONTEXT_SAVED: {
            return {
                ...state,
                context: action.payload
            }
        }

        case organizations.SELECTED: {
            return {
                ...state,
                context: null
            }
        }

        default:
            return state
    }
}

//
//  SAGA

export function* saga() {
    yield takeEvery([INITIALIZED, LANGUAGE_UPDATED], updateI18n)
    yield takeEvery([INITIALIZED, LANGUAGE_UPDATED], setLanguageHeaders)
    yield takeEvery(routing.ROUTE_CHANGED, saveRecentContext)
    yield takeEvery(auth.ACCESS_GRANTED, redirectToRecentContext)
}

function* updateI18n() {
    const language: i18n.Language = yield select(getLanguage)
    i18n.i18n.changeLanguage(language)
}

function* setLanguageHeaders() {
    const language: i18n.Language = yield select(getLanguage)
    yield put(grpc.setMetadata({ key: 'language', value: language }))
}

function* saveRecentContext(action: ActionType<typeof routing.updateRoute>) {
    const { pathname } = action.payload
    const parsed = api.parseName(pathname, api.Resource.site)

    if (!parsed.name || parsed.slug === '_') {
        return
    }

    const currentContext: Maybe<api.ResourceName> = yield select(getRecentContext)
    if (currentContext !== parsed.name) {
        yield put(saveContext(parsed.name))
    }
}

function* redirectToRecentContext() {
    const route: routing.Route = yield select(routing.getCurrentRoute)
    if (route.url !== routing.ROUTE_MAP[routing.Key.dashboard].path) {
        return
    }

    const context: Maybe<api.ResourceName> = yield select(getRecentContext)

    if (!context) {
        return
    }

    yield put(routing.push(routing.routeForResource({ name: context })))
}

//
//  SELECTORS

export const getState = (state: RootState): State => state.app
export const getLanguage = createSelector(
    getState,
    (state) => state.language
)
export const getRecentContext = createSelector(
    getState,
    (state) => state.context
)
export const isInitialized = createSelector(
    getState,
    (state) => state.isInitialized
)
