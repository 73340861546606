import { RPCImpl } from 'protobufjs'
import axios from 'axios'
import { google } from '@bitpoke/bitpoke-proto'

import { map, find, get, times, compact, size, parseInt, upperFirst, has } from 'lodash'

import { ChunkParser, ChunkType } from '../grpc/parser'
import config from '../../config'

type AnyProtoType = {
    type_url: string // eslint-disable-line camelcase
    value: Uint8Array
}

export class RequestError extends Error {
    public status: google.rpc.IStatus

    public constructor(status: google.rpc.IStatus) {
        super(status.message || 'Request failed')
        this.name = 'RequestError'
        this.status = status
    }
}

export enum StatusCode {
    OK = 0,
    Canceled = 1,
    Unknown = 2,
    InvalidArgument = 3,
    DeadlineExceeded = 4,
    NotFound = 5,
    AlreadyExists = 6,
    PermissionDenied = 7,
    ResourceExhausted = 8,
    FailedPrecondition = 9,
    Aborted = 10,
    OutOfRange = 11,
    Unimplemented = 12,
    Internal = 13,
    Unavailable = 14,
    DataLoss = 15,
    Unauthenticated = 16
}

enum HeaderName {
    status = 'grpc-status',
    message = 'grpc-message',
    statusDetails = 'grpc-status-details-bin'
}

const baseURL: string = config.REACT_APP_API_URL
const timeout: number = config.REACT_APP_API_DEFAULT_TIMEOUT

const StatusDetailsTypes = {
    'type.googleapis.com/google.rpc.BadRequest': google.rpc.BadRequest
}

const transport = axios.create({
    baseURL,
    timeout,
    headers: {
        'content-type' : 'application/grpc-web+proto',
        'x-grpc-web'   : '1'
    }
})

export function createTransport(serviceName: string): RPCImpl {
    return async (method, requestData, callback) => {
        try {
            const response = await transport.request({
                url          : `${serviceName}/${upperFirst(method.name)}`,
                method       : 'POST',
                data         : frameRequest(requestData),
                responseType : 'arraybuffer'
            })

            const buffer = await response.data

            const statusCode = parseInt(get(response.headers, HeaderName.status, 0), 0)
            const message = get(response.headers, HeaderName.message)

            if (response.status !== 200) {
                const error = new Error('Request failed')
                callback(error, null)
            }

            if (statusCode !== StatusCode.OK) {
                if (has(response.headers, HeaderName.statusDetails)) {
                    const header = get(response.headers, HeaderName.statusDetails)
                    const status = decodeStatusDetails(header)
                    const error = new RequestError(status)
                    callback(error, null)
                }
                else {
                    const error = new RequestError({
                        code: statusCode,
                        message
                    })
                    callback(error, null)
                }
            }
            else {
                callback(null, parseData(buffer))
            }
        }
        catch (error) {
            callback(error, null)
        }
    }
}

export function parseData(buffer: ArrayBuffer): Uint8Array {
    const chunk = parseChunk(buffer)
    return new Uint8Array(get(chunk, 'data', []))
}

export function setMetadataHeader(key: string, value: string): void {
    transport.defaults.headers.common[key] = value
}

function parseChunk(buffer: ArrayBuffer) {
    if (buffer.byteLength === 0) {
        return null
    }
    const chunks = new ChunkParser().parse(new Uint8Array(buffer))
    return find(chunks, { chunkType: ChunkType.MESSAGE })
}

function frameRequest(bytes: Uint8Array) {
    const frame = new ArrayBuffer(bytes.byteLength + 5)
    new DataView(frame, 1, 4).setUint32(0, bytes.length, false)
    new Uint8Array(frame, 5).set(bytes)
    return new Uint8Array(frame)
}

function stringToUint8Array(input: string): Uint8Array {
    const length = size(input)
    const buffer = new ArrayBuffer(length)
    const bufferView = new Uint8Array(buffer)
    times(length, (i) => {
        bufferView[i] = input.charCodeAt(i)
    })

    return bufferView
}

function decodeStatusDetails(header: string): google.rpc.IStatus {
    const binaryStatus = stringToUint8Array(atob(header))
    const decodedStatus = google.rpc.Status.decode(binaryStatus)

    const details = compact(map(decodedStatus.details as unknown as AnyProtoType[], (detail) => {
        const errorType = StatusDetailsTypes[detail.type_url]
        return errorType
            ? errorType.decode(detail.value)
            : null
    }))

    const status: google.rpc.IStatus = {
        ...decodedStatus,
        details
    }

    return status
}
