import * as React from 'react'
import { Flex, Box } from 'reflexbox'

import { map, isEmpty } from 'lodash'

import { conditions } from '../redux'
import { arePropsEqual } from '../utils'

import Bullet from '../components/Bullet'

type OwnProps = {
    items: conditions.ICondition[]
}

type Props = OwnProps

const ConditionsList: React.FC<Props> = React.memo((props) => {
    const { items } = props

    if (isEmpty(items)) {
        return null
    }

    return (
        <Flex column>
            { map(items, (condition) => (
                <Flex key={ condition.name }>
                    <Box mr={ 1 }><Bullet intent={ conditions.severityIntent(condition.severity) } /></Box>
                    <Box><span>{ condition.message }</span></Box>
                </Flex>
            )) }
        </Flex>
    )
}, arePropsEqual('items'))

export default ConditionsList
