import * as React from 'react'
import { isString } from 'lodash'
import classes from 'classnames'

import { api, ui } from '../redux'
import { Maybe, initials } from '../utils'

import Icon, { IconName } from '../components/Icon'

import styles from './Avatar.module.scss'

type Props = {
    entry?: Maybe<api.AnyResourceInstance>
    name?: Maybe<string>
    icon?: React.ReactNode | IconName
    imageURL?: Maybe<string>
    isRound?: boolean
    isLight?: boolean
    size?: number
}

const Avatar: React.FC<Props> = (props) => {
    const { entry, imageURL, icon, children } = props

    const name = props.name
        ? props.name
        : entry ? api.displayName(entry) : null

    if (imageURL) {
        return (
            <Container { ...props }>
                <img
                    className={ styles.avatar }
                    src={ imageURL }
                    alt=""
                />
            </Container>
        )
    }

    if (icon) {
        if (isString(icon)) {
            return (
                <Container { ...props }>
                    <Icon name={ icon as IconName } />
                </Container>
            )
        }

        return (
            <Container { ...props }>
                { icon }
                { children }
            </Container>
        )
    }

    if (ui.iconName(entry)) {
        return (
            <Container { ...props }>
                <Icon name={ ui.iconName(entry) } />
            </Container>
        )
    }

    if (name) {
        return (
            <Container { ...props }>
                <span className={ styles.initials }>{ initials(name) }</span>
            </Container>
        )
    }

    return null
}

const Container: React.FC<Props> = (props) => {
    const { size, isRound, isLight, children } = props
    return (
        <div
            className={ classes(styles.container, isLight && styles.light) }
            style={ {
                width        : `${size}px`,
                height       : `${size}px`,
                borderRadius : isRound ? `${size as number / 2}px` : '5px'
            } }
        >
            { children }
        </div>
    )
}

Avatar.defaultProps = {
    isRound : false,
    size    : 40,
    isLight : true
}

export default Avatar
