import { bitpoke } from '@bitpoke/bitpoke-proto'

const {
    ComponentStatus
} = bitpoke.statuses.v1

const {
    GeneralStatus
} = ComponentStatus

export {
    ComponentStatus,
    GeneralStatus
}

export type IComponentStatus = bitpoke.statuses.v1.IComponentStatus
export type ComponentStatus  = bitpoke.statuses.v1.ComponentStatus
export type GeneralStatus    = bitpoke.statuses.v1.ComponentStatus.GeneralStatus
