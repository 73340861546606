import * as React from 'react'
import { connect } from 'react-redux'
import { Field } from 'formik'

import { join, startCase } from 'lodash'

import { RootState, system, forms } from '../redux'
import { useTranslation } from '../hooks'
import { Maybe } from '../utils'

import SelectField from '../components/SelectField'

type ReduxProps = {
    items: system.IComponent[]
}
type Props = forms.FieldProps<system.IComponent> & ReduxProps

const SchedulableComponentSelectField: React.FC<Props> = (props) => {
    const { field, items } = props
    const { value } = field
    const [t] = useTranslation()

    return (
        <Field
            { ...props }
            items={ items }
            component={ SelectField }
            selectedItem={ value }
            itemParser={ (item: Maybe<system.IComponent>) => (item ? {
                value : item,
                key   : system.componentId(item),
                label : join([
                    item.application && startCase(item.application),
                    system.componentDisplayName(item)
                ], ': ')
            } : null) }
            placeholder={ t('Select component...') }
        />
    )
}

function mapStateToProps(state: RootState): ReduxProps {
    return {
        items: system.getSchedulableComponents(state)
    }
}

export default connect(mapStateToProps)(SchedulableComponentSelectField)
