import * as React from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { Flex, Box } from 'reflexbox'

import { pick } from 'lodash'

import {
    RootState, DispatchProp, routing, mysqlClusters,
    projects, organizations, api, navigation, errors
} from '../redux'
import { Maybe } from '../utils'

import PageMeta from '../components/PageMeta'
import MainContainer from '../components/MainContainer'
import MainMenu from '../components/MainMenu'
import ErrorMessage from '../components/ErrorMessage'
import Container from '../components/Container'
import MySQLClusterBackupsList from '../components/MySQLClusterBackupsList'
import MySQLClusterBackupGeneralForm from '../components/MySQLClusterBackupGeneralForm'
import MySQLClusterResourcesForm from '../components/MySQLClusterResourcesForm'
import MySQLClusterSchedulingForm from '../components/MySQLClusterSchedulingForm'
import MySQLClusterSchedulingStatus from '../components/MySQLClusterSchedulingStatus'
import ResourcePods from '../components/ResourcePods'

type ReduxProps = {
    mysqlCluster: Maybe<mysqlClusters.IMySQLCluster>
    project: Maybe<projects.IProject>
    activeMenuEntry: Maybe<navigation.MenuEntry>
    organization: Maybe<organizations.OrganizationName>
    isNotFound: boolean
}

type Props = ReduxProps & DispatchProp

const MysqlClustersContainer: React.FC<Props> = (props) => {
    const { mysqlCluster, project, activeMenuEntry, organization, isNotFound } = props

    if (isNotFound && !mysqlCluster) {
        return (
            <MainContainer>
                <ErrorMessage error={ errors.Error.notFound } />
            </MainContainer>
        )
    }

    if (!project || !organization || !mysqlCluster) {
        return <MainContainer />
    }

    return (
        <>
            <PageMeta
                entry={ mysqlCluster }
                section={ activeMenuEntry }
            />
            <MainMenu
                resource={ api.Resource.mysqlCluster }
                entry={ mysqlCluster }
            />
            <MainContainer>
                <Switch>
                    <Route
                        key={ navigation.MenuEntry.backups }
                        path={ routing.routeForResource(mysqlCluster, { action: navigation.MenuEntry.backups }) }
                        render={ () => (
                            <>
                                <Box>
                                    <MySQLClusterBackupGeneralForm initialValues={ { mysqlCluster } } />
                                </Box>
                                <Box>
                                    <MySQLClusterBackupsList project={ project.name } />
                                </Box>
                            </>
                        ) }
                    />
                    <Route
                        key={ navigation.MenuEntry.scheduling }
                        path={ routing.routeForResource(mysqlCluster, { action: navigation.MenuEntry.scheduling }) }
                        render={ () => (
                            <Container>
                                <Flex column>
                                    <Box>
                                        <MySQLClusterSchedulingForm
                                            initialValues={ { mysqlCluster } }
                                        />
                                    </Box>
                                    <Box mt={ 50 }>
                                        <MySQLClusterSchedulingStatus
                                            entry={ mysqlCluster }
                                        />
                                    </Box>
                                </Flex>
                            </Container>
                        ) }
                    />
                    <Route
                        key={ navigation.MenuEntry.resources }
                        path={ routing.routeForResource(mysqlCluster, { action: navigation.MenuEntry.resources }) }
                        render={ () => (
                            <Container>
                                <MySQLClusterResourcesForm
                                    initialValues={ pick(mysqlCluster, ['name', 'resources']) }
                                />
                            </Container>
                        ) }
                    />
                    <Route
                        key={ navigation.MenuEntry.pods }
                        path={ routing.routeForResource(mysqlCluster, { action: navigation.MenuEntry.pods }) }
                        render={ () => (
                            <ResourcePods
                                entry={ mysqlCluster }
                            />
                        ) }
                    />
                    <Route
                        path={ routing.routeForResource(mysqlCluster) }
                        render={ () => (
                            <div />
                        ) }
                    />
                </Switch>
            </MainContainer>
        </>
    )
}

function mapStateToProps(state: RootState): ReduxProps {
    const project = projects.getForCurrentURL(state)
    const mysqlCluster = mysqlClusters.getForCurrentURL(state)
    const isNotFound = mysqlClusters.isNotFound(state)
    const activeMenuEntry = navigation.getActiveMenuEntry(state)
    const organization = organizations.getCurrentName(state)

    return {
        project,
        mysqlCluster,
        activeMenuEntry,
        organization,
        isNotFound
    }
}

export default connect(mapStateToProps)(MysqlClustersContainer)
