import * as React from 'react'

import { projects, ui, navigation, api } from '../redux'
import { useTranslation } from '../hooks'

import Container from '../components/Container'
import TitleBar from '../components/TitleBar'
import SitesList from '../components/SitesList'
import CreateSiteButton from '../components/CreateSiteButton'

type OwnProps = {
    entry: projects.IProject | null
}

type Props = OwnProps

const ProjectDetails: React.FC<Props> = ({ entry }) => {
    const [t] = useTranslation()

    if (!entry) {
        return null
    }

    return (
        <Container>
            <TitleBar
                title={ t(ui.createTitle(api.Resource.project, navigation.MenuEntry.sites)) }
                rightItem={ <CreateSiteButton project={ entry.name } /> }
            />
            <SitesList project={ entry.name } />
        </Container>
    )
}

export default ProjectDetails
