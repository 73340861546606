import * as React from 'react'
import { connect } from 'react-redux'

import { map } from 'lodash'

import { Popover, Menu, MenuItem, Position } from '@blueprintjs/core'

import { RootState, app } from '../redux'
import { useDispatch, useTranslation } from '../hooks'
import { Language, LanguageName } from '../i18n'

import Button from '../components/Button'
import Icon from '../components/Icon'

import colors from '../assets/colors'

type ReduxProps = {
    currentLanguage: Language
}
type Props = ReduxProps

const LanguageMenu: React.FC<Props> = (props) => {
    const { currentLanguage } = props
    const dispatch = useDispatch()
    const [t] = useTranslation()

    return (
        <Popover
            content={ (
                <Menu>
                    { map(Language, (language: Language) => (
                        <MenuItem
                            active={ currentLanguage === language }
                            key={ language }
                            text={ t(LanguageName[language], { lng: language }) }
                            onClick={ () => dispatch(app.setLanguage(language)) }
                        />
                    )) }
                </Menu>
            ) }
            target={ (
                <Button
                    icon={ <Icon name="languages" size={ 20 } color={ colors.gray500 } /> }
                    isSquare
                />
            ) }
            position={ Position.TOP }
        />
    )
}

function mapStateToProps(state: RootState): ReduxProps {
    return {
        currentLanguage: app.getLanguage(state)
    }
}

export default connect(mapStateToProps)(LanguageMenu)
