import * as React from 'react'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'

import Button, { Props as ButtonProps } from '../components/Button'

import { RootState, api, organizations, projects, routing, ui } from '../redux'
import { useTranslation } from '../hooks'
import { Maybe } from '../utils'

type ReduxProps = {
    organization: Maybe<organizations.OrganizationName>
}

type OwnProps = {
    project: projects.ProjectName
}

type Props = ReduxProps & OwnProps & ButtonProps

const CreateSiteButton: React.FC<Props> = (props) => {
    const { organization, project } = props
    const [t] = useTranslation()

    if (!organization) {
        return null
    }

    return (
        <Button
            text={ t(ui.createTitle(api.Resource.site, api.Request.create)) }
            icon="add"
            intent={ Intent.SUCCESS }
            href={
                routing.routeFor(routing.Key.sites, {
                    organization : organizations.parseName(organization).slug,
                    project      : projects.parseName(project).slug,
                    slug         : '_',
                    action       : 'new'
                })
            }
        />
    )
}

function mapStateToProps(state: RootState) {
    return {
        organization: organizations.getCurrentName(state)
    }
}

export default connect(mapStateToProps)(CreateSiteButton)
