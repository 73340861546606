import * as React from 'react'
import { Field } from 'formik'
import { connect } from 'react-redux'
import { Intent } from '@blueprintjs/core'
import { Flex, Box } from 'reflexbox'

import { merge, toString } from 'lodash'

import { RootState, forms, system, wizard, routing } from '../redux'
import { useDispatch, useTranslation, useEffect } from '../hooks'
import { T } from '../i18n'

import { withForm } from '../components/Form'
import TitleBar from '../components/TitleBar'
import FormContainer from '../components/FormContainer'
import InputField from '../components/InputField'
import AdminUserEmailField from '../components/AdminUserEmailField'
import Button from '../components/Button'
import StepNavBar from '../components/StepNavBar'
import InstallIcon from '../assets/icons/InstallIcon'
import ExternalLink from '../components/ExternalLink'
import ConfigConnectorInstallCard from '../components/ConfigConnectorInstallCard'

type ReduxProps = {
    activeStep: wizard.Step
    initialErrors: forms.Errors
    isFirstStep: boolean
    isLastStep: boolean
}

type Props = forms.FormProps<
    system.IUpdateAdminUsersRequest
    | system.IUpdateAuthConfigurationRequest
    | system.UpdateLetsEncryptConfigurationRequest
> & ReduxProps

const InstallWizardForm: React.FC<Props> = (props) => {
    const {
        values, initialValues, initialErrors, activeStep, setErrors, resetForm,
        isPristine, isSubmitting, isFirstStep, isLastStep
    } = props
    const dispatch = useDispatch()
    const [t] = useTranslation()

    const hasValues = wizard.hasStepValues(activeStep, values)

    useEffect(() => {
        setErrors(initialErrors)
    }, [setErrors, initialErrors, activeStep.number])

    return (
        <FormContainer
            header={
                <>
                    <InstallIcon step={ activeStep.number } width={ 190 } height={ 190 } />
                    <StepNavBar wizard={ wizard.Key.install } />
                </>
            }
            buttons={
                <Flex justify="space-between">
                    <Button
                        text={ t('Back') }
                        onClick={ () => dispatch(routing.push({ step: toString(activeStep.number - 1) })) }
                        disabled={ isFirstStep }
                        minimal
                    />
                    <Box>
                        { !isPristine && (
                            <Button
                                text={ t('Discard changes') }
                                onClick={ () => resetForm({
                                    values: merge(values, wizard.pickValuesForStep(activeStep, initialValues))
                                }) }
                                minimal
                            />
                        ) }
                        <Button
                            text={
                                isLastStep
                                    ? t('Finish installation')
                                    : activeStep.isSkippable && (!hasValues || isPristine) ? t('Skip') : t('Next')
                            }
                            intent={ Intent.PRIMARY }
                            loading={ isSubmitting }
                            disabled={ !activeStep.isSkippable && (!hasValues || isSubmitting) }
                            type="submit"
                        />
                    </Box>
                </Flex>
            }
            isCentered
            { ...props }
        >
            <Box w={ 560 }>
                { activeStep.key === wizard.StepKey.adminUser && (
                    <>
                        <Field
                            name="adminUsers"
                            label={ t(system.FieldName.adminUsers) }
                            helperText={ t(system.FieldDescription.adminUsers) }
                            component={ AdminUserEmailField }
                            autoFocus
                        />
                    </>
                ) }
                { activeStep.key === wizard.StepKey.authentication && (
                    <>
                        <TitleBar
                            subtitle={
                                <T
                                    defaults={ system.SectionDescription.authentication }
                                    components={ [
                                        <ExternalLink to="https://auth0.com/docs/getting-started" />,
                                        <ExternalLink to="https://developers.google.com/identity/protocols/oauth2/openid-connect" /> // eslint-disable-line max-len
                                    ] }
                                />
                            }
                        />
                        <Field
                            name="configuration.oidcClientId"
                            label={ t(system.FieldName.oidcClientId) }
                            component={ InputField }
                        />
                        <Field
                            name="configuration.oidcIssuer"
                            label={ t(system.FieldName.oidcIssuer) }
                            helperText={ t(system.FieldDescription.oidcIssuer) }
                            component={ InputField }
                            autoFocus
                        />
                    </>
                ) }
                { activeStep.key === wizard.StepKey.letsEncrypt && (
                    <>
                        <TitleBar
                            subtitle={
                                <T
                                    defaults={ system.SectionDescription.letsEncrypt }
                                    components={ [
                                        <ExternalLink to="https://cert-manager.io/" />,
                                        <ExternalLink to="https://letsencrypt.org/" />
                                    ] }
                                />
                            }
                        />
                        <Field
                            label={ t('Email') }
                            name="configuration.email"
                            component={ InputField }
                            autoFocus
                        />
                        <Field
                            label={ t('Server') }
                            name="configuration.server"
                            component={ InputField }
                        />
                    </>
                ) }
                { activeStep.key === wizard.StepKey.configConnector && (
                    <>
                        <TitleBar
                            subtitle={ (
                                <T
                                    defaults={ system.SectionDescription.configConnector }
                                    components={ [
                                        <ExternalLink to="https://cloud.google.com/config-connector/docs/how-to/getting-started" /> // eslint-disable-line max-len
                                    ] }
                                />
                            ) }
                        />
                        <ConfigConnectorInstallCard />
                    </>
                ) }
            </Box>
        </FormContainer>
    )
}

function mapStateToProps(state: RootState) {
    const activeStep = wizard.getActiveStep(wizard.Key.install)(state)
    const initialErrors = wizard.getErrors(wizard.Key.install)(state)
    const isStepSkippable = wizard.isStepSkippable(wizard.Key.install)(state)
    const isFirstStep = wizard.isFirstStep(wizard.Key.install)(state)
    const isLastStep = wizard.isLastStep(wizard.Key.install)(state)

    return {
        activeStep,
        initialErrors,
        isStepSkippable,
        isFirstStep,
        isLastStep
    }
}

export default withForm({ name: forms.Name.installWizard })(connect(mapStateToProps)(InstallWizardForm))
