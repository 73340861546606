import * as React from 'react'
import { Field } from 'formik'
import { Intent } from '@blueprintjs/core'

import { get, parseInt, toString, isNumber } from 'lodash'

import { forms } from '../redux'

import SliderField from '../components/SliderField'

type Props = forms.FormProps<any>

const CommonScalingSliderField: React.FC<Props> = (props) => {
    const { initialValues } = props

    const name = 'resources.desiredReplicas'
    const isDisabled = !isNumber(get(initialValues, name))

    return (
        <Field
            name={ name }
            component={ SliderField }
            steps={ 16 }
            scaleRenderer={ toString }
            intent={ Intent.SUCCESS }
            icon="cube"
            disabled={ isDisabled }
            parse={ (value: any) => parseInt(value) || 0 }
            format={ (value: any) => toString(value) }
            isFat
        />
    )
}

export default CommonScalingSliderField
