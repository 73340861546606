import * as React from 'react'
import { Router as ReactRouter, Switch, Route } from 'react-router-dom'

import { map } from 'lodash'

import { routing, errors } from '../redux'

import * as containers from '../containers'
import ErrorMessage from '../components/ErrorMessage'
import MainContainer from '../components/MainContainer'

const Router: React.FC = () => (
    <ReactRouter history={ routing.history }>
        <Switch>
            { map(routing.ROUTE_MAP, ({ path, component }, key) => (
                <Route
                    key={ key }
                    path={ path }
                    component={ containers[component] }
                    exact
                />
            )) }
            <Route
                render={ () => (
                    <MainContainer>
                        <ErrorMessage error={ errors.Error.notFound } />
                    </MainContainer>
                ) }
            />
        </Switch>
    </ReactRouter>
)

export default Router
