import * as React from 'react'
import { Flex, Box } from 'reflexbox'

import { forms, resources, memcacheds } from '../redux'
import { useTranslation } from '../hooks'

import { withForm } from '../components/Form'
import FormContainer from '../components/FormContainer'
import TitleBar from '../components/TitleBar'
import CommonScalingSliderField from '../components/CommonScalingSliderField'
import CommonResourceAllocationSliderField from '../components/CommonResourceAllocationSliderField'
import TotalResourceUsageLabel from '../components/TotalResourceUsageLabel'
import MemcachedDisableButton from '../components/MemcachedDisableButton'

type Props = forms.FormProps<memcacheds.IMemcached>

const MemcachedResourcesForm: React.FC<Props> = (props) => {
    const { values } = props
    const [t] = useTranslation()

    return (
        <FormContainer
            { ...props }
            isDisabled={ !!values?.resources?.paused }
            header={
                <TitleBar
                    title={ t('Memcached Scaling Settings') }
                    subtitle={ t('Adjust the number of pods used to scale the Memcached deployment') }
                    rightItem={ (
                        <MemcachedDisableButton entry={ values } />
                    ) }
                />
            }
        >
            <Flex column>
                <Flex>
                    <Box w={ 0.5 } mr={ 30 }>
                        <CommonScalingSliderField { ...props } />
                    </Box>
                    <Box w={ 0.5 }>
                        <TotalResourceUsageLabel entry={ values } />
                    </Box>
                </Flex>
                <TitleBar
                    title={ t('Pod Resource Allocation Settings') }
                    subtitle={ t('Configure resource allocation for each of the memcahed cache pods') }
                    isSmall
                />
                <Flex>
                    <Box w={ 1 / 2 } mr={ 30 }>
                        <CommonResourceAllocationSliderField
                            resource={ resources.ResourceType.cpuPerPod }
                            { ...props }
                        />
                    </Box>
                    <Box w={ 1 / 2 }>
                        <CommonResourceAllocationSliderField
                            resource={ resources.ResourceType.memoryPerPod }
                            { ...props }
                        />
                    </Box>
                </Flex>
            </Flex>
        </FormContainer>
    )
}

export default withForm({ name: forms.Name.updateMemcachedResources })(MemcachedResourcesForm)
