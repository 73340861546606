import * as React from 'react'
import { Flex, Box } from 'reflexbox'
import { Tooltip } from '@blueprintjs/core'

import { routing, sites, navigation, ui } from '../redux'
import { useTranslation } from '../hooks'

import Card from '../components/Card'
import Button from '../components/Button'
import TitleBar from '../components/TitleBar'
import CopyToClipboardLabel from '../components/CopyToClipboardLabel'

type Props = {
    entry: sites.ISite | null
}

const SiteRuntimeCard: React.FC<Props> = ({ entry }) => {
    const [t] = useTranslation()

    if (!entry) {
        return null
    }

    return (
        <Card
            title={ t('Runtime') }
            subtitle={ t('The source code and docker image that currently power your site.') }
            rightItem={ (
                <Tooltip content={ t(ui.createTitle(navigation.MenuEntry.settings)) }>
                    <Button
                        icon="tune"
                        href={ routing.routeForResource(entry, { action: navigation.MenuEntry.runtime }) }
                        minimal
                        isSquare
                    />
                </Tooltip>
            ) }
            isBorderedTop
            isBorderedBottom
        >
            <TitleBar
                title={ t(sites.FieldName.wordpressImage) }
                subtitle={ <CopyToClipboardLabel text={ entry.wordpressImage || t('Managed') } /> }
                isSmall
            />
            <Flex>
                <Box w={ 1 / 2 }>
                    <TitleBar
                        title={ t(sites.FieldName.kubernetesNamespace) }
                        subtitle={ <CopyToClipboardLabel text={ entry.kubernetesNamespace } /> }
                        isSmall
                    />
                </Box>
                <Box w={ 1 / 2 }>
                    <TitleBar
                        title={ t(sites.FieldName.kubernetesName) }
                        subtitle={ <CopyToClipboardLabel text={ entry.kubernetesName } /> }
                        isSmall
                    />
                </Box>
            </Flex>
        </Card>
    )
}

export default SiteRuntimeCard
