import * as React from 'react'
import { Flex, Box } from 'reflexbox'

import { isString } from 'lodash'

import TitleBar from '../components/TitleBar'
import Icon, { IconName } from '../components/Icon'

import styles from './SliderContainer.module.scss'

type Props = {
    title: string
    subtitle?: string
    icon?: React.ReactNode | IconName
    rightItem?: React.ReactNode
}

const SliderContainer: React.FC<Props> = (props) => {
    const { children, title, subtitle, icon, rightItem } = props
    return (
        <>
            <Flex align="center" mt={ -20 }>
                { icon && (
                    <Box mr={ 3 }>
                        <div className={ styles.iconWrapper }>
                            { isString(icon) && <Icon name={ icon as IconName } size={ 35 } /> }
                            { !isString(icon) && React.isValidElement(icon) && icon }
                        </div>
                    </Box>
                ) }
                <Box w={ 1 } mt={ 5 }>
                    <div className={ styles.titleWrapper }>
                        <TitleBar
                            title={ title }
                            subtitle={ subtitle }
                            rightItem={ rightItem }
                            isSmall
                        />
                    </div>
                    <div className={ styles.slider }>
                        { children }
                    </div>
                </Box>
            </Flex>
        </>
    )
}

export default SliderContainer
