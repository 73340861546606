import * as React from 'react'
import { connect } from 'react-redux'
import { Tag } from '@blueprintjs/core'
import { Flex, Box } from 'reflexbox'

import { sortBy, reverse, head, size, toString, isEmpty } from 'lodash'

import { RootState, api, grpc, routing, organizations, projects, navigation } from '../redux'
import { useTranslation, useDispatch, useEffect } from '../hooks'
import { Maybe, arePropsEqual, formatDistance } from '../utils'

import Card from '../components/Card'
import Icon from '../components/Icon'
import BlankSlate from '../components/BlankSlate'
import AvatarsList from '../components/AvatarsList'
import CreateProjectButton from '../components/CreateProjectButton'

import OrganizationIcon from '../assets/icons/OrganizationIcon'

type ReduxProps = {
    items: api.ResourcesList<projects.IProject>
    isLoading: boolean
    isFailed: boolean
}

type OwnProps = {
    entry: Maybe<organizations.IOrganization>
}

type Props = OwnProps & ReduxProps

const OrganizationProjectsCard: React.FC<Props> = React.memo((props) => {
    const { entry, isLoading, isFailed } = props
    const [t] = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        if (!entry) {
            return
        }

        dispatch(projects.list({ organization: entry.name }))
    }, [entry, dispatch])

    if (!entry) {
        return null
    }

    const items = reverse(sortBy(props.items, 'createdAt.seconds'))
    const recentItem = head(items)
    const link = routing.routeForResource(entry, { action: navigation.MenuEntry.projects })

    return (
        <Card
            title={ t('Projects') }
            tag={ toString(size(items)) }
            link={ link }
            rightItem={ <CreateProjectButton isRound /> }
            isBorderedRight
            isBorderedBottom
        >
            { !isLoading && !isFailed && isEmpty(items) && (
                <BlankSlate
                    icon={ <OrganizationIcon width={ 160 } height={ 120 } /> }
                    title={ t("You don't have any projects yet.") }
                    description={
                        t('Create your first project by clicking the + button at'
                        + ' the upper right of the organization pane')
                    }
                />
            ) }
            { !isLoading && isFailed && isEmpty(items) && (
                <BlankSlate
                    icon={ <Icon name="delete-circle" size={ 32 } /> }
                    title={ t('Failed to load projects.') }
                />
            ) }
            { recentItem && recentItem.createdAt && (
                <p>
                    <strong>{ t('Last project added:') }</strong><br />
                    <span>{ formatDistance(recentItem.createdAt) }</span>
                </p>
            ) }
            <AvatarsList
                items={ items }
                listRoute={ link }
                itemRoute={ (item) => routing.routeForResource(item) }
                itemTooltipRenderer={ (item) => (
                    <Flex>
                        <Box>{ api.displayName(item) }</Box>
                        <Box ml={ 1 }><Tag>{ item.sitesCount } { t('sites') }</Tag></Box>
                    </Flex>
                ) }
            />
        </Card>
    )
}, arePropsEqual(['entry.name', 'items', 'isFailed', 'isLoading']))

function mapStateToProps(state: RootState, ownProps: OwnProps): ReduxProps {
    const { entry } = ownProps
    if (!entry) {
        return {
            items     : {},
            isLoading : false,
            isFailed  : false
        }
    }

    const itemsRequest = projects.list({ organization: entry.name })
    return {
        items     : projects.getForOrganization(entry.name)(state),
        isLoading : grpc.isLoadingRequest(itemsRequest.payload)(state),
        isFailed  : grpc.isFailedRequest(itemsRequest.payload)(state)
    }
}

export default connect(mapStateToProps)(OrganizationProjectsCard)
