import * as React from 'react'
import { connect } from 'react-redux'

import { map, isString, isEmpty } from 'lodash'
import { Flex, Box } from 'reflexbox'

import { RootState, grpc, api, pods, nodes, projects, statuses } from '../redux'
import { POLL_INTERVAL, formatDistance } from '../utils'
import { useDispatch, useEffect, useTranslation, useState } from '../hooks'

import PodTitle from '../components/PodTitle'
import NodeTitle from '../components/NodeTitle'
import TitleBar from '../components/TitleBar'
import Button from '../components/Button'
import Container from '../components/Container'
import ContextMenu from '../components/ContextMenu'
import ComponentStatusTag from '../components/ComponentStatusTag'
import ContainerStatusTag from '../components/ContainerStatusTag'
import Table, {
    TableColumn, TableCellProps, CellGetterProps, TableCell, getCellData
} from '../components/Table'

type OwnProps = {
    project?: projects.ProjectName
    parents?: api.ResourceName[]
    itemsRequest: grpc.RequestAction
}

type ReduxProps = {
    nodesState: nodes.State
}

type Props = OwnProps & ReduxProps

const PodsList: React.FC<Props> = (props) => {
    const { project, parents, nodesState, itemsRequest } = props
    const [t] = useTranslation()
    const [isPolling, setPolling] = useState(true)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(nodes.list())
    }, [dispatch])

    if (isEmpty(parents) && isEmpty(project)) {
        return null
    }

    const itemsSelector = isString(project) && !isEmpty(project)
        ? pods.getForProject(project)
        : map(parents, pods.getForParent)

    const columns: TableColumn[] = [
        {
            label        : t('Pod'),
            dataKey      : 'name',
            cellRenderer : (props: TableCellProps) => (
                <TableCell>
                    <PodTitle
                        entry={ props.rowData }
                        withAvatar
                        withTag={ false }
                        isCompact
                        isStretched
                    />
                </TableCell>
            )
        },
        {
            label        : t('Status'),
            dataKey      : 'status.componentStatus',
            width        : 120,
            cellRenderer : (props: TableCellProps) => (
                <TableCell align="flex-start">
                    <ComponentStatusTag entry={ props.cellData } />
                </TableCell>
            )
        },
        {
            label        : t('Containers'),
            dataKey      : 'containerStatuses',
            width        : 100,
            cellRenderer : (props: TableCellProps) => (
                <TableCell>
                    <Flex>
                        { map(props.rowData?.status?.containerStatuses, (
                            status: pods.IContainerStatus,
                            index: number
                        ) => (
                            <Box pr={ 1 } key={ index }>
                                <ContainerStatusTag key={ status.name } entry={ status } minimal />
                            </Box>
                        )) }
                    </Flex>
                </TableCell>
            )
        },
        {
            label          : t('Started'),
            dataKey        : 'status.startedAt',
            width          : 140,
            cellDataGetter : (props: CellGetterProps) => (
                getCellData(props) ? formatDistance(getCellData(props)) : null
            )
        },
        {
            label          : t('Restarts'),
            dataKey        : 'status',
            width          : 80,
            cellDataGetter : (props: CellGetterProps) => (
                pods.restartCount(props.rowData)
            )
        },
        {
            label          : t('Node'),
            dataKey        : 'node',
            cellDataGetter : (props: CellGetterProps) => {
                const name = getCellData(props)
                const state = { nodes: nodesState } as unknown as RootState
                return (
                    nodes.getByName(name)(state)
                )
            },
            cellRenderer: (props: TableCellProps) => {
                if (!props.cellData) {
                    return <TableCell />
                }

                return (
                    <TableCell>
                        <NodeTitle
                            entry={ props.cellData }
                            isCompact
                        />
                    </TableCell>
                )
            }
        },
        {
            dataKey      : 'actions',
            width        : 100,
            flexGrow     : 0,
            cellRenderer : (props: TableCellProps) => {
                const pod = props.rowData
                if (pod?.status?.generalStatus === statuses.GeneralStatus.TERMINATING) {
                    return <TableCell />
                }

                return (
                    <TableCell align="flex-end" onClick={ (e) => e.stopPropagation() }>
                        <ContextMenu
                            entry={ props.rowData }
                            resource={ api.Resource.pod }
                        />
                    </TableCell>
                )
            }
        }
    ]

    return (
        <Container>
            <TitleBar
                title={ t('Pods') }
                rightItem={
                    <Button
                        text={ isPolling ? t('Auto-refresh enabled') : t('Auto-refresh disabled') }
                        icon={ isPolling ? 'recycle-cw' : 'circle' }
                        onClick={ () => setPolling(!isPolling) }
                        minimal
                    />
                }
            />
            <Table
                itemsRequest={ itemsRequest }
                itemsSelector={ itemsSelector }
                pollInterval={ isPolling ? POLL_INTERVAL.fast : undefined }
                emptyRenderer={ () => t('No available pods.') }
                columns={ columns }
                sortBy="lastTimestamp"
            />
        </Container>
    )
}

function mapStateToProps(state: RootState): ReduxProps {
    return {
        nodesState: nodes.getState(state)
    }
}

export default connect(mapStateToProps)(PodsList)
