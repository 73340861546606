import * as React from 'react'
import { connect } from 'react-redux'
import { Flex, Box } from 'reflexbox'

import { RootState, system } from '../redux'
import { useTranslation } from '../hooks'

import AuthenticationStatusCard from '../components/AuthenticationStatusCard'
import ApplicationStatusCard from '../components/ApplicationStatusCard'
import CRDStatusCard from '../components/CRDStatusCard'

type ReduxProps = {
    status: system.ISystemStatus
}

type Props = ReduxProps

const StatusOverview: React.FC<Props> = (props) => {
    const { status } = props
    const [t] = useTranslation()

    if (!status) {
        return null
    }

    return (
        <Flex>
            <Box w={ 1 / 5 }>
                <AuthenticationStatusCard
                    title={ t('Authentication Provider') }
                    entry={ status.auth }
                />
            </Box>
            <Box w={ 1 / 5 }>
                <ApplicationStatusCard
                    title={ t('Stack') }
                    entry={ status.stack }
                />
            </Box>
            <Box w={ 1 / 5 }>
                <ApplicationStatusCard
                    title={ t('Dashboard') }
                    entry={ status.dashboard }
                />
            </Box>
            <Box w={ 1 / 5 }>
                <ApplicationStatusCard
                    title={ t('Config Connector') }
                    entry={ status.configConnector }
                />
            </Box>
            <Box w={ 1 / 5 }>
                <CRDStatusCard
                    title={ t('Custom Resources') }
                    entry={ status.crd }
                />
            </Box>
        </Flex>
    )
}

function mapStateToProps(state: RootState): ReduxProps {
    return {
        status: system.getStatus(state)
    }
}

export default connect(mapStateToProps)(StatusOverview)
