import * as React from 'react'

import styles from './MainContainer.module.scss'

const MainContainer: React.FC = ({ children }) => (
    <div className={ styles.container }>
        <div className={ styles.content }>
            { children }
        </div>
    </div>
)

export default MainContainer
