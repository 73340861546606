import * as React from 'react'
import { Intent } from '@blueprintjs/core'
import { grafanas } from '../redux'
import { useDispatch, useTranslation } from '../hooks'
import { createUpdateMask } from '../utils'

import ConfirmButton from '../components/ConfirmButton'
import Button from '../components/Button'

type Props = {
    entry?: grafanas.IGrafana
}

const GrafanaDisableButton: React.FC<Props> = (props) => {
    const { entry } = props
    const dispatch = useDispatch()
    const [t] = useTranslation()

    if (!entry) {
        return null
    }

    const setEnabled = (value: boolean) => dispatch(grafanas.updateResources({
        name      : entry.name,
        resources : {
            enabled: value
        },
        updateMask: createUpdateMask(['resources.enabled'])
    }))

    return !entry.resources?.enabled ? (
        <Button
            text={ t('Enable Grafana') }
            intent={ Intent.SUCCESS }
            onClick={ () => setEnabled(true) }
        />
    ) : (
        <ConfirmButton
            button={ (
                <Button
                    text={ t('Disable Grafana') }
                    intent={ Intent.WARNING }
                />
            ) }
            onConfirm={ () => setEnabled(false) }
            confirmationIcon="issue"
            confirmationText={
                <>
                    { t('Disabling Grafana will prevent you from viewing the metrics collected for this project.') }
                    { t('Are you sure you want to continue?') }
                </>
            }
        />
    )
}

export default GrafanaDisableButton
