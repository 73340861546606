import * as React from 'react'
import { Field } from 'formik'
import { Intent } from '@blueprintjs/core'
import { Flex } from 'reflexbox'

import { map, reduce, get, size, toUpper, isEmpty, isEqual } from 'lodash'

import { forms, sites } from '../redux'
import { useTranslation, useEffect } from '../hooks'

import Table, { TableColumn, TableCellProps, TableCell } from '../components/Table'
import Button from '../components/Button'
import DiscardButton from '../components/DiscardButton'
import FormGroup from '../components/FormGroup'
import InputField from '../components/InputField'
import MaskedInputField from '../components/MaskedInputField'
import CopyToClipboardButton from '../components/CopyToClipboardButton'

const SiteEnvVarsFieldList: React.FC<any> = (props) => {
    const { name, form, push, remove, replace } = props
    const [t] = useTranslation()
    const entries: sites.IEnvVar[] = get(form.values, name)
    const errors = forms.getFieldErrors(form.errors, name)
    const initialValue = get(form.initialValues, name)
    const initialEntries = reduce(initialValue, (acc, variable: sites.IEnvVar) => {
        if (isEmpty(variable.name)) {
            return acc
        }

        return {
            ...acc,
            [variable.name]: variable.value
        }
    }, {})

    useEffect(() => {
        if (isEmpty(entries)) {
            return
        }

        const entriesCount = size(entries)
        const lastEntry = entries[entriesCount - 1]
        const penultimateEntry = entries[entriesCount - 2]

        if (!isEqual(lastEntry, sites.EMPTY_ENV_VAR)) {
            push(sites.EMPTY_ENV_VAR)
        }

        if (isEqual(lastEntry, penultimateEntry) && isEqual(lastEntry, sites.EMPTY_ENV_VAR)) {
            remove(entriesCount - 1)
        }
    }, [entries, push, remove])

    const rows = map(entries, (entry: sites.IEnvVar, index: number) => {
        const isEmptyEntry = isEqual(entry, sites.EMPTY_ENV_VAR)
        const isUnsaved = !isEmptyEntry && !isEqual(initialEntries[entry.name], entry.value)
        const isFirstEntry = index === 0
        const isLastEntry = size(entries) - 1 === index

        return {
            ...entry,
            isEmptyEntry,
            isUnsaved,
            isFirstEntry,
            isLastEntry
        }
    })

    const columns: TableColumn[] = [
        {
            label        : t('Name'),
            dataKey      : 'name',
            cellRenderer : (props: TableCellProps) => {
                const { rowIndex, rowData: { isFirstEntry, isLastEntry, isEmptyEntry } } = props
                return (
                    <TableCell>
                        <Field
                            autoFocus={ isFirstEntry && isEmptyEntry }
                            name={ `${name}.${rowIndex}.name` }
                            component={ MaskedInputField }
                            mask={ (value: string) => toUpper(value) }
                            placeholder={ isLastEntry ? t('Add a new variable') : null }
                            isMonospaced
                        />
                    </TableCell>
                )
            }
        },
        {
            label        : t('Value'),
            dataKey      : 'value',
            cellRenderer : (props: TableCellProps) => {
                const { rowIndex, cellData } = props
                return (
                    <TableCell>
                        <Field
                            name={ `${name}.${rowIndex}.value` }
                            component={ InputField }
                            rightElement={ !isEmpty(cellData) && (
                                <CopyToClipboardButton text={ cellData } />
                            ) }
                            isMonospaced
                        />
                    </TableCell>
                )
            }
        },
        {
            dataKey      : 'actions',
            width        : 110,
            cellRenderer : (props: TableCellProps) => {
                const { isUnsaved, isLastEntry } = props.rowData
                return (
                    <TableCell>
                        <Flex>
                            { !isLastEntry && (
                                <Button
                                    icon="bin-full"
                                    minimal
                                    intent={ Intent.DANGER }
                                    onClick={ () => remove(props.rowIndex) }
                                    isSquare
                                />
                            ) }
                            { isUnsaved && (
                                <DiscardButton
                                    onClick={ () => {
                                        size(initialValue) > props.rowIndex + 1
                                            ? replace(props.rowIndex, get(initialValue, props.rowIndex))
                                            : remove(props.rowIndex)
                                    } }
                                />
                            ) }
                        </Flex>
                    </TableCell>
                )
            }
        }
    ]

    return (
        <FormGroup errors={ errors }>
            <Table
                items={ rows }
                columns={ columns }
                isSortable={ false }
            />
        </FormGroup>
    )
}

export default SiteEnvVarsFieldList
