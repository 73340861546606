import * as React from 'react'
import { Slider } from '@blueprintjs/core'
import { Flex } from 'reflexbox'
import classes from 'classnames'

import { get, pick, times, findIndex, toString, isFunction, isEqual } from 'lodash'

import { forms } from '../redux'

import FormGroup from '../components/FormGroup'
import SliderContainer from '../components/SliderContainer'
import DiscardButton from '../components/DiscardButton'
import { IconName } from '../components/Icon'

import styles from './SliderField.module.scss'

export type Step = number
export type Value = number
export type InternalValue = Step
export type Scale = (step: Step) => number
export type Formatter = (value: Value, rawValue?: string) => string
export type Parser = (internalValue: InternalValue) => Value
export type LabelRenderer = (value: Value) => string
export type ScaleRenderer = (step: Step) => string
export type Props = forms.FieldProps & {
    scale: Scale
    format: Formatter
    parse: Parser
    icon?: IconName
    labelRenderer?: LabelRenderer
    scaleRenderer?: ScaleRenderer
    className?: string
    isFat?: boolean
}

const SliderField: React.FC<Props> = (props) => {
    const { field, form, parse, format, label, icon, intent } = props
    const { name } = field

    const errors = forms.getFieldErrors(form.errors, name)
    const steps = get(props, 'steps', 10)
    const min = get(props, 'min', 1)
    const max = get(props, 'max', steps)

    const rawValue = get(field, 'value', '')
    const value = isFunction(parse) ? parse(rawValue) : rawValue
    const scale = isFunction(props.scale) ? props.scale : (step: Step) => step
    const toStep = (value: any) => {
        const allValues = times(steps, (i) => scale(i + 1))
        const step = findIndex(allValues, (v) => isEqual(value, v))
        return step !== -1 ? step + 1 : 1
    }

    const initialRawValue = get(form.initialValues, name)
    const initialValue = isFunction(parse) ? parse(initialRawValue) : initialRawValue

    const isUnsaved = !isEqual(value, initialValue)

    const labelRenderer: LabelRenderer = isFunction(props.labelRenderer) ? props.labelRenderer : toString
    const scaleRenderer: ScaleRenderer = isFunction(props.scaleRenderer)
        ? props.scaleRenderer
        : (step: Step) => {
            if (step === 1 || step === max) {
                return labelRenderer(scale(step))
            }

            return ''
        }

    return (
        <SliderContainer
            title={ label }
            icon={ icon }
            rightItem={
                <Flex align="center">
                    { isUnsaved && (
                        <DiscardButton
                            onClick={ () => form.setFieldValue(name, initialRawValue) }
                        />
                    ) }
                    <span className={ styles.valueLabel }>
                        <strong>{ labelRenderer(value) }</strong>
                    </span>
                </Flex>
            }
        >
            <FormGroup
                errors={ forms.formatFieldErrors(label, errors) }
                { ...field }
            >
                <Slider
                    { ...pick(props, ['style', 'disabled']) }
                    { ...field }
                    className={ classes(
                        props.className,
                        styles.slider,
                        props.isFat && styles.fat
                    ) }
                    min={ min }
                    max={ max }
                    intent={ intent }
                    labelRenderer={ scaleRenderer }
                    value={ toStep(value) }
                    onChange={ (step: Step) => {
                        const value = scale(step)
                        const outputValue = isFunction(format) ? format(value, rawValue) : toString(value)
                        form.setFieldValue(name, outputValue)
                    } }
                />
            </FormGroup>
        </SliderContainer>
    )
}

export default SliderField
