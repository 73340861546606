import * as React from 'react'
import { Flex, Box } from 'reflexbox'

import { api, forms, projects, ui, navigation } from '../redux'
import { useTranslation } from '../hooks'

import { withForm } from '../components/Form'
import Container from '../components/Container'
import TitleBar from '../components/TitleBar'
import FormContainer from '../components/FormContainer'
import ResourceQuotaSliderField from '../components/ResourceQuotaSliderField'

type Props = forms.FormProps<projects.IProject>

const ProjectQuotasForm: React.FC<Props> = (props) => {
    const [t] = useTranslation()

    return (
        <Container>
            <FormContainer
                header={ (
                    <TitleBar
                        title={ t(ui.createTitle(api.Resource.project, navigation.MenuEntry.quotas)) }
                        subtitle={ t('Limit the amount of resources used in this project') }
                    />
                ) }
                { ...props }
            >
                <Flex column>
                    <Flex justify="space-between">
                        <Box w={ 0.5 } mr={ 30 }>
                            <ResourceQuotaSliderField resource={ projects.QuotaResourceType.cpu } { ...props } />
                        </Box>
                        <Box w={ 0.5 }>
                            <ResourceQuotaSliderField resource={ projects.QuotaResourceType.memory } { ...props } />
                        </Box>
                    </Flex>
                    <Flex justify="space-between">
                        <Box w={ 0.5 } mr={ 30 }>
                            <ResourceQuotaSliderField resource={ projects.QuotaResourceType.pods } { ...props } />
                        </Box>
                        <Box w={ 0.5 }>
                            <ResourceQuotaSliderField resource={ projects.QuotaResourceType.sites } { ...props } />
                        </Box>
                    </Flex>
                </Flex>
            </FormContainer>
        </Container>
    )
}

export default withForm({ name: forms.Name.updateProjectResourceQuotas })(ProjectQuotasForm)
