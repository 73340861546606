import * as React from 'react'
import { connect } from 'react-redux'
import { Flex, Box } from 'reflexbox'
import { Switch, Route } from 'react-router-dom'

import { pick } from 'lodash'

import {
    RootState, DispatchProp, routing, memcacheds,
    projects, organizations, api, navigation, errors, pods
} from '../redux'
import { Maybe } from '../utils'

import PageMeta from '../components/PageMeta'
import MainContainer from '../components/MainContainer'
import MainMenu from '../components/MainMenu'
import ErrorMessage from '../components/ErrorMessage'
import Container from '../components/Container'
import MemcachedResourcesForm from '../components/MemcachedResourcesForm'
import MemcachedSchedulingForm from '../components/MemcachedSchedulingForm'
import MemcachedSchedulingStatus from '../components/MemcachedSchedulingStatus'
import PodsList from '../components/PodsList'

type ReduxProps = {
    memcached: Maybe<memcacheds.IMemcached>
    project: Maybe<projects.IProject>
    activeMenuEntry: Maybe<navigation.MenuEntry>
    organization: Maybe<organizations.OrganizationName>
    isNotFound: boolean
}

type Props = ReduxProps & DispatchProp

const MemcachesContainer: React.FC<Props> = (props) => {
    const { memcached, project, activeMenuEntry, organization, isNotFound } = props

    if (project && !memcached) {
        return (
            <>
                <MainMenu
                    resource={ api.Resource.project }
                    entry={ project }
                />
                <MainContainer>
                    { isNotFound && <ErrorMessage error={ errors.Error.notFound } /> }
                </MainContainer>
            </>
        )
    }

    if (!project || !organization || !memcached) {
        return <MainContainer />
    }

    return (
        <>
            <PageMeta
                entry={ memcached }
                section={ activeMenuEntry }
            />
            <MainMenu
                resource={ api.Resource.memcached }
                entry={ memcached }
            />
            <MainContainer>
                <Switch>
                    <Route
                        key={ navigation.MenuEntry.scheduling }
                        path={ routing.routeForResource(memcached, { action: navigation.MenuEntry.scheduling }) }
                        render={ () => (
                            <Container>
                                <Flex column>
                                    <Box>
                                        <MemcachedSchedulingForm
                                            initialValues={ { memcached } }
                                        />
                                    </Box>
                                    <Box mt={ 50 }>
                                        <MemcachedSchedulingStatus
                                            entry={ memcached }
                                        />
                                    </Box>
                                </Flex>
                            </Container>
                        ) }
                    />
                    <Route
                        key={ navigation.MenuEntry.resources }
                        path={ routing.routeForResource(memcached, { action: navigation.MenuEntry.resources }) }
                        render={ () => (
                            <Container>
                                <MemcachedResourcesForm
                                    initialValues={ pick(memcached, ['name', 'resources']) }
                                />
                            </Container>
                        ) }
                    />
                    <Route
                        key={ navigation.MenuEntry.pods }
                        path={ routing.routeForResource(memcached, { action: navigation.MenuEntry.pods }) }
                        render={ () => (
                            <PodsList
                                parents={ [memcached.name] }
                                itemsRequest={ pods.list({ parents: [memcached.name] }) }
                            />
                        ) }
                    />
                    <Route
                        path={ routing.routeForResource(memcached) }
                        render={ () => (
                            <div />
                        ) }
                    />
                </Switch>
            </MainContainer>
        </>
    )
}

function mapStateToProps(state: RootState): ReduxProps {
    const project = projects.getForCurrentURL(state)
    const memcached = memcacheds.getForCurrentURL(state)
    const isNotFound = memcacheds.isNotFound(state)
    const activeMenuEntry = navigation.getActiveMenuEntry(state)
    const organization = organizations.getCurrentName(state)

    return {
        project,
        memcached,
        activeMenuEntry,
        organization,
        isNotFound
    }
}

export default connect(mapStateToProps)(MemcachesContainer)
