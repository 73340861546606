import * as React from 'react'

import { Popover, Menu, MenuItem, AnchorButton, Position } from '@blueprintjs/core'

import { useTranslation } from '../hooks'

import Icon from '../components/Icon'
import TitleBar from '../components/TitleBar'

import colors from '../assets/colors'

import styles from './SupportMenu.module.scss'

const SupportMenu: React.FC = () => {
    const [t] = useTranslation()

    const supportEmail = 'hello@bitpoke.io'

    return (
        <Popover
            content={ (
                <Menu className={ styles.menu }>
                    <MenuItem
                        onClick={ () => window.open(`mailto:${supportEmail}`) }
                        text={ (
                            <TitleBar
                                title={ t('Support') }
                                subtitle={ (
                                    <>
                                        { t('If you need help, contact us at ') }
                                        <br />
                                        <strong>{ supportEmail }</strong>
                                    </>
                                ) }
                                icon={ <Icon name="help-alt" size={ 28 } color={ colors.green500 } /> }
                                isSmall
                                isCompact
                            />
                        ) }
                    />
                    <MenuItem
                        onClick={ () => window.open('https://www.bitpoke.io/docs/app-for-wordpress', '_blank') }
                        text={ (
                            <TitleBar
                                title={ t('Documentation') }
                                subtitle={ (
                                    <>
                                        { t('Check out the documentation at') }
                                        <br />
                                        <strong>www.bitpoke.io/docs/app-for-wordpress</strong>
                                    </>
                                ) }
                                icon={ <Icon name="book" size={ 28 } color={ colors.green500 } /> }
                                isSmall
                                isCompact
                            />
                        ) }
                    />
                </Menu>
            ) }
            target={ (
                <AnchorButton
                    text={ t('Support') }
                    icon={ <Icon name="help" /> }
                    rightIcon="chevron-up"
                />
            ) }
            position={ Position.TOP }
        />
    )
}

export default SupportMenu
