import * as React from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import { Box } from 'reflexbox'

import TitleBar from '../components/TitleBar'
import PageMeta from '../components/PageMeta'
import Container from '../components/Container'
import MainContainer from '../components/MainContainer'
import StatusOverview from '../components/StatusOverview'
import AdminUsersList from '../components/AdminUsersList'
import AuthConfigurationForm from '../components/AuthConfigurationForm'
import SystemComponentScheduling from '../components/SystemComponentScheduling'
import CertificatesConfigurationForm from '../components/CertificatesConfigurationForm'
import ConfigConnectorInstallCard from '../components/ConfigConnectorInstallCard'
import AuthVerificationStatusCard from '../components/AuthVerificationStatusCard'
import SystemSettingsMenu from '../components/SystemSettingsMenu'
import ExternalLink from '../components/ExternalLink'

import { RootState, routing, navigation, system, ui } from '../redux'
import { useDispatch, useInterval, useEffect, useTranslation } from '../hooks'
import { Maybe, POLL_INTERVAL } from '../utils'
import { T } from '../i18n'

type ReduxProps = {
    config: system.IConfiguration
    activeTab: Maybe<navigation.TabEntry>
    activeMenuEntry: Maybe<navigation.MenuEntry>
}

type Props = ReduxProps

const SystemContainer: React.FC<Props> = (props) => {
    const { config, activeTab, activeMenuEntry } = props
    const dispatch = useDispatch()
    const [t] = useTranslation()

    useEffect(() => {
        dispatch(system.fetchStatus())
        dispatch(system.fetchAuthConfiguration())
        dispatch(system.fetchLetsEncryptConfiguration())
        dispatch(system.fetchConfigConnectorConfiguration())
        dispatch(system.listComponentSchedulings())
    }, [dispatch])

    useInterval(() => {
        dispatch(system.fetchStatus())
    }, POLL_INTERVAL.slow)

    return (
        <>
            { activeMenuEntry !== navigation.MenuEntry.status && (
                <SystemSettingsMenu />
            ) }
            <PageMeta
                section={ activeMenuEntry }
                tab={ activeTab }
            />
            <MainContainer>
                <Switch>
                    <Route
                        path={ routing.routeFor(routing.Key.system, {
                            action : navigation.MenuEntry.settings,
                            tab    : navigation.TabEntry.authentication
                        }) }
                        render={ () => (
                            <AuthConfigurationForm
                                initialValues={ { configuration: config.auth } }
                            />
                        ) }
                    />
                    <Route
                        path={ routing.routeFor(routing.Key.system, {
                            action : navigation.MenuEntry.settings,
                            tab    : navigation.TabEntry.scheduling
                        }) }
                        render={ () => (
                            <SystemComponentScheduling />
                        ) }
                    />
                    <Route
                        path={ routing.routeFor(routing.Key.system, {
                            action : navigation.MenuEntry.settings,
                            tab    : navigation.TabEntry.certificates
                        }) }
                        render={ () => (
                            <CertificatesConfigurationForm
                                initialValues={ { configuration: config.letsEncrypt } }
                            />
                        ) }
                    />
                    <Route
                        path={ routing.routeFor(routing.Key.system, {
                            action : navigation.MenuEntry.settings,
                            tab    : navigation.TabEntry.configConnector
                        }) }
                        render={ () => (
                            <Container>
                                <Box w={ 1 / 2 }>
                                    <TitleBar
                                        title={ t(ui.createTitle(navigation.TabEntry.configConnector)) }
                                        subtitle={ (
                                            <T
                                                defaults={ system.SectionDescription.configConnector }
                                                components={ [
                                                    <ExternalLink to="https://cloud.google.com/config-connector/docs/how-to/getting-started" /> // eslint-disable-line max-len
                                                ] }
                                            />
                                        ) }
                                    />
                                    <ConfigConnectorInstallCard />
                                </Box>
                            </Container>
                        ) }
                    />
                    <Route
                        path={ routing.routeFor(routing.Key.system, {
                            action : navigation.MenuEntry.settings,
                            tab    : navigation.TabEntry.adminUsers
                        }) }
                        render={ () => (
                            <AdminUsersList />
                        ) }
                    />
                    <Route
                        path={ routing.routeFor(routing.Key.system, {
                            action : navigation.MenuEntry.status,
                            tab    : navigation.TabEntry.authentication
                        }) }
                        render={ () => (
                            <AuthVerificationStatusCard />
                        ) }
                    />
                    <Route
                        path={ routing.routeFor(routing.Key.system, {
                            action: navigation.MenuEntry.status
                        }) }
                        render={ () => (
                            <StatusOverview />
                        ) }
                    />
                </Switch>
            </MainContainer>
        </>
    )
}

function mapStateToProps(state: RootState) {
    const config = system.getConfiguration(state)
    const activeTab = navigation.getActiveTab(state)
    const activeMenuEntry = navigation.getActiveMenuEntry(state)

    return {
        config,
        activeTab,
        activeMenuEntry
    }
}

export default connect(mapStateToProps)(SystemContainer)
