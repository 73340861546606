import * as React from 'react'
import { MenuItem as BaseMenuItem, IMenuItemProps } from '@blueprintjs/core'

import { routing } from '../redux'

export type Props = IMenuItemProps

const MenuItem = (props: Props) => {
    const { href, ...otherProps } = props
    return (
        <BaseMenuItem
            href={ href ? routing.toFragment(href) : undefined }
            { ...otherProps }
        />
    )
}

export default MenuItem
