import * as React from 'react'
import { FieldArray } from 'formik'

import { get, last, slice, isEmpty, isEqual } from 'lodash'

import { api, forms, sites, ui, navigation, errors } from '../redux'
import { useTranslation, useDispatch, useInterval } from '../hooks'
import { POLL_INTERVAL } from '../utils'

import { withForm } from '../components/Form'
import ErrorMessage from '../components/ErrorMessage'
import Container from '../components/Container'
import FormContainer from '../components/FormContainer'
import TitleBar from '../components/TitleBar'
import SiteEnvVarsFieldList from '../components/SiteEnvVarsFieldList'

type Props = forms.FormProps<sites.ISite>

const SiteEnvVarsForm: React.FC<Props> = (props) => {
    const { initialValues } = props
    const [t] = useTranslation()
    const dispatch = useDispatch()

    const isEditable = !isEmpty(initialValues.envVars)

    useInterval(() => {
        if (!isEditable) {
            dispatch(sites.get({ name: initialValues.name }))
        }
    }, POLL_INTERVAL.default)

    if (!isEditable) {
        return <ErrorMessage error={ errors.Error.siteProvisioning } />
    }

    return (
        <Container>
            <FormContainer
                header={ (
                    <TitleBar title={ t(ui.createTitle(api.Resource.site, navigation.MenuEntry.environment)) } />
                ) }
                { ...props }
            >
                <FieldArray
                    name="envVars.items"
                    component={ SiteEnvVarsFieldList }
                />
            </FormContainer>
        </Container>
    )
}

export default withForm({
    name            : forms.Name.updateSiteEnvVars,
    valuesFormatter : (values: sites.ISitePayload) => {
        const envVarsItems = get(values, 'envVars.items', [])
        return {
            ...values,
            envVars: {
                items: isEqual(last(envVarsItems), sites.EMPTY_ENV_VAR)
                    ? slice(envVarsItems, 0, -1)
                    : envVarsItems
            }
        }
    }
})(SiteEnvVarsForm)
