import * as React from 'react'
import { connect } from 'react-redux'

import { isString } from 'lodash'

import { RootState, system, routing } from '../redux'
import { Maybe } from '../utils'

import SystemComponentSchedulingForm from '../components/SystemComponentSchedulingForm'

type ReduxProps = {
    componentScheduling: Maybe<system.IComponentScheduling>
}

type Props = ReduxProps

const SystemComponentScheduling: React.FC<Props> = (props) => {
    const { componentScheduling } = props

    return (
        <SystemComponentSchedulingForm
            initialValues={ { componentScheduling } }
        />
    )
}

function mapStateToProps(state: RootState): ReduxProps {
    const componentName = routing.getParam('component')(state)
    const selectedComponent = isString(componentName) ? system.getComponent(componentName)(state) : null
    const componentScheduling = selectedComponent ? system.getComponentScheduling(selectedComponent)(state) || {} : {}

    return {
        componentScheduling
    }
}

export default connect(mapStateToProps)(SystemComponentScheduling)
