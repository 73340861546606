/* eslint-disable no-use-before-define, require-yield */

import { channel as createChannel } from 'redux-saga'
import { Effect, takeEvery, fork, call } from 'redux-saga/effects'
import { ActionType, action as createAction } from 'typesafe-actions'
import { createSelector } from 'reselect'

import { filter, find, omit, get as _get, concat, isEqual, isEmpty, isFunction } from 'lodash'

import { RootState, ActionDescriptor } from '../redux'

import { watchChannel } from '../utils'
import { createTransport, setMetadataHeader, RequestError, StatusCode } from '../utils/grpc/transport'

export { createTransport, StatusCode, RequestError }

export type State = {
    ongoingRequests: SerializedRequest[]
    failedRequests: SerializedRequest[]
    successiveNetworkErrors: number
    metadata: {
        [key: string]: string
    }
}

export type Actions = ActionType<typeof actions>

export type Request = {
    method: string
    data: any
    service: any
    responseType?: ResponseParser
}

export type ResponseParser = {
    create: (data: any) => any
    toObject: (data: any, options: any) => Record<string, unknown>
}

export type Response = {
    data?: any
    error?: Error | RequestError
    request: Request
}

export type Metadata = {
    key: string
    value: string
}

export type FieldViolation = {
    field: string
    description: string
}

export type RequestAction = { type: ActionDescriptor, payload: Request }
export type ResponseAction = { type: ActionDescriptor, payload: Response }
export type SerializedRequest = {
    method: string
    data: Record<string, unknown>
    service: string
}

const SUCCESSIVE_NETWORK_ERRORS_BEFORE_OFFLINE = 4

//
//  ACTIONS

export const INVOKED      = '@ grpc / INVOKED'
export const SUCCEEDED    = '@ grpc / SUCCEEDED'
export const FAILED       = '@ grpc / FAILED'
export const METADATA_SET = '@ grpc / METADATA_SET'

export const invoke = (payload: Request) => createAction(INVOKED, payload)
export const success = (payload: Response) => createAction(SUCCEEDED, payload)
export const fail = (payload: Response) => createAction(FAILED, payload)
export const setMetadata = (payload: Metadata) => createAction(METADATA_SET, payload)

const actions = {
    invoke,
    success,
    fail,
    setMetadata
}

//
//  SAGA

const channel = createChannel()

export function* saga() {
    yield takeEvery(INVOKED, performRequest)
    yield takeEvery(METADATA_SET, updateTransportMetadata)
    yield fork(watchChannel, channel)
}

function* performRequest(action: ActionType<typeof invoke>): Iterator<Effect> {
    const request = action.payload
    const { service, method, data, responseType } = request

    try {
        service[method](data, (error: Error | null, responseData: Record<string, unknown> | null) => {
            if (error) {
                channel.put(fail({ request, error }))
                return
            }

            if (responseType && isFunction(responseType.create) && isFunction(responseType.toObject)) {
                const { create, toObject } = responseType
                const data = toObject(create(responseData), { defaults: true })
                channel.put(success({ request, data }))
            }
            else {
                const data = responseData
                channel.put(success({ request, data }))
            }
        })
    }
    catch (error) {
        channel.put(fail({ request, error }))
    }
}

function* updateTransportMetadata(action: ActionType<typeof setMetadata>) {
    const metadata = action.payload
    const { key, value } = metadata
    yield call(setMetadataHeader, key, value)
}

export function isNetworkErrorResponse(action: ResponseAction) {
    const { error } = action.payload
    return error && isNetworkError(error)
}

export function isNetworkError(error: Error) {
    return _get(error, 'request.status') === 0 || error.message === 'Network Error'
}

export function serializeRequest(request: Request): SerializedRequest {
    return {
        ...omit(request, ['service', 'responseType']),
        service: request.service.constructor.name
    }
}

//
//  REDUCER

const initialState: State = {
    ongoingRequests         : [],
    failedRequests          : [],
    successiveNetworkErrors : 0,
    metadata                : {}
}

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case INVOKED: {
            const request = action.payload
            return {
                ...state,
                ongoingRequests: concat(state.ongoingRequests, serializeRequest(request))
            }
        }

        case SUCCEEDED: {
            const request = serializeRequest(action.payload.request)
            return {
                ...state,
                ongoingRequests         : withoutRequest(state.ongoingRequests, request),
                failedRequests          : withoutRequest(state.failedRequests, request),
                successiveNetworkErrors : 0
            }
        }

        case FAILED: {
            const request = serializeRequest(action.payload.request)
            return {
                ...state,
                ongoingRequests         : withoutRequest(state.ongoingRequests, request),
                failedRequests          : concat(state.failedRequests, request),
                successiveNetworkErrors : isNetworkErrorResponse(action)
                    ? state.successiveNetworkErrors + 1
                    : state.successiveNetworkErrors
            }
        }

        case METADATA_SET: {
            const { key, value } = action.payload
            return {
                ...state,
                metadata: {
                    [key]: value
                }
            }
        }

        default:
            return state
    }
}

const withoutRequest = (list: SerializedRequest[], item: SerializedRequest) => (
    filter(list, (entry) => !isEqual(entry, item))
)

//
//  SELECTORS

export const getState = (state: RootState) => state.grpc
export const getOngoingRequests = createSelector(
    getState,
    (state) => state.ongoingRequests
)
export const getFailedRequests = createSelector(
    getState,
    (state) => state.failedRequests
)
export const isLoading = createSelector(
    getOngoingRequests,
    (ongoingRequests) => !isEmpty(ongoingRequests)
)
export const isLoadingRequest = (request: Request) => createSelector(
    getOngoingRequests,
    (ongoingRequests) => {
        const serializedRequest = serializeRequest(request)
        return !!find(ongoingRequests, (ongoingRequest) => isEqual(ongoingRequest, serializedRequest))
    }
)
export const isFailedRequest = (request: Request) => createSelector(
    getFailedRequests,
    (failedRequests) => {
        const serializedRequest = serializeRequest(request)
        return !!find(failedRequests, (failedRequest) => isEqual(failedRequest, serializedRequest))
    }
)
export const getMetadata = (key: string) => createSelector(
    getState,
    (state) => _get(state, ['metadata', key])
)
export const isOffline = createSelector(
    getState,
    (state) => state.successiveNetworkErrors >= SUCCESSIVE_NETWORK_ERRORS_BEFORE_OFFLINE
)
