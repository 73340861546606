import * as React from 'react'
import { Intent } from '@blueprintjs/core'

import { prometheuses } from '../redux'
import { useDispatch, useTranslation } from '../hooks'
import { createUpdateMask } from '../utils'

import ConfirmButton from '../components/ConfirmButton'
import Button from '../components/Button'

type Props = {
    entry?: prometheuses.IPrometheus
}

const PrometheusDisableButton: React.FC<Props> = (props) => {
    const { entry } = props
    const dispatch = useDispatch()
    const [t] = useTranslation()

    if (!entry) {
        return null
    }

    const setEnabled = (value: boolean) => {
        dispatch(prometheuses.updateResources({
            name      : entry.name,
            resources : {
                enabled: value
            },
            updateMask: createUpdateMask(['resources.enabled'])
        }))
    }

    return !entry.resources?.enabled ? (
        <Button
            text={ t('Enable Prometheus') }
            intent={ Intent.SUCCESS }
            onClick={ () => setEnabled(true) }
        />
    ) : (
        <ConfirmButton
            button={ (
                <Button
                    text={ t('Disable Prometheus') }
                    intent={ Intent.WARNING }
                />
            ) }
            onConfirm={ () => setEnabled(false) }
            confirmationIcon="issue"
            confirmationText={ (
                <>
                    { t('Disabling Prometheus will disable metric collection for this project.') }
                    { t('Are you sure you want to continue?') }
                </>
            ) }
        />
    )
}

export default PrometheusDisableButton
