import * as React from 'react'
import { Flex, Box } from 'reflexbox'

import { forms, prometheuses } from '../redux'
import { useTranslation } from '../hooks'

import { withForm } from '../components/Form'
import FormContainer from '../components/FormContainer'
import TitleBar from '../components/TitleBar'
import CommonScalingSliderField from '../components/CommonScalingSliderField'
import PrometheusResourceAllocationSliderField from '../components/PrometheusResourceAllocationSliderField'
import TotalResourceUsageLabel from '../components/TotalResourceUsageLabel'
import PrometheusDisableButton from '../components/PrometheusDisableButton'

type Props = forms.FormProps<prometheuses.IPrometheus>

const PrometheusResourcesForm: React.FC<Props> = (props) => {
    const { values } = props
    const [t] = useTranslation()

    return (
        <FormContainer
            { ...props }
            isDisabled={ !values?.resources?.enabled }
            header={
                <TitleBar
                    title={ t('Prometheus Scaling Settings') }
                    subtitle={ t('Adjust the number of pods used to scale the Prometheus deployment') }
                    rightItem={ (
                        <PrometheusDisableButton entry={ values } />
                    ) }
                />
            }
        >
            <Flex column>
                <Flex>
                    <Box w={ 0.5 } mr={ 30 }>
                        <CommonScalingSliderField { ...props } />
                    </Box>
                    <Box w={ 0.5 }>
                        <TotalResourceUsageLabel entry={ values } />
                    </Box>
                </Flex>
                <TitleBar
                    title={ t('Pod Resource Allocation Settings') }
                    subtitle={ t('Configure resource allocation for each of the Prometheus pods') }
                    isSmall
                />
                <Flex>
                    <Box w={ 1 / 2 } mr={ 30 }>
                        <PrometheusResourceAllocationSliderField
                            resource={ prometheuses.ResourceType.cpuPerPod }
                            { ...props }
                        />
                    </Box>
                    <Box w={ 1 / 2 }>
                        <PrometheusResourceAllocationSliderField
                            resource={ prometheuses.ResourceType.memoryPerPod }
                            { ...props }
                        />
                    </Box>
                </Flex>
                <Flex>
                    <Box w={ 1 / 2 }>
                        <PrometheusResourceAllocationSliderField
                            resource={ prometheuses.ResourceType.storageSize }
                            { ...props }
                        />
                    </Box>
                </Flex>
            </Flex>
        </FormContainer>
    )
}

export default withForm({ name: forms.Name.updatePrometheusResources })(PrometheusResourcesForm)
