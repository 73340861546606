import * as React from 'react'
import { RangeSlider } from '@blueprintjs/core'
import { Flex } from 'reflexbox'
import classes from 'classnames'

import { map, get, pick, times, findIndex, toString, isFunction, isEqual } from 'lodash'

import { forms } from '../redux'
import { useTranslation } from '../hooks'

import FormGroup from '../components/FormGroup'
import SliderContainer from '../components/SliderContainer'
import DiscardButton from '../components/DiscardButton'
import { IconName } from '../components/Icon'

import styles from './SliderField.module.scss'

export type Step = number
export type InternalValue = [Step, Step]
export type Value = [number, number]
export type Scale = (step: Step) => number
export type Formatter = (parsedValue: Value, rawValue?: any) => string
export type Parser = (internalValue: InternalValue) => Value
export type LabelRenderer = (value: number) => string
export type ScaleRenderer = (step: Step) => string
export type Props = forms.FieldProps & {
    scale: Scale
    format: Formatter
    parse: Parser
    icon?: IconName
    labelRenderer?: LabelRenderer
    scaleRenderer?: ScaleRenderer
    className?: string
    isFat?: boolean
}

const RangeSliderField: React.FC<Props> = (props) => {
    const { field, form, parse, format, label, icon, intent } = props
    const { name } = field

    const errors = forms.getFieldErrors(form.errors, name)
    const steps = get(props, 'steps', 10)
    const min = get(props, 'min', 1)
    const max = get(props, 'max', steps)

    const rawValue = get(field, 'value', '')
    const value = isFunction(parse) ? parse(rawValue) : rawValue
    const scale = isFunction(props.scale) ? props.scale : (step: Step) => step
    const toStep = (value: any) => {
        const allValues = times(steps, (i) => scale(i + 1))
        const step = findIndex(allValues, (v) => isEqual(value, v))
        return step !== -1 ? step + 1 : 1
    }

    const initialRawValue = get(form.initialValues, name)
    const initialValue = isFunction(parse) ? parse(initialRawValue) : initialRawValue

    const isUnsaved = !isEqual(value, initialValue)

    const labelRenderer: LabelRenderer = isFunction(props.labelRenderer) ? props.labelRenderer : toString
    const scaleRenderer: ScaleRenderer = isFunction(props.scaleRenderer)
        ? props.scaleRenderer
        : (step: Step) => {
            if (step === 1 || step === max) {
                return labelRenderer(scale(step))
            }

            return ''
        }

    return (
        <SliderContainer
            title={ label }
            icon={ icon }
            rightItem={
                <Flex align="center">
                    { isUnsaved && (
                        <DiscardButton
                            onClick={ () => form.setFieldValue(name, initialRawValue) }
                        />
                    ) }
                    <ValueLabel value={ value } labelRenderer={ labelRenderer } />
                </Flex>
            }
        >
            <FormGroup
                errors={ forms.formatFieldErrors(label, errors) }
                { ...field }
            >
                <RangeSlider
                    { ...pick(props, ['style', 'disabled']) }
                    { ...field }
                    className={ classes(
                        props.className,
                        styles.slider,
                        props.isFat && styles.fat
                    ) }
                    min={ min }
                    max={ max }
                    intent={ intent }
                    labelRenderer={ scaleRenderer }
                    value={ map(value, toStep) as InternalValue }
                    onChange={ (steps: InternalValue) => {
                        const parsedValue = map(steps, scale) as Value
                        const outputValue = isFunction(format)
                            ? format(parsedValue, rawValue)
                            : map(parsedValue, toString)
                        form.setFieldValue(name, outputValue)
                    } }
                />
            </FormGroup>
        </SliderContainer>
    )
}

const ValueLabel: React.FC<{ value: InternalValue, labelRenderer: LabelRenderer }> = (props) => {
    const { value, labelRenderer } = props
    const [t] = useTranslation()

    return (
        <div className={ styles.valueLabel }>
            <span>{ t('min') }</span>&nbsp;<strong>{ labelRenderer(value[0]) }</strong>
            <span>&nbsp;&mdash;&nbsp;</span>
            <span>{ t('max') }</span>&nbsp;<strong>{ labelRenderer(value[1]) }</strong>
        </div>
    )
}

export default RangeSliderField
