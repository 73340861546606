import * as React from 'react'

import { app } from '../redux'
import { useDispatch, useEffect } from '../hooks'

import ConfigurationGate from '../containers/ConfigurationGate'
import AuthenticationGate from '../containers/AuthenticationGate'
import ConnectivityGate from '../containers/ConnectivityGate'
import ContentContainer from '../components/ContentContainer'
import Router from '../containers/Router'

import OrganizationSwitcher from '../components/OrganizationSwitcher'
import PageMeta from '../components/PageMeta'
import Footer from '../components/Footer'
import NavBar from '../components/NavBar'

const App: React.FC = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(app.initialize())
    }, [dispatch])

    return (
        <ConfigurationGate>
            <AuthenticationGate>
                <PageMeta />
                <OrganizationSwitcher />
                <NavBar />
                <ContentContainer>
                    <ConnectivityGate>
                        <Router />
                    </ConnectivityGate>
                    <Footer />
                </ContentContainer>
            </AuthenticationGate>
        </ConfigurationGate>
    )
}

export default App
