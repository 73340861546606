import * as React from 'react'

import styles from './ContentContainer.module.scss'

const ContentContainer: React.FC = ({ children }) => (
    <div className={ styles.container }>
        { children }
    </div>
)

export default ContentContainer
