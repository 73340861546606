import * as React from 'react'
import { connect } from 'react-redux'
import { Field } from 'formik'
import { Flex } from 'reflexbox'
import { Intent, Elevation } from '@blueprintjs/core'

import { get } from 'lodash'

import { RootState, api, auth, forms, organizations, ui, navigation } from '../redux'
import { useTranslation, useDispatch } from '../hooks'

import { withForm } from '../components/Form'
import TitleBar from '../components/TitleBar'
import Container from '../components/Container'
import FormContainer from '../components/FormContainer'
import InputField from '../components/InputField'
import ImageUploadField from '../components/ImageUploadField'
import Card from '../components/Card'
import ConfirmButton from '../components/ConfirmButton'

type ReduxProps = {
    isAdmin: boolean
}
type Props = forms.FormProps<{ organization: organizations.IOrganization }> & ReduxProps

const OrganizationGeneralForm: React.FC<Props> = (props) => {
    const { initialValues, isAdmin } = props
    const [t] = useTranslation()
    const dispatch = useDispatch()
    const entry = get(initialValues, 'organization')

    return (
        <Container>
            <FormContainer
                header={ (
                    <TitleBar
                        title={ t(ui.createTitle(api.Resource.organization, navigation.MenuEntry.general)) }
                    />
                ) }
                footer={ isAdmin && (
                    <Card
                        title={ t(ui.createTitle(api.Resource.organization, api.Request.destroy)) }
                        subtitle={ t('Once you delete an organization, all sites, '
                                     + 'projects and users belonging to it will also be deleted.') }
                        elevation={ Elevation.ZERO }
                        intent={ Intent.DANGER }
                    >
                        <ConfirmButton
                            onConfirm={ () => dispatch(organizations.destroy(entry)) }
                            confirmationText={ t(ui.createTitle(api.Resource.organization, ui.Action.confirm)) }
                            text={ t(ui.createTitle(api.Resource.organization, api.Request.destroy)) }
                        />
                    </Card>
                ) }
                { ...props }
            >
                <Flex w={ 0.5 } column>
                    <Field
                        name="organization.displayName"
                        label={ t(organizations.FieldName.displayName) }
                        component={ InputField }
                        autoFocus
                    />
                    <Field
                        name="organization.iconUrl"
                        label={ t(organizations.FieldName.iconUrl) }
                        component={ ImageUploadField }
                        autoFocus
                    />
                </Flex>
            </FormContainer>
        </Container>
    )
}

function mapStateToProps(state: RootState): ReduxProps {
    const isAdmin = auth.isAdmin(state)

    return {
        isAdmin
    }
}

export default withForm({
    name: forms.Name.updateOrganizationSettings
})(connect(mapStateToProps)(OrganizationGeneralForm))
