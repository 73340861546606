import { Dispatch } from 'redux'
import { has } from 'lodash'
import { Selector as ReselectSelector } from 'reselect'

import * as app from './app'
import * as api from './api'
import * as grpc from './grpc'
import * as auth from './auth'
import * as forms from './forms'
import * as routing from './routing'
import * as organizations from './organizations'
import * as projects from './projects'
import * as sites from './sites'
import * as events from './events'
import * as memcacheds from './memcacheds'
import * as mysqlClusters from './mysqlClusters'
import * as mysqlClusterBackups from './mysqlClusterBackups'
import * as grafanas from './grafanas'
import * as prometheuses from './prometheuses'
import * as accountBindings from './accountBindings'
import * as invites from './invites'
import * as pods from './pods'
import * as nodes from './nodes'
import * as affinities from './affinities'
import * as tolerations from './tolerations'
import * as statuses from './statuses'
import * as conditions from './conditions'
import * as operations from './operations'
import * as resources from './resources'
import * as system from './system'
import * as data from './data'
import * as search from './search'
import * as wizard from './wizard'
import * as ui from './ui'
import * as errors from './errors'
import * as navigation from './navigation'


export type RootState = {
    app: app.State
    grpc: grpc.State
    auth: auth.State
    routing: routing.State
    organizations: organizations.State
    projects: projects.State
    sites: sites.State
    events: events.State
    memcacheds: memcacheds.State
    mysqlClusters: mysqlClusters.State
    mysqlClusterBackups: mysqlClusterBackups.State
    prometheuses: prometheuses.State
    grafanas: grafanas.State
    accountBindings: accountBindings.State
    invites: invites.State
    pods: pods.State
    nodes: nodes.State
    system: system.State
    search: search.State
    wizard: wizard.State
    ui: ui.State
}

export type Reducer = (state: RootState | undefined, action: AnyAction) => RootState

export type AnyAction =
    app.Actions
    | grpc.Actions
    | auth.Actions
    | forms.Actions
    | routing.Actions
    | organizations.Actions
    | projects.Actions
    | sites.Actions
    | accountBindings.Actions
    | invites.Actions
    | nodes.Actions
    | search.Actions
    | wizard.Actions
    | ui.Actions

export type Selector<Output> = ReselectSelector<RootState, Output>
export type SelectorCreator<Filter, Output> = (filter: Filter) => Selector<Output>
export type ActionDescriptor = string
export type ActionWithPayload = { type: ActionDescriptor, payload: any }

export type DispatchProp = { dispatch: Dispatch }

export function isAction(payload: any): payload is AnyAction {
    return has(payload, 'type')
}

export function isActionWithPayload(payload: any): payload is ActionWithPayload {
    return has(payload, 'type') && has(payload, 'payload')
}

export {
    app,
    api,
    grpc,
    auth,
    forms,
    routing,
    organizations,
    projects,
    sites,
    events,
    memcacheds,
    mysqlClusters,
    mysqlClusterBackups,
    prometheuses,
    grafanas,
    accountBindings,
    invites,
    pods,
    nodes,
    affinities,
    tolerations,
    statuses,
    conditions,
    operations,
    resources,
    system,
    data,
    search,
    wizard,
    ui,
    errors,
    navigation
}
