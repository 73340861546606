import * as React from 'react'

import { get, join, round, min } from 'lodash'

import { nodes } from '../redux'
import { useTranslation } from '../hooks'
import { parseQuantity, formatQuantity } from '../utils'

import ProgressIndicator from '../components/ProgressIndicator'

type Props = {
    resource: nodes.NodeResourceType
    entry?: nodes.INode
}

const NodeResourceUsageIndicator: React.FC<Props> = (props) => {
    const { resource, entry } = props
    const [t] = useTranslation()

    const usage = get(entry, join(['status', 'used', resource], '.'), 0)
    const capacity = get(entry, join(['status', 'allocatable', resource], '.'), 0)

    const usagePercent = min([
        parseQuantity(capacity) > 0 ? round((parseQuantity(usage) / parseQuantity(capacity)) * 100, 1) : 0,
        100
    ]) as number

    const omitUnit = resource !== nodes.NodeResourceType.memory

    return (
        <ProgressIndicator
            percent={ usagePercent }
            label={ t('Usage: {{percent}}% ({{used}}/{{capacity}})', {
                percent  : usagePercent,
                used     : formatQuantity(parseQuantity(usage), { omitUnit }),
                capacity : formatQuantity(parseQuantity(capacity), { omitUnit })
            }) }
        />
    )
}

export default NodeResourceUsageIndicator
