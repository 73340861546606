/* eslint-disable max-len */

import * as React from 'react'
import { Props } from '../../components/BaseIcon'

const OrganizationIcon: React.FC<Props> = (props) => (
    <svg width={ 317 } height={ 236 } preserveAspectRatio="xMaxYMax meet" fill="none" viewBox="0 0 317 216" { ...props }>
        <g stroke="#EEF2F8" strokeMiterlimit="10">
            <path d="M201.836 190.327L44.2031 99.3231" />
            <path d="M316.484 124.136L158.852 33.0591" />
            <path d="M302.107 132.382L144.475 41.3785" />
            <path d="M259.123 157.195L101.491 66.191" />
            <path d="M273.5 148.948L115.867 57.9446" />
            <path d="M287.804 140.629L130.171 49.625" />
            <path d="M244.82 165.514L87.187 74.4376" />
            <path d="M187.459 198.573L29.8262 107.57" />
            <path d="M216.139 182.007L58.5068 91.0037" />
            <path d="M230.516 173.761L72.8105 82.7571" />
            <path d="M173.155 206.82L15.5227 115.816" />
            <path d="M1.21899 124.136L158.852 215.139" />
            <path d="M101.491 182.007L259.123 91.0037" />
            <path d="M1.21899 124.136L158.852 33.0591" />
            <path d="M15.5227 132.382L173.155 41.3785" />
            <path d="M115.867 190.327L273.5 99.3231" />
            <path d="M29.8262 140.629L187.459 49.625" />
            <path d="M87.187 173.761L244.82 82.7571" />
            <path d="M44.2031 148.948L201.836 57.9446" />
            <path d="M72.8105 165.514L230.516 74.4376" />
            <path d="M58.5068 157.195L216.139 66.191" />
            <path d="M130.171 198.573L287.804 107.57" />
            <path d="M144.475 206.82L302.107 115.816" />
            <path d="M158.852 215.139L316.484 124.136" />
        </g>
        <path d="M158.851 198.573L287.804 124.136L158.851 49.625L29.8262 124.136L158.851 198.573Z" stroke="#B4C6DF" strokeMiterlimit="10" strokeDasharray="4 4" />
        <path opacity="0.1" d="M244.82 132.382L230.516 124.136L201.836 140.629L173.155 124.136L158.852 132.382L144.475 124.136L115.868 140.629L87.1872 124.136L72.8105 132.382L115.868 157.195L130.171 148.948L158.852 165.441L187.459 148.948L201.836 157.195L244.82 132.382Z" fill="#89A5CC" />
        <g opacity="0.8">
            <path d="M72.8103 66.191V115.816L115.867 140.629V91.0036L72.8103 66.191Z" fill="#9396C1" />
            <path d="M158.852 66.191V115.816L115.867 140.629V91.0036L158.852 66.191Z" fill="#303284" />
            <path d="M158.851 66.1911L115.867 91.0036L72.8103 66.1911L115.867 41.3785L158.851 66.1911Z" fill="#C5C7E0" />
            <path d="M158.852 66.191V115.816L201.836 140.629V91.0036L158.852 66.191Z" fill="#7BAEF5" />
            <path d="M244.82 66.191V115.816L201.835 140.629V91.0036L244.82 66.191Z" fill="#297BEF" />
            <path d="M244.82 66.1911L201.836 91.0036L158.852 66.1911L201.836 41.3785L244.82 66.1911Z" fill="#CCE1FC" />
            <path d="M115.867 24.8125V74.4376L158.852 99.3231V49.625L115.867 24.8125Z" fill="#618AC2" />
            <path d="M201.836 24.8125V74.4376L158.852 99.3231V49.625L201.836 24.8125Z" fill="#00419B" />
            <path d="M201.836 24.8125L158.852 49.6251L115.867 24.8125L158.852 0L201.836 24.8125Z" fill="#C5D5EB" />
        </g>
    </svg>
)

export default OrganizationIcon
