/* eslint-disable no-use-before-define */
import { ActionType, action as createAction } from 'typesafe-actions'
import { takeEvery, put, take, fork, race, call, select } from 'redux-saga/effects'
import { channel as createChannel } from 'redux-saga'
import { UserManager } from 'oidc-client'
import { createSelector } from 'reselect'
import { Intent } from '@blueprintjs/core'
import jwtDecode from 'jwt-decode'

import {
    map, reduce, find, get, filter, omit, flatMap, flatten, concat, join, split, replace,
    without, reject, uniq, startCase, has, includes, isEmpty, isEqual
} from 'lodash'

import { bitpoke, google } from '@bitpoke/bitpoke-proto'

import { RootState, Selector, SelectorCreator, grpc, api, forms, auth, ui } from '../redux'
import { Maybe, watchChannel, objectHash, toBoolean, isEmail, isURL } from '../utils'
import config from '../config'
import { i18n } from '../i18n'

const {
    SystemStatus,
    ApplicationStatus,
    ComponentStatus,
    Component,
    AdminUser,
    AuthStatus,
    AuthConfiguration,
    ComponentScheduling,
    LetsEncryptConfiguration,
    ConfigConnectorConfiguration,
    UpdateAuthConfigurationRequest,
    UpdateLetsEncryptConfigurationRequest,
    GetComponentSchedulingRequest,
    ListComponentSchedulingsRequest,
    ListComponentSchedulingsResponse,
    UpdateComponentSchedulingRequest,
    UpdateAdminUsersRequest,
    AdminUsersResponse,
    SystemStatusesService
} = bitpoke.systems.v1

const {
    Status: ComponentStatusStatus
} = ComponentStatus

export {
    SystemStatus,
    ApplicationStatus,
    ComponentStatus,
    Component,
    AdminUser,
    AuthStatus,
    AuthConfiguration,
    ComponentScheduling,
    LetsEncryptConfiguration,
    ConfigConnectorConfiguration,
    UpdateAuthConfigurationRequest,
    UpdateLetsEncryptConfigurationRequest,
    GetComponentSchedulingRequest,
    ListComponentSchedulingsRequest,
    UpdateComponentSchedulingRequest,
    UpdateAdminUsersRequest,
    AdminUsersResponse,
    SystemStatusesService
}

export type SystemStatus                           = bitpoke.systems.v1.SystemStatus
export type ISystemStatus                          = bitpoke.systems.v1.ISystemStatus
export type ApplicationStatus                      = bitpoke.systems.v1.ApplicationStatus
export type IApplicationStatus                     = bitpoke.systems.v1.IApplicationStatus
export type ComponentStatus                        = bitpoke.systems.v1.ComponentStatus
export type IComponentStatus                       = bitpoke.systems.v1.IComponentStatus
export type ComponentStatusStatus                  = bitpoke.systems.v1.ComponentStatus.Status
export type Component                              = bitpoke.systems.v1.Component
export type IComponent                             = bitpoke.systems.v1.IComponent
export type CRD                                    = bitpoke.systems.v1.CRD
export type ICRD                                   = bitpoke.systems.v1.ICRD
export type CRDStatus                              = bitpoke.systems.v1.CRDStatus
export type ICRDStatus                             = bitpoke.systems.v1.ICRDStatus
export type AdminUser                              = bitpoke.systems.v1.AdminUser
export type AuthStatus                             = bitpoke.systems.v1.AuthStatus
export type IAuthStatus                            = bitpoke.systems.v1.IAuthStatus
export type AuthConfiguration                      = bitpoke.systems.v1.AuthConfiguration
export type IAuthConfiguration                     = bitpoke.systems.v1.IAuthConfiguration
export type ComponentScheduling                    = bitpoke.systems.v1.ComponentScheduling
export type IComponentScheduling                   = bitpoke.systems.v1.IComponentScheduling
export type LetsEncryptConfiguration               = bitpoke.systems.v1.LetsEncryptConfiguration
export type ILetsEncryptConfiguration              = bitpoke.systems.v1.ILetsEncryptConfiguration
export type ConfigConnectorConfiguration           = bitpoke.systems.v1.ConfigConnectorConfiguration
export type IConfigConnectorConfiguration          = bitpoke.systems.v1.IConfigConnectorConfiguration
export type UpdateAdminUsersRequest                = bitpoke.systems.v1.UpdateAdminUsersRequest
export type IUpdateAdminUsersRequest               = bitpoke.systems.v1.IUpdateAdminUsersRequest
export type UpdateAuthConfigurationRequest         = bitpoke.systems.v1.UpdateAuthConfigurationRequest
export type IUpdateAuthConfigurationRequest        = bitpoke.systems.v1.IUpdateAuthConfigurationRequest
export type GetComponentSchedulingRequest          = bitpoke.systems.v1.GetComponentSchedulingRequest
export type IGetComponentSchedulingRequest         = bitpoke.systems.v1.IGetComponentSchedulingRequest
export type ListComponentSchedulingsRequest        = bitpoke.systems.v1.ListComponentSchedulingsRequest
export type IListComponentSchedulingsRequest       = bitpoke.systems.v1.IListComponentSchedulingsRequest
export type UpdateComponentSchedulingRequest       = bitpoke.systems.v1.UpdateComponentSchedulingRequest
export type IUpdateComponentSchedulingRequest      = bitpoke.systems.v1.IUpdateComponentSchedulingRequest
export type UpdateLetsEncryptConfigurationRequest  =
    bitpoke.systems.v1.UpdateLetsEncryptConfigurationRequest
export type IUpdateLetsEncryptConfigurationRequest =
    bitpoke.systems.v1.IUpdateLetsEncryptConfigurationRequest

export interface IAdminUser extends bitpoke.systems.v1.IAdminUser {
    email: string
}

export type State = {
    status: ISystemStatus
    config: IConfiguration
    validAuthConfigs: Record<string, boolean>
}

export type IConfiguration = {
    auth: IAuthConfiguration
    letsEncrypt: ILetsEncryptConfiguration
    configConnector: IConfigConnectorConfiguration
    componentSchedulings: Record<string, IComponentScheduling>
    adminUsers: IAdminUser[]
}

export enum ComponentKind {
    deployment = 'Deployment',
    statefulSet = 'StatefulSet',
    service = 'Service'
}

export type Actions = ActionType<typeof actions>

export enum FieldName {
    adminUsers = 'Admin User Email',
    oidcIssuer = 'OIDC Issuer',
    oidcClientId = 'OIDC Client ID',
    oidcClientSecret = 'OIDC Client Secret',

    certificateEmail = 'Email',
    certificateServer = 'Server'
}

export enum FieldDescription {
    adminUsers = 'A valid email address',
    oidcIssuer = 'A valid URL pointing to the OIDC server',

    certificateEmail = 'Let’s Encrypt will use this email to notify you in case your certificates expire',
    certificateServer = 'Let’s Encrypt <0>API endpoint</0>'
}

export enum SectionDescription {
    authentication = 'Bitpoke App uses OpenID Connect (OIDC) protocol for authentication. '
                   + 'Here you can set an OIDC provider such as <0>Auth0</0> or <1>Google</1>',
    letsEncrypt = 'Certificates are managed using <0>cert-manager</0> '
                + 'and signed by <1>Let’s Encrypt</1> certificate authority.',
    configConnector = '<0>Config Connector</0> allows you to manage '
                    + 'Google Cloud resources and services through '
                    + 'Kubernetes configuration.'

}

const service = SystemStatusesService.create(
    grpc.createTransport('bitpoke.systems.v1.SystemStatusesService')
)

//
//  ACTIONS

export const fetchStatus = () => grpc.invoke({
    service,
    method       : 'getSystemStatus',
    data         : google.protobuf.Empty,
    responseType : SystemStatus
})

export const fetchAuthConfiguration = () => grpc.invoke({
    service,
    method       : 'getAuthConfiguration',
    data         : google.protobuf.Empty,
    responseType : AuthConfiguration
})

export const updateAuthConfiguration = (payload: IUpdateAuthConfigurationRequest) => grpc.invoke({
    service,
    method       : 'updateAuthConfiguration',
    data         : UpdateAuthConfigurationRequest.create(payload),
    responseType : AuthConfiguration
})

export const fetchLetsEncryptConfiguration = () => grpc.invoke({
    service,
    method       : 'getLetsEncryptConfiguration',
    data         : google.protobuf.Empty,
    responseType : LetsEncryptConfiguration
})

export const updateLetsEncryptConfiguration = (payload: IUpdateLetsEncryptConfigurationRequest) => grpc.invoke({
    service,
    method       : 'updateLetsEncryptConfiguration',
    data         : UpdateLetsEncryptConfigurationRequest.create(payload),
    responseType : LetsEncryptConfiguration
})

export const fetchConfigConnectorConfiguration = () => grpc.invoke({
    service,
    method       : 'getConfigConnectorConfiguration',
    data         : google.protobuf.Empty,
    responseType : ConfigConnectorConfiguration
})

export const fetchAdminUsers = () => grpc.invoke({
    service,
    method       : 'getAdminUsers',
    data         : google.protobuf.Empty,
    responseType : AdminUsersResponse
})

export const updateAdminUsers = (payload: IUpdateAdminUsersRequest) => grpc.invoke({
    service,
    method       : 'updateAdminUsers',
    data         : UpdateAdminUsersRequest.create(payload),
    responseType : AdminUsersResponse
})

export const fetchComponentScheduling = (payload: IGetComponentSchedulingRequest) => grpc.invoke({
    service,
    method       : 'getComponentScheduling',
    data         : GetComponentSchedulingRequest.create(payload),
    responseType : ComponentScheduling
})

export const listComponentSchedulings = (payload?: IListComponentSchedulingsRequest) => grpc.invoke({
    service,
    method       : 'listComponentSchedulings',
    data         : ListComponentSchedulingsRequest.create(payload),
    responseType : ListComponentSchedulingsResponse
})

export const updateComponentScheduling = (payload: IUpdateComponentSchedulingRequest) => grpc.invoke({
    service,
    method       : 'updateComponentScheduling',
    data         : UpdateComponentSchedulingRequest.create(payload),
    responseType : ComponentScheduling
})

export const destroyAdminUser = (payload: IAdminUser) => createAction(adminUser.DESTROY_REQUESTED, payload)

export const verifyAuthConfiguration = (config: IAuthConfiguration) => (
    createAction(authConfiguration.VERIFY_REQUESTED, config)
)
export const verifyAuthConfigurationSuccess = (config: IAuthConfiguration) => (
    createAction(authConfiguration.VERIFY_SUCCEEDED, config)
)
export const verifyAuthConfigurationFailure = (error: Error) => (
    createAction(authConfiguration.VERIFY_FAILED, error)
)


const actions = {
    fetchStatus,
    fetchAuthConfiguration,
    updateAuthConfiguration,
    fetchLetsEncryptConfiguration,
    updateLetsEncryptConfiguration,
    fetchAdminUsers,
    updateAdminUsers
}

const apiTypes = {
    adminUser                    : api.createActionTypes(api.Resource.adminUser, 'system'),
    systemStatus                 : api.createActionTypes(api.Resource.systemStatus, 'system'),
    componentScheduling          : api.createActionTypes(api.Resource.componentScheduling, 'system'),
    letsEncryptConfiguration     : api.createActionTypes(api.Resource.letsEncryptConfiguration, 'system'),
    configConnectorConfiguration : api.createActionTypes(api.Resource.configConnectorConfiguration, 'system'),
    authConfiguration            : {
        ...api.createActionTypes(api.Resource.authConfiguration, 'system'),
        VERIFY_REQUESTED : '@ system.auth_configuration / VERIFY_REQUESTED',
        VERIFY_SUCCEEDED : '@ system.auth_configuration / VERIFY_SUCCEEDED',
        VERIFY_FAILED    : '@ system.auth_configuration / VERIFY_FAILED'
    }
}

const {
    adminUser,
    systemStatus,
    componentScheduling,
    authConfiguration,
    letsEncryptConfiguration,
    configConnectorConfiguration
} = apiTypes

export {
    adminUser,
    systemStatus,
    componentScheduling,
    authConfiguration,
    letsEncryptConfiguration,
    configConnectorConfiguration
}


//
//  REDUCER

const initialState: State = {
    status : {},
    config : {
        auth                 : {},
        letsEncrypt          : {},
        configConnector      : {},
        componentSchedulings : {},
        adminUsers           : []
    },
    validAuthConfigs: {}
}

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case systemStatus.GET_SUCCEEDED: {
            return {
                ...state,
                status: action.payload.data
            }
        }

        case componentScheduling.GET_SUCCEEDED:
        case componentScheduling.UPDATE_SUCCEEDED: {
            const component = get(action.payload.data, 'component')
            if (!component) {
                return state
            }

            return {
                ...state,
                config: {
                    ...state.config,
                    componentSchedulings: {
                        ...state.config.componentSchedulings,
                        [componentId(component)]: action.payload.data
                    }
                }
            }
        }

        case componentScheduling.LIST_SUCCEEDED: {
            const componentSchedulings = get(action.payload.data, 'componentSchedulings')
            return {
                ...state,
                config: {
                    ...state.config,
                    componentSchedulings: reduce(componentSchedulings, (acc, componentScheduling) => ({
                        ...acc,
                        [componentId(componentScheduling.component)]: componentScheduling
                    }), {})
                }
            }
        }

        case authConfiguration.GET_SUCCEEDED:
        case authConfiguration.UPDATE_SUCCEEDED: {
            return {
                ...state,
                config: {
                    ...state.config,
                    auth: omit(action.payload.data, 'oidcClientSecret')
                }
            }
        }

        case letsEncryptConfiguration.GET_SUCCEEDED:
        case letsEncryptConfiguration.UPDATE_SUCCEEDED: {
            return {
                ...state,
                config: {
                    ...state.config,
                    letsEncrypt: action.payload.data
                }
            }
        }

        case adminUser.GET_SUCCEEDED:
        case adminUser.UPDATE_SUCCEEDED: {
            return {
                ...state,
                config: {
                    ...state.config,
                    adminUsers: get(action.payload.data, 'adminUsers', [])
                }
            }
        }

        case configConnectorConfiguration.GET_SUCCEEDED: {
            return {
                ...state,
                config: {
                    ...state.config,
                    configConnector: action.payload.data
                }
            }
        }

        case authConfiguration.VERIFY_SUCCEEDED: {
            const config = action.payload
            return {
                ...state,
                validAuthConfigs: {
                    ...state.validAuthConfigs,
                    [objectHash(config)]: true
                }
            }
        }

        case authConfiguration.VERIFY_FAILED: {
            const config = action.payload
            return {
                ...state,
                validAuthConfigs: {
                    ...state.validAuthConfigs,
                    [objectHash(config)]: false
                }
            }
        }

        case authConfiguration.GET_FAILED: {
            const errorCode = get(action, 'payload.error.code')
            if (errorCode === grpc.StatusCode.NotFound) {
                return {
                    ...state,
                    auth: {}
                }
            }

            return state
        }

        case configConnectorConfiguration.GET_FAILED: {
            const errorCode = get(action, 'payload.error.code')
            if (errorCode === grpc.StatusCode.NotFound) {
                return {
                    ...state,
                    configConnector: {}
                }
            }

            return state
        }

        case letsEncryptConfiguration.GET_FAILED: {
            const errorCode = get(action, 'payload.error.code')
            if (errorCode === grpc.StatusCode.NotFound) {
                return {
                    ...state,
                    letsEncrypt: {}
                }
            }

            return state
        }

        default:
            return state
    }
}

//
//  SAGA

const channel = createChannel()

export function* saga() {
    yield fork(api.emitResourceActions, api.Resource.adminUser, apiTypes.adminUser)
    yield fork(api.emitResourceActions, api.Resource.systemStatus, apiTypes.systemStatus)
    yield fork(api.emitResourceActions, api.Resource.authConfiguration, apiTypes.authConfiguration)
    yield fork(api.emitResourceActions, api.Resource.componentScheduling, apiTypes.componentScheduling)
    yield fork(api.emitResourceActions, api.Resource.letsEncryptConfiguration, apiTypes.letsEncryptConfiguration)
    yield fork(
        api.emitResourceActions,
        api.Resource.configConnectorConfiguration,
        apiTypes.configConnectorConfiguration
    )

    yield fork(forms.takeEverySubmission, [
        forms.Name.updateAuthConfiguration,
        forms.Name.updateLetsEncryptConfiguration,
        forms.Name.updateSystemComponentScheduling,
        forms.Name.createAdminUser
    ], handleFormSubmit)

    yield takeEvery(adminUser.DESTROY_REQUESTED, handleAdminUserRemoval)
    yield takeEvery(authConfiguration.VERIFY_REQUESTED, handleAuthConfigVerification)
    yield takeEvery([
        authConfiguration.VERIFY_SUCCEEDED, authConfiguration.VERIFY_FAILED
    ], notifyAboutVerification)
    yield fork(watchChannel, channel)
}


function* handleFormSubmit(action: ActionType<typeof forms.submit>) {
    const { name, values, initialValues, helpers } = action.payload

    const formMapping = {
        [forms.Name.updateAuthConfiguration]: [
            updateAuthConfiguration, apiTypes.authConfiguration
        ],
        [forms.Name.updateLetsEncryptConfiguration]: [
            updateLetsEncryptConfiguration, apiTypes.letsEncryptConfiguration
        ],
        [forms.Name.updateSystemComponentScheduling]: [
            updateComponentScheduling, apiTypes.componentScheduling
        ],
        [forms.Name.createAdminUser]: [
            updateAdminUsers, apiTypes.adminUser
        ]
    }

    const mapping = get(formMapping, name)
    if (!mapping) {
        return
    }

    const [handler, types] = mapping

    if (!handler) {
        return
    }

    const resolvers = forms.getResolvers(handler, types)

    if (!resolvers) {
        yield call(helpers.setSubmitting, false)
        return
    }

    const updateMask = forms.createUpdateMaskFromValues(values, initialValues)
    yield put(handler({ ...values, updateMask }))

    const { successResult, failureResult } = yield race({
        successResult : take(resolvers.success),
        failureResult : take(resolvers.failure)
    })

    yield call(helpers.setSubmitting, false)

    if (failureResult) {
        const errors = api.getFieldErrorsFromAction(failureResult)
        yield fork(ui.notifyAboutAction, failureResult)
        yield call(helpers.setErrors, errors)
    }
    else {
        yield call(helpers.resetForm)
        yield fork(ui.notifyAboutAction, successResult)
    }
}

function* handleAdminUserRemoval(action: ActionType<typeof destroyAdminUser>) {
    const adminToDelete = action.payload
    const currentAdminUsers: IAdminUser[] = yield select(getAdminUsers)
    const updatedAdminUsers: IAdminUser[] = reject(currentAdminUsers, { email: adminToDelete.email })

    yield put(updateAdminUsers({ adminUsers: updatedAdminUsers }))

    const { success } = yield race({
        success : apiTypes.adminUser.UPDATE_SUCCEEDED,
        failure : apiTypes.adminUser.UPDATE_FAILED
    })

    if (success) {
        yield put({ type: apiTypes.adminUser.DESTROY_SUCCEEDED, payload: action.payload })
    }
    else {
        yield put({ type: apiTypes.adminUser.DESTROY_FAILED, payload: action.payload })
    }
}

function handleAuthConfigVerification(action: ActionType<typeof verifyAuthConfiguration>) {
    const config = action.payload

    if (!has(global, 'window')) {
        return
    }

    if (!auth.isValidConfiguration(config)) {
        return
    }

    const provider = new UserManager({
        authority : config.oidcIssuer as string,
        client_id : config.oidcClientId as string,
        ...auth.DEFAULT_OIDC_SETTINGS
    })

    provider.signinPopup().then((user) => {
        const token = jwtDecode(user.id_token)
        const issuer = get(token, 'iss')
        if (isEqual(issuer, config.oidcIssuer)) {
            channel.put(verifyAuthConfigurationSuccess(config))
        }
        else {
            const error = new Error(`Issuer mismatch, expected "${config.oidcIssuer}" but received "${issuer}"`)
            channel.put(verifyAuthConfigurationFailure(error))
        }
    }).catch((error) => {
        channel.put(verifyAuthConfigurationFailure(error))
    })
}

function* notifyAboutVerification(
    action: ActionType<typeof verifyAuthConfigurationSuccess | typeof verifyAuthConfigurationFailure>
) {
    switch (action.type) {
        case authConfiguration.VERIFY_SUCCEEDED: {
            yield put(ui.showToast({
                message : i18n.t('The configuration is valid'),
                intent  : Intent.SUCCESS,
                icon    : 'ok-circle'
            }))
            break
        }

        case authConfiguration.VERIFY_FAILED: {
            const error = action.payload as Error
            const message = error
                ? i18n.t('The configuration is not valid: {{error}}', {
                    error         : error.message,
                    interpolation : { escapeValue: false }
                })
                : i18n.t('The configuration is not valid')

            channel.put(ui.showToast({
                message,
                intent       : Intent.DANGER,
                icon         : 'delete-circle',
                isPersistent : true
            }))
            break
        }

        default:
            break
    }
}

export function componentDisplayName(component: IComponent, extraNamespaces?: string[]) {
    const name = get(component, 'name', '') as string
    const segments = split(replace(name, 'components/', ''), '-')

    const namespacesToStrip = [
        ...split(config.REACT_APP_DASHBOARD_PREFIX, '-'),
        ...split(config.REACT_APP_STACK_PREFIX, '-'),
        ...flatMap(extraNamespaces, (ns: string) => split(ns, '-')),
        'default',
        'cnrm'
    ]

    const segmentsWithoutNamespaces = without(segments, ...namespacesToStrip)

    switch (join(segmentsWithoutNamespaces, '-')) {
        case 'alertmanager-prometheus-operator-alertmanager':
            return 'Prometheus Alertmanager'

        case 'deletiondefender':
            return 'Deletion Defender'

        case 'apiserver':
            return 'API Server'

        case 'wordpress-operator':
            return 'WordPress Operator'

        case 'mysql-operator':
            return 'MySQL Operator'

        case 'configconnector-operator':
            return 'Config Connector Operator'

        case 'nginx-ingress-controller':
            return startCase(join([...uniq(segmentsWithoutNamespaces), component?.kind], ' '))

        default:
            return startCase(join(uniq(segmentsWithoutNamespaces), ' '))
    }
}

export function componentId(component: IComponent): string {
    return join([component.namespace, component.name, component.kind], '.')
}

export function componentStatusIntent(status: Maybe<ComponentStatusStatus>) {
    if (!status) {
        return Intent.NONE
    }

    const mapping: Record<ComponentStatusStatus, Intent> = {
        [ComponentStatusStatus.UNSPECIFIED] : Intent.NONE,
        [ComponentStatusStatus.UNKNOWN]     : Intent.NONE,
        [ComponentStatusStatus.READY]       : Intent.SUCCESS,
        [ComponentStatusStatus.IN_PROGRESS] : Intent.WARNING
    }

    return get(mapping, status, Intent.NONE)
}

export function statusIntent(entry: IApplicationStatus | IAuthStatus | ICRDStatus): Intent {
    return isReady(entry) ? Intent.SUCCESS : Intent.WARNING
}

export function isReady(entry: IApplicationStatus | IAuthStatus | ICRDStatus): boolean {
    const readyCondition = find(entry.conditions, { name: 'Ready' })
    return toBoolean(get(readyCondition, 'status'))
}

export function readyMessage(entry: IApplicationStatus | IAuthStatus | ICRDStatus): Maybe<string> {
    return find(entry.conditions, { name: 'Ready' })?.message
}

export function isCertificateIssuer(component: IComponent): boolean {
    return component.kind === 'ClusterIssuer'
}

export function isAdminUser(entry: any): entry is IAdminUser {
    return isEmail(get(entry, 'email'))
}


//
//  SELECTORS

const getState: Selector<State> = (state: RootState) => state.system
export const getStatus: Selector<ISystemStatus> = createSelector(
    getState,
    (state) => state.status
)
export const getConfiguration: Selector<IConfiguration> = createSelector(
    getState,
    (state) => state.config
)
export const getAuthConfiguration: Selector<IAuthConfiguration> = createSelector(
    getConfiguration,
    (config) => config.auth
)
export const getAdminUsers: Selector<IAdminUser[]> = createSelector(
    getConfiguration,
    (config) => config.adminUsers
)
export const getLetsEncryptConfiguration: Selector<ILetsEncryptConfiguration> = createSelector(
    getConfiguration,
    (config) => config.letsEncrypt
)
export const getConfigConnectorConfiguration: Selector<IConfigConnectorConfiguration> = createSelector(
    getConfiguration,
    (config) => config.configConnector
)
export const getSchedulableComponents: Selector<IComponent[]> = createSelector(
    getStatus,
    (status) => filter(map(flatten(concat([
        status.stack?.components,
        status.dashboard?.components,
        status.configConnector?.components
    ])), 'component'), (component: IComponent) => (
        includes([ComponentKind.deployment, ComponentKind.statefulSet], component?.kind)
    ))
)
export const getComponent: SelectorCreator<string, Maybe<IComponent>> = (
    componentId: string
) => createSelector(
    getConfiguration,
    (config) => get(config.componentSchedulings, componentId)?.component
)
export const getComponentScheduling: SelectorCreator<IComponent, Maybe<IComponentScheduling>> = (
    component: IComponent
) => createSelector(
    getConfiguration,
    (config) => get(config.componentSchedulings, componentId(component))
)
export const isLetsEncryptConfigured: Selector<boolean> = createSelector(
    getLetsEncryptConfiguration,
    (config) => !isEmpty(config) && isEmail(config.email) && isURL(config.server)
)
export const isAuthConfigured: Selector<boolean> = createSelector(
    getAuthConfiguration,
    (config) => auth.isValidConfiguration(config)
)
export const isAuthConfigVerified: SelectorCreator<IAuthConfiguration, boolean> = (
    config: IAuthConfiguration
) => createSelector(
    getState,
    (state) => get(state.validAuthConfigs, objectHash(config), false)
)
export const isConfigConnectorConfigured: Selector<boolean> = createSelector(
    getStatus,
    (status) => status.configConnector ? isReady(status.configConnector) : false
)
export const isConfigured: Selector<boolean> = createSelector(
    [isAuthConfigured, isLetsEncryptConfigured, isConfigConnectorConfigured],
    (isAuthConfigured, isLetsEncryptConfigured, isConfigConnectorConfigured) => (
        isAuthConfigured && isLetsEncryptConfigured && isConfigConnectorConfigured
    )
)
