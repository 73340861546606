import { has, defaults, parseInt, toString, includes, isEmpty, isNumber, isDate } from 'lodash'
import { parseISO } from 'date-fns'

declare global {
    export interface Window {
        env: any
    }
}

type Config = {
    REACT_APP_API_URL: string
    REACT_APP_API_DEFAULT_TIMEOUT: number
    REACT_APP_BUILD_INFO: BuildInfo
    REACT_APP_DEBUG: string
    REACT_APP_DEFAULT_PAGE_TITLE: string
    REACT_APP_DASHBOARD_PREFIX: string
    REACT_APP_STACK_PREFIX: string
}

type BuildInfo = {
    BUILD_DATE: Date
    GIT_COMMIT: string
    GIT_TREE_STATE: string
    GIT_VERSION: string
}

const DEFAULT_ENV: Config = {
    REACT_APP_API_URL             : 'http://localhost:8080',
    REACT_APP_API_DEFAULT_TIMEOUT : 10000,
    REACT_APP_BUILD_INFO          : {
        BUILD_DATE     : new Date(0),
        GIT_COMMIT     : 'unknown',
        GIT_TREE_STATE : '',
        GIT_VERSION    : 'unreleased'
    },
    REACT_APP_DEBUG              : 'false',
    REACT_APP_DASHBOARD_PREFIX   : 'dashboard',
    REACT_APP_DEFAULT_PAGE_TITLE : 'Dashboard',
    REACT_APP_STACK_PREFIX       : 'stack'
}

function parseConfig(env: any): Config {
    const envWithDefaults = defaults(env, DEFAULT_ENV)
    return {
        ...envWithDefaults,
        REACT_APP_API_DEFAULT_TIMEOUT : parseNumber(envWithDefaults.REACT_APP_API_DEFAULT_TIMEOUT),
        REACT_APP_DEBUG               : parseBoolean(envWithDefaults.REACT_APP_DEBUG),
        REACT_APP_BUILD_INFO          : {
            ...envWithDefaults.REACT_APP_BUILD_INFO,
            BUILD_DATE: parseDate(envWithDefaults.REACT_APP_BUILD_INFO.BUILD_DATE)
        }
    }
}

const parseBoolean = (value: any) => includes(['1', 'true', 't', true], value)
const parseNumber = (value: any) => isNumber(value) ? value : parseInt(toString(value))
const parseDate = (value: any) => isDate(value) ? value : parseISO(toString(value))

const env: Config = has(global, 'window') && !isEmpty(window.env)
    ? parseConfig(window.env)
    : parseConfig(process.env)

export default env
