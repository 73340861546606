import * as React from 'react'
import { Tooltip, Intent, Position, IMenuItemProps } from '@blueprintjs/core'
import { Flex, Box } from 'reflexbox'

import { noop } from 'lodash'

import BaseMenuItem from '../components/MenuItem'
import Icon from '../components/Icon'
import ConfirmButton from '../components/ConfirmButton'
import Button from '../components/Button'

import { api, ui, navigation } from '../redux'
import { useTranslation } from '../hooks'
import { isAbsoluteURL } from '../utils'

import styles from './ResourceMenuItem.module.scss'

type Action = ui.Action | navigation.MenuEntry | api.Request
type Resource = ui.Resource | api.Resource
type Props = Partial<IMenuItemProps> & {
    action: Action
    resource: Resource
    isActive?: boolean
    isButton?: boolean
    onClick?: () => void
}

const ResourceMenuItem = (props: Props) => {
    const { action, resource, onClick } = props
    const [t] = useTranslation()

    if (action === api.Request.destroy) {
        return (
            <ConfirmButton
                onConfirm={ onClick || noop }
                confirmationText={ t(ui.createTitle(resource, ui.Action.confirm)) }
                button={
                    <MenuItem
                        { ...props }
                        intent={ Intent.DANGER }
                    />
                }
            />
        )
    }

    return navigation.isMenuEntryDisabled(action, resource)
        ? (
            <Tooltip
                content={ t('Coming soon!') }
                position={ Position.RIGHT }
            >
                <MenuItem disabled { ...props } />
            </Tooltip>
        ) : (
            <MenuItem { ...props } />
        )
}

type MenuItemProps = Props & {
    intent?: Intent
    disabled?: boolean
}

const MenuItem: React.FC<MenuItemProps> = (props) => {
    const { action, resource, isButton, isActive, intent, onClick, href } = props
    const [t] = useTranslation()

    if (isButton) {
        return (
            <Button
                onClick={ onClick }
                minimal
                intent={ intent || Intent.PRIMARY }
                icon={
                    <Icon
                        name={ ui.getIconNameForAction(action, resource) }
                        size={ 18 }
                    />
                }
            />
        )
    }

    const isExternal = href && isAbsoluteURL(href)
    const target = isExternal ? '_blank' : undefined

    return (
        <BaseMenuItem
            onClick={ onClick }
            href={ href }
            target={ target }
            className={ styles.menuItem }
            text={
                <Flex>
                    <Box>{ t(ui.createTitle(resource, action)) }</Box>
                    { isExternal && (
                        <Box ml={ 1 }>
                            <Tooltip content={ t('Opens in a new tab') }>
                                <Icon className={ styles.externalIcon } name="external-alt" />
                            </Tooltip>
                        </Box>
                    ) }
                </Flex>
            }
            active={ isActive }
            icon={
                <Icon
                    name={ ui.getIconNameForAction(action, resource) }
                    size={ 18 }
                />
            }
        />
    )
}

export default ResourceMenuItem
