import * as React from 'react'

import { get, has, isString } from 'lodash'

import { api, routing, nodes, ui } from '../redux'
import { useTranslation } from '../hooks'
import { Maybe } from '../utils'

import Avatar from '../components/Avatar'
import TitleBar, { ModifierProps } from '../components/TitleBar'
import ComponentStatusTag from '../components/ComponentStatusTag'

export type Props = {
    resource: api.Resource
    entry?: api.AnyResourceInstance | null
    rightItem?: React.ReactNode
    avatar?: React.ReactNode
    withAvatar?: boolean
    withTag?: boolean
    title?: Maybe<React.ReactNode>
    subtitle?: Maybe<React.ReactNode>
    minimal?: boolean
    onClick?: () => void
} & ModifierProps

const ResourceTitle: React.FC<Props> = (props) => {
    const { entry, resource, withAvatar, withTag, isInteractive, minimal } = props
    const [t] = useTranslation()

    const title = props.title || (api.isEntry(entry)
        ? api.displayName(entry)
        : t(ui.createTitle(resource, api.Request.create)))

    const link = isInteractive && api.isEntry(entry)
        ? routing.routeForResource(entry)
        : null

    const subtitle = api.isOfType(api.Resource.node, entry)
        ? props.subtitle || (entry ? nodes.capacityDescription(entry) : null)
        : null

    const hasStatus = has(entry, 'status.componentStatus')
    const tag = withTag && hasStatus ? (
        <ComponentStatusTag entry={ get(entry, 'status.componentStatus') } minimal={ minimal } />
    ) : null

    const avatar = props.avatar || (withAvatar && isString(title)
        ? <Avatar entry={ entry } imageURL={ ui.avatarURL(entry) } />
        : null)

    return (
        <TitleBar
            title={ title }
            subtitle={ subtitle }
            link={ link }
            tag={ tag }
            icon={ avatar }
            { ...props }
        />
    )
}

ResourceTitle.defaultProps = {
    withAvatar    : false,
    withTag       : true,
    isCompact     : false,
    isInteractive : false
}

export default ResourceTitle
