import { bitpoke } from '@bitpoke/bitpoke-proto'

const {
    Toleration
} = bitpoke.toleration.v1

const {
    Period
} = Toleration

export {
    Toleration,
    Period
}

export type Toleration  = bitpoke.toleration.v1.Toleration
export type IToleration = bitpoke.toleration.v1.IToleration

export enum Operator {
    exists = 'Exists',
    equal = 'Equal'
}

export enum Effect {
    noSchedule = 'NoSchedule',
    preferNoSchedule = 'PreferNoSchedule',
    noExecute = 'NoExecute',
    matchAll = ''
}

export const EMPTY_TOLERATION: IToleration = {
    key      : '',
    value    : '',
    operator : Operator.equal,
    effect   : Effect.noSchedule
}
