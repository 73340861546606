import * as React from 'react'
import { Helmet } from 'react-helmet'

import { join, compact, isEmpty } from 'lodash'

import { api, navigation } from '../redux'
import { Maybe } from '../utils'
import config from '../config'

export type Props = {
    entry?: api.AnyResourceInstance
    section?: Maybe<navigation.MenuEntry>
    tab?: Maybe<navigation.TabEntry>
}

const PageMeta: React.FC<Props> = (props) => {
    const { entry, section, tab } = props
    const segments = compact([
        entry ? api.displayName(entry) : null,
        section ? navigation.entryDisplayName(section) : null,
        tab ? navigation.entryDisplayName(tab) : null
    ])
    const title = !isEmpty(segments) ? join(segments, ' - ') : config.REACT_APP_DEFAULT_PAGE_TITLE

    return (
        <Helmet>
            <title>{ title }</title>
        </Helmet>
    )
}

export default PageMeta
